<section class="content-section-2" id="new-layout">
    <app-loader [loader]="loader"></app-loader>
    <app-breadcrumb [previousPage]="previousPage" [currentPage]="currentPage" [isCancel]="true" [isSave]="true"
        (backbtnClick)="Back()" (cancelbtnClick)="Cancel()" (savebtnClick)="Save()"></app-breadcrumb>

    <div class="content-section-1">
        <div class="container-fluid">
            <div class="main-section">
                <form [formGroup]="form">
                    <div class="parent">
                        <div class="partinfo border-backgroud">
                            <div class="div1 part-heading">
                                <span>Part Information</span>
                            </div>
                            <div class="div2">
                                <div class="">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Part Number</mat-label>
                                        <input matInput formControlName="partNo" type="text" placeholder="Part Number">
                                    </mat-form-field>
                                </div>
                                <div class="">
                                    <mat-form-field appearance="outline">
                                        <mat-label>GTIN/UPC</mat-label>
                                        <input matInput formControlName="gtinupc" type="text" placeholder="GTIN/UPC">
                                    </mat-form-field>
                                </div>
                                <div class="">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Inventory Type</mat-label>
                                        <mat-select formControlName="inventoryType" class="select-arrow-change-icon"
                                            placeholder="Inventory Type">
                                            <mat-option *ngFor="let item of invTypeList" [value]="item.typeId">
                                                {{item.typeValue}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Unit of Measure</mat-label>
                                        <mat-select formControlName="unitofMeasureId" class="select-arrow-change-icon"
                                            placeholder="Unit of Measure">
                                            <mat-option *ngFor="let x of uomList" [value]="x.id">
                                                {{x.uomValue}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Status</mat-label>
                                        <mat-select formControlName="isActive" class="select-arrow-change-icon"
                                            placeholder="Status">
                                            <mat-option *ngFor="let x of statusList" [value]="x.value">
                                                {{x.type}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="div3">
                                <div class="">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Part Description</mat-label>
                                        <input matInput formControlName="name" type="text"
                                            placeholder="Part Description">
                                    </mat-form-field>
                                </div>
                                <div class="">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Brand Name</mat-label>
                                        <input matInput formControlName="brandName" type="text"
                                            placeholder="Brand Name">
                                    </mat-form-field>
                                </div>
                                <div class="">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Part Category</mat-label>
                                        <mat-select [(value)]="categoryArray" class="select-arrow-change-icon"
                                            (selectionChange)="OnParentCatChange($event)" multiple
                                            disableOptionCentering>
                                            <ng-container *ngTemplateOutlet="
                                                  recursiveOptionsMulti;
                                                  context: { $implicit: programs, depth: 1 } ">
                                            </ng-container>

                                            <ng-template #recursiveOptionsMulti let-list let-depth="depth">
                                                <ng-container *ngFor="let program of list">
                                                    <mat-option [value]="program.value" [ngStyle]="{
                                                      'padding-left.px': depth <= 1 ? null : 16 * depth
                                                    }">
                                                        {{ program.text }}
                                                    </mat-option>

                                                    <ng-container
                                                        *ngIf="!!program.children && program.children.length > 0">
                                                        <ng-container *ngTemplateOutlet="
                                                        recursiveOptionsMulti;
                                                        context: { $implicit: program.children, depth: depth + 1 }
                                                      ">
                                                        </ng-container>
                                                    </ng-container>
                                                </ng-container>
                                            </ng-template>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="div4">
                                <div class="">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Purpose</mat-label>
                                        <mat-select formControlName="purpose" class="select-arrow-change-icon"
                                            placeholder="Purpose">
                                            <mat-option *ngFor="let x of purposeList" [value]="x.key">
                                                {{x.value}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Source of Part</mat-label>
                                        <mat-select formControlName="source" class="select-arrow-change-icon"
                                            placeholder="Source of Part">
                                            <mat-option *ngFor="let x of sourceList" [value]="x.key">
                                                {{x.value}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Tracking Type</mat-label>
                                        <mat-select formControlName="partTracking" class="select-arrow-change-icon"
                                            placeholder="Tracking Type">
                                            <mat-option *ngFor="let x of trackingList" [value]="x.id">
                                                {{x.name}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Package Type</mat-label>
                                        <mat-select formControlName="packageType" class="select-arrow-change-icon"
                                            placeholder="Package Type" [disabled]="true">
                                            <mat-option *ngFor="let x of pakageTypeList" [value]="x.key">
                                                {{x.value}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="vendorinfo border-backgroud">
                            <div class="div5 part-heading">
                                <span>
                                    Vendor Information
                                </span>
                                    <!-- {{form.value.vendorId > 0 ? form.value.vendorName : 'Vendor Name'}} -->

                                    <ng-container *ngFor="let tab of tabs.controls; let i = index">
                                        <span class="links border-bottom-blue ms-3">
                                            <a type="button" (click)="openTab(i)" class="mr-3">{{tab.value.label}}</a>
                                            <!-- <button mat-icon-button color="warn" (click)="removeTab(i)">x</button> -->
                                        </span>
                                    </ng-container>
                                <span class="links ms-3">
                                    <a type="button" (click)="addTab()">+ Add Vendor</a>
                                </span>
                            </div>
                            <div class="div6">
                                <!--<mat-tab-group [(selectedIndex)]="selectedIndex">
                                     <mat-tab *ngFor="let tab of tabs.controls; let i = index" [label]="tab.value.label">
                                    <mat-tab *ngFor="let tab of tabs.controls; let i = index">
                                        <div
                                            style="display: flex; justify-content: space-between; align-items: center;">
                                            <p>{{tab.value.content}}</p>
                                            <div class="div6left">
                                                <div class="left6">
            
                                                </div>
                                                <div class="left6a">
                                                    <div>
                                                        <mat-form-field appearance="outline">
                                                            <mat-label>Vendor Name</mat-label>
                                                            <input matInput placeholder="Search Vendors" [matAutocomplete]="auto"
                                                                (input)="onVendorInput($event.target.value)">
                                                            <mat-autocomplete #auto="matAutocomplete">
                                                                <mat-option *ngFor="let item of filteredVendors"
                                                                    [value]="item.vendorName" (click)="getVendor(item)">
                                                                    {{item.vendorName}}
                                                                </mat-option>
                                                            </mat-autocomplete>
                                                            <span class="material-icons">
                                                                search
                                                            </span>
                                                        </mat-form-field>
                                                    </div>
                                                    <div>
                                                        <mat-form-field appearance="outline">
                                                            <mat-label>Vendor List Price</mat-label>
                                                            <input matInput formControlName="vendorPartCost" type="text"
                                                                placeholder="Vendor List Price">
                                                            <span matTextPrefix>$&nbsp;</span>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                                <div class="left6b">
                                                    <div>
                                                        <mat-form-field appearance="outline">
                                                            <mat-label>Vendor Part Number/SKU</mat-label>
                                                            <input matInput formControlName="vendorPartNumber" type="text"
                                                                placeholder="Vendor Part Number/SKU">
                                                        </mat-form-field>
                                                    </div>
                                                    <div>
                                                        <mat-form-field appearance="outline">
                                                            <mat-label>Unit Cost</mat-label>
                                                            <input matInput type="text" placeholder="Unit Cost">
                                                            <span matTextPrefix>$&nbsp;</span>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                                <div class="left6c">
                                                    <div>
                                                        <mat-form-field appearance="outline">
                                                            <mat-label>Vendor Part Description</mat-label>
                                                            <input matInput formControlName="vendorPartName" type="text"
                                                                placeholder="Vendor Part Description">
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                                <div class="left6d">
                                                    <div>
                                                        <mat-form-field appearance="outline">
                                                            <mat-label>Lead Time</mat-label>
                                                            <input matInput formControlName="partLeadTime" type="text"
                                                                placeholder="Lead Time">
                                                            <mat-select formControlName="leadUnit" class="select-arrow-change-icon">
                                                                <mat-option [value]="0">Days</mat-option>
                                                                <mat-option [value]="1">Weeks</mat-option>
                                                                <mat-option [value]="2">Months</mat-option>
                                                                <mat-option [value]="3">Years</mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                    </div>
                                                    <div>
                                                        <mat-form-field appearance="outline">
                                                            <mat-label>Reorder Quantity</mat-label>
                                                            <input matInput formControlName="reorderAmount" type="text"
                                                                placeholder="Reorder Quantity">
                                                        </mat-form-field>
                                                    </div>
                                                    <div>
                                                        <mat-form-field appearance="outline">
                                                            <mat-label>Reorder Point</mat-label>
                                                            <input matInput formControlName="reorderQty" type="text"
                                                                placeholder="Reorder Point">
                                                        </mat-form-field>
                                                    </div>
                                                    <div>
                                                        <a type="button" (click)="openEOQCalculatorFlyout()">
                                                            <img src="../../../../../assets/image/CalculatorIcon.svg">
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="div6right">
                                                <div>
                                                    Volumne Discount Pricing
                                                </div>
                                                <div>
                                                    <div class="row top-heading">
                                                        <div class="col-2">
                                                            <span class="table-heading">Min Qty</span>
                                                        </div>
                                                        <div class="col-2">
                                                            <span class="table-heading">Max Qty</span>
                                                        </div>
                                                        <div class="col-2">
                                                            <span class="table-heading">Discount Type</span>
                                                        </div>
                                                        <div class="col-2">
                                                            <span class="table-heading">Discount Value</span>
                                                        </div>
                                                        <div class="col-2">
                                                            <span class="table-heading">Unit Cost</span>
                                                        </div>
                                                        <div class="col-2">
                                                            <span class="table-heading">&nbsp;</span>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="row" formArrayName="vendorDiscount"
                                                            *ngFor="let item of vendorDiscountArray.controls; let i = index;">
                                                            <ng-container [formGroupName]="i">
                                                                <div class="col-2">
                                                                    <mat-form-field>
                                                                        <input matInput type="text" formControlName="minQty">
                                                                    </mat-form-field>
                                                                </div>
                                                                <div class="col-2">
                                                                    <mat-form-field>
                                                                        <input matInput type="text" formControlName="qty">
                                                                    </mat-form-field>
                                                                </div>
                                                                <div class="col-2">
                                                                    <mat-form-field class="w-100 icon-change-input">
                                                                        <mat-select formControlName="discountType">
                                                                            <mat-option value="percentage">Percent
                                                                                Discount</mat-option>
                                                                            <mat-option value="flat">Fixed Amount
                                                                                Discount</mat-option>
                                                                            <mat-option value="fixed_price">Fixed Price for Item
                                                                            </mat-option>
                                                                        </mat-select>
                                                                    </mat-form-field>
                                                                </div>
                                                                <div class="col-2">
                                                                    <mat-form-field>
                                                                        <input matInput type="text" formControlName="discount">
                                                                    </mat-form-field>
                                                                </div>
                                                                <div class="col-2">
                                                                    <mat-form-field>
                                                                        <input matInput type="text" formControlName="vendorCost">
                                                                        <span matTextPrefix>$&nbsp;</span>
                                                                    </mat-form-field>
                                                                </div>
                                                                <div class="col-2">
                                                                    <a type="button" (click)="removeVendorDiscount(i)">
                                                                        <mat-icon>cancel</mat-icon>
                                                                    </a>
                                                                </div>
                                                            </ng-container>
                                                        </div>
                                                    </div>
                                                    <div class="text-start">
                                                        <span class="links ms-3" (click)="addNewVendorDiscount()">
                                                            + Add Row
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </mat-tab>
                                </mat-tab-group>-->
                                <div class="div6left">
                                    <div class="left6">

                                    </div>
                                    <div class="left6a">
                                        <div>
                                            <mat-form-field appearance="outline">
                                                <mat-label>Vendor Name</mat-label>
                                                <input matInput placeholder="Search Vendors" [matAutocomplete]="auto"
                                                    (input)="onVendorInput($event.target.value)">
                                                <mat-autocomplete #auto="matAutocomplete">
                                                    <mat-option *ngFor="let item of filteredVendors"
                                                        [value]="item.vendorName" (click)="getVendor(item)">
                                                        {{item.vendorName}}
                                                    </mat-option>
                                                </mat-autocomplete>
                                                <span class="material-icons">
                                                    search
                                                </span>
                                            </mat-form-field>
                                        </div>
                                        <div>
                                            <mat-form-field appearance="outline">
                                                <mat-label>Vendor List Price</mat-label>
                                                <input matInput formControlName="vendorPartCost" type="text"
                                                    placeholder="Vendor List Price">
                                                <span matTextPrefix>$&nbsp;</span>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="left6b">
                                        <div>
                                            <mat-form-field appearance="outline">
                                                <mat-label>Vendor Part Number/SKU</mat-label>
                                                <input matInput formControlName="vendorPartNumber" type="text"
                                                    placeholder="Vendor Part Number/SKU">
                                            </mat-form-field>
                                        </div>
                                        <div>
                                            <mat-form-field appearance="outline">
                                                <mat-label>Unit Cost</mat-label>
                                                <input matInput type="text" placeholder="Unit Cost">
                                                <span matTextPrefix>$&nbsp;</span>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="left6c">
                                        <div>
                                            <mat-form-field appearance="outline">
                                                <mat-label>Vendor Part Description</mat-label>
                                                <input matInput formControlName="vendorPartName" type="text"
                                                    placeholder="Vendor Part Description">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="left6d">
                                        <div>
                                            <mat-form-field appearance="outline">
                                                <mat-label>Lead Time</mat-label>
                                                <input matInput formControlName="partLeadTime" type="text"
                                                    placeholder="Lead Time">
                                                <mat-select formControlName="leadUnit" class="select-arrow-change-icon">
                                                    <mat-option [value]="0">Days</mat-option>
                                                    <mat-option [value]="1">Weeks</mat-option>
                                                    <mat-option [value]="2">Months</mat-option>
                                                    <mat-option [value]="3">Years</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div>
                                            <mat-form-field appearance="outline">
                                                <mat-label>Reorder Quantity</mat-label>
                                                <input matInput formControlName="reorderAmount" type="text"
                                                    placeholder="Reorder Quantity">
                                            </mat-form-field>
                                        </div>
                                        <div>
                                            <mat-form-field appearance="outline">
                                                <mat-label>Reorder Point</mat-label>
                                                <input matInput formControlName="reorderQty" type="text"
                                                    placeholder="Reorder Point">
                                            </mat-form-field>
                                        </div>
                                        <div>
                                            <a type="button" (click)="openEOQCalculatorFlyout()">
                                                <img src="../../../../../assets/image/CalculatorIcon.svg">
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="div6right">
                                    <div>
                                        Volumne Discount Pricing
                                    </div>
                                    <div>
                                        <div class="row top-heading">
                                            <div class="col-2">
                                                <span class="table-heading">Min Qty</span>
                                            </div>
                                            <div class="col-2">
                                                <span class="table-heading">Max Qty</span>
                                            </div>
                                            <div class="col-2">
                                                <span class="table-heading">Discount Type</span>
                                            </div>
                                            <div class="col-2">
                                                <span class="table-heading">Discount Value</span>
                                            </div>
                                            <div class="col-2">
                                                <span class="table-heading">Unit Cost</span>
                                            </div>
                                            <div class="col-2">
                                                <span class="table-heading">&nbsp;</span>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="row" formArrayName="vendorDiscount"
                                                *ngFor="let item of vendorDiscountArray.controls; let i = index;">
                                                <ng-container [formGroupName]="i">
                                                    <div class="col-2">
                                                        <mat-form-field>
                                                            <input matInput type="text" formControlName="minQty">
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="col-2">
                                                        <mat-form-field>
                                                            <input matInput type="text" formControlName="qty">
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="col-2">
                                                        <mat-form-field class="w-100 icon-change-input">
                                                            <mat-select formControlName="discountType">
                                                                <mat-option value="percentage">Percent
                                                                    Discount</mat-option>
                                                                <mat-option value="flat">Fixed Amount
                                                                    Discount</mat-option>
                                                                <mat-option value="fixed_price">Fixed Price for Item
                                                                </mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="col-2">
                                                        <mat-form-field>
                                                            <input matInput type="text" formControlName="discount">
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="col-2">
                                                        <mat-form-field>
                                                            <input matInput type="text" formControlName="vendorCost">
                                                            <span matTextPrefix>$&nbsp;</span>
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="col-2">
                                                        <a type="button" (click)="removeVendorDiscount(i)">
                                                            <mat-icon>cancel</mat-icon>
                                                        </a>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                        <div class="text-start">
                                            <span class="links ms-3" (click)="addNewVendorDiscount()">
                                                + Add Row
                                            </span>
                                        </div>
                                    </div>
                                </div> 
                            
                            </div>
                        </div>
                        <div class="pricinginfo border-backgroud">
                            <div>
                                <div class="part-heading">
                                    <span>
                                        Customer Pricing Information
                                    </span>
                                    <span class="ms-3">
                                        <mat-form-field appearance="outline">
                                            <mat-label>List Price</mat-label>
                                            <input matInput type="text" formControlName="cost">
                                            <span matTextPrefix>$&nbsp;</span>
                                        </mat-form-field>
                                    </span>
                                </div>
                                <div class="pricing">
                                    <div>
                                        <div>
                                            Volumne Pricing Discounts
                                        </div>
                                        <div>
                                            <div class="row top-heading">
                                                <div class="col-2">
                                                    <span class="table-heading">Min Qty</span>
                                                </div>
                                                <div class="col-2">
                                                    <span class="table-heading">Max Qty</span>
                                                </div>
                                                <div class="col-2">
                                                    <span class="table-heading">Discount Type</span>
                                                </div>
                                                <div class="col-2">
                                                    <span class="table-heading">Discount Value</span>
                                                </div>
                                                <div class="col-2">
                                                    <span class="table-heading">Unit Cost</span>
                                                </div>
                                                <div class="col-2">
                                                    <span class="table-heading">&nbsp;</span>
                                                </div>
                                            </div>
                                            <div>
                                                <div class="row" formArrayName="customerDiscount"
                                                    *ngFor="let item of volumePricingArray.controls; let i = index;">
                                                    <ng-container [formGroupName]="i">
                                                        <div class="col-2">
                                                            <mat-form-field>
                                                                <input matInput type="text" formControlName="minQty">
                                                            </mat-form-field>
                                                        </div>
                                                        <div class="col-2">
                                                            <mat-form-field>
                                                                <input matInput type="text" formControlName="qty">
                                                            </mat-form-field>
                                                        </div>
                                                        <div class="col-2">
                                                            <mat-form-field class="w-100 icon-change-input">
                                                                <mat-select formControlName="discountType">
                                                                    <mat-option value="percentage">Percent
                                                                        Discount</mat-option>
                                                                    <mat-option value="flat">Fixed Amount
                                                                        Discount</mat-option>
                                                                    <mat-option value="fixed_price">Fixed Price for Item
                                                                    </mat-option>
                                                                </mat-select>
                                                            </mat-form-field>
                                                        </div>
                                                        <div class="col-2">
                                                            <mat-form-field>
                                                                <input matInput type="text" formControlName="discount">
                                                            </mat-form-field>
                                                        </div>
                                                        <div class="col-2">
                                                            <mat-form-field>
                                                                <input matInput type="text"
                                                                    formControlName="customerCost">
                                                                <span matTextPrefix>$&nbsp;</span>
                                                            </mat-form-field>
                                                        </div>
                                                        <div class="col-2">
                                                            <a type="button" (click)="removeVolumnePricingDiscount(i)">
                                                                <mat-icon>cancel</mat-icon>
                                                            </a>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div>
                                            <div class="text-start">
                                                <span class="links ms-3" (click)="addNewVolumnePricingDiscount()">
                                                    + Add Row
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            Role Based Pricing
                                        </div>
                                        <div>
                                            <div class="row top-heading">
                                                <div class="col-2">
                                                    <span class="table-heading">Discount Type</span>
                                                </div>
                                                <div class="col-2">
                                                    <span class="table-heading">Discount Value</span>
                                                </div>
                                                <div class="col-2">
                                                    <span class="table-heading">Customer Type</span>
                                                </div>
                                                <div class="col-2">
                                                    <span class="table-heading">Customer Value</span>
                                                </div>
                                                <div class="col-2">
                                                    <span class="table-heading">Price</span>
                                                </div>
                                                <div class="col-2">
                                                    <span class="table-heading">&nbsp;</span>
                                                </div>
                                            </div>
                                            <div>
                                                <div class="row" formArrayName="roleBasePricingDiscount"
                                                    *ngFor="let item of roleDiscountArray.controls; let i = index;">
                                                    <ng-container [formGroupName]="i">
                                                        <div class="col-2">
                                                            <mat-form-field class="w-100 icon-change-input">
                                                                <mat-select formControlName="discountType">
                                                                    <mat-option value="1">Percent
                                                                        Discount</mat-option>
                                                                    <mat-option value="2">Fixed Amount
                                                                        Discount</mat-option>
                                                                    <mat-option value="3">Fixed Price for Item
                                                                    </mat-option>
                                                                </mat-select>
                                                            </mat-form-field>
                                                        </div>
                                                        <div class="col-2">
                                                            <mat-form-field>
                                                                <input matInput type="text"
                                                                    formControlName="discountValue">
                                                            </mat-form-field>
                                                        </div>
                                                        <div class="col-2">
                                                            <mat-form-field class="w-100 icon-change-input">
                                                                <mat-select formControlName="customerType">
                                                                    <mat-option value="">Select</mat-option>
                                                                    <mat-option value="1">User Role</mat-option>
                                                                </mat-select>
                                                            </mat-form-field>
                                                        </div>
                                                        <div class="col-2">
                                                            <mat-form-field class="w-100 icon-change-input">
                                                                <mat-select formControlName="customerValue">
                                                                    <mat-option *ngFor="let item of CustomerTypeList"
                                                                        [value]="item.id">
                                                                        {{item.customerType}}
                                                                    </mat-option>
                                                                </mat-select>
                                                            </mat-form-field>
                                                        </div>
                                                        <div class="col-2">
                                                            <mat-form-field>
                                                                <input matInput type="text" formControlName="cost">
                                                                <span matTextPrefix>$&nbsp;</span>
                                                            </mat-form-field>
                                                        </div>
                                                        <div class="col-2">
                                                            <a type="button"
                                                                (click)="removeRoleBasedPricingDiscount(i)">
                                                                <mat-icon>cancel</mat-icon>
                                                            </a>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div>
                                            <div class="text-start">
                                                <span class="links ms-3" (click)="addNewRoleBasedPricingDiscount()">
                                                    + Add Row
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="ecominfo border-backgroud" *ngIf="form.value.purpose == enumPurpose.ECommerece">
                            <div>
                                <div class="part-heading">
                                    <span>
                                        eCommerce Information
                                    </span>
                                </div>
                                <div class="ecom1">
                                    <div>
                                        <mat-form-field appearance="outline">
                                            <mat-label>Product Name</mat-label>
                                            <input matInput formControlName="name" type="text"
                                                placeholder="Product Name">
                                        </mat-form-field>
                                    </div>
                                    <div>
                                        <mat-form-field appearance="outline">
                                            <mat-label>eCommerce Status</mat-label>
                                            <mat-select formControlName="eCommerceStatus"
                                                class="select-arrow-change-icon" placeholder="eCommerce Status">
                                                <mat-option value=''>Not Exported</mat-option>
                                                <mat-option value='draft'>Draft</mat-option>
                                                <mat-option value='pending'>Pending Review</mat-option>
                                                <mat-option value='private'>Privately Published</mat-option>
                                                <mat-option value='publish'>Published</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div>
                                        <mat-form-field appearance="outline">
                                            <mat-label>Sync With Wordpress</mat-label>
                                            <mat-select formControlName="wpData" class="select-arrow-change-icon"
                                                placeholder="Sync With Wordpress">
                                                <mat-option value="1">Export § fields to WordPress</mat-option>
                                                <mat-option value="2">Do not Sync § fields</mat-option>
                                                <mat-option value="3">Import § fields from WordPress</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div>
                                        <mat-form-field appearance="outline">
                                            <mat-label>Wordpress ID</mat-label>
                                            <input matInput formControlName="wordpressId" type="text"
                                                placeholder="Wordpress ID">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="ecom2">
                                    <div>
                                        <label class="customLabel">Short
                                            Description</label><sup>§</sup>
                                        <editor apiKey="constants.EditorKeyTinyMCE" formControlName="shortDescription"
                                            [init]="{   
                                            height: 250,
                                            width: auto,
                                            menubar: false,
                                            plugins:'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
                                            toolbar:
                                            'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat'
                                        }"></editor>
                                    </div>
                                    <div>
                                        <label class="customLabel">Detail
                                            Description</label><sup>§</sup>
                                        <editor apiKey="constants.EditorKeyTinyMCE" formControlName="description"
                                            [init]="{
                                            height: 250,
                                            width: auto,
                                            menubar: false,
                                            plugins:'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
                                            toolbar:
                                            'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat'
                                        }"></editor>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="imageinfo border-backgroud">
                            <div>
                                <div class="part-heading flexandspacebw">
                                    <span>
                                        Product Images
                                    </span>
                                    <span>
                                        <div class="row align-items-center h-100 border-div">
                                            <div class="col-md-9 col-7">
                                                <div class="first-card-1" appFileUpload
                                                    (filesChangeEmiter)="onFileChange($event)">
                                                    <div class="dropzone">
                                                        <div class="text-wrapper">
                                                            <div class="centered">
                                                                <input type="file"
                                                                    class="choose-file-hidden upload_icon_custom"
                                                                    (change)="onFileChange($event)" accept="image/*"
                                                                    multiple>
                                                                <label for="file"
                                                                    class="drop-file-text mr-4 w-100 text-center mb-0">Drop&nbsp;files&nbsp;here&nbsp;to&nbsp;upload&nbsp;or</label>
                                                                <label for=""
                                                                    class="upload-btn">Select&nbsp;File</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </span>
                                </div>
                                <div class="imagesboxes">
                                    <div cdkDropList cdkDropListOrientation="horizontal" class="example-list"
                                        (cdkDropListDropped)="drop($event)">
                                        <!-- <div class="example-box" *ngFor="let movie of movies" cdkDrag>
                                            <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
                                            <div>
                                                <span>{{movie.title}}</span>
                                                <img [src]="movie.poster" [alt]="movie.title" style="height: 100px;width: auto;">
                                            </div>
                                        </div> -->
                                        <div class="example-box" *ngFor="let item of filesGet.value" cdkDrag>
                                            <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
                                            <div>
                                                <span>{{item.partImage}}</span>
                                                <img [src]="item.imagePath" [alt]="item.partImage"
                                                    style="height: 100px;width: auto;">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="partrp border-backgroud">
                            <div>
                                <div class="part-heading">
                                    <span>
                                        Attributes and Related Products
                                    </span>
                                    <span class="ms-3">

                                    </span>
                                </div>
                                <div class="partrpdiv">
                                    <!-- <div>
                                        <div>
                                            Volumne Pricing Discounts
                                        </div>
                                        <div>
                                            <div class="row top-heading">
                                                <div class="col-2">
                                                    <span class="table-heading">Min Qty</span>
                                                </div>
                                                <div class="col-2">
                                                    <span class="table-heading">Max Qty</span>
                                                </div>
                                                <div class="col-2">
                                                    <span class="table-heading">Discount Type</span>
                                                </div>
                                                <div class="col-2">
                                                    <span class="table-heading">Discount Value</span>
                                                </div>
                                                <div class="col-2">
                                                    <span class="table-heading">Unit Cost</span>
                                                </div>
                                                <div class="col-2">
                                                    <span class="table-heading">&nbsp;</span>
                                                </div>
                                            </div>
                                            <div>
                                                <div class="row" formArrayName="customerDiscount"
                                                    *ngFor="let item of volumePricingArray.controls; let i = index;">
                                                    <ng-container [formGroupName]="i">
                                                        <div class="col-2">
                                                            <mat-form-field appearance="outline">
                                                                <input matInput type="text" formControlName="minQty">
                                                            </mat-form-field>
                                                        </div>
                                                        <div class="col-2">
                                                            <mat-form-field appearance="outline">
                                                                <input matInput type="text" formControlName="qty">
                                                            </mat-form-field>
                                                        </div>
                                                        <div class="col-2">
                                                            <mat-form-field class="w-100 icon-change-input"
                                                                appearance="outline">
                                                                <mat-select formControlName="discountType">
                                                                    <mat-option value="percentage">Percent
                                                                        Discount</mat-option>
                                                                    <mat-option value="flat">Fixed Amount
                                                                        Discount</mat-option>
                                                                    <mat-option value="fixed_price">Fixed Price for Item
                                                                    </mat-option>
                                                                </mat-select>
                                                            </mat-form-field>
                                                        </div>
                                                        <div class="col-2">
                                                            <mat-form-field appearance="outline">
                                                                <input matInput type="text" formControlName="discount">
                                                            </mat-form-field>
                                                        </div>
                                                        <div class="col-2">
                                                            <mat-form-field appearance="outline">
                                                                <input matInput type="text"
                                                                    formControlName="customerCost">
                                                            </mat-form-field>
                                                        </div>
                                                        <div class="col-2">
                                                            <mat-icon>
                                                                <mat-icon>cancel</mat-icon>
                                                            </mat-icon>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div>
                                            <div class="text-start">
                                                <span class="links ms-3">
                                                    + Add Row
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            Role Based Pricing
                                        </div>
                                        <div>
                                            <div class="row top-heading">
                                                <div class="col-2">
                                                    <span class="table-heading">Min Qty</span>
                                                </div>
                                                <div class="col-2">
                                                    <span class="table-heading">Max Qty</span>
                                                </div>
                                                <div class="col-2">
                                                    <span class="table-heading">Discount Type</span>
                                                </div>
                                                <div class="col-2">
                                                    <span class="table-heading">Discount Value</span>
                                                </div>
                                                <div class="col-2">
                                                    <span class="table-heading">Unit Cost</span>
                                                </div>
                                                <div class="col-2">
                                                    <span class="table-heading">&nbsp;</span>
                                                </div>
                                            </div>
                                            <div>
                                                <div class="row" formArrayName="roleBasePricingDiscount"
                                                    *ngFor="let item of roleDiscountArray.controls; let i = index;">
                                                    <ng-container [formGroupName]="i">
                                                        <div class="col-2">
                                                            <mat-form-field class="w-100 icon-change-input"
                                                                appearance="outline">
                                                                <mat-select formControlName="discountType">
                                                                    <mat-option value="percentage">Percent
                                                                        Discount</mat-option>
                                                                    <mat-option value="flat">Fixed Amount
                                                                        Discount</mat-option>
                                                                    <mat-option value="fixed_price">Fixed Price for Item
                                                                    </mat-option>
                                                                </mat-select>
                                                            </mat-form-field>
                                                        </div>
                                                        <div class="col-2">
                                                            <mat-form-field appearance="outline">
                                                                <input matInput type="text"
                                                                    formControlName="discountValue">
                                                            </mat-form-field>
                                                        </div>
                                                        <div class="col-2">
                                                            <div class="col-2">
                                                                <mat-form-field class="w-100 icon-change-input"
                                                                    appearance="outline">
                                                                    <mat-select formControlName="discountType">
                                                                        <mat-option value="percentage">Percent
                                                                            Discount</mat-option>
                                                                        <mat-option value="flat">Fixed Amount
                                                                            Discount</mat-option>
                                                                        <mat-option value="fixed_price">Fixed Price for
                                                                            Item
                                                                        </mat-option>
                                                                    </mat-select>
                                                                </mat-form-field>
                                                            </div>
                                                        </div>
                                                        <div class="col-2">
                                                            <mat-form-field appearance="outline">
                                                                <input matInput type="text"
                                                                    formControlName="customerValue">
                                                            </mat-form-field>
                                                        </div>


                                                        <div class="col-2">
                                                            <mat-form-field appearance="outline">
                                                                <input matInput type="text" formControlName="cost">
                                                            </mat-form-field>
                                                        </div>
                                                        <div class="col-2">
                                                            <mat-icon>
                                                                <mat-icon>cancel</mat-icon>
                                                            </mat-icon>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div>
                                            <div class="text-start">
                                                <span class="links ms-3">
                                                    + Add Row
                                                </span>
                                            </div>
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                        <div class="partspec border-backgroud">
                            <div>
                                <div class="part-heading">
                                    <span>
                                        Part Specifications
                                    </span>
                                    <span class="ms-3">

                                    </span>
                                </div>
                                <div class="partspecdiv">
                                    <!-- <div>
                                        <div>
                                            Volumne Pricing Discounts
                                        </div>
                                        <div>
                                            <div class="row top-heading">
                                                <div class="col-2">
                                                    <span class="table-heading">Min Qty</span>
                                                </div>
                                                <div class="col-2">
                                                    <span class="table-heading">Max Qty</span>
                                                </div>
                                                <div class="col-2">
                                                    <span class="table-heading">Discount Type</span>
                                                </div>
                                                <div class="col-2">
                                                    <span class="table-heading">Discount Value</span>
                                                </div>
                                                <div class="col-2">
                                                    <span class="table-heading">Unit Cost</span>
                                                </div>
                                                <div class="col-2">
                                                    <span class="table-heading">&nbsp;</span>
                                                </div>
                                            </div>
                                            <div>
                                                <div class="row" formArrayName="customerDiscount"
                                                    *ngFor="let item of volumePricingArray.controls; let i = index;">
                                                    <ng-container [formGroupName]="i">
                                                        <div class="col-2">
                                                            <mat-form-field appearance="outline">
                                                                <input matInput type="text" formControlName="minQty">
                                                            </mat-form-field>
                                                        </div>
                                                        <div class="col-2">
                                                            <mat-form-field appearance="outline">
                                                                <input matInput type="text" formControlName="qty">
                                                            </mat-form-field>
                                                        </div>
                                                        <div class="col-2">
                                                            <mat-form-field class="w-100 icon-change-input"
                                                                appearance="outline">
                                                                <mat-select formControlName="discountType">
                                                                    <mat-option value="percentage">Percent
                                                                        Discount</mat-option>
                                                                    <mat-option value="flat">Fixed Amount
                                                                        Discount</mat-option>
                                                                    <mat-option value="fixed_price">Fixed Price for Item
                                                                    </mat-option>
                                                                </mat-select>
                                                            </mat-form-field>
                                                        </div>
                                                        <div class="col-2">
                                                            <mat-form-field appearance="outline">
                                                                <input matInput type="text" formControlName="discount">
                                                            </mat-form-field>
                                                        </div>
                                                        <div class="col-2">
                                                            <mat-form-field appearance="outline">
                                                                <input matInput type="text"
                                                                    formControlName="customerCost">
                                                            </mat-form-field>
                                                        </div>
                                                        <div class="col-2">
                                                            <mat-icon>
                                                                <mat-icon>cancel</mat-icon>
                                                            </mat-icon>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div>
                                            <div class="text-start">
                                                <span class="links ms-3">
                                                    + Add Row
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            Role Based Pricing
                                        </div>
                                        <div>
                                            <div class="row top-heading">
                                                <div class="col-2">
                                                    <span class="table-heading">Min Qty</span>
                                                </div>
                                                <div class="col-2">
                                                    <span class="table-heading">Max Qty</span>
                                                </div>
                                                <div class="col-2">
                                                    <span class="table-heading">Discount Type</span>
                                                </div>
                                                <div class="col-2">
                                                    <span class="table-heading">Discount Value</span>
                                                </div>
                                                <div class="col-2">
                                                    <span class="table-heading">Unit Cost</span>
                                                </div>
                                                <div class="col-2">
                                                    <span class="table-heading">&nbsp;</span>
                                                </div>
                                            </div>
                                            <div>
                                                <div class="row" formArrayName="roleBasePricingDiscount"
                                                    *ngFor="let item of roleDiscountArray.controls; let i = index;">
                                                    <ng-container [formGroupName]="i">
                                                        <div class="col-2">
                                                            <mat-form-field class="w-100 icon-change-input"
                                                                appearance="outline">
                                                                <mat-select formControlName="discountType">
                                                                    <mat-option value="percentage">Percent
                                                                        Discount</mat-option>
                                                                    <mat-option value="flat">Fixed Amount
                                                                        Discount</mat-option>
                                                                    <mat-option value="fixed_price">Fixed Price for Item
                                                                    </mat-option>
                                                                </mat-select>
                                                            </mat-form-field>
                                                        </div>
                                                        <div class="col-2">
                                                            <mat-form-field appearance="outline">
                                                                <input matInput type="text"
                                                                    formControlName="discountValue">
                                                            </mat-form-field>
                                                        </div>
                                                        <div class="col-2">
                                                            <div class="col-2">
                                                                <mat-form-field class="w-100 icon-change-input"
                                                                    appearance="outline">
                                                                    <mat-select formControlName="discountType">
                                                                        <mat-option value="percentage">Percent
                                                                            Discount</mat-option>
                                                                        <mat-option value="flat">Fixed Amount
                                                                            Discount</mat-option>
                                                                        <mat-option value="fixed_price">Fixed Price for
                                                                            Item
                                                                        </mat-option>
                                                                    </mat-select>
                                                                </mat-form-field>
                                                            </div>
                                                        </div>
                                                        <div class="col-2">
                                                            <mat-form-field appearance="outline">
                                                                <input matInput type="text"
                                                                    formControlName="customerValue">
                                                            </mat-form-field>
                                                        </div>


                                                        <div class="col-2">
                                                            <mat-form-field appearance="outline">
                                                                <input matInput type="text" formControlName="cost">
                                                            </mat-form-field>
                                                        </div>
                                                        <div class="col-2">
                                                            <mat-icon>
                                                                <mat-icon>cancel</mat-icon>
                                                            </mat-icon>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div>
                                            <div class="text-start">
                                                <span class="links ms-3">
                                                    + Add Row
                                                </span>
                                            </div>
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                        <div class="questions border-backgroud">
                            <div>
                                <div class="part-heading">
                                    <span>
                                        Sales Questions
                                    </span>
                                </div>
                                <div class="questionsdiv">
                                    <div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

                <div class="overcstfixedsidebar active" *ngIf="showEOQCalculatorFlyout"></div>
                <div class="sidebar_trackingnum active card-bg" *ngIf="showEOQCalculatorFlyout">
                    <app-eoq-calculator (closeEvent)="closeEOQCalculatorFlyout($event)"></app-eoq-calculator>
                </div>

            </div>
        </div>
    </div>
</section>