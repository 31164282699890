import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PartCategoryService {
  baseUrl = environment.baseUrl;

  constructor(private _http: HttpClient) { }

  GetCategoryList(req: any) {
    return this._http.post(this.baseUrl + 'api/Category/GetCategoryList', req,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetCategoryById(companyId, Id: number) {
    return this._http.get(this.baseUrl + 'api/Category/GetCategoryList?companyId=' + companyId + '&Id=' + Id,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetPurpose() {
    return this._http.get(this.baseUrl + 'api/Category/GetPurpose',
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetCategoryType() {
    return this._http.get(this.baseUrl + 'api/Category/GetCategoryType',
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetPlateformType() {
    return this._http.get(this.baseUrl + 'api/Category/GetPlateformType',
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  //Error Handler Method
  errorHandler(error: HttpErrorResponse) {
    return throwError(error.message || "server error.");
  }
}
