import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ConstantsService } from '../constants.service';
import { SharedService } from '../services/shared.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SelectionModel } from '@angular/cdk/collections';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { GlobalChangeService } from '../services/global-change.service';
import { PartService } from '../services/part/part.service';
import { VendorService } from '../services/vendor/vendor.service';
import { CustomerService } from '../services/customer/customer.service';
import { error } from 'console';
import { InventoryService } from '../services/inventory/inventory.service';

export interface WordressPartData {
  id: number;
  sku: string;
  name: string;
  ecomm: boolean;
  active: boolean;
  inv: boolean;
  LastModified: string;
  LastSyncd: string;
  LastWPModified: string;
  exist_erp: boolean;
}

@Component({
  selector: 'app-utilities',
  templateUrl: './utilities.component.html',
  styleUrls: ['./utilities.component.css']
})
export class UtilitiesComponent implements OnInit {
  data: any;
  fileToUpload: File;
  imageUrl: any;
  companyList: any;
  userGroupObjects = new Array();
  value: number;
  companyId: string;
  validation = false;
  summaryRecords: any;
  summarySuccesfull: any;
  summaryUnsuccesfull: any;
  sectionType = 0;
  isSummarySuccessRecordShow = false;
  isSummaryUnSuccessRecordShow = false;
  summaryUpdateSuccesfull: any;
  loader: boolean = false;
  errorMsg: any;
  errorRow = [];
  downloadFileUrl: any;
  downloadSampleName: any;
  displayedColumns: string[] = ['select', 'sku', 'name', 'exist_erp', 'ecomm', 'active', 'inv', 'LastModified', 'LastSyncd', 'LastWPModified'];
  dataSource: MatTableDataSource<WordressPartData>;
  selection = new SelectionModel<WordressPartData>(true, []);
  form: FormGroup;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  counter: number;
  wppartlist: any;
  selectedCompany: number;
  OnSyncChangesType: any;
  loadingTxt: string= "Analyzing…";

  constructor(
    private constantsService: ConstantsService,
    public sharedService: SharedService,
    private partService: PartService,
    private inventoryService: InventoryService,
    private vendorService: VendorService,
    private customerService: CustomerService,
    private router: Router,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private globalChangeService: GlobalChangeService,
  ) { }

  ngOnInit(): void {
    if (localStorage.getItem('AuthHeader') === null || localStorage.getItem('AuthHeader') === undefined) {
      this.router.navigate(['']);

    }
    this.companyId = localStorage.getItem('companyId');
    this.errorMsg = "";

    this.form = this.fb.group({
      page: ['1', [Validators.required, Validators.pattern('[0-9 ]+')]],
      per_page: ['10', [Validators.required, Validators.pattern('[0-9 ]+'), Validators.max(100), Validators.min(10)]],
      action: ['1', [Validators.required]]
    });

    var company = this.globalChangeService.getGlobalCompany();
    if (company > 0) {
      this.value = company;
      this.selectedCompany = company;
      if (this.form.value.action == '1') {
        this.GetWordpressProducts(this.selectedCompany, Number(this.form.value.page), Number(this.form.value.per_page));
      } else {
        this.PopupModal(this.constantsService.ValidationError, this.constantsService.ValidationErrorMsg);
      }
    }
    else {
      this.PopupModal(this.constantsService.Error, this.constantsService.ValidationCompanyMsg);
    }

    //on change function
    this.globalChangeService.dropdownChange$.subscribe((data) => {
      this.onValueChange(data);
    })

  }

  ChngSettingImage(files: FileList) {
    this.loader = true;
    this.fileToUpload = files.item(0);
    var reader = new FileReader()
    reader.onload = (event: any) => {
      this.imageUrl = event.target.result;
    }
    reader.readAsDataURL(this.fileToUpload);
    this.loader = false;
  }

  uploadFiles() {
    this.validation = true;
    this.loader = true;
    if (this.value == 0
      || this.value == undefined
      || this.sectionType == 0
      || this.sectionType == undefined) {
      this.loader = false;
      return
    }
    this.errorMsg = "";
    let file = new FormData();
    file.append('file', this.fileToUpload);
    this.sectionType = Number(this.sectionType);

    if (this.value > 0) {
      switch (this.sectionType) {
        case 1:
          this.vendorService.uploadVendor(file, this.value)
            .subscribe(
              data => {
                this.loader = false;
                this.data = data;
                this.downloadSampleName = '';
                this.summaryRecords = this.data.dataList;
                switch (data.statusCode) {
                  case 200:
                    $("#add_update").show();
                    // this.toastr.success(this.constants.RecordUpdateSuccessfully);
                    this.summarySuccesfull = '';
                    this.summaryUnsuccesfull = '';
                    this.UploadSummary();
                    this.Openmodalpopup();
                    break;
                  case 400:
                    $("#wrong_msg").show();
                    // this.toastr.error(this.constants.SomethingWentWrong);
                    break;
                  case 204:
                    (<HTMLInputElement>document.getElementById('file')).value = null;
                    this.fileToUpload = null;
                    this.errorMsg = this.data.errorMessage;
                    break;
                  default:
                    $("#wrong_msg").show();
                  // this.toastr.error(this.constants.SomethingWentWrong);
                }
              },
              error =>{
                this.loader = false;
                this.PopupModal(this.constantsService.APIError, this.constantsService.SomethingWentWrong)
              });
          break;
        case 2:
          this.customerService.uploadCustomer(file, this.value)
            .subscribe(
              data => {
                this.loader = false;
                this.data = data;
                this.summaryRecords = this.data.dataList;
                this.downloadSampleName = '';
                switch (data.statusCode) {
                  case 200:
                    $("#add_update").show();
                    // this.toastr.success(this.constants.RecordUpdateSuccessfully);
                    this.UploadSummary();
                    this.Openmodalpopup();
                    break;
                  case 400:
                    $("#wrong_msg").show();
                    // this.toastr.error(this.constants.SomethingWentWrong);
                    break;
                  case 204:
                    (<HTMLInputElement>document.getElementById('file')).value = null;
                    this.fileToUpload = null;
                    this.errorMsg = this.data.errorMessage;
                    break;
                  default:
                    $("#wrong_msg").show();
                  // this.toastr.error(this.constants.SomethingWentWrong);
                }
              },
              error =>{
                this.loader = false;
                this.PopupModal(this.constantsService.APIError, this.constantsService.SomethingWentWrong)
              });
          break;
        case 3:
          this.partService.UploadPart(file, this.value)
            .subscribe(
              data => {
                this.loader = false;
                this.data = data;
                this.summaryRecords = this.data.dataList;
                this.downloadSampleName = '';
                this.UploadPartSummary();
                this.Openmodalpopup();
              },
            error =>{
              this.loader = false;
              this.PopupModal(this.constantsService.APIError, this.constantsService.SomethingWentWrong)
            });
          break;
        case 4:
          this.inventoryService.UploadInventory(file, this.value)
            .subscribe(
              data => {
                this.loader = false;
                this.data = data;
                this.summaryRecords = this.data.dataList;
                this.downloadSampleName = '';
                this.UploadSummary();
                this.Openmodalpopup();
              },
            error =>{
              this.loader = false;
              this.PopupModal(this.constantsService.APIError, this.constantsService.SomethingWentWrong)
            });
          break;
        default:
          this.loader = false;
      }
    } else {
      this.loader = false;
      this.PopupModal(this.constantsService.ValidationError, this.constantsService.ValidationCompanyMsg);
    }

  }
  clearData() {

  }
  UploadSummary() {
    $("#file").val('');
    (<HTMLInputElement>document.getElementById('file')).value = "";
    this.summaryUnsuccesfull = this.summaryRecords.filter(x => x.status == false);
    this.summarySuccesfull = this.summaryRecords.filter(x => x.isInserted != false);
    this.summaryUpdateSuccesfull = this.summaryRecords.filter(x => x.isUpdated != false);
  }
  UploadPartSummary() {
    $("#file").val('');
    (<HTMLInputElement>document.getElementById('file')).value = "";
    this.summaryUnsuccesfull = this.summaryRecords.filter(x => x.status == false);
    this.summarySuccesfull = this.summaryRecords.filter(x => x.isInserted == true);
    this.summaryUpdateSuccesfull = this.summaryRecords.filter(x => x.isUpdated == true);
  }

  onValueChange(value: any): void {
    this.value = value;
    this.selectedCompany = this.value;
    //ankit implementaion
    if (this.form.value.action == '1') {
      this.GetWordpressProducts(this.selectedCompany, Number(this.form.value.page), Number(this.form.value.per_page));
    } else {
      this.PopupModal(this.constantsService.ValidationError, this.constantsService.ValidationErrorMsg);
    }
  }

  changetype(event) {

    this.sectionType = event.value
    //this.sectionType = event.target.value
    if (this.sectionType == 1) {
      this.downloadSampleName = "Vendor Sample";
      this.downloadFileUrl = "./assets/Sample_Vendor.xlsx";
    }
    else if (this.sectionType == 2) {
      this.downloadSampleName = "Customer Sample";
      this.downloadFileUrl = "./assets/Sample_Customer.xlsx";
    }
    else if (this.sectionType == 3) {
      this.downloadSampleName = "Part Sample";
      this.downloadFileUrl = "./assets/Sample_Part.xlsx";
    }
    else if (this.sectionType == 4) {
      this.downloadSampleName = "Inventory Sample";
      this.downloadFileUrl = "./assets/Sample_Inventory.xlsx";
    }
    else {
      this.downloadSampleName = "";
      this.downloadFileUrl = "";
    }
  }

  Openmodalpopup() {
    $("#myModal").show();
    // this.modalService.open(content, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
    // this.deletedItem = item;
  }

  CloseModalpopup() {
    $("#myModal").hide();
    this.sectionType = 0;
    this.summaryRecords = '';
    this.validation = false;
    this.isSummaryUnSuccessRecordShow = false;

  }

  PreviewDetails() {
    this.isSummarySuccessRecordShow = true;
  }
  Preview1Details() {
    this.errorRow = [];
    var obj = {};
    this.summaryRecords = this.summaryRecords.filter(x => x.status == false);
    this.isSummaryUnSuccessRecordShow = true;
    $("#myModal2").show();
  }

  CloseModalpopup2() {
    $("#myModal2").hide();
  }
  addsuccess() {
    $("#add_success").hide();
  }
  updateitem() {
    $("#add_update").hide();
  }
  deleteitem() {
    $("#delete_success").hide();
  }
  msgitem() {
    $("#wrong_msg").hide();
  }

  onSubmit(page) {
    if (this.selectedCompany != null) {
      if (this.form.value.action == '1') {
        this.GetWordpressProducts(this.selectedCompany, page, Number(this.form.value.per_page));
      } else {
        this.PopupModal(this.constantsService.ValidationError, this.constantsService.ValidationErrorMsg);
      }
    } else {
      this.PopupModal(this.constantsService.ValidationError, this.constantsService.ValidationCompanyMsg);
    }
  }

  rewind_page() {
    if (this.form.value.action == '1') {
      var page = Number(this.form.value.page) - 1;
      if (page <= 0) {
        page = 1;
      }
      this.form.value.page = page;
      this.onSubmit(page);
    } else {
      this.PopupModal(this.constantsService.ValidationError, this.constantsService.ValidationErrorMsg);
    }
  }

  forward_page() {
    if (this.form.value.action == '1') {
      var page = Number(this.form.value.page) + 1;
      this.form.value.page = page;
      this.onSubmit(page);
    } else {
      this.PopupModal(this.constantsService.ValidationError, this.constantsService.ValidationErrorMsg);
    }
  }

  GetWordpressProducts(companyid: number, page: number, per_page: number) {
    if (!this.loader) {
      this.loader = true;
    }
    this.partService.GetWordpressProducts(companyid, page, per_page).subscribe(
      (data: any) => {
        this.loader = false;
        if (data != null) {
          if (data.eRPs != null) {
            this.wppartlist = data;
            this.selection.selected.forEach(element => {
              this.wppartlist.eRPs.filter(x => x.id == element.id)[0].lastSyncd = this.wppartlist.eRPs.filter(x => x.id == element.id)[0].lastWPModified;
              this.wppartlist.eRPs.filter(x => x.id == element.id)[0].lastModified = this.wppartlist.eRPs.filter(x => x.id == element.id)[0].lastWPModified;
            });
            this.selection.deselect();
            this.dataSource = new MatTableDataSource(this.wppartlist.eRPs);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            //window.location.reload();
          } else {
            this.dataSource = new MatTableDataSource<WordressPartData>();
          }
        } else {
          this.dataSource = new MatTableDataSource<WordressPartData>();
        }
      }, (err) => {
        this.loader = false;
      });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  isAllPartsSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllPartsSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  removeDuplicates(myArray, Prop) {
    return myArray.filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj[Prop]).indexOf(obj[Prop]) === pos;
    });
  }
  OnSyncClick() {
    this.loader = true;
    var arr = [];
    this.selection.selected.forEach(s => {
      if (this.wppartlist.wpAllProductsResponse != null) {
        const singlepartarray = this.wppartlist.wpAllProductsResponse.filter(x => x.id == s.id);
        arr.push(...singlepartarray);
      }
    });
    let ar2 = [];
    ar2 = this.removeDuplicates(arr, "id");
    // if (this.OnSyncChangesType == 2) {
    //   arr[0].meta_data = arr[0].meta_data.filter(x => !(x.key.startsWith('_specification_section_') || x.value == ''));
    // }
    this.partService.AddWPProductstoERP(ar2, this.selectedCompany).subscribe(
      (data: any) => {
        this.loader = false;
        this.selection.clear();
        //this.PopupModal(this.constantsService.Success, this.constantsService.SyncSuccessMsg);
        if(data.data!=null && data.data!=undefined){
          if(data.data.responseCode == 1){
            let messages: string[] = [];
            if(data.data.addedSyncParts.length>0){
              messages.push(`<p>Added Parts</p><ul>`);
              data.data.addedSyncParts.forEach(msg1 => {
                messages.push(`<li style="list-style-type: circle;">SKU:`+msg1.sku+`, WPID: `+msg1.wordpressId+`</li>`);
              });
              messages.push(`</ul>`);
            }
            if(data.data.updatedSyncParts.length>0){
              messages.push(`<p>Updated Parts</p><ul>`);
              data.data.updatedSyncParts.forEach(msg1 => {
                messages.push(`<li style="list-style-type: circle;">SKU:`+msg1.sku+`, WPID: `+msg1.wordpressId+`</li>`);
              });
              messages.push(`</ul>`);
            }
            if(data.data.notSyncParts.length>0){
              messages.push(`<p>Non-Synced Parts</p><ul>`);
              data.data.notSyncParts.forEach(msg1 => {
                messages.push(`<li style="list-style-type: circle;">SKU:`+msg1.sku+`, WPID: `+msg1.wordpressId+` (` + msg1.msg + `)</li>`);
              });
              messages.push(`</ul>`);
            }
            var message = messages.length > 0 ? messages.join('') : '';
            Swal.fire({
              title: this.constantsService.Success,
              html: this.constantsService.SyncSuccessMsg,
              showCancelButton: true,
              confirmButtonText: 'Show Report'
            }).then((result) => {
              if (result.value) {
                Swal.fire({
                  title: "Sync Report",
                  html: message,
                  showCancelButton: false,
                  confirmButtonText: 'Close'
                })
              }
            });
          }
        }
        this.GetWordpressProducts(this.selectedCompany, Number(this.form.value.page), Number(this.form.value.per_page));
      }, (err) => {
        this.loader = false;
        this.PopupModal(this.constantsService.APIError, this.constantsService.APIErrorMsg);
      });

  }
  OnSyncChanges(event: any) {
    this.OnSyncChangesType = event.value;
  }
  PopupModal(title: string, message: string) {
    Swal.fire({
      title: title,
      text: message,
      showCancelButton: true,
      cancelButtonText: 'Close',
    })
  }

  ReloadData(){
    if (this.form.value.action == '1') {
      this.GetWordpressProducts(this.selectedCompany, Number(this.form.value.page), Number(this.form.value.per_page));
    } else {
      this.PopupModal(this.constantsService.ValidationError, this.constantsService.ValidationErrorMsg);
    }
  }

}
