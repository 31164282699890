import { Injectable } from '@angular/core';
import { Observable, throwError, of, BehaviorSubject, Subject } from 'rxjs';
import { tap, delay, catchError, shareReplay } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { TitleCasePipe } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  baseUrl = environment.baseUrl;
  //  IntegrationbaseUrl = environment.IntegrationbaseUrl
  IntegrationbaseUrl = environment.baseUrl;
  orderDetails: any = null;
  orderDetailsdeDenormalize: any = null;
  ExitDetails: any = null;
  backdata: any = null;
  sideBar: any;
  Receive: any;
  shipping: any;
  invetory: any;
  vendor: any;
  company: any;
  // public notify = new BehaviorSubject<any>('');
  // notifyObservable = this.notify.asObservable();
  public isChangeValue: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isChangeDbValue: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public purchaseLoadedComponent: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public IndividualpartLoadedComponent: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public custompartLoadedComponent: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public VendorLoadedComponent: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public CustomerLoadedComponent: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private categoryDropDownListCache$: Observable<any[]> | null = null;
  // private customer = new BehaviorSubject<string>('');
  // castUser = this.customer.asObservable();

  constructor(
    private _http: HttpClient,
    public titlecasePipe: TitleCasePipe,
    private _sanitizer: DomSanitizer,
  ) {
  }

  setOrderDetails(orderDetails) {
    this.orderDetails = orderDetails;
  }
  setExitDetails(exitbackdata) {
    this.ExitDetails = exitbackdata;
  }
  getExitDetails() {
    return this.ExitDetails;
  }
  setBackDetails(backdata) {
    this.backdata = backdata;
  }

  getBackDetails() {
    return this.backdata;
  }

  getOrderDetailsDenormalize() {
    return this.orderDetailsdeDenormalize;
  }

  setSideBar(sideBar) {
    this.sideBar = sideBar;
  }

  getSideBar() {
    return this.sideBar;
  }

  FormatAddress(add1, add2, city, state, zip) {
    let address = '';
    if (add1) {
      address += this.titlecasePipe.transform(add1) + "<br/>";
    }
    if (add2) {
      address += this.titlecasePipe.transform(add2) + "<br/>";
    }
    if (city) {
      address += this.titlecasePipe.transform(city);
    }
    if (state) {
      address += ',&nbsp;' + this.titlecasePipe.transform(state).toUpperCase();
    }
    if (zip) {
      address += "&nbsp;&nbsp;" + this.titlecasePipe.transform(zip);
    }

    return this._sanitizer.bypassSecurityTrustHtml(address);
  }

  getAllState(countryId) {
    return this._http.get(this.baseUrl + 'api/State/GetAllState?countryId=' + countryId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  getAllCountry() {
    return this._http.get(this.baseUrl + 'api/Country/GetAllCountry',
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  getAllUserFunction() {
    return this._http.get(this.baseUrl + 'api/UserFunction/GetAllUserFunction',
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  getAllUserRoles() {
    return this._http.get(this.baseUrl + 'api/Role/GetAllRoles',
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  addNewUserRoles(item: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/Role/AddRole', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  updateUserroles(item: any): Observable<any> {
    return this._http.put(this.baseUrl + 'api/Role/UpdateRole', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  deleteUserRoles(item: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/Role/Delete', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  getAllUserRolesByUserId(id: any, searchTerm: any) {
    return this._http.get(this.baseUrl + 'api/Role/GetRoleByUserId?copmanyId=' + id + '&searchTerm=' + searchTerm,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  getAllProductionRole(id: any, searchTerm: any) {
    return this._http.get(this.baseUrl + 'api/Role/GetProductionRole?copmanyId=' + id + '&searchTerm=' + searchTerm,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetCheckInventory(no: any, qty: any, partId: any) {
    return this._http.get(this.baseUrl + 'api/Inventory/GetCheckInventory?Orderno=' + no + '&qty=' + qty + '&partId=' + partId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  getAllRoleForCopy(id: any, roleId: any) {
    return this._http.get(this.baseUrl + 'api/Role/GetCopyFromRole?copmanyId=' + id + '&roleId=' + roleId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  getAllUsers() {
    return this._http.get(this.baseUrl + 'api/User/GetAllUser',
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  getAllUsersWithParameter(companyId: any, searchTerm: any) {
    return this._http.get(this.baseUrl + 'api/User/GetAllUser?companyId=' + companyId + '&searchTerm=' + searchTerm,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  addUser(item: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/User/AddUser', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  changePassword(item: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/User/ChangeUserPassword', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }


  updateUser(item: any): Observable<any> {
    return this._http.put(this.baseUrl + 'api/User/UpdateUser', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  deleteUser(id: string): Observable<any> {
    return this._http.post(this.baseUrl + 'api/User/Delete?id=' + id,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  getSkillLevelUser(id, roleId) {
    return this._http.get(this.baseUrl + 'api/User/GetSkillLevelDDL?companyId=' + id + '&roleId=' + roleId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  ValidateAddress(item: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/ShippEngine/GetShippingAddressValidate', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetDropdownCategoryHierarchyModel(companyId): Observable<any[]> {
    this.categoryDropDownListCache$ =  this._http.get<any[]>(this.baseUrl + 'api/Category/GetDropdownCategoryHierarchyModel?companyId=' + companyId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(shareReplay(1), catchError(this.errorHandler));
    return this.categoryDropDownListCache$;
  }

  GetCategoryDropdown(companyId): Observable<any[]> {
    if(!this.categoryDropDownListCache$){
      this.categoryDropDownListCache$ = this._http.get<any[]>(this.baseUrl + 'api/Category/GetDropdownCategoryHierarchyModel?companyId=' + companyId,
        { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
        .pipe(shareReplay(1), catchError(this.errorHandler));
    }
    return this.categoryDropDownListCache$;
  }

  GetAllRolePermissionByUserId(id: any) {
    return this._http.get(this.baseUrl + 'api/RolePermission/GetRolePermissionById?Id=' + id,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetRoleCopyfromPermission(nopermission: any, permission: any) {
    return this._http.get(this.baseUrl + 'api/RolePermission/GetRoleCopy?nonPermissionRoleId=' + nopermission + '&permissionRoleId=' + permission,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  SaveRolePermission(item: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/RolePermission/AddRolePermission', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  getAllQuestions(companyId: any, searchTerm: any) {
    return this._http.get(this.baseUrl + 'api/PartQuestion/GetAllQuestions?companyId=' + companyId + '&searchTerm=' + searchTerm,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  addQuestions(item: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/PartQuestion/AddQuestions', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  updateQuestions(item: any): Observable<any> {
    return this._http.put(this.baseUrl + 'api/PartQuestion/UpdateQuestions', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  deleteQuestions(item: any): Observable<any> {

    return this._http.post(this.baseUrl + 'api/PartQuestion/DeleteQuestion?partQuestionModel=' + item, {},
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetEditUserDetail(userId: any) {
    return this._http.get(this.baseUrl + 'api/User/GetEditUserProfile?userId=' + userId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetDbTypeList(userId: any, companyId: any) {
    return this._http.get(this.baseUrl + 'api/Search/GetDbTypeList?userId=' + userId + '&companyId=' + companyId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetSearchData(dbType: any, search: any, Label: any, UI: any, company: any) {
    return this._http.get(this.baseUrl + 'api/Search/GetSearchData?dbType=' + dbType + '&search=' + search + '&Label=' + Label + '&UI=' + UI + '&companyId=' + company,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetSearchAllData(company: any, event: any) {
    return this._http.get(this.baseUrl + 'api/Search/GetSearchAllData?companyId=' + company + '&search=' + event,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  UpdateProfile(profileModel: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/User/UpdateUserProfile', profileModel,
      {
        headers: new HttpHeaders({
          value: 'multipart/form-data'
        }).set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader'))
      })
      .pipe(catchError(this.errorHandler));
  }

  GetCategoryById(id: any) {
    return this._http.get(this.baseUrl + 'api/Category/GetCategoryById?categoryId=' + id, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  getAllCatogary(companyId, searchTerm) {
    return this._http.get(this.baseUrl + 'api/Category/GetpartCategoryDropdownList?companyId=' + companyId + '&searchTerm=' + searchTerm,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  getAllCatogaryWithProduction(companyId, searchTerm) {
    return this._http.get(this.baseUrl + 'api/Category/GetpartCategoryList?companyId=' + companyId + '&searchTerm=' + searchTerm,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  getAllMenus(companyId, searchTerm) {
    return this._http.get(this.baseUrl + 'api/EcommerceMenu/GetMenusList?companyId=' + companyId + '&searchTerm=' + searchTerm,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  addCategory(item: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/Category/AddCategory', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  addMenus(item: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/EcommerceMenu/AddMenus', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  getAllProducts(companyId, searchTerm, offsetNo, pageSize) {
    return this._http.get(this.baseUrl + 'api/Product/GetAllProduct?companyId=' + companyId + '&searchTerm=' + searchTerm + '&offsetNo=' + offsetNo + '&pageSize=' + pageSize,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  addProduct(item: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/Product/AddProduct', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  editProduct(item: any): Observable<any> {
    return this._http.put(this.baseUrl + 'api/Product/UpdateProduct', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  deleteProduct(id: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/Product/DeleteProduct?productId=' + id, {},
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  getAllAnswerType() {
    return this._http.get(this.baseUrl + 'api/PartQuestion/GetAllAnswerType',
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetProductByProductId(id) {
    return this._http.get(this.baseUrl + 'api/Product/GetProductByProductId?productId=' + id,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetCompanyName(companyId: any) {
    return this._http.get(this.baseUrl + 'api/Company/GetCompanyName?companyId=' + companyId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  SaveOrder(item: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/Order/SaveOrder', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetProductDetails(productId, type, companyId) {
    return this._http.get(this.baseUrl + 'api/Order/GetProductDetailByProductId?productId=' + productId + '&type=' + type + '&companyId=' + companyId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  // GetAllOrder(companyId, statusId, offsetNo, pageSize, searchTerm) {
  //   return this._http.get(this.baseUrl + 'api/Order/GetOrderList?companyId=' + companyId + '&statusId=' + statusId + '&offsetNo=' + offsetNo + '&pageSize=' + pageSize + '&searchTerm=' + searchTerm,
  //     { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
  //     .pipe(catchError(this.errorHandler));
  // }

  // GetAllTypeOrder(companyId, statusId, offsetNo, pageSize, searchTerm, type) {
  //   return this._http.get(this.baseUrl + 'api/Order/GetAllTypeOrder?companyId=' + companyId + '&statusId=' + statusId + '&offsetNo=' + offsetNo + '&pageSize=' + pageSize + '&searchTerm=' + searchTerm + '&type=' + type,
  //     { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
  //     .pipe(catchError(this.errorHandler));
  // }

  GetAllOrder(orderRequest: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/Order/GetOrderList', orderRequest,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetAllTypeOrder(orderRequest: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/Order/GetAllTypeOrder', orderRequest,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  DeleteOrder(orderId: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/Order/DeleteOrder?orderId=' + orderId, {},
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  // getAllParts(companyId, vendorId, partnamenumber, offsetNo, pageSize) {
  //   return this._http.get(this.baseUrl + 'api/Order/GetPartDropdownList?companyId=' + companyId + '&vendorId=' + vendorId + '&partname=' + partnamenumber + '&offsetNo=' + offsetNo + '&pageSize=' + pageSize,
  //     { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
  //     .pipe(catchError(this.errorHandler));
  // }
  getAllParts(reqObj) {
    return this._http.post(this.baseUrl + 'api/Order/GetPartDropdownList', reqObj,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  AddorderPart(item: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/Order/AddOrderPart', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetOrderDetails(orderId, type) {
    return this._http.get(this.baseUrl + 'api/Order/GetOrderDetails?orderId=' + orderId + '&type=' + type,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetDashBoardDetails(companyId) {
    return this._http.get(this.baseUrl + 'api/DashBoard/GetDashBoardDetails?companyId=' + companyId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  getCustomDashboard(TempId, companyId, statusId) {
    return this._http.get(this.baseUrl + 'api/DashBoard/GetCustomDashBoard?TempId=' + TempId + '&companyId=' + companyId + '&statusId=' + statusId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetProductDetailsByOrderId(orderId, type) {
    return this._http.get(this.baseUrl + 'api/Order/GetOrderDetailsByOrderId?orderId=' + orderId + '&type=' + type,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  deleteCategory(categoryId: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/Category/DeleteCategory?categoryId=' + categoryId, {},
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  deleteMenu(categoryId: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/EcommerceMenu/DeleteMenus?menuId=' + categoryId, {},
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  updateCategory(item: object): Observable<any> {
    return this._http.put(this.baseUrl + 'api/Category/UpdateCategory', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  updateMenus(item: object): Observable<any> {
    return this._http.put(this.baseUrl + 'api/EcommerceMenu/UpdateMenus', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  UpdateOrder(item: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/Order/UpdateOrder', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetAllOpenCacelOrder(companyId) {
    return this._http.get(this.baseUrl + 'api/Order/GetOpencancelOrderList?companyId=' + companyId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetCustomPartList(partId) {
    return this._http.get(this.baseUrl + 'api/Order/GetCustomPartList?partId=' + partId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  SendEmails(emails: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/ResetPassword/SendMail?emaiAddress=' + emails, {})
      .pipe(catchError(this.errorHandler));
  }

  GetStatusdropdownlistforSOProduct() {
    return this._http.get(this.baseUrl + 'api/Order/GetStatusdropdownlistforSOProduct',
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetStatusdropdownlist() {
    return this._http.get(this.baseUrl + 'api/Order/GetStatusDropdownList',
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetPaymentStatusDropdownList() {
    return this._http.get(this.baseUrl + 'api/Order/GetPaymentStatusDropdownList',
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetShippingStatusdropdownlist() {
    return this._http.get(this.baseUrl + 'api/Order/GetShippingStatusDropdownList',
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetPaymentTransactionDateDropdownList() {
    return this._http.get(this.baseUrl + 'api/Order/GetPaymentTransactionDateDropdownList',
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetProductionStatusdropdownlist() {
    return this._http.get(this.baseUrl + 'api/Order/GetProductionStatusDropdownList',
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  UpdateStatus(orderId, statusid): Observable<any> {
    return this._http.post(this.baseUrl + 'api/Order/UpdateStatus?orderId=' + orderId + '&Statustype=' + statusid, {},
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetSettingSuperAdminDetails(companyId: any) {
    return this._http.get(this.baseUrl + 'api/Company/GetSettingBySuperAdmin?companyid=' + companyId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  POVendorDropdow(companyId: any) {
    return this._http.get(this.baseUrl + 'api/PurchaseOrder/GetPOVendorDropdown?companyId=' + companyId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  AddPrintZPL(obj: any) {
    return this._http.post(this.baseUrl + 'api/PurchaseOrder/AddPrintZPL', obj,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  PrintPdf(Base64Pdf: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/PurchaseOrder/AddPrintPdf', Base64Pdf,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  ConvertZpl(request: any): Observable<Blob> {
    return this._http.post(this.baseUrl + 'api/PurchaseOrder/GetZplToPng', request,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')), responseType: 'blob' })
      .pipe(catchError(this.errorHandler));
  }
  // loadPdfasBlob(pdf: string): any{
  //   this._http.get(pdf, {responseType: 'blob'}).subscribe((blob)=>{
  //     const blobUrl = URL.createObjectURL(blob);
  //     return blobUrl;
  //     //return this._sanitizer.bypassSecurityTrustResourceUrl(blobUrl);
  //   })
  // }
  POVendorDetailVendor(id: any) {
    return this._http.get(this.baseUrl + 'api/PurchaseOrder/GetPOVendorDetail?vendorId=' + id, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  POGetAllCustomerByCustomerId(customerId) {
    return this._http.get(this.baseUrl + 'api/PurchaseOrder/GetPOCustomerDetail?customerId=' + customerId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetCompanyDetail(companyId) {
    return this._http.get(this.baseUrl + 'api/PurchaseOrder/GetPOCompanyDetail?companyId=' + companyId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetCountry() {
    return this._http.get(this.baseUrl + 'api/Company/GetCountryDropdownList',
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetPOStatusDropdownList() {
    return this._http.get(this.baseUrl + 'api/PurchaseOrder/GetPOStatusDropdown',
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetPOTypeDetail() {
    return this._http.get(this.baseUrl + 'api/PurchaseOrder/GetPOTypeDetail',
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  POBuyerDropdow(companyId: any) {
    return this._http.get(this.baseUrl + 'api/PurchaseOrder/GetBuyerDropdown?companyId=' + companyId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  getVendorPartDetail(vendorId, companyId) {
    return this._http.get(this.baseUrl + 'api/PurchaseOrder/GetVendorPartDetail?vendorId=' + vendorId + '&companyId=' + companyId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetPurchaseOrderPartById(id: any) {

    return this._http.get(this.baseUrl + 'api/PurchaseOrder/GetPOParDetails?partId=' + id, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  PullAllParts(orderId: any, vendorId: any) {
    return this._http.get(this.baseUrl + 'api/PurchaseOrder/GetAllPartBySo?orderId=' + orderId + '&vendorId=' + vendorId, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  PartOrderDropdown(companyId: any, custId: any, companyLoc: any) {
    return this._http.get(this.baseUrl + 'api/PurchaseOrder/GetPartOrderDropdown?companyId=' + companyId + '&custId=' + custId + '&companyLoc=' + companyLoc,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  ProductOrderDropdown(companyId: any, custId: any, companyLoc: any) {
    return this._http.get(this.baseUrl + 'api/PurchaseOrder/GetProductOrderDropdown?companyId=' + companyId + '&custId=' + custId + '&companyLoc=' + companyLoc,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetAllPurchaseorder(companyId, statusId) {
    return this._http.get(this.baseUrl + 'api/PurchaseOrder/GetAllPurchaseOrder?companyId=' + companyId + '&statusId=' + statusId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  DeletePurchaseOrder(item: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/PurchaseOrder/DeletePurchasOrder?purchaseOrderId=' + item, {},
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  getallpartbyVendor(id, companyId, offsetNo, pageSize, searchTerm) {
    return this._http.get(this.baseUrl + 'api/PurchaseOrder/GetPartDrowListBindVendor?vendorId=' + id + '&companyId=' + companyId + '&offsetNo=' + offsetNo + '&pageSize=' + pageSize + '&searchTerm=' + searchTerm,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  getallpartbySO(SO, vendorId) {
    return this._http.get(this.baseUrl + 'api/PurchaseOrder/GetPartBySo?orderId=' + SO + '&vendorId=' + vendorId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  AddPurchaseOrder(item: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/PurchaseOrder/AddPODetails', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetPOPartDetailsById(id: any) {
    return this._http.get(this.baseUrl + 'api/PurchaseOrder/GetPOPartById?partId=' + id, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  UpdatePurchaseOrder(item: any): Observable<any> {
    return this._http.put(this.baseUrl + 'api/PurchaseOrder/UpdatePurchaseOrder', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GeneratePurchaseOrderNo(PoNo, companyId) {
    return this._http.get(this.baseUrl + 'api/PurchaseOrder/GetGeneratePO?PONo=' + PoNo + '&companyId=' + companyId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  AddPOReceived(item: any): Observable<any> {

    return this._http.post(this.baseUrl + 'api/PurchaseOrder/AddPOReceived', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  UpdatePOReceived(item: any): Observable<any> {

    return this._http.put(this.baseUrl + 'api/PurchaseOrder/UpdatePOReceived', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetPODetailsByPONumber(PoNumber: any, companyId: any) {

    return this._http.get(this.baseUrl + 'api/PurchaseOrder/GetPODetailsByPONumber?PONumber=' + PoNumber + '&companyId=' + companyId, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetPODetailsById(id: any) {
    return this._http.get(this.baseUrl + 'api/PurchaseOrder/GetPODetailsById?Id=' + id, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  getReceiveOrderSearch(companyId: any, statusId: any, vendorId: any, offsetNo: any, pageSize: any, searchTerm: any) {
    return this._http.get(this.baseUrl + 'api/PurchaseOrder/GetReceiveOrderSearch?companyId=' + companyId + '&statusId=' + statusId + '&vendorId=' + vendorId + '&offsetNo=' + offsetNo + '&pageSize=' + pageSize + '&searchTerm=' + searchTerm,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  getSalesOrderCustomerWise(companyId: any, statusId: any, customerId: any, soNumber: any) {
    return this._http.get(this.baseUrl + 'api/PurchaseOrder/GetSalesOrderCustomerWise?companyId=' + companyId + '&statusId=' + statusId + '&customerId=' + customerId + '&soNumber=' + soNumber,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetShippingList(reqObj) {
    return this._http.post(this.baseUrl + 'api/PurchaseOrder/GetShippingList', reqObj,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetPoStatusList(PoId: any, companyId: any, ItemId: any) {
    return this._http.get(this.baseUrl + 'api/PurchaseOrder/GetPoStatusList?PoId=' + PoId + '&companyId=' + companyId + '&ItemId=' + ItemId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  DeletePoReceived(item: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/PurchaseOrder/DeletePoReceived?ReceivedId=' + item, {},
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetSODetailsBySONumber(SoNumber: any, companyId: any) {
    return this._http.get(this.baseUrl + 'api/PurchaseOrder/GetDetailsBySONo?SONumber=' + SoNumber + '&companyId=' + companyId, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetDetailsBySONoByOrderId(SoNumber: any, companyId: any) {
    return this._http.get(this.baseUrl + 'api/PurchaseOrder/GetDetailsBySONoByOrderId?SONumber=' + SoNumber + '&companyId=' + companyId, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetShippingDetailsByOrderId(OrderId: number, masterTrackingNo: string) {
    return this._http.get(this.baseUrl + 'api/PurchaseOrder/GetShippingDetailsByOrderId?OrderId=' + OrderId + '&masterTrackingNo=' + masterTrackingNo, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  UpdateShippedItem(item: any): Observable<any> {
    return this._http.put(this.baseUrl + 'api/PurchaseOrder/UpdateShippedItem', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  UpdateFinalShippedItem(item: any, OrderId: number, eOrderNumber: any): Observable<any> {
    return this._http.put(this.baseUrl + 'api/PurchaseOrder/UpdateFinalShippedItem?OrderId=' + OrderId + '&eOrderNumber=' + eOrderNumber, item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetShippedItemList(SoId: any, companyId: any, ItemId: any) {
    return this._http.get(this.baseUrl + 'api/PurchaseOrder/GetShippedItemList?SoId=' + SoId + '&companyId=' + companyId + '&ItemId=' + ItemId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetCartonList(SoNo: any, companyId: any) {
    return this._http.get(this.baseUrl + 'api/PurchaseOrder/GetCartonList?SoNo=' + SoNo + '&companyId=' + companyId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetShippedCartonByOrderId(OrderId: number, companyId: number, masterTrackingNo: string) {
    return this._http.get(this.baseUrl + 'api/PurchaseOrder/GetShippedCartonByOrderId?OrderId=' + OrderId + '&companyId=' + companyId + '&masterTrackingNo=' + masterTrackingNo,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetShippedInvDetails(companyId: any, soId: any, custId: any, partId: any, orderTypeId: Number) {
    return this._http.get(this.baseUrl + 'api/PurchaseOrder/GetShippedInvDetails?companyId=' + companyId + '&soId=' + soId + '&custId=' + custId + '&partId=' + partId + '&OrderTypeId=' + orderTypeId, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  MasterShipping(value: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/PurchaseOrder/MasterShipping', value,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetPartDropdownList(vendorId, companyId, serachText) {
    return this._http.get(this.baseUrl + 'api/PurchaseOrder/GetPartSearchList?vendorId=' + vendorId + '&companyId=' + companyId + '&serachText=' + serachText,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler)); vendorId
  }

  GetAllOrderBYSO(companyId, searchTerm, offsetNo, pageSize) {
    return this._http.get(this.baseUrl + 'api/Order/GetOrderListBySO?companyId=' + companyId + '&searchTerm=' + searchTerm + '&offsetNo=' + offsetNo + '&pageSize=' + pageSize,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  getAllPartsByPartNumber(companyId, partname) {
    return this._http.get(this.baseUrl + 'api/Order/GetSearchsPartDropdownList?companyId=' + companyId + '&partname=' + partname,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  getAllproductParts(companyId, vendorId, partnamenumber, categoryId, offsetNo, pageSize) {
    return this._http.get(this.baseUrl + 'api/Product/GetPartDropdownList?companyId=' + companyId + '&vendorId=' + vendorId + '&partname=' + partnamenumber + '&categoryId=' + categoryId + '&offsetNo=' + offsetNo + '&pageSize=' + pageSize,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  SearchgetAllproductParts(companyId, partname) {
    return this._http.get(this.baseUrl + 'api/Product/GetSearchsPartDropdownList?companyId=' + companyId + '&partname=' + partname,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetInventoryPartList(companyId: any, vId: any, search: any, searchTerm: any) {
    return this._http.get(this.baseUrl + 'api/Inventory/GetInventoryPartList?companyId=' + companyId + '&vendorId=' + vId + '&search=' + search + '&searchTerm=' + searchTerm, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetInventory(companyId: any, vId: any, partId: any) {
    return this._http.get(this.baseUrl + 'api/Inventory/GetInventory?companyId=' + companyId + '&vendorId=' + vId + '&partId=' + partId, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  UpdateInventory(item: any): Observable<any> {
    return this._http.put(this.baseUrl + 'api/Inventory/UpdateInventory', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GenerateSaleOrderNo(PoNo, companyId) {
    return this._http.get(this.baseUrl + 'api/Order/GetGenerateSO?PONo=' + PoNo + '&companyId=' + companyId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetLog(companyId: any, partId: any) {
    return this._http.get(this.baseUrl + 'api/Inventory/GetLog?companyId=' + companyId + '&partId=' + partId, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetMarkup(companyId: any, partId: any) {
    return this._http.get(this.baseUrl + 'api/Category/GetMarkup?companyId=' + companyId + '&partCategoryId=' + partId, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  getAllTemplate(companyId: any, searchTerm: any) {
    return this._http.get(this.baseUrl + 'api/Template/GetAllTemplate?companyId=' + companyId + '&searchTerm=' + searchTerm,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  getAllWidgetItem(companyId: any) {
    return this._http.get(this.baseUrl + 'api/Template/GetAllWidget?companyId=' + companyId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  AddTemplateItem(item: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/Template/AddTemplate', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  deleteTemplate(item: any): Observable<any> {

    return this._http.post(this.baseUrl + 'api/Template/DeleteTemplate?templateId=' + item, {},
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetAllWidget(companyId: any, widgetName: any) {
    return this._http.get(this.baseUrl + 'api/Widget/GetAllWidget?companyId=' + companyId + '&widgetName=' + widgetName, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetWidget(id: any) {
    return this._http.get(this.baseUrl + 'api/Widget/GetWidget?wId=' + id, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  AddWidget(item: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/Widget/AddWidget', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetGeneratePurchaseOrderPDF(purchaseorderId: any) {
    return this._http.get(this.baseUrl + 'api/PurchaseOrder/GetPurchaseOrderPDF?purchaseorderId=' + purchaseorderId, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetGeneratePurchaseOrderSummaryPDF(purchaseorderId: any) {
    return this._http.get(this.baseUrl + 'api/PurchaseOrder/GetPurchaseOrderSummaryPDF?purchaseorderId=' + purchaseorderId, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetSaleOrderLog(orderid: any) {
    return this._http.get(this.baseUrl + 'api/Order/GetSaleOrderLog?orderId=' + orderid,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  UpdateWidget(item: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/Widget/UpdateWidget', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  deleteWidget(item: any): Observable<any> {

    return this._http.post(this.baseUrl + 'api/Widget/DeleteWidget?widgetId=' + item, {},
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetEditTemplateItem(templateId: any) {
    return this._http.get(this.baseUrl + 'api/Template/GetTemplateDetail?templateId=' + templateId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  UpdateTemplate(item: any): Observable<any> {

    return this._http.put(this.baseUrl + 'api/Template/UpdateTemplate', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetTemplatedropdownlist(companyId) {
    return this._http.get(this.baseUrl + 'api/Role/GetTemplateList?companyId=' + companyId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetTemplateDetail(companyId, userId) {
    return this._http.get(this.baseUrl + 'api/DashBoard/GetTemplateDetails?companyId=' + companyId + '&userId=' + userId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetPurchaseOrderLog(purchaseId: any) {
    return this._http.get(this.baseUrl + 'api/PurchaseOrder/GetAllPurChaseOrderLogDetail?purchaseorderId=' + purchaseId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  SendPOEmail(item: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/PurchaseOrder/AddSendPOEmail', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  SendEmail(item: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/PurchaseOrder/AddSendEmail', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  SendSOEmail(item: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/Order/SendSOEmail', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetAllPONumbers(companyId: any, type: any) {
    return this._http.get(this.baseUrl + 'api/EmailTemp/GetPOSONumbers?companyId=' + companyId + '&type=' + type,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  AddEmailTemplate(item: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/EmailTemp/AddTemplate', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  UpdateEmailTemplate(item: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/EmailTemp/UpdateTemplate', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetAllEmailTemplate(companyId: any, tempName: any, tempType: any) {
    return this._http.get(this.baseUrl + 'api/EmailTemp/GetAllTemplate?companyId=' + companyId + '&tempName=' + tempName + '&tempType=' + tempType, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  deleteEmailTemplate(item: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/EmailTemp/DeleteTemplate?tempId=' + item, {},
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetEmailTemplate(id: any) {
    return this._http.get(this.baseUrl + 'api/EmailTemp/GetTemplate?tId=' + id, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  SendSaleOrderEmail(item: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/Order/AddSendPOEmail', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetAllSaleorderNumber(companyId: any, customerId: any) {
    return this._http.get(this.baseUrl + 'api/Order/GetAllSalerderNoList?companyId=' + companyId + '&customerId=' + customerId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GenerateChangeOrderNo(PoNo, companyId, purchaseorder) {
    return this._http.get(this.baseUrl + 'api/Order/GetChangeOrderNo?PONo=' + PoNo + '&companyId=' + companyId + '&PONumber=' + purchaseorder,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  AddEmailConfig(item: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/EmailTemp/AddConfig', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  UpdateEmailConfig(item: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/EmailTemp/UpdateConfig', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetAllEmailConfig(companyId: any, searchTerm: any) {
    return this._http.get(this.baseUrl + 'api/EmailTemp/GetAllConfig?companyId=' + companyId + '&searchTerm=' + searchTerm, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  deleteEmailConfig(item: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/EmailTemp/DeleteConfig?tempId=' + item, {},
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetEmailConfig(id: any) {
    return this._http.get(this.baseUrl + 'api/EmailTemp/GetConfig?tId=' + id, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  SendTestEmail(item: any): Observable<any> {

    return this._http.post(this.baseUrl + 'api/EmailTemp/AddSendTestEmail', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetOpDashboardList(companyId: any, statusId: any, type: any) {
    return this._http.get(this.baseUrl + 'api/DashboardList/GetOpDashboardList?companyId=' + companyId + '&statusId=' + statusId + '&type=' + type, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetCooDashboardList(companyId: any, type: any) {
    return this._http.get(this.baseUrl + 'api/DashboardList/GetCooDashboardList?companyId=' + companyId + '&type=' + type, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetConfigForSendEmail(companyId: any, item_id: any) {
    return this._http.get(this.baseUrl + 'api/EmailTemp/GetConfigForSendEmail?companyId=' + companyId + '&itemid=' + item_id, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  getAllWorkflows(companyId, searchTerm) {
    return this._http.get(this.baseUrl + 'api/Workflow/GetWorkflowList?companyId=' + companyId + '&searchTerm=' + searchTerm,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetTaskByWorkFlow(companyId) {
    return this._http.get(this.baseUrl + 'api/Workflow/GetTaskByWorkFlow?companyId=' + companyId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetWorkflow(id: any) {
    return this._http.get(this.baseUrl + 'api/Workflow/GetWorkflow?tId=' + id, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  addWorkflow(item: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/Workflow/AddWorkflow', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  updateWorkflow(item: object): Observable<any> {
    return this._http.put(this.baseUrl + 'api/Workflow/UpdateWorkflow', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  deleteWorkflow(workflowId: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/Workflow/DeleteWorkflow?workflowId=' + workflowId, {},
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  getAllAlertCatogary(companyId, searchTerm) {
    return this._http.get(this.baseUrl + 'api/AlertCategory/GetAlertCategoryList?companyId=' + companyId + '&searchTerm=' + searchTerm,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  addAlertCategory(item: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/AlertCategory/AddAlertCategory', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  updateAlertCategory(item: object): Observable<any> {
    return this._http.put(this.baseUrl + 'api/AlertCategory/UpdateAlertCategory', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  deleteAlertCategory(workflowId: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/AlertCategory/DeleteAlertCategory?Id=' + workflowId, {},
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  getAllTaskOnHold(companyId, searchTerm) {
    return this._http.get(this.baseUrl + 'api/TaskOnHold/GetTaskOnHoldList?companyId=' + companyId + '&searchTerm=' + searchTerm,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  addTaskOnHold(item: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/TaskOnHold/AddTaskOnHold', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  updateTaskOnHold(item: object): Observable<any> {
    return this._http.put(this.baseUrl + 'api/TaskOnHold/UpdateTaskOnHold', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  deleteTaskOnHold(taskOnHoldId: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/TaskOnHold/DeleteTaskOnHold?taskOnHoldId=' + taskOnHoldId, {},
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  getAllTaskCategory(companyId, searchTerm) {
    return this._http.get(this.baseUrl + 'api/TaskCategory/GetAllTaskCategory?companyId=' + companyId + '&searchTerm=' + searchTerm,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  addTaskCategory(item: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/TaskCategory/AddTaskCategory', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  updateTaskCategory(item: object): Observable<any> {
    return this._http.put(this.baseUrl + 'api/TaskCategory/UpdateTaskCategory', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  deleteTaskCategory(taskCategoryId: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/TaskCategory/DeleteTaskCategory?taskCategoryId=' + taskCategoryId, {},
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  //start Task

  getAllTask(companyId, searchTerm) {
    return this._http.get(this.baseUrl + 'api/Task/GetTaskModuleList?companyId=' + companyId + '&searchTerm=' + searchTerm,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  getAllTaskByWorkflow(companyId, workflowId, searchTerm, offsetNo, pageSize) {
    return this._http.get(this.baseUrl + 'api/Task/GetTaskListByWorkflow?companyId=' + companyId + '&workflowId=' + workflowId + '&searchTerm=' + searchTerm + '&offsetNo=' + offsetNo + '&pageSize=' + pageSize,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  addTask(item: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/Task/AddTaskModule', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  updateTask(item: object): Observable<any> {
    return this._http.put(this.baseUrl + 'api/Task/UpdateTaskModule', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  deleteTask(taskModuleId: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/Task/DeleteTaskModule?taskModuleId=' + taskModuleId, {},
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  //end Task

  //start Alert Category
  getAllAlertType(companyId, searchTerm) {
    return this._http.get(this.baseUrl + 'api/AlertType/GetAlertTypeList?companyId=' + companyId + '&searchTerm=' + searchTerm,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  addAlertType(item: any): Observable<any> {

    return this._http.post(this.baseUrl + 'api/AlertType/AddAlertType', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }


  updateAlertType(item: object): Observable<any> {
    return this._http.put(this.baseUrl + 'api/AlertType/UpdateAlertType', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  deleteAlertType(workflowId: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/AlertType/DeleteAlertType?Id=' + workflowId, {},
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  //end Alert Type

  //start Customer Contact type
  GetCustomerContactTypeList(companyId, searchTerm) {

    return this._http.get(this.baseUrl + 'api/CustomerContactType/GetCustomerContactTypeList?companyId=' + companyId + '&searchTerm=' + searchTerm,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  addCustomerContactType(item: any): Observable<any> {

    return this._http.post(this.baseUrl + 'api/CustomerContactType/AddCustomerContactType', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  updateCustomerContactType(item: any): Observable<any> {
    return this._http.put(this.baseUrl + 'api/CustomerContactType/UpdateCustomerContactType', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  deleteCustomerContactType(workflowId: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/CustomerContactType/DeleteCustomerContactType?Id=' + workflowId, {},
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  UpdateAssignInventorySO(item: any): Observable<any> {
    return this._http.put(this.baseUrl + 'api/AssignInventorytoSOs/UpdateAssignInventorytoSOs', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  UpdateAssignInventorySONew(item: any): Observable<any> {
    return this._http.put(this.baseUrl + 'api/AssignInventorytoSOs/UpdateAssignInventorytoSOsNew', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetAllPOsToCreateSOsList(companyId: any, vId: any) {
    return this._http.get(this.baseUrl + 'api/POsToCreateForSOs/GetSOsList?companyId=' + companyId + '&vendorId=' + vId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  UpdatePOsToCreateSOs(item: any): Observable<any> {

    return this._http.put(this.baseUrl + 'api/AssignInventorytoSOs/UpdatePOsToCreateSOs', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetAllBelowInventoryMinimum(companyId: any, vId: any) {
    return this._http.get(this.baseUrl + 'api/BelowInventoryMinimum/GetBelowInventoryMinimum?companyId=' + companyId + '&vendorId=' + vId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  UpdateBelowInventoryMinimum(item: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/BelowInventoryMinimum/UpdateBelowInventoryMinimum', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  //For Ecommerce Api Details

  addEcommerceApiDetails(item: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/EcommerceApiDetails/AddEcommerceApiDetails', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetAllEcommerceDetails(companyId, searchTerm) {
    return this._http.get(this.baseUrl + 'api/EcommerceApiDetails/GetEcommerceApiDetailsList?companyId=' + companyId + '&searchTerm=' + searchTerm,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetEcommerceCategory(companyId) {
    return this._http.get(this.baseUrl + 'api/Category/GetEcommerceCategory?companyId=' + companyId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  deteleEcommerceApiDetails(ecommerceId: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/EcommerceApiDetails/DeleteEcommerceApiDetails?ecommerceId=' + ecommerceId, {},
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  updateEcommerceApiDetails(item: object): Observable<any> {
    return this._http.put(this.baseUrl + 'api/EcommerceApiDetails/UpdateEcommerceApiDetails', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetFulfillPartSOList(companyId: any) {
    return this._http.get(this.baseUrl + 'api/AssignInventorytoSOs/GetFulfillPartSOList?companyId=' + companyId, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetFulfillPartsList(orderId: any, type: any) {
    return this._http.get(this.baseUrl + 'api/AssignInventorytoSOs/GetFulfillPartsList?orderId=' + orderId + '&type=' + type,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetFulfillPartsListNew(orderId: any, type: any) {
    return this._http.get(this.baseUrl + 'api/AssignInventorytoSOs/GetFulfillPartsListNew?orderId=' + orderId + '&type=' + type,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetOrderPartQtyToFulfill(orderId: any, partId: any) {
    return this._http.get(this.baseUrl + 'api/Inventory/GetOrderPartQtyToFulfill?OrderId=' + orderId + '&PartId=' + partId, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetSOOperationView(partId: any, orderId: any) {
    return this._http.get(this.baseUrl + 'api/Order/GetOperationView?partId=' + partId + '&orderId=' + orderId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetPartDetailsForPdfDownload(orderId: any) {
    return this._http.get(this.baseUrl + 'api/Order/GetPartDetailsForPdfDownload?orderId=' + orderId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetSOOperationProductView(partId: any, orderId: any) {
    return this._http.get(this.baseUrl + 'api/Order/GetOperationProductView?partId=' + partId + '&orderId=' + orderId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  //Add Searchable Fields

  AddSearchableField(item: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/Search/AddSearchableField', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  updateSearchableField(item: object): Observable<any> {
    return this._http.put(this.baseUrl + 'api/Search/UpdateSearchableField', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  getAllSearchableField(companyId, searchTerm) {
    return this._http.get(this.baseUrl + 'api/Search/GetSearchableField?companyId=' + companyId + '&searchTerm=' + searchTerm,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  deleteSearchableField(id: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/Search/DeleteSearchableField?Id=' + id, {},
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  updateLaborCost(companyId: any, laborCost: any): Observable<any> {
    return this._http.put(this.baseUrl + 'api/Order/UpdateLaborCost?CompanyId=' + companyId + '&LaborCost=' + laborCost, {},
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetPartVendors(companyId, partId) {
    return this._http.get(this.baseUrl + 'api/Inventory/GetPartVendors?companyId=' + companyId + '&partId=' + partId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  // Add Skill Level Category API'S
  getAllSkillLevelCategory(companyId, searchTerm) {
    return this._http.get(this.baseUrl + 'api/SkillLevel/GetAllSkillLevelCategory?companyId=' + companyId + '&searchTerm=' + searchTerm,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  addSkillLevelCategory(item: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/SkillLevel/AddSkillLevelCategory', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  updateSkillLevelCategory(item: object): Observable<any> {
    return this._http.put(this.baseUrl + 'api/SkillLevel/UpdateSkillLevelCategory', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  deleteSkillLevelCategory(taskCategoryId: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/SkillLevel/DeleteSkillLevelCategory?skillLevelCategoryID=' + taskCategoryId, {},
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  // Add Vehicle 

  addVehicle(item: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/Vehicle/AddVehicle', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  updateVehicle(item: object): Observable<any> {
    return this._http.put(this.baseUrl + 'api/Vehicle/UpdateVehicle', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  deleteVehicle(categoryId: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/Vehicle/DeleteVehicle?vehicleId=' + categoryId, {},
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  getAllVehicleWithProduction(companyId, searchTerm) {
    return this._http.get(this.baseUrl + 'api/Vehicle/GetVehicleList?companyId=' + companyId + '&searchTerm=' + searchTerm,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  getVehicleList(vehicleId) {
    return this._http.get(this.baseUrl + 'api/Vehicle/GetVehicleModelList?vehicleId=' + vehicleId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetManufacturerList(companyId: any) {
    return this._http.get(this.baseUrl + 'api/Vehicle/GetManufacturerList?companyId=' + companyId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetVehicleDetail(productId: any) {
    return this._http.get(this.baseUrl + 'api/Product/GetVehicleDetail?productId=' + productId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetManufactureDetail(vehicleId: any) {
    return this._http.get(this.baseUrl + 'api/Vehicle/GetManufactureDetail?vehicleId=' + vehicleId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetModelDetail(modelId: any) {
    return this._http.get(this.baseUrl + 'api/Vehicle/GetModelDetail?modelId=' + modelId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  // Add Location Details
  getLocationTypeList(companyId, searchTerm) {
    return this._http.get(this.baseUrl + 'api/LocationType/GetLocationTypeList?companyId=' + companyId + '&searchTerm=' + searchTerm,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  //Start Location Level API
  addLocationLevelDetails(item: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/LocationLevels/AddLocationLevelsDetails', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  updateLocationLevelDetails(item: any): Observable<any> {
    return this._http.put(this.baseUrl + 'api/LocationLevels/UpdateLocationLevelDetails', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  // Add WorkFlow Category

  updateLocationTypeDetails(item: object): Observable<any> {
    return this._http.put(this.baseUrl + 'api/LocationType/UpdateLocationTypeDetails', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  addLocationTypeDetails(item: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/LocationType/AddLocationTypeDetails', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  deleteLocationTypeDetails(Id: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/LocationType/DeleteLocationTypeDetails?Id=' + Id, {},
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  getLocationLevel(id, search) {
    return this._http.get(this.baseUrl + 'api/LocationLevels/GetLocationDetails?companyId=' + id + '&searchTerm=' + search,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  getPartLocationLevel(id, locationName, cid) {
    return this._http.get(this.baseUrl + 'api/LocationLevels/GetLocationLevel?locationTypeId=' + id + '&locationName=' + locationName + '&companyId=' + cid,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  //pick list
  GetPartPickListType(orderId: any, companyId: any, type: any, BatchType: any, Count: number) {
    return this._http.get(this.baseUrl + 'api/PickList/GetPartPickList?orderId=' + orderId + '&companyId=' + companyId + '&type=' + type + '&VariationType=' + BatchType + '&Count=' + Count,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  UpdateCreatePicklist(item: any): Observable<any> {
    return this._http.put(this.baseUrl + 'api/PickList/UpdateCreatePicklist', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetSerialExist(serial: any, partId: any, type: any) {
    return this._http.get(this.baseUrl + 'api/Part/GetSerialExist?serialNo=' + serial + '&partId=' + partId + '&type=' + type,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetSerialExists(serial: any, partId: any, type: any) {
    return this._http.get(this.baseUrl + 'api/PurchaseOrder/GetSerialExist?serialNo=' + serial + '&partId=' + partId + '&type=' + type,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetTrackingDetails(id: any, companyId: any, pid: any) {
    return this._http.get(this.baseUrl + 'api/PickList/GetTrackingDetails?id=' + id + '&companyId=' + companyId + '&partId=' + pid,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  //get copy product
  GetCopyProduct(productId, name) {
    return this._http.get(this.baseUrl + 'api/Product/GetCopyProduct?productId=' + productId + '&name=' + name,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetCopySalesOrder(orderId, number, cid) {
    return this._http.get(this.baseUrl + 'api/Order/GetCopySalesOrder?orderId=' + orderId + '&number=' + number + '&companyId=' + cid,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  UpdateSerial(item: object): Observable<any> {
    return this._http.put(this.baseUrl + 'api/PickList/UpdateSerial', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetHistoryLog(orderid: any) {
    return this._http.get(this.baseUrl + 'api/Order/GetHistoryLog?orderId=' + orderid,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetSalesOrderReportData(companyId, statusId, offsetNo, pageSize, fromDate, toDate) {
    return this._http.get(this.baseUrl + 'api/Report/GetSalesOrderReportData?companyId=' + companyId + '&statusId=' + statusId + '&offsetNo=' + offsetNo + '&pageSize=' + pageSize + '&fromDate=' + fromDate + '&toDate=' + toDate,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetSalesOrderReportAllData(companyId, statusId, fromDate, toDate) {
    return this._http.get(this.baseUrl + 'api/Report/GetSalesOrderReportAllData?companyId=' + companyId + '&statusId=' + statusId + '&fromDate=' + fromDate + '&toDate=' + toDate,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  ////Purchase Order Report
  GetPurchaseOrdeReportAllDataWithPagging(companyId, statusId, vendorId, offsetNo, pageSize, fromDate, toDate) {
    return this._http.get(this.baseUrl + 'api/Report/GetPurchaseOrdeReportAllDataWithPagging?companyId=' + companyId + '&statusId=' + statusId + '&vendorId=' + vendorId + '&offsetNo=' + offsetNo + '&pageSize=' + pageSize + '&fromDate=' + fromDate + '&toDate=' + toDate,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetPurchaseOrdeReportAllData(companyId, statusId, vendorId, fromDate, toDate) {
    return this._http.get(this.baseUrl + 'api/Report/GetPurchaseOrdeReportAllData?companyId=' + companyId + '&statusId=' + statusId + '&vendorId=' + vendorId + '&fromDate=' + fromDate + '&toDate=' + toDate,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  //Inventory Report
  GetAllInventoryPartReportByName(companyId: any, vendorId: any, inventoryType: any, offsetNo: any, pageSize: any) {
    return this._http.get(this.baseUrl + 'api/Report/GetInventoryPartListWithPagging?companyId=' + companyId + '&vendorId=' + vendorId + '&inventoryType=' + inventoryType + '&offsetNo=' + offsetNo + '&pageSize=' + pageSize,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }


  GetAllInventoryPartReportAllData(companyId: any, vendorId: any, inventoryType: any) {
    return this._http.get(this.baseUrl + 'api/Report/GetInventoryPartListAllData?companyId=' + companyId + '&vendorId=' + vendorId + '&inventoryType=' + inventoryType,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }


  GetAllInventoryPartReportByLocationandDate(companyId: any, vendorId: any, inventoryType: any, locationName: any, offsetNo: any, pageSize: any) {

    return this._http.get(this.baseUrl + 'api/Report/GetInventoryPartListByLocationWithPagging?companyId=' + companyId + '&vendorId=' + vendorId + '&inventoryType=' + inventoryType + '&locationName=' + locationName + '&offsetNo=' + offsetNo + '&pageSize=' + pageSize,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  // TaxesAPIPost(item: any): Observable<any> {
  //   
  //   return this._http.post(this.baseUrl + 'api/TaxCalculation/TaxCalculation',item,
  //     { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
  //     .pipe(catchError(this.errorHandler));
  // }

  GetShippingDetails(item: object): Observable<any> {
    return this._http.post(this.baseUrl + 'api/ShippEngine/GetShippingRateEstimate', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetShippingDetailsDefault(item: object): Observable<any> {
    return this._http.post(this.baseUrl + 'api/ShippEngine/ShippingRateEstimateDefault', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  TaxesAPIPost(item: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/TaxCalculation/GetTaxCalculation', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  //ankit start
  CompletePayment(payment_id: string, orderid: number): Observable<any> {
    return this._http.post(this.baseUrl + 'api/Payment/AddCompletePayment?paymentid=' + payment_id + '&orderid=' + orderid,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  CreatePayment(item: object, orderid: number): Observable<any> {
    return this._http.post(this.baseUrl + 'api/Payment/CreatePayment?OrderId=' + orderid, item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  SaveCreatePayment(item: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/Payment/SaveCreatePayment/', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetCreatedPayment(orderid: number) {
    return this._http.get(this.baseUrl + 'api/Payment/GetCreatedPayment?orderid=' + orderid,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetPaymentBalance(orderid: number) {
    return this._http.get(this.baseUrl + 'api/Payment/GetPaymentBalance?orderid=' + orderid,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }


  GetSumAmount(orderid: number, status: string) {
    return this._http.get(this.baseUrl + 'api/Payment/GetSumAmount?orderid=' + orderid + '&status=' + status,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetMulShippingDetails(item: object): Observable<any> {
    return this._http.post(this.baseUrl + 'api/ShippEngine/GetShippingRate', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetDefaultCompanyId() {
    return this._http.get(this.baseUrl + 'api/Search/GetCompanyId/',
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetShipping(orderId, id) {
    return this._http.get(this.baseUrl + 'api/Order/GetShipping?orderId=' + orderId + '&pid=' + id,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  ShippingVarianceLog(value: any): Observable<any> {
    return this._http.post(this.IntegrationbaseUrl + 'api/PurchaseOrder/ShippingVarianceLog', value,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  FinalPayment(orderid, customerid, amount) {
    return this._http.post(this.baseUrl + 'api/Payment/AddFinalPayment?orderid=' + orderid + '&customerid=' + customerid + '&amount=' + amount,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  //ankit end
  //Navneet
  GetMultiPackageShipping(orderId: any, masterTrackingNo: string) {
    return this._http.get(this.baseUrl + 'api/PurchaseOrder/GetMultiPackageShipping?orderId=' + orderId + '&masterTrackingNo=' + masterTrackingNo, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetEqualShippedSerialLot(OrderId: any) {
    return this._http.get(this.baseUrl + 'api/PurchaseOrder/GetEqualShippedSerialLot?OrderId=' + OrderId, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  ShippingRate(value: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/ShippEngine/GetShippingRate', value,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  RateAPI(CompanyId: number, value: any): Observable<any> {
    return this._http.post(this.IntegrationbaseUrl + 'api/ShippingRate/v1/' + CompanyId, value,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  LabelAPI(CompanyId: number, providerId: any, value: any): Observable<any> {
    return this._http.post(this.IntegrationbaseUrl + 'api/ShippingLabel/v1/' + CompanyId + '/' + providerId, value,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  AddSinglePackage(OrderId: number, companyId: number): Observable<any> {
    return this._http.post(this.baseUrl + 'api/PurchaseOrder/AddSinglePackage?OrderId=' + OrderId + '&companyId=' + companyId, null,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  SaveFlyOut(value: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/PurchaseOrder/SaveFlyOut', value,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  // ShippingLabel(value: any, OrderId: number, PackageIds: any): Observable<any> {
  //   return this._http.post(this.baseUrl + 'api/ShippEngine/ShippingLabel?OrderId=' + OrderId, value,
  //     { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
  //     .pipe(catchError(this.errorHandler));
  // }
  CancelShippingLabel(label_id: any, OrderId: number): Observable<any> {
    return this._http.post(this.baseUrl + 'api/ShippEngine/CancelShippingLabel?label_id=' + label_id + '&OrderId=' + OrderId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  CancelLabel(CompanyId: number, providerId: number, modal: any): Observable<any> {
    return this._http.put(this.IntegrationbaseUrl + 'api/ShippingLabel/v1/' + CompanyId + '/' + providerId, modal,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  ShippingPayment(OrderId: number, shipmentMultiPackage: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/Payment/ShippingPayment?OrderId=' + OrderId, shipmentMultiPackage,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  DeleteCartonFromList(Id: number) {
    return this._http.get(this.baseUrl + 'api/PurchaseOrder/DeleteCartonFromList?Id=' + Id,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  AddMultiPackageShipping(item: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/PurchaseOrder/AddMultiPackageShipping', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  SaveUpdateShippedCartoon(item: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/PurchaseOrder/SaveUpdateShippedCartoon', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetShippingMasterById(OrderId: any) {
    return this._http.get(this.baseUrl + 'api/PurchaseOrder/GetShippingMasterById?Id=' + OrderId, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetFlyOutById(Id: any) {
    return this._http.get(this.baseUrl + 'api/PurchaseOrder/GetFlyOutById?Id=' + Id, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  DeleteShipmentMultiPackageById(Id: any) {
    return this._http.get(this.baseUrl + 'api/PurchaseOrder/DeleteShipmentMultiPackageById?Id=' + Id, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetPrinterList() {
    return this._http.get(this.baseUrl + 'api/PurchaseOrder/GetPrinterList', { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  SetDefaultPrinter(PrinterName: any) {
    return this._http.get(this.baseUrl + 'api/PurchaseOrder/SetDefaultPrinter?PrinterName=' + PrinterName, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  FedexIntegration(model: object) {
    return this._http.post(this.baseUrl + 'api/ShippEngine/GetFedexIntegration', model,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  AddUpdPrinter(model: object) {
    return this._http.post(this.baseUrl + 'api/PurchaseOrder/AddUpdPrinter', model,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetShippingServiceMaster() {
    return this._http.get(this.baseUrl + 'api/Order/GetShippingServiceMaster',
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  MarkCompleted(value: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/Order/UpdateMarkShipped', value,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  AddSalesOrderLog(value: any, ActionType: string): Observable<any> {
    return this._http.post(this.baseUrl + 'api/Order/AddSalesOrderLog?ActionType=' + ActionType, value,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  AddUpdVarianceLog(value: any, ActionType: string): Observable<any> {
    return this._http.post(this.baseUrl + 'api/Order/AddUpdVarianceLog?ActionType=' + ActionType, value,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetOrderDetailsDenormalize(orderId, type) {
    return this._http.get(this.baseUrl + 'api/Order/GetOrderDetailsDenormalize?orderId=' + orderId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  CompleteHelcimPayment(transactionId: any, orderId: number): Observable<any> {
    return this._http.get(this.baseUrl + 'api/Order/AddCompleteHelcimPayment?transactionId=' + transactionId + "&orderId=" + orderId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  fetchZpl(url: string): Observable<Blob> {
    return this._http.get(url, { responseType: 'blob' });
  }

  printPDF(base64PDF: string, printerIp: string) {
    const requestData = { base64PDF, printerIp };
    return this._http.post(`${this.baseUrl}api/PurchaseOrder/pdf`, requestData);
  }

  printZPL(zplCommand: string, printerIp: string) {
    const requestData = { ZPLCommand: zplCommand, printerIp };
    return this._http.post(`${this.baseUrl}api/PurchaseOrder/zpl`, requestData);
  }

  private errorHandler(err: HttpErrorResponse): Observable<never> {
    // just a test ... more could would go here
    return throwError(() => err.message || "server error.");
  }

  // errorHandler(error: HttpErrorResponse) {
  //   return throwError(error.message || "server error.");
  // }

  GetZplString(obj: any) {
    return this._http.post(this.baseUrl + 'api/PurchaseOrder/GetZPLString', obj,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetSwalpopupModel(title: string, message: string) {
    Swal.fire({
      title: title,
      text: message,
      showCancelButton: true,
      cancelButtonText: 'Close',
    })
  }

  public getIPAddress() {
    return this._http.get("http://api.ipify.org/?format=json");
  }
}

