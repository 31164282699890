import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, Subject, switchMap } from 'rxjs';
import { ConstantsService } from 'src/app/constants.service';
import { searchfiltersEnum } from 'src/app/enums/commonenums';
import { GlobalChangeService } from 'src/app/services/global-change.service';
import { PurchaseOrderService } from 'src/app/services/purchaseOrder/purchase-order.service';
import { ISearchFilters, ISearchRequest } from 'src/app/shared/commonInterfaces';
import { PurchaseOrder } from 'src/app/shared/purchaseOrder';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-receiving-list',
  templateUrl: './receiving-list.component.html',
  styleUrls: ['./receiving-list.component.css']
})
export class ReceivingListComponent implements OnInit {
  private searchSubject = new Subject<ISearchRequest>();
  loader: boolean = false;
  companyId: number = 0;
  dataSource: MatTableDataSource<PurchaseOrder>;
  displayedColumns: string[] = this.constants.ReceivingListColumns;
  statuslist: any = [];
  poPartStatusList: any = [];
  userId: string = '';
  SearchTypes: any = this.constants.masterSearchFilterList;
  defaultSearchValue: any = searchfiltersEnum.All;
  ReceivingSearchPlaceHolder: string = this.constants.ReceivingSearchPlaceHolder;
  searchText: string = '';
  dataS: any = [];

  constructor(
    private POService: PurchaseOrderService,
    private constants: ConstantsService,
    private globalChangeService: GlobalChangeService,
    public datepipe: DatePipe,
    private router: Router
  ){
    this.getStatusList();
    this.searchSubject.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((obj: ISearchRequest) =>{
        return this.POService.GetReceivings(obj);
      })
    ).subscribe(data => {
      this.dataS = data.dataList;
      this.dataSource = new MatTableDataSource(this.dataS);
    },
    error => {
      this.PopupModal(this.constants.APIError, this.constants.SomethingWentWrong);
    });
  }

  ngOnInit(): void{
    this.companyId = this.globalChangeService.getGlobalCompany();
    if (this.companyId > 0) {
      this.dataS =[];
      const obj: ISearchRequest = {
        CompanyId: this.companyId,
        SearchType: searchfiltersEnum.Active
      }
      this.getRecieveList(obj);
    } else {
      this.PopupModal(this.constants.APIError, this.constants.SelectCompany);
    }
    //on change function 
    this.globalChangeService.dropdownChange$.subscribe((data) => {
      this.companyId = data;
      this.dataS =[];
      const obj: ISearchRequest = {
        CompanyId: this.companyId,
        SearchType: searchfiltersEnum.Active
      }
      this.getRecieveList(obj);
    })
    this.userId = localStorage.getItem('userId');
  }

  getRecieveList(object: ISearchRequest) {
    this.loader = true;
    this.POService.GetReceivings(object).subscribe((data: any) => {
      this.loader = false;
      this.dataS = data.dataList;
      this.dataSource = new MatTableDataSource(this.dataS);
    },
    error => {
      this.loader = false;
      this.PopupModal(this.constants.APIError, this.constants.SomethingWentWrong);
    })
  }

  Search(result: ISearchFilters){
    const obj: ISearchRequest = {
      CompanyId: this.companyId,
      SearchType: result.searchStatus,
      SearchTerm: result.SearchText,
    }
    //this.getRecieveList(obj);
    this.searchSubject.next(obj);
  }

  getStatusList() {
    this.POService.GetPOStatus().subscribe(
      (data: any) => {
        this.statuslist = data.filter(x=>x.isPurchaseOrder == true).sort((a, b) => a.poOrdering - b.poOrdering);
        this.poPartStatusList = data.filter(x=>x.isPOPart == true).sort((a, b) => a.poPartOrdering - b.poPartOrdering);
      }
    )
  }

  getStatusKey(id: any){
    if (this.statuslist.length > 0 && id>0) {
      const list = this.statuslist.filter(x => x.statusId == Number(id));
      if (list.length > 0) {
        return list[0].statusName;
      }
    }
    return '';
  }

  getPOById(row: any){
    this.router.navigate(['/receiving', row.companyId, row.id])
  }

  PopupModal(title: string, message: string) {
    Swal.fire({
      title: title,
      text: message,
      showCancelButton: true,
      cancelButtonText: 'Close',
    })
  }
  
}
