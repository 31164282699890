import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, Subject, switchMap } from 'rxjs';
import { ConstantsService } from 'src/app/constants.service';
import { searchfiltersEnum } from 'src/app/enums/commonenums';
import { GlobalChangeService } from 'src/app/services/global-change.service';
import { InventoryService } from 'src/app/services/inventory/inventory.service';
import { ISearchFilters, ISearchRequest } from 'src/app/shared/commonInterfaces';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-inventory-list',
  templateUrl: './inventory-list.component.html',
  styleUrls: ['./inventory-list.component.css','../inventory-details.component.css']
})
export class InventoryListComponent implements OnInit{
  private searchSubject = new Subject<ISearchRequest>();
  loader: boolean = false;
  data: any;
  OnHand: any = 0;
  OnHandTotal: any = 0.00;
  Available: any = 0;
  AvailableTotal: any = 0.00;
  Committed: any = 0;
  CommittedTotal: any = 0.00;
  BackOrdered: any = 0;
  BackOrderedTotal: any = 0.00;
  // MasterInvType: number[] = [0];
  MasterInvType: number[] = localStorage.getItem('invfilters') == null ? localStorage.setItem('invfilters', JSON.stringify([0])) : JSON.parse(localStorage.getItem('invfilters'));
  invMasterList: any = [];
  masterList: any = [];
  rolePermissionsList: any;
  permissionMessage: string;
  companyId: number = 0;
  InvTypeList: any = [];
  InventorySearchPlaceHolder: string = this.constants.InventorySearchPlaceHolder;
  invListColumns: string[] = this.constants.invListColumns;
  PartsCount: number = 0;
  SearchTypes: any = this.constants.masterSearchFilterList;
  defaultSearchValue: any = searchfiltersEnum.All;

  constructor(
    private inventoryService: InventoryService,
    private router: Router,
    private globalChangeService: GlobalChangeService,
    private constants: ConstantsService
  ){
    this.GetInventoryType();
    this.searchSubject.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((obj: ISearchRequest) =>{
        return this.inventoryService.GetInventoryItemsList(obj);
      })
    ).subscribe(data => {
      this.data = data;
      this.invMasterList = this.data.data;
    },
    error => {
      this.PopupModal(this.constants.APIError, this.constants.SomethingWentWrong);
    });
  }

  ngOnInit(): void {
    //For this we have to create this page in pages table
    if (localStorage.getItem('AuthHeader') === null || localStorage.getItem('AuthHeader') === undefined) {
      this.router.navigate(['']);
    }
    this.rolePermissionsList = JSON.parse(localStorage.getItem('rolePermissionsList'));
    this.rolePermissionsList = this.rolePermissionsList.filter(x => x.pageId == 1018);
    this.permissionMessage = '';
    if (this.rolePermissionsList != null && this.rolePermissionsList != undefined) {
      if (!this.rolePermissionsList[0].isView) {
        this.permissionMessage += 'view';
      } if (!this.rolePermissionsList[0].isInsert) {
        if (this.permissionMessage != '') {
          this.permissionMessage += ', ';
        }
        this.permissionMessage += 'add';
      } if (!this.rolePermissionsList[0].isUpdate) {
        if (this.permissionMessage != '') {
          this.permissionMessage += ', ';
        }
        this.permissionMessage += 'edit';
      } if (!this.rolePermissionsList[0].isDelete) {
        if (this.permissionMessage != '') {
          this.permissionMessage += ', ';
        }
        this.permissionMessage += 'delete';
      }
    }
    if (this.permissionMessage != '') {
      this.permissionMessage = 'You do not have access to ' + this.permissionMessage + '. Please contact to administrator!';
    }
    // get company by default set value
    var company = this.globalChangeService.getGlobalCompany();
    if (company > 0) {
      this.CompanyDepedsFn(company);
    } else {
      this.PopupModal(this.constants.APIError, this.constants.SelectCompany);
    }
    //on change function 
    this.globalChangeService.dropdownChange$.subscribe((data) => {
      this.CompanyDepedsFn(data);
    })
  }

  CompanyDepedsFn(comapanyId: number) {
    this.companyId = comapanyId;
    this.CalculateInventoryQuantity(this.companyId);
    this.invMasterList = [];
    const obj: ISearchRequest = {
      CompanyId: this.companyId,
      SearchType: searchfiltersEnum.Active
    }
    this.GetMasterTableData(obj, true);
  }

  CalculateInventoryQuantity(companyId) {
    this.inventoryService.GetCalculateInventoryQuantity(companyId).subscribe(
      (data: any) => {
        const response = data.data;
        if (response != null) {
          this.OnHand = response.onHand;
          this.OnHandTotal = response.onHandTotal;
          this.Available = response.available;
          this.AvailableTotal = response.availableTotal;
          this.Committed = response.committed;
          this.CommittedTotal = response.committedTotal;
          this.BackOrdered = response.backOrdered;
          this.BackOrderedTotal = response.backOrderedTotal;
          this.PartsCount = response.partsCount;
        }
      },
      (error) => {
        this.PopupModal(this.constants.APIError, error);
      }
    );
  }

  GetInventoryType() {
    this.inventoryService.GetInventoryType().subscribe(
      (data: any) => {
        this.InvTypeList = data.data;
      }
    )
  }

  Search(result: ISearchFilters){
    const obj: ISearchRequest = {
      CompanyId: this.companyId,
      SearchType: result.searchStatus,
      SearchTerm: result.SearchText,
    }
    //this.GetMasterTableData(obj);
    this.searchSubject.next(obj);
  }

  OnMaterInvTypeChange() {
    let x = this.MasterInvType.indexOf(0);
    if(x > -1){
      this.invMasterList = this.masterList;
    }else{
      this.invMasterList = this.masterList.filter(x => this.MasterInvType.includes(x.inventoryType));
    }
    localStorage.setItem('invfilters', JSON.stringify(this.MasterInvType))
  }

  PopupModal(title: string, message: string) {
    Swal.fire({
      title: title,
      text: message,
      showCancelButton: true,
      cancelButtonText: 'Close',
    })
  }

  GetMasterTableData(obj: ISearchRequest, isOnLoad?: boolean) {
    this.loader = true;
    this.inventoryService.GetInventoryItemsList(obj).subscribe(
      data => {
        this.loader = false;
        this.data = data;
        this.invMasterList = this.data.data;
        if(isOnLoad){
          this.masterList = this.data.data;
          this.OnMaterInvTypeChange();
        }
      },
      error => {
        this.loader = false;
        this.PopupModal(this.constants.APIError, this.constants.SomethingWentWrong);
      });
  }

  GetInventoryTypeValue(id): string {
    if (this.InvTypeList.length > 0 && id > 0) {
      const list = this.InvTypeList.filter(x => x.typeId == Number(id));
      if (list.length >0) {
        return list[0].typeValue;
      }
    }
    return '';
  }

  selectedRow(row) {
    this.router.navigate(['/inventory-detail', row.companyId ,row.item])
  }
}

