import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError, shareReplay } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { ISearchRequest } from 'src/app/shared/commonInterfaces';

@Injectable({
  providedIn: 'root'
})
export class PartService {
  baseUrl = environment.baseUrl;
  private uomListCache$: Observable<any[]> | null = null;
  private partTrackingCache$: Observable<any[]> | null = null;

  constructor(private _http: HttpClient) { }

  UploadPart(formData: any, pram1: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/Part/AddUploadPart?companyId=' + pram1, formData,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetAllPart(companyId: any, searchTerm: any, offsetNo: any, pageSize: any, type: any) {
    return this._http.get(this.baseUrl + 'api/Part/GetAllPart?companyId=' + companyId + '&searchTerm=' + searchTerm + '&offsetNo=' + offsetNo + '&pageSize=' + pageSize + '&type=' + type,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  
  GetPartList(reqObj:ISearchRequest) {
    return this._http.post(this.baseUrl + 'api/Part/GetPartList', reqObj, 
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetAllPartForReports(reqObj:any) {
    return this._http.post(this.baseUrl + 'api/Part/GetAllReportPart', reqObj, 
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetAllPartReportsCSV(reqObj:any) {
    return this._http.post(this.baseUrl + 'api/Part/GetAllReportPartCSV', reqObj, 
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetAllPart1(companyId: any, searchTerm: any, offsetNo: any, pageSize: any) {
    return this._http.get(this.baseUrl + 'api/Part/GetAllPartType?companyId=' + companyId + '&searchTerm=' + searchTerm + '&offsetNo=' + offsetNo + '&pageSize=' + pageSize,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  DeletePart(item: any): Observable<any> {

    return this._http.post(this.baseUrl + 'api/Part/DeletePart?partId=' + item, {},
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }


  Getvendordropdownlist(companyId) {
    return this._http.get(this.baseUrl + 'api/Part/GetVendorDropdownList?companyId=' + companyId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  Getmultivendordropdownlist(companyId) {
    return this._http.get(this.baseUrl + 'api/Part/GetMultiVendorDropdownlist?companyId=' + companyId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetpartGategoryDropdownList(companyId, searchTerm) {
    return this._http.get(this.baseUrl + 'api/Part/GetpartCategoryDropdownList?companyId=' + companyId + '&searchTerm=' + searchTerm,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  Getquestiondropdownlist(companyId) {
    return this._http.get(this.baseUrl + 'api/Part/GetPartQuestionDropdownList?companyId=' + companyId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  AddPart(item: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/Part/AddPart', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  callImageBlobApi(image: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/Part/UpdateImageBlobURL', image,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  // Add FFPart by Shahbaz
  AddFFPart(item: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/Part/AddFFPart', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  EditFFPart(item: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/Part/UpdateFFPart', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetFFParts(obj) {
    return this._http.post(this.baseUrl + 'api/Part/GetFFParts', obj,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
    // return this._http.get(this.baseUrl + 'api/Part/GetFFParts?productId=' + productId + '&partCategories=' + partCategories,
    //   { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
    //   .pipe(catchError(this.errorHandler));
  }
  DeleteFFParts(partId) {
    return this._http.delete(this.baseUrl + 'api/Part/DeleteFFPart?partId=' + partId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetPartById(id: any) {
    return this._http.get(this.baseUrl + 'api/Part/GetPartDetailsById?partId=' + id, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetApiDetailsById(id: any, companyId: number) {

    return this._http.get(this.baseUrl + 'api/Part/GetApiDetailsById?partId=' + id, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetCategoryDetails(id: any) {

    return this._http.get(this.baseUrl + 'api/Part/GetCategoryDetails?Id=' + id, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetPartHistoryDetails(id: any) {

    return this._http.get(this.baseUrl + 'api/Part/GetPartHistoryDetails?partId=' + id, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  UpdatePart(item: any): Observable<any> {
    return this._http.put(this.baseUrl + 'api/Part/UpdatePart', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  UpdatePartWp(item: any) {
    return this._http.get(this.baseUrl + 'api/Part/UpdatePartWP?partId=' + item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  getPartDetailsById(id: any) {
    return this._http.get(this.baseUrl + 'api/Part/GetPartDetailsById?partId=' + id,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetPartDetailsByPartCategoryId(id: any) {
    return this._http.get(this.baseUrl + 'api/Part/GetPartDetailsByPartCategoryId?partCategoryId=' + id,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  getAllPart(id) {
    return this._http.get(this.baseUrl + 'api/Part/GetAllPart?userId=' + id,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  
  getAllPartByCategoryId(id, companyId) {
    return this._http.get(this.baseUrl + 'api/Part/GetPartDetailsByPartCategoryId?partCategoryId=' + id + '&companyId=' + companyId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  getAllPartDetailsByPartId1(id, customerID) {

    return this._http.get(this.baseUrl + 'api/Part/GetAllPartDetailsById?partId=' + id + '&customerID=' + customerID,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetCustomPartById(id: any) {
    return this._http.get(this.baseUrl + 'api/Part/GetCustompartDetailsById?partId=' + id, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetAllCustomPart(companyId: any, searchTerm: any, offsetNo: any, pageSize: any, type: any) {

    return this._http.get(this.baseUrl + 'api/Part/GetAllCustomPart?companyId=' + companyId + '&searchTerm=' + searchTerm + '&offsetNo=' + offsetNo + '&pageSize=' + pageSize + '&type=' + type,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  AddCustomPart(item: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/Part/AddCustomPart', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetCustomPartDetailsById(id: any) {
    return this._http.get(this.baseUrl + 'api/Part/GetCustomPartById?partId=' + id, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  UpdateCustomPart(item: any): Observable<any> {
    return this._http.put(this.baseUrl + 'api/Part/UpdateCustomPart', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  UnitofMeasurement(): Observable<any[]> {
    if(!this.uomListCache$){
      this.uomListCache$ = this._http.get<any[]>(this.baseUrl + 'api/Part/GetUnitofMeasureDropdownList',
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(shareReplay(1), catchError(this.errorHandler));
    }
    return this.uomListCache$;
  }

  FobPointsDropdownList() {
    return this._http.get(this.baseUrl + 'api/Part/GetFobPointDropdownList',
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  PartInvOrderDropdownList(id: any, companyId: any) {
    return this._http.get(this.baseUrl + 'api/Part/GetPartInvOrderDropdownList?OrderTypeId=' + id + '&companyId=' + companyId, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  PartInvOrderDropdownList1(id: any, companyId: any, partId: any) {
    return this._http.get(this.baseUrl + 'api/Part/GetPartInvOrderDropdownListByPart?OrderTypeId=' + id + '&companyId=' + companyId + '&partId=' + partId, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  DeletePartInventory(item: any): Observable<any> {

    return this._http.post(this.baseUrl + 'api/Part/DeletePartInventory?invId=' + item, {},
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetAllImageTypeList() {
    return this._http.get(this.baseUrl + 'api/Part/GetImagePartDropdown',
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  //For Wordpress Api Details

  GetAllWordpressCategory(companyId: any) {
    return this._http.get(this.baseUrl + 'api/Part/GetAllWordpressCategory?companyId=' + companyId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetAllWordpressProduct(companyId: any) {
    return this._http.get(this.baseUrl + 'api/Part/GetAllWordpressProduct?companyId=' + companyId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetAllCustomerPartNameByCompanyId(companyId: any, Id: number) {
    return this._http.get(this.baseUrl + 'api/Part/GetAllCustomerPartNameByCompanyId?companyId=' + companyId + '&Id=' + Id,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetAllCustomerPartNameSelected(companyId: any, Id: number, partId: number, name: string) {
    return this._http.get(this.baseUrl + 'api/Part/GetAllCustomerPartNameSelected?companyId=' + companyId + '&Id=' + Id + '&partId=' + partId + '&name=' + name,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  CheckInvetoryExist(partId) {
    return this._http.get(this.baseUrl + 'api/Part/GetCheckInvetoryExist?partId=' + partId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  RemoveInventory(id: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/Part/DeleteNonInventoryPart?partId=' + id, {},
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  // Get Part By Category
  getAllCategoryParts(companyId, categoryId, partnamenumber, offsetNo, pageSize) {
    return this._http.get(this.baseUrl + 'api/Part/GetCategoryPartList?companyId=' + companyId + '&categoryId=' + categoryId + '&partname=' + partnamenumber + '&offsetNo=' + offsetNo + '&pageSize=' + pageSize,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetSerialExist(serial: any, partId: any, type: any) {
    return this._http.get(this.baseUrl + 'api/Part/GetSerialExist?serialNo=' + serial + '&partId=' + partId + '&type=' + type,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  ////Part Export Report
  // GetAllPartWithoutPagging(companyId: any, searchTerm: any, type: any) {
  //   return this._http.get(this.baseUrl + 'api/Part/GetAllPartWithoutPagging?companyId=' + companyId + '&searchTerm=' + searchTerm + '&type=' + type,
  //     { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
  //     .pipe(catchError(this.errorHandler));
  // }

  //Custom Export Report 
  GetAllCustomPartWithoutPagging(companyId: any, searchTerm: any, type: any) {
    return this._http.get(this.baseUrl + 'api/Part/GetAllCustomPartWithoutPagging?companyId=' + companyId + '&searchTerm=' + searchTerm + '&type=' + type,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetWordpressProducts(companyid: number, page: number, per_page: number) {
    return this._http.get(this.baseUrl + 'api/Part/GetWordpressProducts?ComapnyId=' + companyid + '&page=' + page + '&per_page=' + per_page,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  AddWPProductstoERP(wordpressProductList: object, companyid: number) {
    return this._http.post(this.baseUrl + 'api/Part/AddWPProductstoERP?CompanyId=' + companyid, wordpressProductList,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetAllPartWithoutPagging(companyId: any) {
    return this._http.get(this.baseUrl + 'api/Part/GetAllPartWithoutPagging?companyId=' + companyId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetPartsByCompanyId(companyId: any) {
    return this._http.get(this.baseUrl + 'api/Part/GetPartsByCompanyId?companyId=' + companyId, 
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetPartTrackingList(): Observable<any[]> {
    if(!this.partTrackingCache$){
      this.partTrackingCache$ = this._http.get<any[]>(this.baseUrl + 'api/Part/GetPartTrackingList',
        { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
        .pipe(shareReplay(1), catchError(this.errorHandler));
    }
    return this.partTrackingCache$;
  }

  AddWPProduct(partId: number, companyId: number) {
    return this._http.post(this.baseUrl + 'api/Part/AddWPProduct?partId=' + partId + '&companyId=' + companyId, '',
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  AddWPProductDiscount(partId: number, companyId: number) {
    return this._http.post(this.baseUrl + 'api/Part/AddWPProductDiscount?partId=' + partId + '&companyId=' + companyId, '',
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  //Error Handler Method
  errorHandler(error: HttpErrorResponse) {
    return throwError(error.message || "server error.");
  }
}
