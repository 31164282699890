import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap, shareReplay } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { IInventoryItems, IInventoryItemWithCategoryId } from 'src/app/shared/inventoryItem';
import { ISearchRequest } from 'src/app/shared/commonInterfaces';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InventoryService {
  private baseUrl = environment.baseUrl;
  private inventoryTypesCache$: Observable<any[]> | null = null;

  constructor(private _http: HttpClient) { }

  GetInventoryType(): Observable<any[]>{
    if(!this.inventoryTypesCache$){
      this.inventoryTypesCache$ = this._http.get<any[]>(this.baseUrl + 'api/Inventory/GetInventoryType',
        { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
        .pipe(
          // tap(data => console.log(data)), //only for debugging
          shareReplay(1), // caches the result for subsequet calls
          catchError(this.errorHandler)
        );
    }
    return this.inventoryTypesCache$;
  }

  GetInventoryAction(){
    return this._http.get(this.baseUrl + 'api/Inventory/GetInventoryAction',
    { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
    .pipe(catchError(this.errorHandler));
  }

  GetInventoryActive(){
    return this._http.get(this.baseUrl + 'api/Inventory/GetInventoryActive',
    { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
    .pipe(catchError(this.errorHandler));
  }

  GetInventoryStatus(){
    return this._http.get(this.baseUrl + 'api/Inventory/GetInventoryStatus',
    { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
    .pipe(catchError(this.errorHandler));
  }
  
  GetInventoryDocType(){
    return this._http.get(this.baseUrl + 'api/Inventory/GetInventoryDocType',
    { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
    .pipe(catchError(this.errorHandler));
  }

  GetInventoryReason(){
    return this._http.get(this.baseUrl + 'api/Inventory/GetInventoryReason',
    { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
    .pipe(catchError(this.errorHandler));
  }

  CreateInventory(item: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/Inventory/AddInventory', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetInventoryItems(reqObj: IInventoryItems){
    return this._http.post(this.baseUrl + 'api/Inventory/GetInventoryItems', reqObj,
    { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetInventoryItemsList(reqObj: ISearchRequest){
    return this._http.post(this.baseUrl + 'api/Inventory/GetInventoryItems', reqObj,
    { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  // Created by shail for Inventory Report 
  GetInventoryReport(reqObj: IInventoryItemWithCategoryId){
    return this._http.post(this.baseUrl + 'api/Inventory/GetInventoryReportItems', reqObj,
    { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetInventoryList(companyId, partId){
    return this._http.get(this.baseUrl + 'api/Inventory/GetInventoryList?companyId='+companyId+'&partId='+partId,
    { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetBOList(companyId, partId){
    return this._http.get(this.baseUrl + 'api/Inventory/GetBOList?companyId='+companyId+'&partId='+partId,
    { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetPOList(companyId, partId){
    return this._http.get(this.baseUrl + 'api/Inventory/GetPOList?companyId='+companyId+'&partId='+partId,
    { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetSerialLotList(companyId: number, partId: number, inventoryId: number, type: number){
    return this._http.get(this.baseUrl + 'api/Inventory/GetSerialLotList?companyId='+companyId+'&partId='+partId+'&inventoryId='+inventoryId+'&Type='+type,
    { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  AddSerialLotList(requestObj, companyId){
    return this._http.post(this.baseUrl + 'api/Inventory/AddSerialLotList?companyId='+companyId,requestObj,
    { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetBatchList(companyId: number, partId: number){
    return this._http.get(this.baseUrl + 'api/Inventory/GetBatchList?companyId='+companyId+'&partId='+partId,
    { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetInvetoryByBatchNo(companyId, batchNo, partId){
    return this._http.get(this.baseUrl + 'api/Inventory/GetInvetoryByBatchNo?companyId='+companyId+'&BatchNo='+batchNo+'&partId='+partId,
    { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetInvetoryListByBatchNo(companyId, batchNo, partId){
    return this._http.get(this.baseUrl + 'api/Inventory/GetInvetoryListByBatchNo?companyId='+companyId+'&BatchNo='+batchNo+'&partId='+partId,
    { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetLocationLevelsPositions(companyId: number, locationId: number){
    return this._http.get(this.baseUrl + 'api/LocationLevels/GetLocationLevelsPositions?companyId='+companyId+'&locationId='+locationId,
    { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetCalculateInventoryQuantity(companyId: number){
    return this._http.get(this.baseUrl + 'api/Inventory/GetCalculateInventoryQuantity?companyId='+companyId,
    { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetCalculateInventoryQuantityById(companyId: number, partId: number){
    return this._http.get(this.baseUrl + 'api/Inventory/GetCalcInvQuantityByPartId?companyId='+companyId+'&partId='+partId,
    { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  AddInventoryLocation(object: any, isDefault: boolean){
    return this._http.post(this.baseUrl + 'api/Inventory/AddInventoryLocation?isDefault='+isDefault ,object,
    { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetDefaultInventoryLocation(id: number){
    return this._http.get(this.baseUrl + 'api/Inventory/GetDefaultInventoryLocation?id='+id,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
        .pipe(catchError(this.errorHandler));
  }

  UploadInventory(formData: any, pram1: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/Inventory/AddUploadInventory?companyId=' + pram1, formData,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  //Error Handler Method
  errorHandler(error: HttpErrorResponse) {
    return throwError(error.message || "server error.");
  }
}
