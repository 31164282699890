
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PurchaseOrder } from 'src/app/shared/purchaseOrder';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PurchaseOrderService } from 'src/app/services/purchaseOrder/purchase-order.service';
import { GlobalChangeService } from 'src/app/services/global-change.service';
import Swal from 'sweetalert2';
import { ConstantsService } from 'src/app/constants.service';
import { PartService } from 'src/app/services/part/part.service';
import { VendorService } from 'src/app/services/vendor/vendor.service';
import { DatePipe } from '@angular/common';
import * as _ from 'lodash';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
  selector: 'app-po-list',
  templateUrl: './po-list.component.html',
  styleUrls: ['./po-list.component.css'],
})

export class PoListComponent implements OnInit {
  loader: boolean = false;
  FilterData: { id: number; value: string; }[];
  DateFilterData: { id: number; value: string; }[];
  ArrivalFilterDate: { id: number; value: string; }[];
  dataSource: MatTableDataSource<PurchaseOrder>;
  dataS: any = [];
  displayedColumns: string[] = ["menu", "issuedDate", "poNumber", "vendorName", "contactName", "status", "EstArrDate", "payterms", "fobpoint", "shippingTerms"];
  formSearch: FormGroup;
  companyId: number;
  StatusValue: any[] = [];
  IsActive: any = '1';
  vendors: any = [];
  selectedVendorSearch: string;
  maxFromDate: any;
  minToDate: any;
  shippingData: any = [];
  FobPoint: any = [];
  termsData: any = [];
  statuslist: any = [];
  vendorPartNumbers: any;
  defaultStatus: any[] = [];
  isShowDefaultSelected: boolean = false;
  StatusVendorValue: any[] = [];
  statusPo: any[];
  selectedStatus: any;
  isActivePA: { id: number; name: string; }[];
  selectedListStatus: any;
  selectedVendors: any;
  selectedFilterType: any;
  selectedTimePeriod: any;
  selectedFrom: any;
  selectedTO: any;
  selectedVendorPartNo: any;
  isDefaultFilter: boolean;


  constructor(
    private router: Router,
    private POService: PurchaseOrderService,
    private globalChangeService: GlobalChangeService,
    private constants: ConstantsService,
    private partService: PartService,
    private vendorService: VendorService,
    public datepipe: DatePipe,
  ) {
    this.FilterData = this.constants.POFilter;
    this.DateFilterData = this.constants.CustomDate;
    this.ArrivalFilterDate = this.constants.CustomArrDate;
    this.defaultStatus = this.constants.statusIDsPO;
    this.selectedStatus = JSON.parse(localStorage.getItem("StatusValuePO"));
    this.selectedVendors = JSON.parse(localStorage.getItem("Vendor"));
    this.selectedFilterType = JSON.parse(localStorage.getItem("FilterType"));
    this.selectedTimePeriod = JSON.parse(localStorage.getItem("TimePeriod"));
    this.selectedFrom = JSON.parse(localStorage.getItem("From"));
    this.selectedTO = JSON.parse(localStorage.getItem("TO"));
    this.selectedVendorPartNo = JSON.parse(localStorage.getItem("VendorPartNo"));
    this.isActivePA = constants.isActivePA;
    this.getAllShippings();
    this.createSerchForm();
    this.POStatusList();
    this.getAllTerms();
    this.getFOBPointList();
  }

  ngOnInit(): void {
    this.isDefaultFilter = false;
    this.companyId = this.globalChangeService.getGlobalCompany();
    this.onHandelDefaultValues();
    if (this.companyId > 0 && this.isDefaultFilter == false) {
      this.dataS = [];
      this.getAllPOList(false, this.statusPo);
      this.Getvendordropdownlist(this.companyId);
    } else if (this.companyId > 0 && this.isDefaultFilter != false) {
      this.formSearch.controls['filterType'].setValue(this.selectedFilterType == 7 ? 1 : this.selectedFilterType);
      this.formSearch.controls['vendor'].setValue(this.selectedVendorPartNo != null || this.selectedVendorPartNo != "" ? this.selectedVendors : [0]);
      this.formSearch.controls['vendorValue'].setValue(this.selectedVendorPartNo != null || this.selectedVendorPartNo != undefined ? this.selectedVendorPartNo : '');
      const orderRequest = {
        companyId: this.companyId,
        statusIds: JSON.parse(localStorage.getItem("StatusValuePO")) === null ? this.defaultStatus : JSON.parse(localStorage.getItem("StatusValuePO")),
        vendorIds: this.selectedVendors == "" ? [0] : this.selectedVendors,
        vendorPartNumber: this.selectedVendorPartNo != null || this.selectedVendorPartNo == undefined ? this.selectedVendorPartNo : '',
        fromDate: this.selectedFrom != null ? this.datepipe.transform(this.selectedFrom, 'MM/dd/yyyy') : '',
        toDate: this.selectedTO != null ? this.datepipe.transform(this.selectedTO, 'MM/dd/yyyy') : '',
        filterType: this.selectedFilterType,
        searchText: '',
      }
      this.getDataForFilter(orderRequest);
      this.Getvendordropdownlist(this.companyId);
    } else {
      this.PopupModal(this.constants.APIError, this.constants.SelectCompany);
    }
    //on change function 
    this.globalChangeService.dropdownChange$.subscribe((data) => {
      this.companyId = data;
      this.dataS = [];
      this.getAllPOList(false, this.statusPo);
      this.Getvendordropdownlist(this.companyId);
    })
  }
  onHandelDefaultValues() {
    if (this.selectedStatus != null && _.isEqual(this.selectedStatus, this.defaultStatus)) {
      this.statusPo = this.defaultStatus;
    } else if (this.selectedStatus != null && JSON.stringify(this.selectedStatus) != JSON.stringify(this.defaultStatus)) {
      this.statusPo = this.selectedStatus;
    } else {
      this.statusPo = this.defaultStatus;
    }
    this.selectedListStatus = 1;
    this.formSearch.controls['selected'].setValue(this.selectedListStatus);

    if (this.selectedFilterType != null && this.selectedTimePeriod != null && this.selectedFrom != null
      && this.selectedTO != null && (this.selectedFilterType == 1 || this.selectedFilterType == 7)) {
      localStorage.setItem('Vendor', JSON.stringify([0]));
      localStorage.setItem('VendorPartNo', JSON.stringify(""));
      this.formSearch.controls['filterType'].setValue(this.selectedFilterType);
      this.formSearch.controls['timeperiod'].setValue(this.selectedTimePeriod);
      this.formSearch.controls['prevdate'].setValue(this.selectedFrom);
      this.formSearch.controls['curdate'].setValue(this.selectedTO);
      this.isDefaultFilter = true;
    } else if (this.selectedVendors != null && this.selectedFilterType == 2) {
      localStorage.setItem('TimePeriod', JSON.stringify(""));
      localStorage.setItem('From', JSON.stringify(""));
      localStorage.setItem('TO', JSON.stringify(""));
      localStorage.setItem('VendorPartNo', JSON.stringify(""));
      this.formSearch.controls['filterType'].setValue(this.selectedFilterType);
      this.formSearch.controls['vendor'].setValue(this.selectedVendors);
      this.isDefaultFilter = true;
    } else if (this.selectedVendorPartNo != null && this.selectedFilterType == 3) {
      localStorage.setItem('Vendor', JSON.stringify([0]));
      localStorage.setItem('TimePeriod', JSON.stringify(""));
      localStorage.setItem('From', JSON.stringify(""));
      localStorage.setItem('TO', JSON.stringify(""));
      this.formSearch.controls['filterType'].setValue(this.selectedFilterType);
      this.formSearch.controls['vendorValue'].setValue(this.selectedVendorPartNo);
      this.isDefaultFilter = true;
    }
    else if (this.selectedFilterType == 4 && this.selectedTimePeriod != null && this.selectedFrom != null
      && this.selectedTO != null) {
      localStorage.setItem('Vendor', JSON.stringify([0]));
      localStorage.setItem('VendorPartNo', JSON.stringify(""));
      this.formSearch.controls['filterType'].setValue(this.selectedFilterType);
      this.formSearch.controls['timeperiod'].setValue(this.selectedTimePeriod);
      this.formSearch.controls['prevdate'].setValue(this.selectedFrom);
      this.formSearch.controls['curdate'].setValue(this.selectedTO);
      this.isDefaultFilter = true;
    }
    else if (this.selectedFilterType == 6 && this.selectedTimePeriod != null && this.selectedFrom != null
      && this.selectedTO != null) {
      localStorage.setItem('Vendor', JSON.stringify([0]));
      localStorage.setItem('VendorPartNo', JSON.stringify(""));
      this.formSearch.controls['filterType'].setValue(this.selectedFilterType);
      this.formSearch.controls['timeperiod'].setValue(this.selectedTimePeriod);
      this.formSearch.controls['prevdate'].setValue(this.selectedFrom);
      this.formSearch.controls['curdate'].setValue(this.selectedTO);
      this.isDefaultFilter = true;
    }
  }


  createSerchForm() {
    const today = new Date();
    this.formSearch = new FormGroup({
      "selected": new FormControl(''),
      "searchText": new FormControl(''),
      "filterType": new FormControl(1),
      "status": new FormControl(this.defaultStatus),
      "vendor": new FormControl(''),
      "vendorValue": new FormControl(''),
      "timeperiod": new FormControl(''),
      "prevdate": new FormControl(''),
      "curdate": new FormControl('')
    });
  }

  getAllPOList(isUpdate, selectedStatusIds) {
    this.loader = true;
    selectedStatusIds = this.selectedStatus != null ? this.selectedStatus : this.defaultStatus;
    const obj = {
      companyId: this.companyId,
      statusIds: selectedStatusIds,
      vendorIds: [0],
      vendorPartNumber: '',
      FromDate: '',
      ToDate: '',
      FilterType: 1,
      searchText: this.formSearch.value.searchText == undefined ? '' : this.formSearch.value.searchText,
    }
    this.POService.GetAllPurchaseOrderList(obj).subscribe({
      next: (data: any) => {
        this.loader = false;
        this.dataS = this.dataS.concat(data.dataList);
        this.dataS = this.removeDuplicates(this.dataS, 'id');
        this.dataSource = new MatTableDataSource(this.dataS);
      },
      error: error => {
        this.PopupModal(this.constants.APIError, error);
      },
      complete: () => {
      }
    });
  }

  removeDuplicates(myArray, Prop) {
    return myArray.filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj[Prop]).indexOf(obj[Prop]) === pos;
    });
  }

  onScroll(e) {
    const tableViewHeight = e.target.offsetHeight // viewport: ~500px
    const tableScrollHeight = e.target.scrollHeight // length of all table
    const scrollLocation = e.target.scrollTop; // how far user scrolled
    // If the user has scrolled within 200px of the bottom, add more data
    const buffer = 200;
    const limit = tableScrollHeight - tableViewHeight - buffer;
    if (scrollLocation > limit) {
      //this.getAllPOList(true,this.defaultStatus);
      this.getFilterStatusData();
    }
  }

  Getvendordropdownlist(companyId) {
    if (companyId == undefined) { companyId = 0; }
    this.partService.Getvendordropdownlist(this.companyId).subscribe(
      (data: any) => {
        if (companyId > 0) {
          this.vendors = data.dataList;
        }
        else {
          this.selectedVendorSearch = '0';
        }
      }
    )
  }

  createNewOrder() {
    if (this.companyId > 0) {
      this.router.navigate(['/poDetails'])
    } else {
      this.PopupModal(this.constants.Alert, this.constants.SelectCompany);
    }
  }

  onInput(event) {
    this.vendorPartNumbers = event.target.value;
  }

  getDataForFilter(orderRequest) {
    this.loader = true;
    this.POService.GetAllPurchaseOrderList(orderRequest).subscribe((data: any) => {
      this.loader = false;
      this.dataSource = new MatTableDataSource(data.dataList);
    }, error => {
      this.loader = false;
      this.PopupModal(this.constants.APIError, error);
    })
  }

  fromDateChange(type: string, event: MatDatepickerInputEvent<Date>) {
  }

  toDateChange(type: string, event: MatDatepickerInputEvent<Date>) {
  }

  onSearch() {
    this.dataS = [];
    this.GetPOListONStatusChange();
  }

  getFilterStatusData() {
    const value = this.formSearch.value.filterType;
    localStorage.setItem('FilterType', JSON.stringify(this.formSearch.value.filterType));
    localStorage.setItem('TimePeriod', JSON.stringify(this.formSearch.value.timeperiod));
    localStorage.setItem('From', JSON.stringify(this.formSearch.value.prevdate));
    localStorage.setItem('TO', JSON.stringify(this.formSearch.value.curdate));
    localStorage.setItem('Vendor', JSON.stringify(this.formSearch.value.vendor));
    localStorage.setItem('VendorPartNo', JSON.stringify(this.formSearch.value.vendorValue));
    var date = new Date();
    const orderRequest = {
      companyId: this.companyId,
      statusIds: JSON.parse(localStorage.getItem("StatusValuePO")) === null ? this.defaultStatus : JSON.parse(localStorage.getItem("StatusValuePO")),
      vendorIds: [0],
      vendorPartNumber: '',
      fromDate: '',
      toDate: '',
      filterType: value,
      searchText: '',
    }
    switch (value) {
      case 1:
        orderRequest.vendorIds = [0];
        if (orderRequest.statusIds != null || orderRequest.statusIds != undefined) {
          if (this.formSearch.value.prevdate === '' || this.formSearch.value.curdate === '') {
            this.getDataForFilter(orderRequest);
          } else if (this.formSearch.value.prevdate != '' || this.formSearch.value.curdate != '') {
            orderRequest.filterType = 7;
            localStorage.setItem('FilterType', JSON.stringify(orderRequest.filterType));
            orderRequest.fromDate = this.datepipe.transform(this.formSearch.value.prevdate, 'MM/dd/yyyy');
            orderRequest.toDate = this.datepipe.transform(date.setDate(date.getDate() + 1), 'MM/dd/yyyy');
            this.getDataForFilter(orderRequest);
          }
        }
        break;
      case 2:
        orderRequest.statusIds = [0];
        this.statusPo = [];
        orderRequest.vendorIds = this.StatusVendorValue.map(str => parseInt(str, 10));
        if (orderRequest.vendorIds != null || orderRequest.vendorIds != undefined) {
          this.getDataForFilter(orderRequest);
        }
        break;
      case 3:
        orderRequest.vendorIds = [0];
        orderRequest.statusIds = [0];
        orderRequest.vendorPartNumber = this.vendorPartNumbers;
        this.getDataForFilter(orderRequest);
        break;
      case 4:
        orderRequest.vendorIds = [0];
        orderRequest.statusIds = [0];
        orderRequest.fromDate = '';
        orderRequest.toDate = '';
        orderRequest.fromDate = this.datepipe.transform(this.formSearch.value.prevdate, 'MM/dd/yyyy');
        orderRequest.toDate = this.datepipe.transform(date.setDate(date.getDate() + 1), 'MM/dd/yyyy');
        this.getDataForFilter(orderRequest);
        break;
      case 6:
        orderRequest.vendorIds = [0];
        orderRequest.statusIds = [0];
        orderRequest.fromDate = '';
        orderRequest.toDate = '';
        orderRequest.fromDate = this.datepipe.transform(this.formSearch.value.prevdate, 'MM/dd/yyyy');
        orderRequest.toDate = this.datepipe.transform(date.setDate(date.getDate() + 1), 'MM/dd/yyyy');
        this.getDataForFilter(orderRequest);
        break;
    }
  }

  GetStatus($event) {
    this.isShowDefaultSelected = true;
    this.StatusValue = $event.value;
    localStorage.setItem('StatusValuePO', JSON.stringify(this.StatusValue))
  }


  GetVendor($event) {
    this.isShowDefaultSelected = false;
    this.StatusVendorValue = $event.value;
  }

  PopupModal(title: string, message: string) {
    Swal.fire({
      title: title,
      text: message,
      showCancelButton: true,
      cancelButtonText: 'Close',
    })
  }

  POStatusList() {
    this.POService.GetPOStatus().subscribe(
      (data: any) => {
        this.statuslist = data.filter(x => x.isPurchaseOrder == true).sort((a, b) => a.poOrdering - b.poOrdering);
      }
    )
  }

  GetDateValue() {
    const today = new Date();
    const timeperiod = this.formSearch.value.timeperiod;
    if (this.formSearch.value.filterType === 4) {
      switch (timeperiod) {
        case 1:
          this.formSearch.patchValue({
            curdate: today,
            prevdate: new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000)
          })
          break;
        case 2:
          this.formSearch.patchValue({
            curdate: today,
            prevdate: new Date(today.getTime() - 2 * 30 * 24 * 60 * 60 * 1000)
          })
          break;
        case 3:
          this.formSearch.patchValue({
            curdate: today,
            prevdate: new Date(today.getTime() - 3 * 30 * 24 * 60 * 60 * 1000)
          })
          break;
        case 4:
          this.formSearch.patchValue({
            curdate: today,
            prevdate: new Date(today.getFullYear(), 0, 1)
          })
          break;
        default:
          this.formSearch.patchValue({
            curdate: '',
            prevdate: ''
          })
          break;
      }
    } else if (this.formSearch.value.filterType === 6 || this.formSearch.value.filterType === 1) {
      switch (timeperiod) {
        case 1:
          this.formSearch.patchValue({
            curdate: today,
            prevdate: new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000)
          })
          break;
        case 2:
          this.formSearch.patchValue({
            curdate: today,
            prevdate: new Date(today.getFullYear(), today.getMonth())
          })
          break;
        case 3:
          this.formSearch.patchValue({
            curdate: today,
            prevdate: new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay())
          })
          break;
        case 4:
          this.formSearch.patchValue({
            curdate: today,
            prevdate: today
          })
          break;
        default:
          this.formSearch.patchValue({
            curdate: '',
            prevdate: ''
          })
          break;
      }
    }
  }

  getAllShippings() {
    this.vendorService.getAllShippings().subscribe(
      (data: any) => {
        this.shippingData = data.dataList;
      });
  }

  GetShippingTermKey(id: any) {
    if (this.shippingData.length > 0 && id > 0) {
      const list = this.shippingData.filter(x => x.id == Number(id));
      if (list.length > 0) {
        return list[0].name;
      }
    }
    return '';
  }

  getFOBPointList() {
    this.partService.FobPointsDropdownList().subscribe(
      (data: any) => {
        this.FobPoint = data.dataList;
      }
    )
  }

  GetFOBKey(id: any) {
    if (this.FobPoint.length > 0 && id > 0) {
      const list = this.FobPoint.filter(x => x.id == Number(id));
      if (list.length > 0) {
        return list[0].name;
      }
    }
    return '';
  }

  getAllTerms() {
    this.vendorService.getAllTerms().subscribe(
      (data: any) => {
        this.termsData = data.dataList;
      });
  }

  GetTermKey(id: any) {
    if (this.termsData.length > 0 && id > 0) {
      const list = this.termsData.filter(x => x.id == Number(id));
      if (list.length > 0) {
        return list[0].name;
      }
    }
    return '';
  }

  GetStatusKey(id: any) {
    if (this.statuslist.length > 0 && id > 0) {
      const list = this.statuslist.filter(x => x.statusId == Number(id));
      if (list.length > 0) {
        return list[0].statusName;
      }
    }
    return '';
  }

  GetPOById(row: any) {
    this.router.navigate(['/poDetails', row.id])
  }

  GetPOListONStatusChange() {
    const poRequest = {
      Id: this.selectedListStatus,
      searchText: this.formSearch.value.searchText == undefined ? '' : this.formSearch.value.searchText,
    }
    this.POService.GetAllPOListWithStatus(poRequest).subscribe((data: any) => {
      this.dataSource = data.dataList;
    })
  }

  GetActiveStatus(event: any) {
    this.selectedListStatus = event.value;
  }
}

