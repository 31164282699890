import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
// import { PartTilesComponent } from 'src/app/admin/common-components/part-tiles/part-tiles.component';
import { ConstantsService } from 'src/app/constants.service';
import { PartConstantsService } from 'src/app/constants/part-constants.service';
import { searchfiltersEnum } from 'src/app/enums/commonenums';
import { GlobalChangeService } from 'src/app/services/global-change.service';
import { PartService } from 'src/app/services/part/part.service';
import { ISearchFilters, ISearchRequest } from 'src/app/shared/commonInterfaces';
import { IPartList } from 'src/app/shared/part';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-part-list',
  templateUrl: './part-list.component.html',
  styleUrls: ['./part-list.component.css']
})
export class PartListComponent implements OnInit {
  // @ViewChild('partTilesRef') partTiles: PartTilesComponent;
  addNewbtnText: string = this.partcosntants.addPartBtn;
  loader: boolean = false;
  previousPage: string = this.partcosntants.blankString;
  currentPage: string = this.partcosntants.breadcrumListLabel;
  partSearchPlaceHolder: string = this.constants.PartSearchPlaceHolder;
  companyId: number = 0;
  listColumns: string[] = this.partcosntants.partListColumns;
  masterList: IPartList[] = [];
  SearchTypes: any = this.constants.masterSearchFilterList;
  defaultSearchValue: any = searchfiltersEnum.All;

  constructor(
    private globalChangeService: GlobalChangeService, 
    private constants: ConstantsService, 
    private router: Router, 
    private partService: PartService, 
    private partcosntants: PartConstantsService) {
  }

  ngOnInit(): void {
    var company = this.globalChangeService.getGlobalCompany();
    if (company > 0) {
      this.companyId = company;
      const obj: ISearchRequest = {
        CompanyId: this.companyId,
        SearchType: searchfiltersEnum.Active
      }
      this.GetList(obj);
    } else {
      this.PopupModal(this.constants.Error, this.constants.companyId);
    }
    //on change function 
    this.globalChangeService.dropdownChange$.subscribe((data) => {
      this.companyId = data;
      // this.partTiles.CalculateInventoryQuantity(this.companyId);
      const obj: ISearchRequest = {
        CompanyId: this.companyId,
        SearchType: searchfiltersEnum.Active
      }
      this.GetList(obj);
    });
  }

  PopupModal(title: string, message: string) {
    Swal.fire({
      title: title,
      text: message,
      showCancelButton: true,
      cancelButtonText: this.partcosntants.closeLabel,
    })
  }

  AddNewPart() {
    this.router.navigate([this.partcosntants.partDetilsPage, this.companyId]);
  }

  SelectedRow(row: IPartList) {
    this.router.navigate([this.partcosntants.partDetilsPage, row.companyId ,row.id])
  }

  Search(result: ISearchFilters) {
    const obj: ISearchRequest = {
      CompanyId: this.companyId,
      SearchType: result.searchStatus,
      SearchTerm: result.SearchText,
    }
    this.GetList(obj);
  }

  GetList(obj: ISearchRequest) {
    this.partService.GetPartList(obj).subscribe(
      (data: any) => {
        this.masterList = data.dataList;
      },
      error => {
        this.PopupModal(this.constants.Error, this.constants.SomethingWentWrong);
      });
  }
}
