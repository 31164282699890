import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PartConstantsService {

  constructor() { }

  public partListColumns = ['item', 'partNo', 'name', 'vendor', 'uom', 'inventoryType', 'onhand', 'available', 'committed', 'backOrdered', 'unitcost'];
  public breadcrumListLabel = 'Part List';
  public blankString = '';
  public addPartBtn = 'Add New Part';
  public closeLabel = 'Close';
  public partDetilsPage = '/part-details';

  public Status = [
    { type: 'Active', value: true },
    { type: 'In-Active', value: false }
  ];
  public Purpose = [
    { value: 'Resale', key: 1 },
    { value: 'Internal Use', key: 2 },
    { value: 'Ecommerece', key: 3 }
  ];
  public Source = [
    { value: 'Vendor', key: 1 },
    { value: 'Assembly', key: 2 }
  ];
  public PakageTypeList = [
    { value: 'Select', key: 0 },
    { value: 'Your Packaging', key: 1 },
    { value: 'Fedex Envelope', key: 2 },
    { value: 'Fedex Pak', key: 3 },
    { value: 'Fedex Small Box', key: 4 },
    { value: 'Fedex Medium Box', key: 5 },
    { value: 'Fedex Large Box', key: 6 },
    { value: 'Fedex Extra Large Box', key: 7 },
    { value: 'Fedex Tube', key: 8 },
  ];
}
