import { Component, OnInit, ChangeDetectorRef, AfterViewInit, ViewChild, OnDestroy, ElementRef, HostListener } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, Observable, Subject } from 'rxjs';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstantsService } from 'src/app/constants.service';
import { SharedService } from 'src/app/services/shared.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
// import { TreeviewConfig, TreeviewItem } from 'ngx-treeview';
import { DatePipe } from '@angular/common';
import { ConfirmationDialogService } from 'src/app/ConfirmationDialog/confirmation-dialog.service'
import { GlobalChangeService } from 'src/app/services/global-change.service';
import Swal from 'sweetalert2';
import { CompanyService } from 'src/app/services/company/company.service';
import { PartService } from 'src/app/services/part/part.service';
import { VendorService } from 'src/app/services/vendor/vendor.service';
import { CustomerService } from 'src/app/services/customer/customer.service';
import { InventoryService } from 'src/app/services/inventory/inventory.service';
import { error } from 'console';
declare var $: any
declare var tinymce: any;

interface Program {
  value: number;
  text: string;
  isActive?: boolean;
  isEcommerece?: boolean;
  wordpressId?: number;
  children?: Program[];
}

interface Part {
  Id: any;
  PartNumber: any;
  PartName: any;
  WPID: any;
}

@Component({
  selector: 'app-parts',
  templateUrl: './parts.component.html',
  styleUrls: ['./parts.component.css']
})
export class PartsComponent implements AfterViewInit, OnDestroy, OnInit {
  AdditionalDiscountLabel: boolean = false;
  IsOpen: boolean = false;
  IsSave: boolean = true;
  IsUpdate: boolean = false;
  IsPricePart: boolean = false;
  IsPPricePart: boolean = true;
  PartName: string = '';
  PartName1: string = '';
  PartNumber1: string = '';
  ManualPartId: Number = 0;
  radioSelected: string;
  arrayadd = [];
  recordAlreadyExist = false;
  recordPartNoExist = false;
  IsMarginDisabled = true;
  IsPartMarginDisabled = true;
  settingDetailCSS: any;
  settingLogoImage: any;
  data: any = {};
  imageUrl: any = "/assets/image/add-part.png";
  datalist: any;
  newdatalist: any;
  vendorlist: any;
  categorylist: any;
  PartQuestions = [0];
  questionlist: any;
  SalesQuestionlist = []; // Added By Navneet
  questionlistNew: any;
  tempQuestionlist: [];
  userId: string;
  value: number;
  id: any;
  vendorId: any;
  name: any;
  description: any;
  MarkupMargin: any;
  OEMDiscount: any;
  PartMarkupMargin: any;
  PVendorNumber: any;
  PVendorPartName: any;
  MarkupCheck: boolean = false;
  PartMarkupCheck: boolean = true;
  NotActive: boolean = false;
  NonInventory: boolean = false;
  cost: any;
  hours: any;
  reorderAmount: any;
  partLeadTime: any;
  partLocation: any;
  imagePath: any
  selectedVendorValue = '';
  partmodelVendorValue = '';
  laborCharge: any;
  search: any;
  customPartId: any;
  customPartImagePath: any
  partCategoryId: any;
  companyId: any;
  productId: any;
  isActive: any;
  createdDate: any;
  modifiedDate: any;
  createdBy: string;
  modifiedBy: string;
  partNo: any
  vendorName: any;
  partCategoryName: any;
  weight: any;
  length: any;
  height: any;
  width: any;
  shortDescription: any;
  InfoName: any = "More Information";
  MatingName: any = "Mating Connectors";
  CrimpName: any = "Crimp Tools";
  partname: any;
  RelatedName: any = "Related Items";
  globalLocationNameList = [];
  newform: FormGroup;
  partForm: FormGroup;
  questionId: any;
  selectedPartCategoryIdValue = '';
  SelectedUnitMeasure = '';
  SelectedLocation = '';
  SelectedPartLocation = '';
  buttonValue: string;
  partid: number;
  validation: boolean = false;
  partvalidation: boolean = false;
  popvalidation: boolean = false;
  loader: boolean = false;
  ActiveCouting: boolean;
  DeActiveCouting: boolean;
  dtOptions: DataTables.Settings = {};
  dtOptions1: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  dtTrigger1: Subject<any> = new Subject<any>();
  partIds: any;
  qnsId: any;
  deleteDetail: string;
  disabled = true;
  questionidZero: any;
  fileToUpload: File = null;
  deletedItem: string;
  PartdeletedItem: string;
  PartImage: any;
  deletedItemImage: any;
  clearbutton: boolean = true;
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  rolePermissionsList: any;
  permissionMessage: string;
  unitofmeasurement: any;
  formSearch: FormGroup;
  isSaveOrUpdate: boolean = false;
  VendorDisCounter: number = 0;
  companyList: any;
  userGroupObjects = new Array();
  CompanyValue: any;
  VendorPartCost: any;
  CustomerNumber: any;
  VendorPartName: any;
  VendorPartNumber: any;
  UnitofMeasureId: any;
  LocationsId: any;
  partVendorsList = [];
  primaryVendorList = [];
  CategoryArr = [];
  InvValidation: boolean = false;
  InvValidationUpdate: boolean = false;
  InvList = [];
  SaveInvList = [];
  selectedItems = [];
  InvDetails = [];
  normalShow = true;
  InvSno = 0;
  InvSnoEdit = 100000;
  InvQty: any;
  InvDate: any;
  InvCost: any;
  InvType: any = '1';
  InvVendor: any;
  InvLocName: any = '';
  InvLocName1: any = '';
  InvPartTracking: any = '3';
  InvPartOrder = '';
  InvProductOrder = '';
  InvType2: any;
  InvTypeList: any;
  InvCustNo: any;
  InvServNo: any;
  InvCustRMA: any;
  InvPartLocation: any;
  UpdateInvQty: any;
  UpdateInvDate: any;
  UpdateInvCost: any;
  UpdateInvType = '';
  UpdateInvPartOrder = '';
  UpdateInvProductOrder = '';
  UpdateInvServNo: any;
  UpdateInvCustNo: any;
  UpdateInvCustRMA: any;
  UpdateInvVendor: any;
  UpdateInvPartLocation: any;
  DelRowNo: any;
  DelInvId: any;
  RowEdit: any;
  CurrentEditRow: any;
  EditRowTxtName: any;
  EditInvSno: any;
  btnShowvalidation: boolean = false;
  btnOkShow: boolean = false;
  now: Date;
  InvPartOrderDDL: any;
  InvProductOrderDDL: any;
  MinDate: any;
  TCreateUpdate: any = 1;
  fieldToSearch = '3';
  fieldToSearchPlaceHolder = 'Enter List or Vendor Part #';
  userSearch: any;
  SearchValidation: any;
  SearchType: any;
  SearchControl: boolean = false;
  ListShow: boolean = false;
  ListDiv: boolean = false;
  ListAngle: any;
  // formSearch: FormGroup;
  userGroupObjects_search = new Array();
  value_search: number;
  companyList_search: any;
  // items_search: TreeviewItem[];
  FilertingData: any;
  ImageTypelist: any;
  ImageTypeId: any;
  SelectedImageTypeId = '';
  MultipleImage = [];
  PartImageDefault: any;
  PartImageArray = [];
  viewimagesshow: any;
  DefaultLogoPDF: any;
  Documentype: any;
  WordFile: any;
  fileUpload: any;
  PictureValue: any = '';
  skillLevel = '0';
  skillLevelList: any;
  minInventory: any;
  userRoleList: any;
  userRole = '';
  WorkflowSteps = '';
  WorkflowList: any[] = [];
  AddvendorList: any[] = [];
  @ViewChild('Image') myInputVariable: ElementRef;

  validationImage: boolean = false;
  installationNotes: any;
  TotalAllType: any;
  SaveTotalAllType: any;
  TotalTypeInventory: any;
  SaveTotalTypeInventory: any;
  TotalInvLog: any;
  SaveTotalInvLog: any;
  i: Number = 0;
  formPopup: FormGroup;
  mInvValidation: boolean = false;
  mInvQtyValidation: boolean = false;
  mQtyMoveMsg: boolean = false;
  mMsg: any;
  mMsgClass: any;
  mInvQty: any;
  mInvQtyToMove: any;
  mInvDate: any;
  mInvCost: any;
  mInvType: any = '1';
  mInvPartOrder = '';
  mInvProductOrder = '';
  mInvType2: any;
  mInvTypeList: any;
  mInvPartOrderDDL: any;
  mInvProductOrderDDL: any;
  mInvCustNo: any;
  mInvServNo: any;
  mInvCustRMA: any;
  mInvLocation = '';
  mInvLocation2 = '';
  mInvLocation1 = '';
  mInvReason: any;
  mValidateQtyToFulfill: any;
  dropdownSettings = {};
  TotalIn: number = 0;
  TotalOut: number = 0;
  max: number = 0;
  min: number = 0
  viewLog: boolean = false;
  logList = [];
  SavelogList = [];
  searchPartId: any;
  isInvFind: boolean = false;
  partTracking: any = '3';
  formPopupAdjustQty: FormGroup;
  adjInvValidation: boolean = false;
  adjInvQty: any;
  adjInvQtyToAdjust: any;
  adjInvReason: any;
  adjInvReasonList = this.constants.InventoryAdjustReason;

  @ViewChild('QtyMoveMsgModel', { static: false }) private QtyMoveMsgModel;
  @ViewChild('itemModel', { static: false }) private itemModel;
  @ViewChild('itemModel2', { static: false }) private itemModel2;
  MainInvRowNo: number;
  WordpressCategoryList: any;
  WordpressCategoryCrimpToolsList: any;
  WordpressCategoryRelatedItemList: any;
  WordpressCategoryPartialList: any[] = [];
  WordpressProductPartialList: any[] = [];
  CheckCategory: boolean;
  CheckCategoryDisable: boolean = false;
  PartHistory: boolean = false;
  CheckId: number = 0;
  CustomerPartNameList: any[] = [];
  MatingDisableList: any[] = [];
  CrimpDisableList: any[] = [];
  RelatedDisableList: any[] = [];
  CustomerPartNameCrimpList: any[] = [];
  CustomerPartNameRelatedList: any[] = [];
  WordpressAllProductList: any[] = [];
  NewArray: any[] = [];
  NewArray1: any[] = [];
  NewArray2: any[] = [];
  CrimpNewArray: any[] = [];
  CrimpNewArray1: any[] = [];
  RelatedNewArray: any[] = [];
  RelatedNewArray1: any[] = [];
  NewPartialArray = [];
  masterSelected: boolean = false;
  chkInv: any;
  partmodelvendorId: any;
  PVendorCost: any;
  PPartPrice: any;
  chkInvCrimp: any;
  wordpressid: any;
  chkInvRelated: any;
  DimentionTypeList: any;
  WeightTypeList: any;
  DimentionTypeL: any;
  DimentionTypeW: any;
  DimentionTypeH: any;
  WeightType: any;
  isTotalInvQty: boolean = false;
  displayOldLocation: any;
  CustomeList: any[];
  PartHistoryList: any[] = [];
  CheckHistoryList: boolean;
  MainProp: string;
  noproduct: string;
  catdropdownList = [];
  Pwordpressid: any;
  gtinupc: any;
  brandname: any;
  ECommerceStatus: string = '';
  partNameProperty: string;
  AlreadyVendorName: string;
  IsPartSave: boolean = true;
  IsPartUpdate: boolean = false;
  EditpartId: any;
  deleteDetailVendor: string;
  AddvendorList1: any[] = [];
  AddvendorList2: any[] = [];
  SaveModel: string;
  ADiscount: any;
  AVendor: any;
  vendorlist1: any;
  vendorlist2: any;
  htmlToAdd: string;
  editrow: any;
  customvendorId: any;
  Type: any;
  vendorText: string;
  InvProp: string;
  isBackgroudImage: boolean = false;
  PartTrackingList = [];
  PartTrackingList1 = [];
  form: FormGroup;
  deleteUnsuccessMsg: string;
  dynamicSpecificArray = [{ 'id': '0', 'Name': '', 'Value': '', 'Ordering': 0, 'isActive': false, 'isReadOnly': false, 'CreateDate': '', 'CreatedBy': '', 'IsDReadonly': false }]
  // dynamicArray = [{ 'Qty': '', 'DiscountPrice': '', 'cost': '', 'id': '0', 'isActive': false, 'isReadOnly': false, 'CreateDate': '', 'CreatedBy': '', 'IsDReadonly': false }];
  dynamicArray = [{ 'minQty': '', 'Qty': '', 'DiscountType': '', 'DiscountPrice': '', 'cost': '', 'description': '', 'id': '0', 'isActive': false, 'isReadOnly': false, 'CreateDate': '', 'CreatedBy': '', 'IsDReadonly': false }];
  dynamicCustomerDiscount = [{ 'minQty': '', 'Qty': '', 'DiscountType': '', 'DiscountPrice': '', 'cost': '', 'description': '', 'id': '0', 'isActive': false, 'CreateDate': '', 'CreatedBy': '', 'isReadOnly': false, 'IsDReadonly': false, 'wordPressId': '0' }];
  dynamicCustomerRoleDiscount = [{ 'DiscountType': '', 'DiscountValue': '', 'CustomerType': '', 'CustomerValue': '', 'cost': '', 'description': '', 'id': '0', 'isActive': false, 'CreateDate': '', 'CreatedBy': '', 'isReadOnly': false, 'IsDReadonly': false, 'wordPressId': '0' }];
  newDynamic;
  newCustomer;
  DiscountModel: string;
  DiscountModel1: string;
  VendorDiscountList = [];
  CustomerDiscountList = [];
  locationNameList: any;
  locationNameMoveList: any;
  locationLevelList = [];
  locationLevelMoveList = [];
  invlocationLevelList = [];
  newlocationLevelList = [];
  selectedLocationName: any;
  locationText: any;
  qtyLabel = [];
  qtyLabelNew = [];
  qtyPartName: any;
  inventoryQty: any;
  SerialList = [];
  exitProp: boolean = false;
  IsSerialDisabled: boolean = false;
  openId: any;
  Tracking: any;
  PartTrackingMsg: string = "";
  AddSerial: boolean = false;
  DeleteSerialArr = [];
  serialNumDiff: number;
  SerialComment: string;
  DSerialTracking: any;
  AddSerials = [];
  MoveSerialArr = [];
  moveDiff: number;
  html: any;
  editor: any;
  ShortDescription: any;
  selected = '';
  selectedMulti = [];
  programs: Program[] = [];
  MultipleDownloadImg = [];
  deletedItemImageDownload: any;
  RowNo: any;
  CustomerTypeList: any = this.constants.RolesList;
  RoleBasedPricingDiscountList: any[];
  CustomerTypeList1: any;
  arr: any[];
  controlWPData: boolean = false;
  controlWPData1: boolean = false;
  WordPressData: any;
  getOptionWP: any;
  isTrue: boolean = false;
  onlyOption: boolean = false;
  getName: any;
  SynWPdata: any;
  _srtDisList: any = [];
  shortStr: any[] = [];
  shortDesc: any;
  //ankit 29012024
  itemNameList = [];
  Part: Part;
  options: Part[] = [];
  filteredOptions: any = [];
  myForm: FormGroup;
  ErrorModelMsg: string;
  EditorKey: string;
  showViewHistory: boolean = false;
  invListDetails: any = [];

  constructor(public sharedService: SharedService,
    private cdr: ChangeDetectorRef,
    private _sanitizer: DomSanitizer,
    private constants: ConstantsService,
    private modalService: NgbModal,
    private router: Router,
    public datepipe: DatePipe,
    public confirmation: ConfirmationDialogService,
    private globalChangeService: GlobalChangeService,
    private partService: PartService,
    private vendorService: VendorService,
    private customerService: CustomerService,
    private fb: FormBuilder,
    private inventoryService: InventoryService
  ) {
    this.getTrackingList();
    //ankit 29012024
    this.createForm();
  }

  ngOnInit(): void {
    this.itemNameList = [];
    for (const [i, v] of <any>this.options.entries()) {
      this.itemNameList[i] = v.itemName;
    }

    if (localStorage.getItem('AuthHeader') === null || localStorage.getItem('AuthHeader') === undefined) {
      this.router.navigate(['']);
    } else {
      this.rolePermissionsList = JSON.parse(localStorage.getItem('rolePermissionsList'));
      this.rolePermissionsList = this.rolePermissionsList.filter(x => x.pageId == 8);
      this.permissionMessage = '';
      //this.questionId="0";

      if (this.rolePermissionsList != null && this.rolePermissionsList != undefined) {
        if (!this.rolePermissionsList[0].isView) {
          this.permissionMessage += 'view';
        } if (!this.rolePermissionsList[0].isInsert) {
          if (this.permissionMessage != '') {
            this.permissionMessage += ', ';
          }
          this.permissionMessage += 'add';
        } if (!this.rolePermissionsList[0].isUpdate) {
          if (this.permissionMessage != '') {
            this.permissionMessage += ', ';
          }
          this.permissionMessage += 'edit';
        } if (!this.rolePermissionsList[0].isDelete) {
          if (this.permissionMessage != '') {
            this.permissionMessage += ', ';
          }
          this.permissionMessage += 'delete';
        }
      }
      if (this.permissionMessage != '') {
        this.permissionMessage = 'You do not have access to ' + this.permissionMessage + '. Please contact to administrator!';
      }

      this.companyId = localStorage.getItem(this.constants.companyId);
      this.userId = localStorage.getItem(this.constants.userId);
      this.buttonValue = this.constants.Save;

      this.getSkillLevel(this.companyId, this.userRole);
      if (this.companyId > 0) {
        this.Getvendordropdownlist(this.companyId);
        this.Getmultivendordropdownlist(this.companyId, undefined, undefined, undefined);
        this.getLocationLevel(this.companyId);
        this.getAllCategories_search(this.companyId);
      }

      this.UnitofMeasurementDropdownList();
      this.Getquestiondropdownlist(this.companyId);

      this.InvVendor = ''
      this.InvTypeDropdownList();
      this.getAllProductionRole(this.companyId);
      this.getAllWorkflowSteps(this.companyId);
      this.WordpressCategoryLoad(this.companyId);
      this.DefaultLogoPDF = "/assets/image/PDFFile.png";
      this.WordFile = "/assets/image/WordFileImage.png";
      this.dtOptions = {
        pagingType: this.constants.full_numbers,
        pageLength: 10,
        ordering: false,
        autoWidth: true,
        scrollX: true,
        scrollY: "253px",

      };
      this.dtOptions1 = {
        pagingType: this.constants.full_numbers,
        pageLength: 10,
        ordering: false,
        scrollX: true
      };

      //ankit implementaion
      // get company by default set value
      var company = this.globalChangeService.getGlobalCompany();
      if (company > 0) {
        this.GetAllPartsList(company);
        this.CompanyValue = company;
        this.value_search = company;
        this.CompanyValue = company;
        this.getAllCategories_search(this.CompanyValue);
        this.Getquestiondropdownlist(this.CompanyValue);
        this.Getvendordropdownlist(this.CompanyValue);
        this.Getmultivendordropdownlist(this.CompanyValue, undefined, undefined, undefined);
        this.getAllProductionRole(this.CompanyValue);
        this.getAllWorkflowSteps(this.CompanyValue);
        this.WordpressCategoryLoad(this.CompanyValue);
        this.AdditionalDiscountLabel = false;
        this.selectedVendorValue = '';
        this.getSkillLevel(this.CompanyValue, this.userRole);
        this.selectedItems = [];
        this.getLocationLevel(this.CompanyValue);
        this.User();
        this.UserRole();
        this.GetCatList(company);
      }
      else {
        this.ErrorModal('Error', 'Please select company.');
      }

      //on change function 
      this.globalChangeService.dropdownChange$.subscribe((data) => {
        this.ListShow = false;
        this.ChangeTCreateUpdate(1);
        this.GetAllPartsList(data);
        this.companyChangeValue(data);
        this.onValueChange_search(data);
      })
    }
    let today = new Date();
    this.PartImageDefault = "/assets/image/add-part.png";
    this.MinDate = this.datepipe.transform(today, 'yyyy-MM-dd');

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'partcategoryid',
      textField: 'partcategorytext',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: false
    };

    this.newform = new FormGroup({
      'chkInv': new FormControl(),
      'chkInvCrimp': new FormControl(),
      'chkInv1': new FormControl(),
      'chkInv1Crimp': new FormControl(),
      'chkInv1Related': new FormControl(),
      'chkInvRelated': new FormControl(),
      'InfoName': new FormControl(),
      'MatingName': new FormControl(),
      'CrimpName': new FormControl(),
      'RelatedName': new FormControl(),
      'shortDescription': new FormControl('')
    });

    this.partForm = new FormGroup({
      'partmodelVendorValue': new FormControl('', Validators.compose([Validators.required, Validators.minLength(1)])),
      'PVendorCost': new FormControl(),
      'PPartPrice': new FormControl(),
      'PartMarkupCheck': new FormControl(),
      'PartMarkupMargin': new FormControl(),
      'PVendorNumber': new FormControl(),
      'PVendorPartName': new FormControl()
    });


    this.form = new FormGroup({
      'NonInventory': new FormControl(),
      'weight': new FormControl(),
      'length': new FormControl(),
      'height': new FormControl(),
      'width': new FormControl(),
      'DimentionTypeL': new FormControl(),
      'DimentionTypeW': new FormControl(),
      'DimentionTypeH': new FormControl(),
      'WeightType': new FormControl(),
      'name': new FormControl(),
      'partNo': new FormControl(),
      'description': new FormControl(),
      'ShortDescription': new FormControl(),
      'MarkupMargin': new FormControl(),
      'MarkupCheck': new FormControl(),
      'NotActive': new FormControl(),
      'hours': new FormControl(),
      'partLocation': new FormControl(),
      'partLeadTime': new FormControl(),
      'reorderAmount': new FormControl(),
      'cost': new FormControl(),
      'VendorPartCost': new FormControl(),
      'search': new FormControl(),
      'partId': new FormControl(),
      'VendorPartName': new FormControl(),
      'VendorPartNumber': new FormControl(),
      'Pwordpressid': new FormControl(),
      'gtinupc': new FormControl(),
      'brandname': new FormControl(),
      'ECommerceStatus': new FormControl(),
      'OEMDiscount': new FormControl(),
      'UnitofMeasureId': new FormControl(),
      'LocationsId': new FormControl(),
      'selectedItems': new FormControl(),
      'vendorId': new FormControl('', Validators.compose([Validators.required, Validators.minLength(1)])),
      'partCategoryId': new FormControl('', Validators.compose([Validators.required, Validators.minLength(1)])),
      'questionIds': new FormArray(
        [
          new FormGroup({
            questionId: new FormControl(0),
          }
          )
        ]
      ),
      'InvQty': new FormControl('', Validators.required),
      'InvDate': new FormControl('', Validators.required),
      'InvCost': new FormControl('', Validators.required),
      'InvType': new FormControl('1', Validators.required),
      'InvVendor': new FormControl('', Validators.required),
      'InvLocName': new FormControl('', Validators.required),
      'InvLocName1': new FormControl('', Validators.required),
      'InvPartTracking': new FormControl('', Validators.required),
      // 'InvCustNo': new FormControl('', Validators.required),
      'InvCustRMA': new FormControl(),
      'InvPartLocation': new FormControl(),
      'InvServNo': new FormControl('', Validators.required),
      'InvPartOrder': new FormControl('', Validators.required),
      'InvProductOrder': new FormControl('', Validators.required),
      'UpdateInvQty': new FormControl('', Validators.required),
      'UpdateInvDate': new FormControl('', Validators.required),
      'UpdateInvCost': new FormControl('', Validators.required),
      'UpdateInvType': new FormControl('', Validators.required),
      'UpdateInvServNo': new FormControl('', Validators.required),
      'UpdateInvCustRMA': new FormControl(),
      'UpdateInvVendor': new FormControl(),
      'UpdateInvPartLocation': new FormControl(),
      'UpdateInvPartOrder': new FormControl('', Validators.required),
      'UpdateInvProductOrder': new FormControl('', Validators.required),
      //salman khan for Image type
      'ImageTypeId': new FormControl(),
      //end
      'skillLevel': new FormControl(),
      'minInventory': new FormControl(),
      'userRole': new FormControl(),
      'WorkflowSteps': new FormControl(),
      'installationNotes': new FormControl(),
      'partTracking': new FormControl('3', Validators.required),
      'Questions': new FormArray([]),
    });
    this.formSearch = new FormGroup({
      'TCreateUpdate': new FormControl(),
      'userSearch': new FormControl(),
      'fieldToSearch': new FormControl()
    });

    this.formPopup = new FormGroup({
      'mInvQty': new FormControl('', Validators.required),
      'mInvQtyToMove': new FormControl('', Validators.required),
      'mInvDate': new FormControl('', Validators.required),
      'mInvCost': new FormControl('', Validators.required),
      'mInvType': new FormControl('', Validators.required),
      'mInvCustRMA': new FormControl(),
      'mInvServNo': new FormControl('', Validators.required),
      'mInvPartOrder': new FormControl('', Validators.required),
      'mInvProductOrder': new FormControl('', Validators.required),
      'mInvLocation': new FormControl('', Validators.required),
      'mInvLocation2': new FormControl('', Validators.required),
      'mInvLocation1': new FormControl('', Validators.required),
      'mInvReason': new FormControl(),
    });

    this.formPopupAdjustQty = new FormGroup({
      'adjInvQty': new FormControl('', Validators.required),
      'adjInvQtyToAdjust': new FormControl('', Validators.required),
      'adjInvReason': new FormControl(),
    });

    this.form.controls['skillLevel'].disable();
    this.DimentionTypeList = this.constants.DimentionType;
    this.WeightTypeList = this.constants.UnitWeight;
    this.DimentionTypeL = 2;
    this.DimentionTypeH = 2;
    this.DimentionTypeW = 2;
    this.WeightType = 5;
    const counting = this.AddvendorList.filter(x => x.IsActive == true);
    if (counting.length > 0) {
      this.DeActiveCouting = false;
      this.ActiveCouting = true;
    }
    else {
      this.DeActiveCouting = true;
      this.ActiveCouting = false;
    }
    this.WordPressData = '1';
    this.onlyOption = true;
  }

  getTrackingList() {
    this.partService.GetPartTrackingList().subscribe(
      (data: any) => {
        if (data.length > 0) {
          this.PartTrackingList = data;
          this.PartTrackingList1 = data;
        }
      }
    )
  }

  SortTableHeader(item: any) {
    if (this.datalist != null) {
      var ret = new Array;
      for (var i = this.datalist.length - 1; i >= 0; i--) {
        ret.push(this.datalist[i]);
      }

      this.datalist = ret;
      var Keys = Object.keys(this.datalist[0]);

      if (Keys.includes(item)) {
        if (this.isBackgroudImage) {
          this.isBackgroudImage = false;
          var element = document.getElementById(item);
          if (element.className === 'sorting1') {
            element.classList.replace('sorting1', 'sorting_asc');
          }
          else if (element.className === 'sorting_asc') {
            element.classList.replace('sorting_asc', 'sorting_desc');
          }
          else {
            element.classList.replace('sorting_desc', 'sorting_asc');
          }
        }
        else {
          this.isBackgroudImage = true;
          var element = document.getElementById(item);
          if (element.className === 'sorting1') {
            element.classList.replace('sorting1', 'sorting_asc');
          }
          else if (element.className === 'sorting_asc') {
            element.classList.replace('sorting_asc', 'sorting_desc');
          }
          else {
            element.classList.replace('sorting_desc', 'sorting_asc');
          }
        }
      }

      const result = Keys.filter(key => key != item);

      if (result) {
        result.forEach(data => {
          var element = document.getElementById(data);
          if (element != null) {
            if (element.className === 'sorting1') {
              element.classList.replace('sorting1', 'sorting1');
            }
            else if (element.className === 'sorting_asc') {
              element.classList.replace('sorting_asc', 'sorting1');
            }
            else {
              element.classList.replace('sorting_desc', 'sorting1');
            }
          }
        });
      }
    }
  }

  async canDeactivate(): Promise<Promise<boolean> | boolean> {

    if (!this.form.pristine && !this.isSaveOrUpdate) {
      const confirmResult = await this.confirmation.confirm('Confirmation', 'Are you sure you want to leave this page ?', 'Cancel', 'OK', 'lg');
      if (confirmResult === true) {
        return true;
      } else {
        return false;
      }
    }
    else {
      return true;
    }
  }

  ChangefieldToSearch(value) {
    this.fieldToSearchPlaceHolder = value == '1' ? 'Enter Search Keywords' : value == '2' ? 'Enter List or Vendor Part Name' : value == '3' ? 'Enter List or Vendor Part #' : '';
  }

  changeUserRole(event) {
    if (event) {
      const getRole = this.userRoleList.filter(x => x.id == event);
      this.getSkillLevel(this.CompanyValue, event);
      if (getRole[0].isSkillRequired == true) {
        this.form.controls['skillLevel'].enable();
      }
      else {
        this.form.controls['skillLevel'].disable();
        this.skillLevel = '0';
      }
      this.getSkillLevel(this.CompanyValue, event);
    }
    else {
      this.skillLevel = '0';
      this.form.controls['skillLevel'].disable();
    }
    this.userRole = event;
  }

  onchangeEvent(event) {
    this.qnsId = '';
    this.qnsId = parseInt(event);
    $('#requiredmessage').html("")
  }

  changeLocation(event) {
    var value = event.target.value;
    var value2 = event.target.options[event.target.options.selectedIndex].text;
    this.selectedLocationName = value2;
    this.getPartLocationLevel(1, value2);
    this.InvLocName = value;
  }
  changeLocations(event) {
    this.InvLocName1 = event.target.options[event.target.options.selectedIndex].text;
    this.locationText = event.target.options[event.target.options.selectedIndex].text;
  }

  changeTracking(event) {
    this.InvPartTracking = event.target.value;
  }

  changeLocationNew(event) {
    var value = event.target.value;
    var value2 = event.target.options[event.target.options.selectedIndex].text;
    this.SelectedLocation = value;
    if (this.selectedLocationName == undefined) {
      this.selectedLocationName = value2;
    }
    this.getPartLocationLevelInv(1, value2);
    this.getPartLocationLevel(1, value2);

  }
  changeLocationNew1(event, event1) {
    var value = event;
    var value2 = event1;
    this.getPartLocationLevelInv(1, value2);
  }

  changeLocationMove(event) {
    var value = event.target.value;
    var value2 = event.target.options[event.target.options.selectedIndex].text;
    this.getPartLocationLevelMove(1, value2);

  }

  onEnterVendorPartName(event: any) {
    if (!this.id) {
      this.name = event.target.value;
    }
  }

  onEnterVendorPartNumber(event: any) {
    if (!this.id) {
      this.partNo = event.target.value;
    }
  }

  SetSettingCSS(settingCSS, logoImagePath) {
    if (settingCSS != null) {
      let headerCss = document.getElementsByClassName(this.constants.CssHealerClass) as HTMLCollectionOf<HTMLElement>;
      if (headerCss.length != 0) {
        headerCss[0].style.backgroundColor = settingCSS.split(',')[0];
      }
      let saveButtonCss = document.getElementsByClassName(this.constants.CssSaveBtnClass) as HTMLCollectionOf<HTMLElement>;
      if (saveButtonCss.length != 0) {
        saveButtonCss[0].style.backgroundColor = settingCSS.split(',')[1];
        $(".blue-bg").css("background", settingCSS.split(',')[1]);
        $(".upload_btn").css("background", settingCSS.split(',')[1]);
      }
      let cencelButtonCss = document.getElementsByClassName(this.constants.CssCancelBtnClass) as HTMLCollectionOf<HTMLElement>;
      if (cencelButtonCss.length != 0) {
        cencelButtonCss[0].style.backgroundColor = settingCSS.split(',')[2];
        $(".darkred-bg").css("background", settingCSS.split(',')[2]);
      }
    }
    if (logoImagePath != null && logoImagePath != "") {
      (document.getElementById('hrdlogoImage') as HTMLImageElement).src = logoImagePath;
    }
  }

  get questionArray() {
    return this.form.get('questionIds') as FormArray;
  }

  closenoti() {
    this.mQtyMoveMsg = false;
  }

  AddQuestionDropdownList(i) {
    if (this.qnsId > 0) {
      this.questionArray.controls.push(
        new FormGroup({
          questionId: new FormControl(this.qnsId),
          partId: new FormControl(this.id == null ? 0 : this.id)
        })
      )
      this.qnsId = '';
      for (let index = 1; index < this.questionArray.controls.length; index++) {
        const items = this.questionlist.find(x => x.id == this.questionArray.controls[index].value.questionId);
        if (items) {
          const indx = this.questionlist.findIndex(x => x.id == this.questionArray.controls[index].value.questionId);
          this.questionlist.splice(indx, 1);
        }
      }
      $('#requiredmessage').html("")
    }
    else {
      $('#requiredmessage').html("Please Select Question")
    }
  }


  RemoveQuestionDropdownList(index) {
    const deletedItem = this.questionArray.controls.splice(index, 1);
    const arrayItem = this.questionlistNew.find(x => x.id === deletedItem[0].value.questionId);
    if (arrayItem) {
      this.questionlist.push(arrayItem);
    }
    $('#requiredmessage').html("");
  }

  getAllProductionRole(companyId) {
    if (companyId == undefined) {
      companyId = 0;
    }
    this.sharedService.getAllProductionRole(companyId, '').subscribe(
      data => {
        this.data = data;
        if (companyId > 0) {
          this.userRoleList = this.data.dataList;
        }
        else {
          this.userRole = '';
        }
      });
  }

  getAllWorkflowSteps(companyId) {
    if (companyId == undefined) {
      companyId = 0;
    }
    this.sharedService.getAllWorkflows(companyId, '').subscribe(
      data => {
        this.data = data;
        if (companyId > 0) {
          this.WorkflowList = this.data.dataList;
        }
        else {
          this.WorkflowList = [];
        }
      });
  }

  getSkillLevel(id, roleId) {
    this.sharedService.getSkillLevelUser(id, roleId).subscribe(
      data => {
        this.data = data;
        if (this.data.dataList) {
          this.skillLevelList = this.data.dataList;
        }
        else {
          this.skillLevelList = '';
        }
      });
  }

  getLocationLevel(id) {
    this.sharedService.getLocationLevel(id, '').subscribe(
      data => {
        this.data = data;
        if (this.data.dataList) {
          this.globalLocationNameList = this.data.dataList;
          this.locationNameList = this.data.dataList.filter(x => x.locationTypeId == 1);
          this.locationNameMoveList = this.data.dataList.filter(x => x.locationTypeId == 1);
        }
        else {
          this.locationNameList = '';
          this.locationNameMoveList = '';
        }
      });
  }

  getPartLocationLevel(id, locationName) {
    this.sharedService.getPartLocationLevel(id, locationName, this.CompanyValue).subscribe(
      data => {
        this.data = data;
        if (this.data.dataList) {
          this.locationLevelList = this.data.dataList;
          this.invlocationLevelList = this.data.dataList;
        }
        else {
          this.locationLevelList = [];
        }
      });
  }
  getPartLocationLevelMove(id, locationName) {
    this.sharedService.getPartLocationLevel(id, locationName, this.CompanyValue).subscribe(
      data => {
        this.data = data;
        if (this.data.dataList) {
          this.locationLevelMoveList = this.data.dataList;
        }
        else {
          this.locationLevelMoveList = [];
        }
      });
  }
  getPartLocationLevelInv(id, locationName) {

    this.sharedService.getPartLocationLevel(id, locationName, this.CompanyValue).subscribe(
      data => {
        this.data = data;
        if (this.data.dataList) {
          this.invlocationLevelList = this.data.dataList;
          this.newlocationLevelList.push(... this.locationLevelList);
        }
        else {
          this.invlocationLevelList = [];
        }
      });
  }

  GetAllPart(type) {

    this.value_search == undefined ? 0 : this.value_search;
    this.loader = true;
    this.viewLog = false;

    $('#datatable').DataTable().destroy();

    this.dtTrigger.next('');

    this.dtOptions = {
      autoWidth: true,
      ordering: false,
      pagingType: 'full_numbers',
      pageLength: 10,
      serverSide: true,

      ajax: (dataTablesParameters: any, callback) => {

        this.partService.GetAllPart(this.value_search, this.userSearch ? this.userSearch : dataTablesParameters.search.value, dataTablesParameters.start, dataTablesParameters.length, type)
          .subscribe(resp1 => {
            this.loader = false;
            let totalRows = null;
            // setTimeout(() => { this.loader = false; }, 1200)
            this.data = resp1;
            if (this.data.dataList.length > 0) {
              this.datalist = this.data.dataList;
              totalRows = [...new Set(this.datalist.map(item => item.totalRows))];
            }
            else {
              this.datalist = null;
              totalRows = 0;
            }

            callback({
              recordsTotal: totalRows,
              recordsFiltered: totalRows,
              data: []
            });

          });
      },
      //columns: [{ data: 'companyName',width:'auto' }, { data: 'name',width:'auto' }, { data: 'partNo',width:'auto' }, { data: 'vendorName',width:'auto' }, { data: 'vendorPartName' ,width:'auto'}, { data: 'vendorPartNumber',width:'auto' }, { data: 'cost',width:'auto' }, { data: 'hours',width:'auto' }],

    };
    this.rerender();
  }


  deletePopup(item, content) {
    this.modalService.open(content, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
    this.deleteDetail = 'Are you sure, you want to delete  ' + item.name + '?';
    this.deletedItem = item.id;
  }

  deletevendorPart(item) {
    $('#deleteVendorlist').show();
    this.deleteDetailVendor = 'Are you sure, you want to delete?';
    this.PartdeletedItem = item.id;
  }

  DeleteImageModalpopup(i, content) {
    this.modalService.open(content, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
    this.deleteDetail = 'Are you sure, you want to delete?';
    this.deletedItemImage = i;
  }

  DeleteMultipleDownloadImg(i, content) {
    this.modalService.open(content, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
    this.deleteDetail = 'Are you sure, you want to delete?';
    this.deletedItemImageDownload = i;
  }

  DeleteVendor() {
    $('#deleteVendorlist').hide();
  }
  notdelete_success() {
    $("#notdelete_success").hide();
  }
  deletePart(item: any) {
    this.loader = true;

    this.partService.DeletePart(item).subscribe(
      data => {
        this.loader = false;
        this.data = data;
        switch (data.statusCode) {
          case 200:
            if (data.data == "-1") {
              this.deleteUnsuccessMsg = "Sales Order already exist related with this part, this part can't be deleted!"
              $("#notdelete_success").show();
            }
            else if (data.data == "-2") {
              this.deleteUnsuccessMsg = "Purchase Order already exist related with this part, this part can't be deleted!"
              $("#notdelete_success").show();
            }
            else if (data.data == "-3") {
              this.deleteUnsuccessMsg = "Inventory already exist related with this part, this part can't be deleted!"
              $("#notdelete_success").show();
            }
            else {
              $("#delete_success").show();
              this.GetAllPart("1");
            }
            break;
          case 400:
            $("#wrong_msg").show();
            break;
          default:
            $("#wrong_msg").show();

        }
      },
      error => {
        this.loader = false;
      }
    );
  }


  Getvendordropdownlist(companyId) {
    this.partService.Getvendordropdownlist(companyId).subscribe(
      data => {
        this.data = data;
        if (companyId > 0) {
          this.vendorlist = this.data.dataList;
          let vendorList = [];
          if (this.data.dataList) {
            this.data.dataList.forEach((item, index) => {
              let obj = {
                partcategoryid: item.id,
                partcategorytext: item.vendorName
              }
              vendorList.push(obj);

            });
          }
        }
        else {
          this.selectedVendorValue = '';
        }
      }
    )
  }
  Getvendordropdownlists(companyId, vendorId) {
    this.partService.Getvendordropdownlist(companyId).subscribe(
      data => {
        this.data = data;
        if (companyId > 0) {
          let vendorList = [];
          if (this.data.dataList) {
            this.data.dataList.forEach((item, index) => {
              if (item.id == vendorId) {
                let obj = {
                  id: item.id,
                  vendorName: item.vendorName,
                  color: item.discount != 0 ? 'red' : 'black',
                  setvalue: false,
                  discount: item.discount
                }
                vendorList.push(obj);
              }
              else {
                let obj = {
                  id: item.id,
                  vendorName: item.vendorName,
                  color: item.discount != 0 ? 'red' : 'black',
                  setvalue: item.discount == 0 ? false : true,
                  discount: item.discount
                }
                vendorList.push(obj);
              }

            });
            this.vendorlist = vendorList;
          }
        }
        else {
          this.selectedVendorValue = '';
        }
      }
    )
  }
  Getmultivendordropdownlist(companyId, editrow, customid, Alist) {
    if (companyId == undefined) {
      companyId = 0;
    }
    this.partService.Getmultivendordropdownlist(companyId).subscribe(
      data => {
        this.data = data;
        if (companyId > 0) {
          this.vendorlist1 = this.data.dataList;
          let vendorList = [];
          if (this.data.dataList) {
            this.data.dataList.forEach((item, index) => {
              let obj = {
                partcategoryid: item.id,
                partcategorytext: item.vendorName
              }
              vendorList.push(obj);

            });
            customid = customid == 0 ? undefined : customid;
            if (editrow != undefined && customid != undefined && Alist != undefined) {

              if (editrow == true) {
                $('#vendorModel').show();
                this.loader = true;
                data = Alist.find(x => x.id == customid && x.IsActive == true);
                this.loader = false;
                this.partmodelVendorValue = data["vendorName"] + "-" + data["vendorId"] + "-" + data["Discount"];
                var spilted = this.selectedVendorValue.split("-");
                const vendorValue = spilted[0];
                this.vendorlist2 = this.vendorlist1.filter(x => x.id != vendorValue);
                this.PVendorCost = data["vendorPartCost"];
                this.PPartPrice = data["cost"];
                this.PartMarkupCheck = data["markupCheck"];
                this.PartMarkupMargin = data["markupMargin"];
                this.PVendorPartName = data['vendorPartName'];
                this.PVendorNumber = data['vendorPartNumber'];
                this.EditpartId = data["id"];
                this.IsPartUpdate = true;
                this.IsPartSave = false;
                const counting = Alist.filter(x => x.IsActive == true);
                if (counting.length > 0) {
                  this.DeActiveCouting = false;
                  this.ActiveCouting = true;
                }
                else {
                  this.DeActiveCouting = true;
                  this.ActiveCouting = false;
                }
              }
            }
          }
        }
        else {
          this.partmodelVendorValue = '';
        }
      }
    )
  }

  UnitofMeasurementDropdownList() {
    this.partService.UnitofMeasurement().subscribe(
      data => {
        this.data = data;
        this.unitofmeasurement = this.data.dataList;
        this.GetAllImageTypeList();
      }
    )
  }

  InvTypeDropdownList() {
    this.sharedService.GetPOTypeDetail().subscribe(
      data => {
        this.data = data;
        this.InvTypeList = this.data.dataList;
        this.mInvTypeList = this.data.dataList;
      }
    )
  }

  InvProductOrderDropdownList(companyId) {
    // Pass 1 For Product Order
    if (companyId == undefined) { companyId = 0; }
    this.partService.PartInvOrderDropdownList(1, companyId).subscribe(
      data => {
        this.data = data;
        if (companyId > 0) {
          this.InvProductOrderDDL = this.data.dataList;
          this.mInvProductOrderDDL = this.data.dataList;
        }
        else {

        }
      }
    );
  }

  InvPartOrderDropdownList(companyId) {
    // Pass 2 For Part Order
    if (companyId == undefined) { companyId = 0; }
    this.partService.PartInvOrderDropdownList(2, companyId).subscribe(
      data => {
        this.data = data;
        if (companyId > 0) {
          this.InvPartOrderDDL = this.data.dataList;
          this.mInvPartOrderDDL = this.data.dataList;
        }
        else {

        }
      }
    );
  }

  Getquestiondropdownlist(companyId) {
    if (companyId == undefined) { companyId = 0; }
    this.partService.Getquestiondropdownlist(companyId).subscribe(
      data => {
        this.data = data;
        if (companyId > 0) {
          this.questionlist = this.data.dataList;
        }
        else {

        }
        this.tempQuestionlist = this.data.dataList;
        this.questionlistNew = [...this.data.dataList];
        //this.GetAllPart(this.companyId,'');

      }
    )
  }

  GetquestiondropdownlistDuplicate(companyId) {
    this.partService.Getquestiondropdownlist(companyId).subscribe(
      data => {
        this.data = data;
        if (companyId > 0) {
          this.questionlist = this.data.dataList;
          if (this.FilertingData.data.partQuestions != null && this.FilertingData.data.partQuestions.length > 0) {
            this.FilertingData.data.partQuestions.forEach(cateLst => {
              this.questionlist = this.questionlist.filter(x => x.id != cateLst.questionId);
            });
          }
        }
        else {
        }
        this.tempQuestionlist = this.data.dataList;
        this.questionlistNew = [...this.data.dataList];
      }
    )
  }
  onValueChange(e) {
    var value = e.target.value;
    if (value != "") {
      const SpiltValue = value.split("-");
      const Discount = SpiltValue[1];
      const vendorValue = SpiltValue[0];

      var CheckExistPartList = this.partVendorsList.filter(x => x.Id == vendorValue && x.type == "2");
      if (CheckExistPartList.length > 0) {
        this.vendorText = CheckExistPartList[0].name;
        $('#AlreadyVendorExist').show();
        return;
      }
      else {
        var checklist = this.partVendorsList.filter(x => x.type == "1");
        if (checklist.length > 0) {
          var updateInvList = this.InvList.filter(x => x.InvVendor == checklist[0].Id);
          if (updateInvList.length > 0) {
            updateInvList.forEach(element => {
              element.InvVendor = Number(vendorValue);
            });

          }
          checklist[0].Id = Number(vendorValue);
          checklist[0].name = e.target.options[e.target.options.selectedIndex].text;
          checklist[0].type = "1";
        }
        else {
          var obj = {
            "Id": Number(vendorValue),
            "name": e.target.options[e.target.options.selectedIndex].text,
            "type": "1"
          }
          this.partVendorsList.push(obj);
        }

        if (Discount != 0) {
          this.AdditionalDiscountLabel = true;
          this.vendorService.GetVendorById(vendorValue).subscribe(
            (data: any) => {
              this.loader = false;
              this.ADiscount = data.data.discount * 100;
              this.AVendor = data.data.vendorName;
            });
        }
        else {
          this.AdditionalDiscountLabel = false;
          this.ADiscount = "";
          this.AVendor = "";
        }
      }
    }
    else {
      this.selectedVendorValue = '';
      this.AdditionalDiscountLabel = false;
      this.ADiscount = "";
      this.AVendor = "";
    }
  }

  AlreadyVendorExist() {
    $('#AlreadyVendorExist').hide();
    this.selectedVendorValue = '';
    this.AdditionalDiscountLabel = false;
    this.ADiscount = "";
    this.AVendor = "";
  }
  companyChangeValue(value: any): void {
    if (value == undefined) {
      value = 0;
    }
    this.CompanyValue = value;
    this.getAllCategories_search(this.CompanyValue);
    this.Getquestiondropdownlist(this.CompanyValue);
    this.Getvendordropdownlist(this.CompanyValue);
    this.Getmultivendordropdownlist(this.CompanyValue, undefined, undefined, undefined);
    this.getAllProductionRole(this.CompanyValue);
    this.getAllWorkflowSteps(this.CompanyValue);
    this.WordpressCategoryLoad(this.CompanyValue);
    this.AdditionalDiscountLabel = false;
    this.selectedVendorValue = '';
    this.getSkillLevel(this.CompanyValue, this.userRole);
    this.selectedItems = [];
    this.getLocationLevel(this.CompanyValue);
    this.User();
    this.UserRole();
    this.GetCatList(value);
  }

  ChngSettingImage(files: FileList) {

    this.fileToUpload = files.item(0);
    var reader = new FileReader()
    reader.onload = (event: any) => {
      this.imageUrl = event.target.result;
      this.Documentype = this.fileToUpload.type == 'application/pdf' ? 'PDF' : this.fileToUpload.type == "application/msword" ? "Word" : "";
    }

    reader.readAsDataURL(this.fileToUpload);
  }

  AddMultiplePartImage() {
    this.validationImage = true;
    this.PictureValue = this.myInputVariable.nativeElement.value;
    if (this.PictureValue == "" || this.SelectedImageTypeId == '') {
      return;
    }

    let ojtmultiplepart = {
      partypeName: this.SelectedImageTypeId,
      imageUrl: this.Documentype == "PDF" ? this._sanitizer.bypassSecurityTrustHtml(this.imageUrl) : this.imageUrl,
      documenttype: this.Documentype,
      wpId: 0,
    }
    if (this.SelectedImageTypeId == '4') {
      if (this.MultipleImage.length <= 5) {
        this.MultipleImage.push(ojtmultiplepart);
        this.SelectedImageTypeId = '';
        this.imageUrl = '';
        this.validationImage = false;
        this.myInputVariable.nativeElement.value = "";
      }
      else {
        $("#Imagevlaidation").show();
      }
    }
    if (this.SelectedImageTypeId == '9') {
      if (this.MultipleDownloadImg.length <= 5) {
        this.MultipleDownloadImg.push(ojtmultiplepart);
        this.SelectedImageTypeId = '';
        this.imageUrl = '';
        this.validationImage = false;
        this.myInputVariable.nativeElement.value = "";
      }
      else {
        $("#Imagevlaidation").show();
      }
    }
  }
  ImageOrderAlertClose() {
    $("#ImageOrderAlert").hide();
  }
  ImageAlertClose() {
    $("#ImageAlert").hide();
  }
  hidemodalpopup() {
    $("#myModal").hide();
  }
  DeleteImage() {
    let index = this.deletedItemImage;
    let index1 = this.deletedItemImageDownload;

    if (index != null) {
      this.MultipleImage.splice(index, 1);
    }
    if (index1 != null) {
      this.MultipleDownloadImg.splice(index, 1);
    }
  }
  resetvendorData() {
    this.Questions.clear();
    this.createForm();
    this.Pwordpressid = '';
    this.gtinupc = '';
    this.brandname = '';
    this.ECommerceStatus = '';
    this.description = '';
    this.ShortDescription = '';
    this.OEMDiscount = '';
    this.Tracking = 0;
    this.SelectedLocation = '';
    this.SelectedPartLocation = '';
    this.recordAlreadyExist = false;
    this.recordPartNoExist = false;
    this.vendorId = '';
    this.name = '';
    this.partNo = '';
    this.cost = '';
    this.VendorPartCost = '';
    this.hours = '';
    this.partLocation = '';
    this.partLeadTime = '';
    this.reorderAmount = '';
    this.search = '';
    this.partCategoryId = '';
    this.selectedVendorValue = '';
    this.selectedPartCategoryIdValue = '';
    this.imageUrl = '/assets/image/add-part.png';
    this.VendorPartName = '';
    this.VendorPartNumber = '';
    this.SelectedUnitMeasure = '';
    this.minInventory = '';
    this.userRole = '';
    this.skillLevel = '0';
    this.form.controls['skillLevel'].disable();
    this.WorkflowSteps = '';
    this.partLocation = '';
    this.partLeadTime = '';
    this.reorderAmount = '';
    this.installationNotes = '';
    this.viewLog = false;
    this.isInvFind = false;
    this.AdditionalDiscountLabel = false;
    this.weight = '';
    this.height = '';
    this.width = '';
    this.length = '';
    this.NewPartialArray = [];
    this.MarkupCheck = false;
    this.MarkupMargin = '';
    this.NotActive = false;
    this.CheckCategory = false;
    this.CheckCategoryDisable = false;
    this.PartHistory = false;
    this.CategoryArr = [];
    this.selectedItems = [];
    this.AddvendorList = [];
    this.DeActiveCouting = true;
    this.ActiveCouting = false;
    this.AddvendorList1 = [];
    this.AddvendorList2 = [];
    this.IsUpdate = false;
    this.NonInventory = false;
    this.partTracking = '3';
    this.IsSave = true;
    this.MultipleImage = [];
    this.MultipleDownloadImg = [];
    this.selectedMulti = [];
    this.dynamicCustomerDiscount = [];
    this.dynamicCustomerDiscount.push({ 'minQty': '', 'Qty': '', 'DiscountType': '', 'DiscountPrice': '', 'cost': '', 'description': '', 'id': '0', 'isActive': false, 'CreateDate': '', 'CreatedBy': '', 'isReadOnly': false, 'IsDReadonly': false, 'wordPressId': '0' });
    this.dynamicArray = [];
    // this.dynamicArray.push({ 'Qty': '', 'DiscountPrice': '', 'cost': '', 'id': '0', 'isActive': false, 'CreateDate': '', 'CreatedBy': '', 'isReadOnly': false, 'IsDReadonly': false });
    this.dynamicArray.push({ 'minQty': '', 'Qty': '', 'DiscountType': '', 'DiscountPrice': '', 'cost': '', 'description': '', 'id': '0', 'isActive': false, 'CreateDate': '', 'CreatedBy': '', 'isReadOnly': false, 'IsDReadonly': false });
    this.dynamicCustomerRoleDiscount = [];
    this.dynamicCustomerRoleDiscount.push({ 'DiscountType': '', 'DiscountValue': '', 'CustomerType': '', 'CustomerValue': '', 'cost': '', 'description': '', 'id': '0', 'isActive': false, 'CreateDate': '', 'CreatedBy': '', 'isReadOnly': false, 'IsDReadonly': false, 'wordPressId': '0' });
    this.dynamicSpecificArray = [];
    this.dynamicSpecificArray.push({ 'id': '0', 'Name': '', 'Value': '', 'Ordering': 0, 'isActive': false, 'isReadOnly': false, 'CreateDate': '', 'CreatedBy': '', 'IsDReadonly': false });
    if (this.companyId > 0) {
      this.value = this.companyId
    }
    else {
      this.value = 0;
    }
    if (this.questionArray.length > 0) {
      const deletedItem = this.questionArray.controls.splice(1, this.questionArray.length - 1);
      for (let i = 0; i < deletedItem.length; i++) {
        const arrayItem = this.questionlistNew.find(x => x.id === deletedItem[i].value.questionId);
        if (arrayItem) {
          if (this.questionlist != undefined) {
            this.questionlist.push(arrayItem);
          }

        }
      }
    }

    this.InvQty = '';
    this.InvDate = '';
    this.InvCost = '';
    this.InvType = '1';
    this.InvVendor = '';
    this.InvCustNo = '';
    this.InvServNo = '';
    this.InvCustRMA = '';
    this.InvPartLocation = '';
    this.InvList = [];
    this.SaveInvList = [];
    this.SavelogList = [];
    this.logList = [];
    this.InvDetails = [];
    this.MultipleImage = [];
    this.SelectedImageTypeId = '';
    this.validationImage = false;
    this.PartImageArray = [];
    this.fileUpload = '';
    this.isTotalInvQty = false;
    this.shortDescription = '';
    this.TotalAllType = '';
    this.SaveTotalAllType = '';
    this.TotalTypeInventory = '';
    this.SaveTotalTypeInventory = '';
    this.TotalInvLog = '';
    this.SaveTotalInvLog = '';
    // this.locationNameList = [];
    this.globalLocationNameList = [];
    this.WordpressCategoryLoad(this.CompanyValue);
    this.ResetPopModelData();
    this.exitProp = false;
    this.IsSerialDisabled = false;
    this.openId = 0;
  }

  AddPartialPart() {

    this.NewPartialArray = [];
    this.popvalidation = true;
    if (this.newform.controls.shortDescription.status === this.constants.Invalid ||
      this.newform.controls.InfoName.status === this.constants.Invalid ||
      this.newform.controls.MatingName.status === this.constants.Invalid ||
      this.newform.controls.CrimpName.status === this.constants.Invalid ||
      this.newform.controls.RelatedName.status === this.constants.Invalid
    ) {
      return;
    }
    let objpartial = {};
    objpartial['MoreInformation'] = String(this.shortDescription);
    objpartial['InformationName'] = String(this.InfoName);
    objpartial['MatingName'] = String(this.MatingName);
    objpartial['CrimpName'] = String(this.CrimpName);
    objpartial['RelatedName'] = String(this.RelatedName);
    let invArr1 = this.CustomerPartNameList;
    let invObj = [];
    for (var j = 0; j < invArr1.length; j++) {
      if (invArr1[j].IsChecked == true) {
        var inv1 = {};
        inv1["ProductId"] = invArr1[j].wordpressId;
        inv1["Type"] = "Mating";
        inv1["isChecked"] = true;
        inv1["CategoryId"] = invArr1[j].partCategoryId;
        invObj.push(inv1);
      }
    }
    objpartial['ApiWordpressProduct'] = invObj;
    let invArr2 = this.CustomerPartNameCrimpList;
    for (var j = 0; j < invArr2.length; j++) {
      if (invArr2[j].IsChecked == true) {
        var inv2 = {};
        inv2["ProductId"] = invArr2[j].wordpressId;
        inv2["Type"] = "Crimp";
        inv2["isChecked"] = true;
        inv2["CategoryId"] = invArr2[j].partCategoryId;
        invObj.push(inv2);
      }
    }
    objpartial['ApiWordpressProduct'] = invObj;
    let invArr3 = this.CustomerPartNameRelatedList;

    for (var j = 0; j < invArr3.length; j++) {
      if (invArr3[j].IsChecked == true) {
        var inv3 = {};
        inv3["ProductId"] = invArr3[j].wordpressId;
        inv3["Type"] = "Related";
        inv3["isChecked"] = true;
        inv3["CategoryId"] = invArr3[j].partCategoryId;
        invObj.push(inv3);
      }
    }
    objpartial['ApiWordpressProduct'] = invObj;

    this.NewPartialArray.push(objpartial)
    $("#CheckCategoryModal").hide();
  }

  VendorPartEdit(item: any) {
    this.loader = true;
    var data = {};
    data = this.AddvendorList.find(x => x.id == item.id && x.IsActive == true);
    this.loader = false;
    this.partmodelVendorValue = data["vendorName"] + "-" + data["vendorId"] + "-" + data["Discount"];
    this.PVendorCost = parseFloat(data["vendorPartCost"]).toFixed(5);
    this.PPartPrice = parseFloat(data["cost"]).toFixed(5);
    this.PartMarkupCheck = data["markupCheck"];
    this.PartMarkupMargin = data["markupMargin"];
    this.PVendorPartName = data['vendorPartName'];
    this.PVendorNumber = data['vendorPartNumber'];
    this.EditpartId = data["id"];
    this.IsPartUpdate = true;
    this.IsPartSave = false;
    const counting = this.AddvendorList.filter(x => x.IsActive == true);
    if (counting.length > 0) {
      this.DeActiveCouting = false;
      this.ActiveCouting = true;
    }
    else {
      this.DeActiveCouting = true;
      this.ActiveCouting = false;
    }
  }
  UpdateVendorPart() {
    this.partvalidation = true;
    if (this.partForm.controls.partmodelVendorValue.status === this.constants.Invalid ||
      this.partForm.controls.PVendorCost.status === this.constants.Invalid ||
      this.partForm.controls.PPartPrice.status === this.constants.Invalid ||
      this.partForm.controls.PVendorNumber.status === this.constants.Invalid ||
      this.partForm.controls.PPartPrice.status === this.constants.Invalid ||
      this.CompanyValue === 0
    ) {
      return;
    }
    var data = {};
    data = this.AddvendorList.find(x => x.id == this.EditpartId && x.IsActive == true);


    var data1 = this.partmodelVendorValue.split("-");
    const AlreadyExist = this.AddvendorList.find(x => x.vendorId == Number(data1[1]) && x.IsActive == true && x.id != this.EditpartId);
    if (AlreadyExist) {
      this.AlreadyVendorName = String(data1[0]) + " Already Added in the Additional Vendors for Parts";
      $('#AlreadyVendor').show();
    }
    else {
      var checklist = this.partVendorsList.filter(x => x.type == "2" && x.Id == Number(data['vendorId']));
      if (checklist.length > 0) {
        var updateInvList = this.InvList.filter(x => x.InvVendor == checklist[0].Id);
        if (updateInvList.length > 0) {
          updateInvList.forEach(element => {
            element.InvVendor = Number(data1[1]);
          });
        }
        checklist[0].Id = Number(data1[1]);
        checklist[0].name = String(data1[0]);
        checklist[0].type = "2";
      }
      else {
        var obj = {
          "Id": Number(data1[1]),
          "name": String(data1[0]),
          "type": "2"
        }
        this.partVendorsList.push(obj);
      }

      data['vendorId'] = Number(data1[1]);
      data['vendorName'] = String(data1[0]);
      data['Discount'] = String(data1[2]);
      data['vendorPartCost'] = Number(this.PVendorCost);
      data['cost'] = Number(this.PPartPrice);
      data['markupCheck'] = this.PartMarkupCheck;
      data['markupMargin'] = this.PartMarkupMargin;
      data['vendorPartName'] = this.PVendorPartName;
      data['vendorPartNumber'] = this.PVendorNumber;
      data['companyId'] = Number(this.CompanyValue);
      data['ModifiedBy'] = this.userId;
      data['CreatedBy'] = this.userId;
      data["CreatedDate"] = data["CreatedDate"];
      data["ModifiedDate"] = data["ModifiedDate"];
      data["IsActive"] = true;
      this.SaveModel = "Update Successfully";
      $('#SaveModel').show();
      this.resetData();
      const counting = this.AddvendorList.filter(x => x.IsActive == true);
      if (counting.length > 0) {
        this.DeActiveCouting = false;
        this.ActiveCouting = true;
      }
      else {
        this.DeActiveCouting = true;
        this.ActiveCouting = false;
      }
    }

  }

  deletePartVendorList(id: any) {
    var data = {};
    data = this.AddvendorList.find(x => x.id == id && x.IsActive == true);
    data['vendorId'] = data["vendorId"];
    data['vendorName'] = data["vendorName"];
    data['Discount'] = data["Discount"];
    data['vendorPartCost'] = data["vendorPartCost"];
    data['cost'] = data["cost"];
    data['markupCheck'] = data["markupCheck"];
    data['markupMargin'] = data['markupMargin'];
    data['vendorPartName'] = data['vendorPartName'];
    data['vendorPartNumber'] = data['vendorPartNumber'];
    data['companyId'] = data['companyId'];
    data['ModifiedBy'] = data['ModifiedBy'];
    data['CreatedBy'] = data['CreatedBy'];
    data["CreatedDate"] = data["CreatedDate"];
    data["ModifiedDate"] = data["ModifiedDate"];
    data["IsActive"] = false;
    // this.AddvendorList = this.AddvendorList.filter(x=>x.id !== id);
    $('#deleteVendorlist').hide();
    this.SaveModel = "Delete Successfully";
    const counting = this.AddvendorList.filter(x => x.IsActive == true);
    if (counting.length > 0) {
      this.DeActiveCouting = false;
      this.ActiveCouting = true;
    }
    else {
      this.DeActiveCouting = true;
      this.ActiveCouting = false;
    }
    $('#SaveModel').show();
    this.partVendorsList = this.partVendorsList.filter(x => x.Id != Number(data['vendorId']));
  }


  AddVendorPart() {
    this.partvalidation = true;
    if (this.partForm.controls.partmodelVendorValue.status === this.constants.Invalid ||
      this.partForm.controls.PVendorCost.status === this.constants.Invalid ||
      this.partForm.controls.PPartPrice.status === this.constants.Invalid || this.partForm.controls.PVendorNumber.status === this.constants.Invalid ||
      this.partForm.controls.PPartPrice.status === this.constants.Invalid ||
      this.CompanyValue === 0
    ) {
      return;
    }
    if (this.PartMarkupCheck == true) {
      if (this.partForm.controls.PartMarkupMargin.status === this.constants.Invalid) {
        return;
      }

    }
    else {
      this.partForm.controls.PartMarkupMargin.status === "VALID";
    }

    let objs = {};
    var data = this.partmodelVendorValue.split("-");

    objs['vendorId'] = Number(data[1]);
    objs['vendorName'] = String(data[0]);
    objs['Discount'] = String(data[2]);
    const vid = Number(objs['vendorId']);
    const AlreadyExist = this.AddvendorList.find(x => x.vendorId == vid && x.IsActive == true);
    if (AlreadyExist) {
      this.AlreadyVendorName = String(data[0]) + " Already Added in the Additional Vendors for Parts";
      $('#AlreadyVendor').show();
    }
    else {
      objs["id"] = Number(this.i) + 1;
      this.i = objs["id"];
      objs['vendorPartCost'] = Number(this.PVendorCost);
      objs['cost'] = Number(this.PPartPrice);
      objs['markupCheck'] = this.PartMarkupCheck;
      objs['markupMargin'] = this.PartMarkupMargin;
      objs['companyId'] = Number(this.CompanyValue);
      objs['vendorPartName'] = this.PVendorPartName;
      objs['vendorPartNumber'] = this.PVendorNumber;
      objs['ModifiedBy'] = this.userId;
      objs['CreatedBy'] = this.userId;
      objs["CreatedDate"] = this.now = new Date();
      objs["ModifiedDate"] = this.now = new Date();
      objs["IsActive"] = true;
      this.AddvendorList.push(objs);
      this.rerender();
      // this.AddvendorList = this.AddvendorList.find(x=>x.IsActive == true);
      this.resetData();
      var obj = {
        "Id": vid,
        "name": objs['vendorName'],
        "type": "2"
      }
      this.partVendorsList.push(obj);

      this.SaveModel = "Save Successfully";
      const counting = this.AddvendorList.filter(x => x.IsActive == true);
      if (counting.length > 0) {
        this.DeActiveCouting = false;
        this.ActiveCouting = true;
      }
      else {
        this.DeActiveCouting = true;
        this.ActiveCouting = false;
      }
      $('#SaveModel').show();
    }

  }
  SavePopModel() {
    $('#SaveModel').hide();
  }
  AlreadyVendor() {
    $('#AlreadyVendor').hide();
  }
  resetData() {
    this.partvalidation = false;
    this.PartMarkupMargin = this.MarkupMargin;
    this.PVendorCost = this.VendorPartCost;
    this.PPartPrice = this.cost;
    this.PartMarkupCheck = this.MarkupCheck;
    this.PartMarkupMargin = this.MarkupMargin;
    this.PVendorNumber = this.VendorPartNumber;
    this.PVendorNumber = this.VendorPartName;
    this.partmodelVendorValue = "";
    this.IsPartUpdate = false;
    this.IsPartSave = true;
  }
  ErrorModel() {
    $('#ErrorModel').hide();
  }

  getStrDesc(event: any) {
    const shortDescrip = tinymce.get('shortDescription').getContent();
    let arr = [];
    if (shortDescrip != null && shortDescrip.includes("<img")) {
      const imgData = shortDescrip.split("<p>");
      for (let i = 0; i < imgData.length; i++) {
        if (imgData[i].includes("<img") && imgData[i] != null && imgData[i] != undefined)
          arr.push(imgData[i])
      }
      this.partService.callImageBlobApi(arr).subscribe((data: any) => {
        if (data != null) {
          let count = 0;
          for (let i = 0; i < imgData.length; i++) {
            if (imgData[i].includes("<img") && imgData[i].includes(".blob.")) {
              imgData[i] = data[count];
              count++;
            } else if (imgData[i].includes("<img") && imgData[i].includes(".blob.") == false) {
              imgData[i] = "<img src='" + data[count] + "'></p>";
              count++;
            }
          }
          this.shortDesc = imgData.join('<p>');
        }
      })
    } else {
      this.ShortDescription = shortDescrip;
    }
  }

  getDtlDesc(event: any) {
    const detaildesc = tinymce.get('detailDescription').getContent();
    let arr1 = [];
    let imgData1 = [];
    if (detaildesc != null && detaildesc.includes("<img")) {
      imgData1 = detaildesc.split("<p>")
      for (let i = 0; i < imgData1.length; i++) {
        if (imgData1[i].includes("<img") && imgData1[i] != null && imgData1[i] != undefined)
          arr1.push(imgData1[i])
      }
      this.partService.callImageBlobApi(arr1).subscribe((data: any) => {
        if (data != null) {
          let count1 = 0;
          for (let i = 0; i < imgData1.length; i++) {
            if (imgData1[i].includes("<img") && imgData1[i].includes(".blob.")) {
              imgData1[i] = data[count1];
              count1++;
            } else if (imgData1[i].includes("<img") && imgData1[i].includes(".blob.") == false) {
              imgData1[i] = "<img src='" + data[count1] + "'></p>";
              count1++;
            }
          }
          this.description = imgData1.join('<p>');
        }
      })
    } else {
      this.description = detaildesc;
    }
  }


  AddPart() {
    this.shortDesc = tinymce.get('shortDescription').getContent();
    // const detaildesc = tinymce.get('detailDescription').getContent();
    this.validation = true;
    this.recordAlreadyExist = false;
    this.recordPartNoExist = false;
    this.clearbutton = true;
    if (this.weight == 0 || this.weight == null || this.weight == undefined || this.weight == '') {
      this.ErrorModelMsg = 'Weight should be greater than Zero.';
      $('#ErrorModel').show();
      return;
    }
    if (this.form.controls.name.status === this.constants.Invalid ||
      this.form.controls.partNo.status === this.constants.Invalid ||
      this.form.controls.cost.status === this.constants.Invalid ||
      this.form.controls.vendorId.status === this.constants.Invalid ||
      this.form.controls.selectedItems.status === this.constants.Invalid ||
      this.form.controls.weight.status === this.constants.Invalid ||
      this.CompanyValue === 0
    ) {
      return;
    }
    if (this.MarkupCheck == true) {
      if (this.form.controls.MarkupMargin.status === this.constants.Invalid) {
        return;
      }
    }
    else {
      this.form.controls.MarkupMargin.status === "VALID";
    }

    if (this.CategoryArr.length == 0) {
      this.ErrorModelMsg = 'Please Select atleast one Part Category.';
      $('#ErrorModel').show();
      return;
    }

    let obj = {};
    const Defaultimage = '/assets/image/add-part.png';
    obj['name'] = this.name;
    obj['partNo'] = this.partNo;
    if (this.description != null && this.shortDesc != null) {
      let detDesc = this.description.replaceAll('\n', ' ');
      let srtDesc = this.shortDesc.replaceAll('\n', ' ')
      obj['Description'] = detDesc.replaceAll('"', "'");
      obj['ShortDescription'] = srtDesc.replaceAll('"', "'");
    } else {
      obj['Description'] = this.description;
      obj['ShortDescription'] = this.shortDesc;
    }

    obj['WPData'] = Number(this.WordPressData);
    obj['cost'] = parseFloat(this.cost);
    obj['hours'] = this.hours == "" ? "0" : this.hours == "undefined" ? "0" : this.hours == undefined ? "0" : String(this.hours);
    obj['partLocation'] = this.SelectedPartLocation;
    obj['partLocationName'] = this.SelectedLocation;
    obj['partLeadTime'] = Number(this.partLeadTime);
    obj['reorderAmount'] = Number(this.reorderAmount);
    obj['search'] = this.search;
    obj['partImage'] = this.PartImage;
    obj['companyId'] = Number(this.CompanyValue);
    const SpiltValue = this.selectedVendorValue.split("-");
    const Discount = SpiltValue[1];
    const vendorValue = parseInt(SpiltValue[0]);
    obj['vendorId'] = vendorValue;
    obj['partCategoryId'] = Number(0);
    obj['VendorPartCost'] = parseFloat(this.VendorPartCost);
    obj['IsCustompart'] = false;
    obj['VendorPartName'] = this.VendorPartName;
    obj['VendorPartNumber'] = this.VendorPartNumber;
    // obj['UnitofMeasureId'] = Number(this.SelectedUnitMeasure);
    obj['UnitofMeasureId'] = Number(0);
    obj['MinInventory'] = Number(this.minInventory);
    obj['SkillLevel'] = Number(this.skillLevel);
    obj['ProductionRole'] = this.userRole;
    obj['WorkflowStep'] = Number(this.WorkflowSteps);
    obj['InstallationNotes'] = this.installationNotes;
    obj['TotalAllType'] = Number(this.TotalAllType);
    obj['TotalTypeInventory'] = Number(this.TotalTypeInventory);
    obj['TotalInvLog'] = Number(this.TotalInvLog);
    obj['partTracking'] = Number(this.partTracking);
    obj['LocationName'] = this.selectedLocationName;
    obj['Location'] = this.locationText;
    // obj['Height'] = this.CovertDiemensionToInch(this.height, this.DimentionTypeH);
    // obj['Length'] = this.CovertDiemensionToInch(this.length, this.DimentionTypeL);
    // obj['Width'] = this.CovertDiemensionToInch(this.width, this.DimentionTypeW);
    obj['DimentionType'] = String(this.DimentionTypeH);  // same for height or length or width
    // obj['Weight'] = this.CovertWeightToLbs(this.weight, this.WeightType);
    obj['Weight'] = Number(this.weight);
    obj['Height'] = this.height;
    obj['Length'] = this.length;
    obj['Width'] = this.width;
    obj['WeightType'] = String(this.WeightType);
    obj['WordpressId'] = Number(this.Pwordpressid ?? 0);
    obj['gtinupc'] = this.gtinupc;
    obj['brandname'] = this.brandname;
    obj['ECommerceStatus'] = this.ECommerceStatus;
    obj['autoMarkup'] = this.MarkupCheck;
    obj['markupMargin'] = Number(this.MarkupMargin);
    obj['isNotActive'] = Boolean(this.NotActive);
    obj['OEMDiscount'] = parseFloat(this.OEMDiscount);
    this.InvDetails = [];
    let invArr = this.InvList;
    for (var j = 0; j < invArr.length; j++) {
      let invObj = {};
      let LocalServNo = '';
      let LocalPartOrderId = '';
      let LocalProductOrderId = '';
      // let LocalInvDate = invArr[j].InvDate.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3");

      if (invArr[j].InvType == "5") {
        LocalServNo = invArr[j].InvType2;
      }
      else if (invArr[j].InvType == "6") {
        LocalPartOrderId = invArr[j].InvType2;
      }
      else if (invArr[j].InvType == "7") {
        LocalProductOrderId = invArr[j].InvType2;
      }

      invObj["Id"] = invArr[j].id;
      // invObj["RcvdId"] = invArr[j].rcvdId;
      invObj["PartId"] = parseInt(invArr[j].partId);
      invObj["Qty"] = parseInt(invArr[j].InvQty);
      invObj["OrderedQty"] = parseInt(invArr[j].InvQtyOrdered);
      invObj["Date"] = invArr[j].InvDate;
      invObj["Cost"] = parseFloat(invArr[j].InvCost);
      invObj["TypeId"] = parseInt(invArr[j].InvType);
      invObj["CustomerSO"] = invArr[j].MovePid; //CustomerSo use for move feature
      invObj["ServiceNo"] = LocalServNo;
      invObj["PartOrderId"] = parseInt(LocalPartOrderId);
      invObj["ProductOrderId"] = parseInt(LocalProductOrderId);
      invObj["CustomerRMA"] = invArr[j].InvCustRMA;
      invObj["PartLocation"] = invArr[j].InvPartLocation;
      invObj["Reason"] = invArr[j].InvReason;
      invObj["IsActive"] = true;
      invObj["CreatedBy"] = this.userId;
      invObj["CreatedDate"] = this.now = new Date();
      invObj["PurchaseOrderId"] = invArr[j].purchaseOrderId;
      invObj["ShipQty"] = invArr[j].shipQty;
      invObj["VendorId"] = invArr[j].InvVendor;
      invObj["LocationName"] = parseInt(invArr[j].InvLoc);
      invObj["Location"] = String(invArr[j].InvLoc1);
      invObj["Tracking"] = String(invArr[j].InvTracking);
      invObj["SerialNo"] = String(invArr[j].isChange);
      invObj["CustomPartId"] = invArr[j].InvCustomPartId;
      this.SerialList = [];
      const SerialNumberList = invArr[j].obj1;
      if (SerialNumberList != undefined) {
        for (var jk = 0; jk < SerialNumberList.length; jk++) {
          let serialObj = {};
          serialObj["Part"] = SerialNumberList[jk].part;
          serialObj["SerialNo"] = SerialNumberList[jk].serialNo;
          serialObj["IsSale"] = SerialNumberList[jk].isSale == null ? false : SerialNumberList[jk].isSale;
          serialObj["PartId"] = this.id;
          serialObj["PartInventoryId"] = invArr[j].id;
          serialObj["type"] = SerialNumberList[jk].type;
          serialObj["companyId"] = this.CompanyValue;
          serialObj["isActive"] = SerialNumberList[jk].isActive;
          serialObj["Ordering"] = SerialNumberList[jk].ordering;
          if (this.id != undefined && this.id != "" && !Number.isNaN(this.id)) {
            serialObj["id"] = SerialNumberList[jk].id;
          }
          this.SerialList.push(serialObj);
        }
      }

      invObj['PartSerialNumber'] = this.SerialList;
      this.InvDetails.push(invObj);
    }
    obj["PartInventory"] = this.InvDetails;
    this.PartImageArray = [];
    let dataPart = this.MultipleImage;
    for (var j = 0; j < dataPart.length; j++) {
      let partObj = {};
      partObj["PartType"] = parseInt(dataPart[j].partypeName);
      partObj["DocumentType"] = dataPart[j].documenttype;
      if (dataPart[j].imageUrl.changingThisBreaksApplicationSecurity) {
        dataPart[j].imageUrl = dataPart[j].imageUrl.changingThisBreaksApplicationSecurity;
      }
      partObj["ImagePath"] = dataPart[j].imageUrl == Defaultimage ? null : dataPart[j].imageUrl;
      partObj['CreatedBy'] = this.userId;
      partObj['IsActive'] = true;
      partObj["CreatedDate"] = this.now = new Date();
      partObj["PartId"] = this.id;
      partObj["WPid"] = parseInt(dataPart[j].wpId);
      this.PartImageArray.push(partObj);
    }

    let dataPart1 = this.MultipleDownloadImg;
    for (var j = 0; j < dataPart1.length; j++) {
      let partObj = {};
      partObj["PartType"] = parseInt(dataPart1[j].partypeName);
      partObj["DocumentType"] = dataPart1[j].documenttype;
      if (dataPart1[j].imageUrl.changingThisBreaksApplicationSecurity) {
        dataPart1[j].imageUrl = dataPart1[j].imageUrl.changingThisBreaksApplicationSecurity;
      }
      partObj["ImagePath"] = dataPart1[j].imageUrl == Defaultimage ? null : dataPart1[j].imageUrl;
      partObj['CreatedBy'] = this.userId;
      partObj['IsActive'] = true;
      partObj["CreatedDate"] = this.now = new Date();
      partObj["PartId"] = this.id;
      this.PartImageArray.push(partObj);
    }

    obj["PartImageUpload"] = this.PartImageArray;
    obj['ApiWordpressDetails'] = this.NewPartialArray;
    const questionIds = [];
    for (let index = 1; index < this.questionArray.controls.length; index++) {
      questionIds.push(this.questionArray.controls[index].value);
    }
    obj['PartQuestions'] = questionIds;
    this.AddvendorList1 = [];
    const partVendorList = this.AddvendorList;
    for (var j = 0; j < partVendorList.length; j++) {

      let partVendorObj = {};
      partVendorObj['vendorPartCost'] = parseFloat(partVendorList[j].vendorPartCost);
      partVendorObj['cost'] = parseFloat(partVendorList[j].cost);
      partVendorObj['automarkup'] = partVendorList[j].markupCheck;
      partVendorObj['markupMargin'] = Number(partVendorList[j].markupMargin);
      partVendorObj['companyId'] = partVendorList[j].companyId
      partVendorObj['ModifiedBy'] = partVendorList[j].ModifiedBy;
      partVendorObj['CreatedBy'] = partVendorList[j].CreatedBy
      partVendorObj["CreatedDate"] = partVendorList[j].CreatedDate;
      partVendorObj["ModifiedDate"] = partVendorList[j].ModifiedDate;
      partVendorObj["IsActive"] = partVendorList[j].IsActive;
      partVendorObj["vendorId"] = partVendorList[j].vendorId;
      partVendorObj["vendorName"] = partVendorList[j].vendorName;
      partVendorObj["vendorPartName"] = partVendorList[j].vendorPartName;
      partVendorObj["vendorPartNumber"] = partVendorList[j].vendorPartNumber;
      partVendorObj["PartId"] = this.id;
      if (this.id != undefined && this.id != "" && !Number.isNaN(this.id)) {
        partVendorObj["id"] = partVendorList[j].id;
      }
      partVendorObj["vendorDiscount"] = parseFloat(partVendorList[j].Discount);

      this.AddvendorList1.push(partVendorObj);
    }

    let CategoryArr1;
    CategoryArr1 = this.CategoryArr;

    const MultiPartCategory = [];
    for (var j = 0; j < CategoryArr1.length; j++) {
      let items = {};
      if (this.id != null) {
        if (this.id != undefined && !Number.isNaN(this.id) && CategoryArr1.length > 0) {
          items["id"] = CategoryArr1[j].id;
        }
      }
      items["CreatedDate"] = this.now = new Date();
      items["ModifiedDate"] = this.now = new Date();
      items['CreatedBy'] = this.userId;
      items['partcategoryid'] = Number(CategoryArr1[j]);
      items["PartId"] = this.id;
      items["CompanyId"] = Number(this.CompanyValue);
      MultiPartCategory.push(items);
    }
    obj['multiPartCategory'] = MultiPartCategory;

    if (this.id != undefined && !Number.isNaN(this.id)) {
      obj['PartVendorList'] = this.AddvendorList1;
    }
    else {
      obj['PartVendorList'] = this.AddvendorList1.filter(x => x.IsActive == true);
    }

    obj['isDiscount'] = this.AdditionalDiscountLabel;
    obj['nonInventory'] = this.NonInventory;
    //
    // this.VendorDiscountList = [];
    // const vendorDiscountList = this.dynamicArray.filter(x => x.id != "" && x.Qty != "");
    // for (var j = 0; j < vendorDiscountList.length; j++) {
    //   ;
    //   let vendorDiscountObj = {};
    //   vendorDiscountObj['companyId'] = Number(this.CompanyValue);
    //   if (vendorDiscountList[j].id != '0' && this.id != undefined) {
    //     vendorDiscountObj['ModifiedBy'] = this.userId;
    //     vendorDiscountObj["ModifiedDate"] = this.now = new Date();
    //     vendorDiscountObj["partId"] = this.id;
    //     vendorDiscountObj["Id"] = parseInt(vendorDiscountList[j].id);
    //     vendorDiscountObj['CreatedBy'] = vendorDiscountList[j].CreatedBy == "" ? this.userId : vendorDiscountList[j].CreatedBy;
    //     vendorDiscountObj["CreatedDate"] = vendorDiscountList[j].CreateDate == "" ? this.now = new Date() : vendorDiscountList[j].CreateDate;
    //   }
    //   else {
    //     vendorDiscountObj['CreatedBy'] = this.userId;
    //     vendorDiscountObj["CreatedDate"] = this.now = new Date();
    //     vendorDiscountObj["Id"] = parseInt('0');
    //   }
    //   vendorDiscountObj["IsActive"] = vendorDiscountList[j].isActive;
    //   vendorDiscountObj["Qty"] = parseInt(vendorDiscountList[j].Qty);
    //   vendorDiscountObj["Discount"] = vendorDiscountList[j].DiscountPrice == "" ? 0 : parseFloat(vendorDiscountList[j].DiscountPrice);
    //   vendorDiscountObj["VendorCost"] = parseFloat(vendorDiscountList[j].cost);
    //   this.VendorDiscountList.push(vendorDiscountObj);
    // }
    // if (this.id != undefined && !Number.isNaN(this.id)) {
    //   obj['VendorDiscount'] = this.VendorDiscountList;
    // }
    // else {
    //   obj['VendorDiscount'] = this.VendorDiscountList.filter(x => x.IsActive == true);
    // }
    //

    this.VendorDiscountList = [];
    const vendorDiscountList = this.dynamicArray.sort((a, b) => Number(a.minQty) - Number(b.minQty));
    for (var j = 0; j < vendorDiscountList.length; j++) {
      ;
      let vendorDiscountObj = {};
      vendorDiscountObj['companyId'] = Number(this.CompanyValue);
      if (vendorDiscountList[j].id != '0' && this.id != undefined) {
        vendorDiscountObj['ModifiedBy'] = this.userId;
        vendorDiscountObj["ModifiedDate"] = this.now = new Date();
        vendorDiscountObj["partId"] = this.id;
        vendorDiscountObj["Id"] = parseInt(vendorDiscountList[j].id);
        vendorDiscountObj['CreatedBy'] = vendorDiscountList[j].CreatedBy == "" ? this.userId : vendorDiscountList[j].CreatedBy;
        vendorDiscountObj["CreatedDate"] = vendorDiscountList[j].CreateDate == "" ? this.now = new Date() : vendorDiscountList[j].CreateDate;
      }
      else {
        vendorDiscountObj['CreatedBy'] = this.userId;
        vendorDiscountObj["CreatedDate"] = this.now = new Date();
        vendorDiscountObj["Id"] = parseInt('0');
      }
      vendorDiscountObj["IsActive"] = vendorDiscountList[j].isActive;
      vendorDiscountObj["MinQty"] = parseInt(vendorDiscountList[j].minQty);
      vendorDiscountObj["Qty"] = parseInt(vendorDiscountList[j].Qty != '' ? vendorDiscountList[j].Qty : '0');
      vendorDiscountObj["DiscountType"] = vendorDiscountList[j].DiscountType;
      vendorDiscountObj["Discount"] = vendorDiscountList[j].DiscountPrice == "" ? 0 : parseFloat(vendorDiscountList[j].DiscountPrice);
      vendorDiscountObj["VendorCost"] = parseFloat(vendorDiscountList[j].cost);
      vendorDiscountObj["Description"] = vendorDiscountList[j].description;
      this.VendorDiscountList.push(vendorDiscountObj);
    }
    if (this.id != undefined && !Number.isNaN(this.id)) {
      obj['VendorDiscount'] = this.VendorDiscountList;
    }
    else {
      obj['VendorDiscount'] = this.VendorDiscountList.filter(x => x.IsActive == true);
    }

    //
    this.CustomerDiscountList = [];
    this.dynamicCustomerDiscount = this.dynamicCustomerDiscount.sort((a, b) => Number(a.minQty) - Number(b.minQty));
    const customerDiscountList = this.dynamicCustomerDiscount.filter(x => x.id != "" && x.minQty != "");

    for (var j = 0; j < customerDiscountList.length; j++) {

      let customerDiscountObj = {};
      customerDiscountObj['companyId'] = Number(this.CompanyValue);
      if (customerDiscountList[j].id != '0' && this.id != undefined) {
        customerDiscountObj['ModifiedBy'] = this.userId;
        customerDiscountObj["ModifiedDate"] = this.now = new Date();
        customerDiscountObj["partId"] = this.id;
        customerDiscountObj["Id"] = parseInt(customerDiscountList[j].id);
        customerDiscountObj["wordPressId"] = parseInt(customerDiscountList[j].wordPressId);
        customerDiscountObj['CreatedBy'] = customerDiscountList[j].CreatedBy == "" ? this.userId : customerDiscountList[j].CreatedBy;
        customerDiscountObj["CreatedDate"] = customerDiscountList[j].CreateDate == "" ? this.now = new Date() : customerDiscountList[j].CreateDate;
      }
      else {
        customerDiscountObj['CreatedBy'] = this.userId;
        customerDiscountObj["CreatedDate"] = this.now = new Date();
        customerDiscountObj["Id"] = parseInt('0');
      }
      customerDiscountObj["IsActive"] = customerDiscountList[j].isActive;
      customerDiscountObj["MinQty"] = parseInt(customerDiscountList[j].minQty);
      customerDiscountObj["Qty"] = parseInt(customerDiscountList[j].Qty != '' ? customerDiscountList[j].Qty : '0');
      customerDiscountObj["DiscountType"] = customerDiscountList[j].DiscountType;
      customerDiscountObj["Discount"] = customerDiscountList[j].DiscountPrice == "" ? 0 : parseFloat(customerDiscountList[j].DiscountPrice);
      customerDiscountObj["CustomerCost"] = parseFloat(customerDiscountList[j].cost);
      customerDiscountObj["Description"] = customerDiscountList[j].description;
      this.CustomerDiscountList.push(customerDiscountObj);
    }


    if (this.id != undefined && !Number.isNaN(this.id)) {
      obj['CustomerDiscount'] = this.CustomerDiscountList;
    }
    else {
      obj['CustomerDiscount'] = this.CustomerDiscountList.filter(x => x.IsActive == true);
    }

    //Role Based Pricing
    this.RoleBasedPricingDiscountList = [];
    const roleBasedPricingDiscountList = this.dynamicCustomerRoleDiscount.filter(x => x.id != "" && x.DiscountValue != "");

    for (var j = 0; j < roleBasedPricingDiscountList.length; j++) {

      let roleBasedPricingDiscountObj = {};
      roleBasedPricingDiscountObj['companyId'] = Number(this.CompanyValue);
      if (roleBasedPricingDiscountList[j].id != '0' && this.id != undefined) {
        roleBasedPricingDiscountObj['ModifiedBy'] = this.userId;
        roleBasedPricingDiscountObj["ModifiedDate"] = this.now = new Date();
        roleBasedPricingDiscountObj["partId"] = this.id;
        roleBasedPricingDiscountObj["Id"] = parseInt(roleBasedPricingDiscountList[j].id);
        roleBasedPricingDiscountObj["wordPressId"] = parseInt(roleBasedPricingDiscountList[j].wordPressId);
        roleBasedPricingDiscountObj['CreatedBy'] = roleBasedPricingDiscountList[j].CreatedBy == "" ? this.userId : roleBasedPricingDiscountList[j].CreatedBy;
        roleBasedPricingDiscountObj["CreatedDate"] = roleBasedPricingDiscountList[j].CreateDate == "" ? this.now = new Date() : roleBasedPricingDiscountList[j].CreateDate;
      }
      else {
        roleBasedPricingDiscountObj['CreatedBy'] = this.userId;
        roleBasedPricingDiscountObj["CreatedDate"] = this.now = new Date();
        roleBasedPricingDiscountObj["Id"] = parseInt('0');
      }
      roleBasedPricingDiscountObj["IsActive"] = roleBasedPricingDiscountList[j].isActive;
      roleBasedPricingDiscountObj["DiscountType"] = roleBasedPricingDiscountList[j].DiscountType;
      roleBasedPricingDiscountObj["DiscountValue"] = roleBasedPricingDiscountList[j].DiscountValue == "" ? 0 : parseFloat(roleBasedPricingDiscountList[j].DiscountValue);
      roleBasedPricingDiscountObj["CustomerType"] = roleBasedPricingDiscountList[j].CustomerType;
      roleBasedPricingDiscountObj["CustomerValue"] = roleBasedPricingDiscountList[j].CustomerValue;
      roleBasedPricingDiscountObj["Cost"] = parseFloat(roleBasedPricingDiscountList[j].cost);
      roleBasedPricingDiscountObj["Description"] = roleBasedPricingDiscountList[j].description;
      this.RoleBasedPricingDiscountList.push(roleBasedPricingDiscountObj);
    }

    if (this.id != undefined && !Number.isNaN(this.id)) {
      obj['RoleBasePricingDiscount'] = this.RoleBasedPricingDiscountList;
    }
    else {
      obj['RoleBasePricingDiscount'] = this.RoleBasedPricingDiscountList.filter(x => x.IsActive == true);
    }
    //Role Based Pricing End

    const specifications = [];
    const specificationsList = this.dynamicSpecificArray.filter(x => x.Name != "");
    for (var k = 0; k < specificationsList.length; k++) {
      let specificationsObj = {};
      if (specificationsList[k].id != '0' && this.id != undefined) {
        specificationsObj["Id"] = Number(specificationsList[k].id);
        specificationsObj["PartId"] = this.id;
        specificationsObj["KeyName"] = specificationsList[k].Name;
        specificationsObj["KeyValue"] = specificationsList[k].Value;
        specificationsObj["Ordering"] = specificationsList[k].Ordering;
        specificationsObj["IsActive"] = specificationsList[k].isActive;
        specificationsObj['ModifiedBy'] = this.userId;
        specificationsObj["ModifiedDate"] = this.now = new Date();
        specificationsObj['CreatedBy'] = specificationsList[k].CreatedBy;
        specificationsObj["CreatedDate"] = specificationsList[k].CreateDate;
      } else {
        specificationsObj["Id"] = parseInt('0');
        specificationsObj["KeyName"] = specificationsList[k].Name;
        specificationsObj["KeyValue"] = specificationsList[k].Value;
        specificationsObj["Ordering"] = specificationsList[k].Ordering;
        specificationsObj["IsActive"] = true;
        specificationsObj['CreatedBy'] = this.userId;
        specificationsObj["CreatedDate"] = this.now = new Date();
      }
      specifications.push(specificationsObj);
    }
    obj['PartSpecifications'] = specifications;

    //
    const relatedProducts = [];
    const relatedProductsList = this.myForm.value.listOfItems.filter(x => x.PartName != "" && x.PartNumber != "" && x.WPID != "");
    for (var rp = 0; rp < relatedProductsList.length; rp++) {
      let relatedProductsObj = {};
      if (relatedProductsList[rp].Id != '0' && relatedProductsList[rp].Id != '' && this.id != undefined) {
        // relatedProductsObj["Id"] = Number(relatedProductsList[rp].Id);
        relatedProductsObj["Id"] = parseInt('0');
        relatedProductsObj["PartId"] = Number(this.id);
        relatedProductsObj["PartName"] = relatedProductsList[rp].PartName;
        relatedProductsObj["PartNumber"] = relatedProductsList[rp].PartNumber;
        relatedProductsObj["WPID"] = Number(relatedProductsList[rp].WPID);
        relatedProductsObj["IsActive"] = true;
        relatedProductsObj['ModifiedBy'] = this.userId;
        relatedProductsObj["ModifiedDate"] = this.now = new Date();
        relatedProductsObj['CreatedBy'] = relatedProductsList[rp].CreatedBy;
        relatedProductsObj["CreatedDate"] = relatedProductsList[rp].CreateDate;
      } else {
        relatedProductsObj["Id"] = parseInt('0');
        relatedProductsObj["PartName"] = relatedProductsList[rp].PartName;
        relatedProductsObj["PartNumber"] = relatedProductsList[rp].PartNumber;
        relatedProductsObj["WPID"] = Number(relatedProductsList[rp].WPID);
        relatedProductsObj["IsActive"] = true;
        relatedProductsObj['CreatedBy'] = this.userId;
        relatedProductsObj["CreatedDate"] = this.now = new Date();
      }
      relatedProducts.push(relatedProductsObj);
    }
    obj['RelatedProducts'] = relatedProducts;
    obj['InventoryType'] = Number(this.InvType);
    obj['Question'] = this.Questions.value; // Navneet

    if (this.id != undefined && !Number.isNaN(this.id)) {
      obj['Id'] = this.id;
      obj['ModifiedBy'] = this.userId;
      obj['CreatedBy'] = this.userId;
      this.loader = true;

      this.partService.UpdatePart(obj)
        .subscribe(
          data => {
            this.loader = false;
            this.UpdatePartPostProcessing(data);
            this.AddWPProduct(Number(data.data), Number(this.CompanyValue));
          },
          error => {
            this.loader = false;
          }
        );
    }
    else {
      obj['CreatedBy'] = this.userId;
      this.loader = true;
      this.partService.AddPart(obj)
        .subscribe(
          data => {
            this.loader = false;
            this.AddPartPostProcessing(data);
            this.AddWPProduct(Number(data.data), Number(this.CompanyValue));
          },
          error => {
            this.loader = false;
          }
        );
    }
  }

  AddPartPostProcessing(data: any) {
    switch (data.statusCode) {
      case 200:
        this.recordAlreadyExist = false;
        this.recordPartNoExist = false;
        this.isSaveOrUpdate = true;
        $("#add_success").show();
        this.validation = false;
        this.popvalidation = false;
        this.validationImage = false;
        this.MultipleImage = [];
        this.MultipleDownloadImg = [];
        this.resetvendorData();
        this.userSearch = '';
        this.SearchValidation = false;
        this.GetAllPart("1");
        this.TCreateUpdate = 1;
        this.SearchControl = false;
        this.ListShow = false;
        this.ListDiv = false;
        this.NewPartialArray = [];
        this.CheckCategory = false;
        this.CheckCategoryDisable = false;
        this.PartHistory = false;
        this.WordpressCategoryList = [];
        this.WordpressCategoryCrimpToolsList = [];
        this.WordpressCategoryRelatedItemList = [];
        this.NewPartialArray = [];
        this.NewArray2 = [];
        this.NewArray = [];
        this.NewArray1 = [];
        this.CrimpNewArray = [];
        this.CrimpNewArray1 = [];
        this.RelatedNewArray = [];
        this.RelatedNewArray1 = [];
        this.CustomerPartNameList = [];
        this.CustomerPartNameCrimpList = [];
        this.CustomerPartNameRelatedList = [];
        this.shortDescription = '';
        this.partTracking = '3';
        this.DimentionTypeL = 2;
        this.DimentionTypeH = 2;
        this.DimentionTypeW = 2;
        this.WeightType = 5;
        this.weight = '';
        this.recordAlreadyExist = false;
        this.recordPartNoExist = false;
        this.clearbutton = true;
        this.buttonValue = this.constants.Save;
        this.resetvendorData();
        this.CheckId = 0;
        break;
      case 400:
        if (data.errorMessage === 'Record Already Exist') {
          this.recordAlreadyExist = true;
          this.recordPartNoExist = false;
        }
        else if (data.errorMessage === 'Part No Already Exist') {
          this.recordPartNoExist = true;
          this.recordAlreadyExist = false;
        }
        else {
          $("#wrong_msg").show();
          this.validation = false;
          this.popvalidation = false;
          this.validationImage = false;
          this.recordAlreadyExist = false;
          this.recordPartNoExist = false;
        }
        break;
      case 404:
        $("#msgModel404").show();
        this.validation = false;
        this.popvalidation = false;
        this.validationImage = false;
        this.loader = false;
        this.recordAlreadyExist = false;
        this.recordPartNoExist = false;
        break;
      default:
        $("#wrong_msg").show();
        // this.toastr.error(this.constants.SomethingWentWrong);
        this.validation = false;
        this.popvalidation = false;
        this.validationImage = false;
        this.recordAlreadyExist = false;
        this.recordPartNoExist = false;
    }
  }

  UpdatePartPostProcessing(data: any) {
    switch (data.statusCode) {
      case 200:
        this.buttonValue = this.constants.Save;
        $("#add_update").show();
        this.isSaveOrUpdate = true;
        this.validation = false;
        this.popvalidation = false;
        this.validationImage = false;
        this.resetvendorData();
        this.userSearch = '';
        this.SearchValidation = false;
        this.GetAllPart("1");
        this.MultipleImage = [];
        this.MultipleDownloadImg = [];
        this.ListDiv = true;
        this.NewPartialArray = [];
        this.WordpressCategoryList = [];
        this.WordpressCategoryCrimpToolsList = [];
        this.WordpressCategoryRelatedItemList = [];
        this.NewPartialArray = [];
        this.NewArray2 = [];
        this.NewArray = [];
        this.NewArray1 = [];
        this.CrimpNewArray = [];
        this.CrimpNewArray1 = [];
        this.RelatedNewArray = [];
        this.RelatedNewArray1 = [];
        this.CustomerPartNameList = [];
        this.CustomerPartNameCrimpList = [];
        this.CustomerPartNameRelatedList = [];
        this.shortDescription = '';
        this.id = null;
        this.CheckId = 0;
        this.IsUpdate = false;
        this.IsSave = true;
        this.CheckCategory = false;
        this.CheckCategoryDisable = false;
        this.PartHistory = false;
        this.DimentionTypeL = 2;
        this.DimentionTypeH = 2;
        this.DimentionTypeW = 2;
        this.WeightType = 3;
        this.recordAlreadyExist = false;
        this.recordPartNoExist = false;
        break;

      case 400:
        if (data.errorMessage === 'Record Already Exist') {
          this.recordAlreadyExist = true;
          this.recordPartNoExist = false;
        }
        else if (data.errorMessage === 'Part No Already Exist') {
          this.recordPartNoExist = true;
          this.recordAlreadyExist = false;
        }
        break;
      case 404:
        $("#msgModel404").show();
        this.validation = false;
        this.popvalidation = false;
        this.validationImage = false;
        this.recordAlreadyExist = false;
        this.recordPartNoExist = false;
        break;
      default:
        $("#wrong_msg").show();
        this.validation = false;
        this.popvalidation = false;
        this.validationImage = false;
        this.recordAlreadyExist = false;
        this.recordPartNoExist = false;
    }
  }

  AddWPProduct(partId, companyId) {
    this.loader = true;
    this.partService.AddWPProduct(partId, companyId).subscribe(
      (data: any) => {
        this.loader = false;
        this.AddWPProductDiscount(partId, companyId)
      },
      (error: any) => {
        this.loader = false;
      }
    )

  }


  AddWPProductDiscount(partId, companyId) {
    this.loader = true;
    this.partService.AddWPProductDiscount(partId, companyId).subscribe(
      (data: any) => {
        this.loader = false;
      },
      (error: any) => {
        this.loader = false;
      }
    )

  }

  // CovertWeightToLbs(dimensions, dimensionsType) {
  //   //public WeightType = [{ id: 1, name: 'kg' }, { id: 2, name: 'oz' }, { id: 3, name: 'lbs' }];
  //   let InchQty = 0;
  //   switch (Number(dimensionsType)) {
  //     case 2:
  //       InchQty = Number(dimensions * 0.0625 ?? "");
  //       break;
  //     case 3:
  //       InchQty = Number(dimensions ?? "");
  //       break;
  //     default:
  //       InchQty = Number(dimensions * 2.20462 ?? "");
  //       break;
  //   }
  //   return InchQty;
  // }

  // CovertDiemensionToInch(dimensions, dimensionsType) {
  //   //public DimentionType = [{ id: 1, name: 'cm' }, { id: 2, name: 'in' }, { id: 3, name: 'ft' }];
  //   let lbsQty = '';
  //   switch (Number(dimensionsType)) {
  //     case 2:
  //       lbsQty = String(dimensions == undefined ? 0 : dimensions);
  //       break;
  //     case 3:
  //       lbsQty = String((dimensions == undefined ? 0 : dimensions) * 12);
  //       break;
  //     default:
  //       lbsQty = String((dimensions == undefined ? 0 : dimensions) * 0.393701);
  //       break;
  //   }
  //   return lbsQty;
  // }


  msg404() {
    $("#msgModel404").hide();
  }

  getPartbyId(item: any) {
    this.loader = true
    this.ResetPopModelData();
    this.resetvendorData();
    this.viewLog = false;
    this.ListDiv = false;
    this.ListAngle = "down";
    localStorage.setItem("EditMainId", JSON.stringify(item));
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    this.buttonValue = this.constants.Update;
    this.clearbutton = false;
    this.CategoryArr = [];
    this.selectedItems = [];
    this.editrow = item.editrow;
    this.customvendorId = item.customId;
    this.partService.GetPartById(item.id).subscribe(
      (data: any) => {
        //Added By Navneet
        this.BindQuestions(data.data.question)
        //Ended BY Navneet
        this.FilertingData = data;
        this.companyId = data.data.companyId;
        this.getAllCategories_search(this.companyId);
        this.id = data.data.id;
        this.InvType = data.data.inventoryType;
        data.data.discount == null ? 0 : data.data.discount;
        this.selectedVendorValue = data.data.vendorId + '-' + data.data.discount;
        this.name = data.data.name;
        this.getName = data.data.name;
        this.partNo = data.data.partNo;
        this.description = data.data.description;
        this.ShortDescription = data.data.shortDescription;
        this.shortDesc = this.ShortDescription;
        this.NonInventory = data.data.nonInventory;
        this.OEMDiscount = data.data.oemDiscount == null ? '' : parseFloat(data.data.oemDiscount).toFixed(2);
        let count = 0;
        if (data.data.multiPartCategory) {
          this.selectedMulti = [];
          const arr = data.data.multiPartCategory;
          for (let ij = 0; ij < arr.length; ij++) {
            this.selectedMulti.push(arr[ij].partcategoryid);
          }
          this.CategoryArr = this.selectedMulti;
          if (count > 0) {
            if (data.data.isNotActive == false && data.data.nonInventory == false) {
              this.CheckCategory = true;
              this.CheckCategoryDisable = false
            }
            else {
              this.CheckCategory = false;
              this.CheckCategoryDisable = true;
            }
          }
          else {
            this.CheckCategory = false;
            this.CheckCategoryDisable = false;
          }

        }
        // this.partCategoryId = data.data.partCategoryId;
        this.Pwordpressid = data.data.productId;
        this.gtinupc = data.data.gtinupc;
        this.brandname = data.data.brandName;
        this.ECommerceStatus = data.data.eCommerceStatus == null ? '' : data.data.eCommerceStatus;
        if (data.data.autoMarkup == true) {
          this.IsMarginDisabled = false;
          this.IsPricePart = true;
        }
        else {
          this.IsMarginDisabled = true;
          this.IsPricePart = false;
        }

        this.MarkupCheck = data.data.autoMarkup;
        this.MarkupMargin = data.data.markupMargin;
        this.WeightType = data.data.weightType;
        this.NotActive = data.data.isNotActive;
        this.height = data.data.height;
        this.width = data.data.width;
        this.length = data.data.length;
        this.weight = data.data.weight;

        this.DimentionTypeL = data.data.dimentionType;
        this.DimentionTypeH = data.data.dimentionType;
        this.DimentionTypeW = data.data.dimentionType;
        this.selectedPartCategoryIdValue = data.data.wordpressId + "-" + data.data.partCategoryId;
        this.CheckId = data.data.id;
        this.loader = false;
        this.cost = parseFloat(data.data.cost).toFixed(5);

        if (data.data.partImageUpload.length > 0) {
          this.MultipleImage = [];
          this.MultipleDownloadImg = [];
          for (var itemimage = 0; itemimage < data.data.partImageUpload.length; itemimage++) {
            if (data.data.partImageUpload[itemimage].partType == 4) {
              var imgUp: any = '';
              if (data.data.partImageUpload[itemimage].wPid != null && data.data.partImageUpload[itemimage].wPid != '' && data.data.partImageUpload[itemimage].wPid != undefined) {
                imgUp = data.data.partImageUpload[itemimage].wpSrc;
              } else {
                imgUp = data.data.partImageUpload[itemimage].documentType == "PDF" ? this._sanitizer.bypassSecurityTrustUrl(data.data.partImageUpload[itemimage].imagePath) : data.data.partImageUpload[itemimage].imagePath == null ? '' : this._sanitizer.bypassSecurityTrustUrl(data.data.partImageUpload[itemimage].imagePath);
              }
              let ojtmultiplepart = {
                partypeName: data.data.partImageUpload[itemimage].partType,
                imageUrl: imgUp,
                partId: data.data.partImageUpload[itemimage].partId,
                documenttype: data.data.partImageUpload[itemimage].documentType ? data.data.partImageUpload[itemimage].documentType : '',
                wpId: data.data.partImageUpload[itemimage].wPid,
              }
              this.MultipleImage.push(ojtmultiplepart);
            }
            if (data.data.partImageUpload[itemimage].partType == 9) {
              let ojtmultiplepart = {
                partypeName: data.data.partImageUpload[itemimage].partType,
                imageUrl: data.data.partImageUpload[itemimage].documentType == "PDF" ? this._sanitizer.bypassSecurityTrustUrl(data.data.partImageUpload[itemimage].imagePath) : data.data.partImageUpload[itemimage].imagePath == null ? '' : this._sanitizer.bypassSecurityTrustUrl(data.data.partImageUpload[itemimage].imagePath),
                // Id: data.data.partImageUpload[itemimage].Id,
                partId: data.data.partImageUpload[itemimage].partId,
                documenttype: data.data.partImageUpload[itemimage].documentType,
                wpId: data.data.partImageUpload[itemimage].wPid,
              }
              this.MultipleDownloadImg.push(ojtmultiplepart);
            }

          }
        }
        else {
          this.MultipleImage = [];
          this.MultipleDownloadImg = [];
        }

        this.AddvendorList = [];
        if (data.data.partVendorList.length > 0) {
          this.AddvendorList2 = [];
          for (var itemvendor = 0; itemvendor < data.data.partVendorList.length; itemvendor++) {

            let ojtVmultiplepart = {};
            ojtVmultiplepart['vendorPartCost'] = data.data.partVendorList[itemvendor].vendorPartCost;
            ojtVmultiplepart['cost'] = data.data.partVendorList[itemvendor].cost;
            ojtVmultiplepart['markupCheck'] = data.data.partVendorList[itemvendor].automarkup;
            ojtVmultiplepart['markupMargin'] = data.data.partVendorList[itemvendor].markupMargin;
            ojtVmultiplepart['vendorPartName'] = data.data.partVendorList[itemvendor].vendorPartName;
            ojtVmultiplepart['vendorPartNumber'] = data.data.partVendorList[itemvendor].vendorPartNumber;
            ojtVmultiplepart['companyId'] = data.data.partVendorList[itemvendor].companyId;
            ojtVmultiplepart['ModifiedBy'] = data.data.partVendorList[itemvendor].ModifiedBy;
            ojtVmultiplepart['CreatedBy'] = data.data.partVendorList[itemvendor].CreatedBy;
            ojtVmultiplepart["CreatedDate"] = data.data.partVendorList[itemvendor].CreatedDate;
            ojtVmultiplepart["ModifiedDate"] = data.data.partVendorList[itemvendor].ModifiedDate;
            ojtVmultiplepart["IsActive"] = data.data.partVendorList[itemvendor].isActive;
            ojtVmultiplepart['vendorId'] = data.data.partVendorList[itemvendor].vendorId;
            ojtVmultiplepart['vendorName'] = data.data.partVendorList[itemvendor].vendorName;
            ojtVmultiplepart['id'] = data.data.partVendorList[itemvendor].id;
            ojtVmultiplepart['Discount'] = data.data.partVendorList[itemvendor].vendorDiscount;
            this.AddvendorList.push(ojtVmultiplepart);
            this.i = ojtVmultiplepart["id"]
            this.AddvendorList = this.AddvendorList.filter(x => x.IsActive == true);
            const counting = this.AddvendorList.filter(x => x.IsActive == true);
            if (counting.length > 0) {
              this.DeActiveCouting = false;
              this.ActiveCouting = true;
            }
            else {
              this.DeActiveCouting = true;
              this.ActiveCouting = false;
            }
          }
        }
        else {
          this.AddvendorList = [];
          this.DeActiveCouting = true;
          this.ActiveCouting = false;
        }

        this.VendorDiscountList = [];
        if (data.data.vendorDiscount.length > 0) {
          for (var j = 0; j < data.data.vendorDiscount.length; j++) {
            if (j > 0) {
              this.dynamicArray.push({ 'minQty': '', 'Qty': '', 'DiscountType': '', 'DiscountPrice': '', 'cost': '', 'description': '', 'id': '0', 'isActive': false, 'CreateDate': '', 'CreatedBy': '', 'isReadOnly': false, 'IsDReadonly': false });
              // this.dynamicArray.push({ 'Qty': '', 'DiscountPrice': '', 'cost': '', 'id': '0', 'isActive': false, 'isReadOnly': false, 'CreateDate': '', 'CreatedBy': '', 'IsDReadonly': false });
            }

            this.dynamicArray[j].minQty = String(data.data.vendorDiscount[j].minQty);
            this.dynamicArray[j].Qty = String(data.data.vendorDiscount[j].qty == 0 ? '' : data.data.vendorDiscount[j].qty);
            this.dynamicArray[j].DiscountType = String(data.data.vendorDiscount[j].discountType);
            this.dynamicArray[j].DiscountPrice = String(data.data.vendorDiscount[j].discount == null ? '' : data.data.vendorDiscount[j].discount);
            this.dynamicArray[j].cost = String(data.data.vendorDiscount[j].vendorCost == null ? '' : (data.data.vendorDiscount[j].vendorCost).toFixed(2));
            this.dynamicArray[j].description = String(data.data.vendorDiscount[j].description);
            this.dynamicArray[j].id = String(data.data.vendorDiscount[j].id);
            this.dynamicArray[j].isActive = true;
            this.dynamicArray[j].CreateDate = data.data.vendorDiscount[j].createdDate;
            this.dynamicArray[j].CreatedBy = data.data.vendorDiscount[j].createdBy;

          }
        }
        if (data.data.customerDiscount.length > 0) {
          for (var j = 0; j < data.data.customerDiscount.length; j++) {
            if (j > 0) {
              this.dynamicCustomerDiscount.push({ 'minQty': '', 'Qty': '', 'DiscountType': '', 'DiscountPrice': '', 'cost': '', 'description': '', 'id': '0', 'isActive': false, 'CreateDate': '', 'CreatedBy': '', 'isReadOnly': false, 'IsDReadonly': false, 'wordPressId': '0' });
            }
            this.dynamicCustomerDiscount[j].minQty = String(data.data.customerDiscount[j].minQty);
            this.dynamicCustomerDiscount[j].Qty = String(data.data.customerDiscount[j].qty == 0 ? '' : data.data.customerDiscount[j].qty);
            this.dynamicCustomerDiscount[j].DiscountType = String(data.data.customerDiscount[j].discountType);
            this.dynamicCustomerDiscount[j].DiscountPrice = String(data.data.customerDiscount[j].discount == null ? '' : data.data.customerDiscount[j].discount);
            this.dynamicCustomerDiscount[j].cost = String(data.data.customerDiscount[j].customerCost == null ? '' : (data.data.customerDiscount[j].customerCost).toFixed(2));
            this.dynamicCustomerDiscount[j].description = String(data.data.customerDiscount[j].description);
            this.dynamicCustomerDiscount[j].id = String(data.data.customerDiscount[j].id);
            this.dynamicCustomerDiscount[j].isActive = true;
            this.dynamicCustomerDiscount[j].CreateDate = data.data.customerDiscount[j].createdDate;
            this.dynamicCustomerDiscount[j].CreatedBy = data.data.customerDiscount[j].createdBy;
            this.dynamicCustomerDiscount[j].wordPressId = data.data.customerDiscount[j].wordPressId;
          }
        }
        //for order by min qty
        this.dynamicCustomerDiscount = this.dynamicCustomerDiscount.sort((a, b) => Number(a.minQty) - Number(b.minQty));

        if (data.data.roleBasePricingDiscount.length > 0) {
          for (var j = 0; j < data.data.roleBasePricingDiscount.length; j++) {
            if (j > 0) {
              this.dynamicCustomerRoleDiscount.push({ 'DiscountType': '', 'DiscountValue': '', 'CustomerType': '', 'CustomerValue': '', 'cost': '', 'description': '', 'id': '0', 'isActive': false, 'CreateDate': '', 'CreatedBy': '', 'isReadOnly': false, 'IsDReadonly': false, 'wordPressId': '0' });
            }
            this.dynamicCustomerRoleDiscount[j].DiscountType = data.data.roleBasePricingDiscount[j].discountType;
            this.dynamicCustomerRoleDiscount[j].DiscountValue = String(data.data.roleBasePricingDiscount[j].discountValue);
            this.dynamicCustomerRoleDiscount[j].CustomerType = data.data.roleBasePricingDiscount[j].customerType;
            this.dynamicCustomerRoleDiscount[j].CustomerValue = String(data.data.roleBasePricingDiscount[j].customerValue);
            this.dynamicCustomerRoleDiscount[j].cost = String(data.data.roleBasePricingDiscount[j].cost == null ? '' : (data.data.roleBasePricingDiscount[j].cost).toFixed(2));
            this.dynamicCustomerRoleDiscount[j].description = data.data.roleBasePricingDiscount[j].description;
            this.dynamicCustomerRoleDiscount[j].id = String(data.data.roleBasePricingDiscount[j].id);
            this.dynamicCustomerRoleDiscount[j].isActive = true;
            this.dynamicCustomerRoleDiscount[j].CreateDate = data.data.roleBasePricingDiscount[j].createdDate;
            this.dynamicCustomerRoleDiscount[j].CreatedBy = data.data.roleBasePricingDiscount[j].createdBy;
            this.dynamicCustomerRoleDiscount[j].wordPressId = data.data.roleBasePricingDiscount[j].wordPressId;
          }
        }
        this.dynamicSpecificArray = [];
        if (data.data.partSpecifications.length > 0) {
          for (var j = 0; j < data.data.partSpecifications.length; j++) {
            if (j >= 0) {
              this.dynamicSpecificArray.push({ 'id': '0', 'Name': '', 'Value': '', 'Ordering': 0, 'isActive': false, 'isReadOnly': false, 'CreateDate': '', 'CreatedBy': '', 'IsDReadonly': false });
            }
            this.dynamicSpecificArray[j].id = String(data.data.partSpecifications[j].id);
            this.dynamicSpecificArray[j].Name = String(data.data.partSpecifications[j].keyName);
            this.dynamicSpecificArray[j].Value = String(data.data.partSpecifications[j].keyValue);
            this.dynamicSpecificArray[j].Ordering = data.data.partSpecifications[j].ordering == undefined ? j : data.data.partSpecifications[j].ordering;
            this.dynamicSpecificArray[j].CreateDate = data.data.partSpecifications[j].createdDate;
            this.dynamicSpecificArray[j].CreatedBy = data.data.partSpecifications[j].createdBy;
            this.dynamicSpecificArray[j].isActive = true;
          }
        }

        // this.dynamicSpecificArray = this.dynamicSpecificArray.filter(x => x.Name !== 'Height' && x.Name !== 'Width' && x.Name !== 'Length' && x.Name !== 'Weight');

        if (data.data.relatedProducts.length > 0) {
          const controls = <FormArray>this.myForm.controls["listOfItems"];
          controls.clear();
          data.data.relatedProducts.forEach(element => {
            let formGroup = this.fb.group({
              Id: element.id.toString(),
              PartId: element.partId.toString(),
              PartNumber: element.partNumber,
              PartName: element.partName,
              WPID: element.wpid.toString(),
            });
            controls.push(formGroup);
          });
        }

        this.partTracking = data.data.partTracking == 0 ? '' : data.data.partTracking;
        this.InvPartTracking = this.partTracking;
        this.PartHistory = true;
        this.PartName = data.data.name;
        this.ManualPartId = data.data.id;
        this.hours = data.data.hours == "0" ? "" : data.data.hours;
        //  toggle edit three properties 25_02_2022

        this.partLeadTime = data.data.partLeadTime;
        this.reorderAmount = data.data.reorderAmount;
        this.PartImage = data.data.partImage;
        this.search = data.data.search;
        this.installationNotes = data.data.installationNotes;
        this.TotalAllType = data.data.totalAllType ? data.data.totalAllType : 0;
        this.TotalTypeInventory = data.data.totalTypeInventory ? data.data.totalTypeInventory : 0;
        this.TotalInvLog = data.data.totalInvLog ? data.data.totalInvLog : 0;
        this.isTotalInvQty = true;
        this.CompanyValue = data.data.companyId;
        this.VendorPartCost = data.data.vendorPartCost == null ? '' : parseFloat(data.data.vendorPartCost).toFixed(5);
        this.VendorPartName = data.data.vendorPartName;
        this.VendorPartNumber = data.data.vendorPartNumber;
        this.getLocationLevel(data.data.companyId);
        this.SelectedLocation = data.data.partLocationName == null ? '' : data.data.partLocationName;
        this.selectedLocationName = data.data.locationName;
        this.SelectedPartLocation = data.data.partLocation == null ? '' : data.data.partLocation;
        this.getPartLocationLevel(1, data.data.locationName);
        this.getPartLocationLevelInv(1, data.data.locationName);
        //this.Getvendordropdownlists(data.data.companyId, data.data.vendorId);
        this.partLocation = data.data.partLocation;
        this.AdditionalDiscountLabel = data.data.isDiscount;
        this.ADiscount = data.data.discount * 100;
        this.AVendor = data.data.vendorName;
        this.Getmultivendordropdownlist(data.data.companyId, this.editrow, this.customvendorId, this.AddvendorList);
        this.GetquestiondropdownlistDuplicate(data.data.companyId);
        this.GetPartVendors(data.data.companyId, data.data.id);
        this.ResetPopModelData();
        this.ResetPopModelData1();

        this.WordpressCategoryLoad(data.data.companyId);
        this.togglePopModel('ok');
        this.SelectedUnitMeasure = data.data.unitofMeasureId == null ? '' : data.data.unitofMeasureId;
        this.questionArray.controls.splice(0, this.questionArray.controls.length);
        this.questionArray.controls.push(
          new FormGroup({
            questionId: new FormControl(0),
            partId: new FormControl(0)
          })
        );
        this.minInventory = data.data.minInventory;
        this.InvProductOrderDropdownList1(data.data.companyId, data.data.id);
        this.InvPartOrderDropdownList1(data.data.companyId, data.data.id);
        let companyID = data.data.companyId;
        this.sharedService.getAllProductionRole(companyID, '').subscribe(
          data1 => {
            this.data = data1;
            if (companyID > 0) {
              this.userRoleList = this.data.dataList;
            }
            else {
              this.userRole = '';
            }

            this.userRole = data.data.productionRole;
            this.changeUserRole(this.userRole);
            if (data.data.skillLevel) {
              this.skillLevel = data.data.skillLevel;
            }
            else {
              this.skillLevel = '0';
            }
          });

        this.getAllWorkflowSteps(data.data.companyId);
        this.WorkflowSteps = data.data.workflowStep ? data.data.workflowStep : '';


        for (var item = 0; item < data.data.partQuestions.length; item++) {
          this.questionArray.controls.push(
            new FormGroup({
              questionId: new FormControl(data.data.partQuestions[item].questionId),
              partId: new FormControl(data.data.partQuestions[item].partId)
            })
          )
        }
        this.InvLocName = this.SelectedLocation;
        this.invlocationLevelList = [];
        this.invlocationLevelList = this.locationLevelList;
        this.locationText = data.data.location;
        this.InvLocName1 = this.locationText == undefined ? '' : this.locationText;
        if (data.data.partInventory.length > 0) {
          this.isInvFind = true;
          this.InvList = [];
          for (var item = 0; item < data.data.partInventory.length; item++) {

            let LocalInvType2 = '';
            if (data.data.partInventory[item].typeId == "5") {
              LocalInvType2 = data.data.partInventory[item].serviceNo;
            }
            else if (data.data.partInventory[item].typeId == "6") {
              LocalInvType2 = data.data.partInventory[item].partOrderId;
            }
            else if (data.data.partInventory[item].typeId == "7") {
              LocalInvType2 = data.data.partInventory[item].productOrderId;
            }

            let qty: number = 0;
            let orderqty: number = 0;
            if (data.data.partInventory[item].purchaseOrderId == null || data.data.partInventory[item].purchaseOrderId == "") {
              qty = data.data.partInventory[item].qty;
            }
            else {
              let recQty = data.data.partInventory[item].qty == null || data.data.partInventory[item].qty == "" ? 0 : data.data.partInventory[item].qty;
              let shipQty = data.data.partInventory[item].shipQty == null || data.data.partInventory[item].shipQty == "" ? 0 : data.data.partInventory[item].shipQty;
              qty = parseInt(recQty) - parseInt(shipQty);
            }

            orderqty = data.data.partInventory[item].orderedQty == null || data.data.partInventory[item].orderedQty == "" ? 0 : data.data.partInventory[item].orderedQty;
            if (Number(qty) != 0) {
              let obj = {
                InvSno: this.InvSnoEdit,
                id: data.data.partInventory[item].id,
                partId: data.data.partInventory[item].partId,
                InvQty: qty,
                InvQtyOrdered: orderqty,
                InvDate: this.datepipe.transform(data.data.partInventory[item].date, 'yyyy-MM-dd'),
                InvCost: data.data.partInventory[item].cost,
                InvType: data.data.partInventory[item].typeId,
                InvType2: LocalInvType2,
                InvCustNo: data.data.partInventory[item].customerSo,
                InvCustRMA: data.data.partInventory[item].customerRma,
                InvPartLocation: data.data.partInventory[item].partLocation,
                purchaseOrderId: data.data.partInventory[item].purchaseOrderId,
                shipQty: data.data.partInventory[item].shipQty,
                InvVendor: data.data.partInventory[item].vendorId,
                InvLoc: String(data.data.partInventory[item].locationName),
                InvLoc1: String(data.data.partInventory[item].location),
                InvTracking: data.data.partInventory[item].tracking,
                obj1: data.data.partInventory[item].partSerialNumber,
                TrackingUpdate: 1,
                InvCustomPartId: data.data.partInventory[item].customPartId
              }
              this.InvList.push(obj);
              this.InvSnoEdit += 1;
            }

          }
        }
        else {
          this.isInvFind = false;
          this.InvList = [];
        }
      });
  }



  public isValidFlg: boolean = true;
  validateLabourHours(field) {
    var labourHours = this.hours;
    //var saletaxfield = this.SaleTaxAmount;
    if (labourHours == "" || labourHours == undefined || labourHours == null) {
      return;
    }
    this.isValidFlg = (labourHours.length == 0 || labourHours.length == 4);
    if (labourHours.length >= 2) {
      field.value = labourHours.substring(0, 2) + "." + labourHours.substring(3, 4);;
      this.hours = field.value;
    }
    else {

    }
  }
  formatCurrencymargin() {

    var input_val = this.cost;
    if (input_val != undefined) {
      var original_len = input_val.length;
      if (input_val.indexOf(".") >= 0) {
        var decimal_pos = input_val.indexOf(".");
        var l = input_val.substring(0, decimal_pos);
        var r = input_val.substring(decimal_pos, decimal_pos + 6);
        var right_side = input_val.substring(decimal_pos, 2);
        this.cost = l + "" + r;
      }
      else {
        // this.cost = this.cost + ".00000";
        this.cost = "";
      }
      const data = this.dynamicCustomerDiscount.filter(x => x.isActive == true && x.Qty != '' && x.DiscountPrice != '');
      if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          if (this.hasDecimalPlace(data[i].DiscountPrice, 1)) {
            const value = (parseFloat(data[i].DiscountPrice) * this.cost).toFixed(2);
            const stringvalue1 = (parseFloat(this.cost) - parseFloat(value)).toFixed(2);
            data[i].cost = String(stringvalue1);
          }
          else {
            const disVlaue = parseFloat(data[i].DiscountPrice) / 100;
            const value = (disVlaue * this.cost).toFixed(2);
            const stringvalue1 = (parseFloat(this.cost) - parseFloat(value)).toFixed(2);
            data[i].cost = String(stringvalue1);
          }

        }
      }
    }
  }


  formatCurrency() {
    var input_val = this.cost;
    if (input_val != undefined) {
      var original_len = input_val.length;
      if (input_val.indexOf(".") >= 0) {
        var decimal_pos = input_val.indexOf(".");
        var l = input_val.substring(0, decimal_pos);
        var r = input_val.substring(decimal_pos, decimal_pos + 6);
        var right_side = input_val.substring(decimal_pos, 2);
        this.cost = l + "" + r;
      }
      else {
        this.cost = this.cost + ".00000";
      }
      const data = this.dynamicCustomerDiscount.filter(x => x.isActive == true && x.Qty != '' && x.DiscountPrice != '');
      if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          if (this.hasDecimalPlace(data[i].DiscountPrice, 1)) {
            const value = (parseFloat(data[i].DiscountPrice) * this.cost).toFixed(2);
            const stringvalue1 = (parseFloat(this.cost) - parseFloat(value)).toFixed(2);
            data[i].cost = String(stringvalue1);
          }
          else {
            const disVlaue = parseFloat(data[i].DiscountPrice) / 100;
            const value = (disVlaue * this.cost).toFixed(2);
            const stringvalue1 = (parseFloat(this.cost) - parseFloat(value)).toFixed(2);
            data[i].cost = String(stringvalue1);
          }

        }
      }
    }
  }

  PartformatCurrency() {

    var input_val = this.PPartPrice;
    if (input_val != undefined) {
      var original_len = input_val.length;
      if (input_val.indexOf(".") >= 0) {
        var decimal_pos = input_val.indexOf(".");
        var l = input_val.substring(0, decimal_pos);
        var r = input_val.substring(decimal_pos, decimal_pos + 6);
        var right_side = input_val.substring(decimal_pos, 2);
        this.PPartPrice = l + "" + r;
      } else {
        this.PPartPrice = this.PPartPrice + ".00000";
      }
    }
  }
  OEMCurrency() {
    var input_val = this.OEMDiscount;
    if (input_val.indexOf(".") >= 0) {
    }
    else {
      this.OEMDiscount = this.OEMDiscount + ".00";
    }

    // this.OEMDiscount = this.OEMDiscount.toFixed(2);
  }
  VendorCurrency() {
    var input_val = this.VendorPartCost;
    if (input_val != undefined) {
      var original_len = input_val.length;
      var calcMargin = 1 + Number(this.MarkupMargin);
      if (this.MarkupCheck) {
        this.cost = Number(this.VendorPartCost * calcMargin).toFixed(5);
        if (this.cost == "NaN") {
          this.cost = 0;
        }
      }
      if (input_val != "") {
        if (input_val.indexOf(".") >= 0) {
          var decimal_pos = input_val.indexOf(".");
          var l = input_val.substring(0, decimal_pos);
          var r = input_val.substring(decimal_pos, decimal_pos + 6);
          var right_side = input_val.substring(decimal_pos, 2);
          this.VendorPartCost = l + "" + r;
        } else {
          this.VendorPartCost = this.VendorPartCost + ".00000";
        }

        const data = this.dynamicArray.filter(x => x.isActive == true && x.Qty != '' && x.DiscountPrice != '');
        if (data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            if (this.hasDecimalPlace(data[i].DiscountPrice, 1)) {
              const value = (parseFloat(data[i].DiscountPrice) * this.VendorPartCost).toFixed(2);
              const stringvalue1 = (parseFloat(this.VendorPartCost) - parseFloat(value)).toFixed(2);
              data[i].cost = String(stringvalue1);
            }
            else {
              const disVlaue = parseFloat(data[i].DiscountPrice) / 100;
              const value = (disVlaue * this.VendorPartCost).toFixed(2);
              const stringvalue1 = (parseFloat(this.VendorPartCost) - parseFloat(value)).toFixed(2);
              data[i].cost = String(stringvalue1)
            }

          }
        }

      }
    }

  }
  VendorPartCurrency() {
    var input_val = this.PVendorCost;
    if (input_val != undefined) {
      var original_len = input_val.length;
      var calcMargin = 1 + Number(this.PartMarkupMargin);
      if (this.PartMarkupCheck) {
        // this.PPartPrice = Number(this.PVendorCost * calcMargin).toFixed(5);
        this.PPartPrice = Number(this.PVendorCost * calcMargin).toFixed(5);
        if (this.PPartPrice == "NaN") {
          this.PPartPrice = 0;
        }
      }
      if (input_val != "") {
        if (input_val.indexOf(".") >= 0) {
          var decimal_pos = input_val.indexOf(".");
          var l = input_val.substring(0, decimal_pos);
          var r = input_val.substring(decimal_pos, decimal_pos + 6);
          var right_side = input_val.substring(decimal_pos, 2);
          this.PVendorCost = l + "" + r;
        } else {
          this.PVendorCost = this.PVendorCost + ".00000";
        }
      }
    }

  }
  // getAllCompany(companyId) {
  //   if (companyId == undefined) { companyId = 0; }
  //   this.companyService.GetDropdownCompanyHierarchyModel(this.companyId).subscribe(
  //     data => {
  //       this.data = data;
  //       // this.programs1 = this.data.dataList;

  //       this.items = this.data.dataList;
  //       this.companyList = this.data.dataList;
  //       this.companyList.forEach((x) => {
  //         this.userGroupObjects.push(new TreeviewItem(x, false));
  //       });
  //       this.CompanyValue = Number(companyId);
  //     });
  // }
  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next('');
    });
  }


  PartBack() {
    this.clearbutton = true;
    this.id = null;
    this.CheckCategory = false;
    this.PartHistory = false;
    this.buttonValue = this.constants.Save;
    this.resetvendorData();
  }

  GetInvTypeDDLText(InvTypeId) {
    if (this.InvTypeList != undefined) {
      return this.InvTypeList.find(s => s.id == InvTypeId).name;
    }

  }

  GetInvType2DDLText(InvTyepValue, InvTypeValue2) {
    if (InvTyepValue == "6") {
      if (this.InvPartOrderDDL != undefined) {
        const InvData = this.InvPartOrderDDL.filter(item => item.id == InvTypeValue2);
        if (InvData.length > 0) {
          return InvData[0].name;
        }
        else {
          return '';
        }
      }

    }
    else if (InvTyepValue == "7") {
      if (this.InvProductOrderDDL != undefined) {
        const InvData = this.InvProductOrderDDL.filter(item => item.id == InvTypeValue2);
        if (InvData.length > 0) {
          return InvData[0].name;
        }
        else {
          return '';
        }
      }

    }
    else {
      return InvTypeValue2;
    }

  }
  AddInv() {
    if (this.NonInventory == false) {
      if (this.form.controls.InvQty.status === this.constants.Invalid ||
        this.form.controls.InvDate.status === this.constants.Invalid ||
        this.form.controls.InvCost.status === this.constants.Invalid ||
        this.form.controls.InvType.status === this.constants.Invalid ||
        this.form.controls.InvVendor.status === this.constants.Invalid ||
        this.form.controls.InvLocName.status === this.constants.Invalid ||
        this.form.controls.InvLocName1.status === this.constants.Invalid ||
        this.form.controls.InvPartTracking.status === this.constants.Invalid ||
        this.form.controls.minInventory.status === this.constants.Invalid ||
        this.form.controls.LocationsId.status === this.constants.Invalid ||
        this.form.controls.partLocation.status === this.constants.Invalid ||
        this.form.controls.partTracking.status === this.constants.Invalid
      ) {
        this.InvValidation = true;
        return;
      }
      else {
        if (this.InvType == "5") {
          if (this.form.controls.InvServNo.status === this.constants.Invalid) {
            this.InvValidation = true;
            return;
          }
        }
        if (this.InvType == "6") {
          if (this.form.controls.InvPartOrder.status === this.constants.Invalid) {
            this.InvValidation = true;
            return;
          }
        }
        if (this.InvType == "7") {
          if (this.form.controls.InvProductOrder.status === this.constants.Invalid) {
            this.InvValidation = true;
            return;
          }
        }

        this.InvValidation = false;

        if (this.InvType == "5") {
          this.InvType2 = this.InvServNo;
        }
        else if (this.InvType == "6") {
          this.InvType2 = Number(this.InvPartOrder);

        }
        else if (this.InvType == "7") {
          this.InvType2 = Number(this.InvProductOrder);
        }
        else {
          this.InvType2 = '';
        }
        this.qtyLabel = [];
        if (this.InvPartTracking != "3") {
          this.inventoryQty = this.InvQty;
          for (var i = 1; i <= this.InvQty; i++) {
            this.qtyPartName = this.name;
            var obj = {
              id: i, part: this.name + ' ' + i,
              serialNo: '', valid: false, type: this.InvPartTracking == "1" ? 'Serial' : 'Lot', message: '', isActive: true,
              ordering: i
            }
            this.exitProp = false;
            this.IsSerialDisabled = false;
            this.Tracking = this.InvPartTracking;
            this.qtyLabel.push(obj);
          }
          $('#SerialModel').show();
        }
        else {
          this.AddInventoryRecord();
        }
      }
    }
    else {
      $('#InvExist').show();
      this.InvQty = '';
      this.InvDate = '';
      this.InvCost = '';
      this.InvType = '1';
      this.InvType2 = '';
      this.InvServNo = '';
      this.InvPartOrder = '';
      this.InvProductOrder = '';
      this.InvCustRMA = '';
      this.InvPartLocation = '';
      this.InvValidation = false;
      this.InvVendor = '';
      this.InvPartTracking = this.partTracking == "3" ? '' : this.partTracking;
    }


  }
  SetTracking(e) {
    if (this.partTracking != "") {
      if (this.partTracking == parseInt(e.target.value)) {

      }
      else {
        $('#SetTracking').show();
        this.SerialComment = "Part Tracking Details already set to ' " + this.checkTracking(this.partTracking) + "'";
      }
    }
    else {
      $('#SetTracking').show();
      this.SerialComment = "Please Select Part Tracking Details First";
    }
  }
  CloseTracking() {
    this.InvPartTracking = this.partTracking;
    $('#SetTracking').hide();
  }
  checkTracking(id) {
    const trackingData = this.PartTrackingList.filter(x => x.id == id);
    return trackingData[0].name;
  }

  SaveDetail() {
    let count = 0; let messageCount = 0; let sameCount = 0;
    const sameSerial = [];
    const filterElement = this.qtyLabel;
    if (filterElement[0].number == '') {
      filterElement[0].message = '';
    }
    else {
      filterElement[0].message = '';
    }
    this.qtyLabel.forEach(element => {
      if (element.serialNo == '') {
        element.valid = true;
        count++;
      }
      else {
        element.valid = false;
      }
    });
    if (count == 0) {
      let serial = '';
      this.qtyLabel.forEach(partLst => {
        sameSerial.push(partLst.serialNo);
        serial += partLst.serialNo + ':' + '' + ',';
      });

      let findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) != index);
      const duplicate = [...new Set(findDuplicates(sameSerial))];
      if (duplicate.length == 0) {
        if (serial) {
          serial = serial.slice(0, -1);
        }
        this.loader = true;
        this.partService.GetSerialExist(serial, this.id == undefined ? 0 : this.id, this.InvPartTracking).subscribe(
          data => {
            this.data = data;
            const list = this.data.dataList;
            this.loader = false;
            if (list.length > 0) {
              list.forEach(element => {
                this.loader = false;
                const getSerial = this.qtyLabel.filter(x => x.serialNo == element.serialNo);
                getSerial[0].message = this.InvPartTracking == "1" ? 'Serial No already exists for this part' : 'Lot # already exists for this part';
                messageCount = messageCount + 1;
              });
            }
            if (messageCount == 0) {
              this.AddInventoryRecord();
            }
            else {

            }
          });
      }
      else {
        if (duplicate.length > 0) {
          duplicate.forEach(element => {
            const getSerial = this.qtyLabel.filter(x => x.serialNo == element);
            getSerial.forEach(element => {
              element.message = this.InvPartTracking == "1" ? 'Serial No already exists for this part' : 'Lot # already exists for this part';
            });
          });
        }
      }

    }
    else {
      if (this.qtyLabel.length > 15) {
        $('#GeneralModel').show();
        var s = this.InvPartTracking == "1" ? " Serial #" : " Lot#";
        this.SerialComment = "Please Add " + count + s;
      }
      return;
    }
  }


  SaveDetail1() {
    $('#ExitModel').hide();
    this.AddInventoryRecord();
  }

  changeValidation(id) {
    const filterElement = this.qtyLabel.filter(x => x.id == id);
    if (filterElement[0].number == '') {
      filterElement[0].valid = true;
      filterElement[0].message = '';
    }
    else {
      filterElement[0].valid = false;
      filterElement[0].message = '';
    }
  }

  changeValidation1(id) {
    const filterElement = this.DeleteSerialArr.filter(x => x.id == id);
    if (filterElement[0].number == '') {
      filterElement[0].valid = true;
      filterElement[0].message = '';
    }
    else {
      filterElement[0].valid = false;
      filterElement[0].message = '';
    }
  }
  changeValidation2(id) {
    const filterElement = this.AddSerials.filter(x => x.ids == id);
    if (filterElement[0].number == '') {
      filterElement[0].valid = true;
      filterElement[0].message = '';
    }
    else {
      filterElement[0].valid = false;
      filterElement[0].message = '';
    }
  }

  OpenSerialModel(item: any, type: any, id: any, tracking: any, qty) {
    if (tracking != "3" && tracking != "0") {
      this.qtyLabel = [];
      // item = item.sort((a, b) => (a.ordering > b.ordering ? 1 : -1));
      for (var i = 0; i < item.length; i++) {
        var obj = {
          id: item[i].id, part: item[i].part, isActive: item[i].isActive,
          serialNo: item[i].serialNo, valid: false, type: tracking == "1" ? 'Serial' : 'Lot', SerialItemId: item[i].id,
          ordering: item[i].ordering
        }
        this.qtyLabel.push(obj);
        this.exitProp = true;
        // this.IsSerialDisabled = (type == 6 || type == 7) ? true : false;
        this.IsSerialDisabled = false;
        this.openId = id;
      }
      this.inventoryQty = qty;
      this.Tracking = tracking;

      $('#SerialModel').show();
    }

  }

  ClosePartTracking() {
    $('#SelectedPartTracking').hide();
  }
  filterActiveRecords(records: any) {
    if (records != undefined) {
      return records.filter(x => x.isActive == true);
    }

  }
  EditDetail(id: any) {
    if (this.IsSerialDisabled == false) {
      const valuel = this.qtyLabel.filter(x => x.isActive == true);
      let count = 0; let messageCount = 0; let sameCount = 0;
      const sameSerial = [];
      const filterElement = valuel;
      if (filterElement[0].number == '') {
        filterElement[0].message = '';
      }
      else {
        filterElement[0].message = '';
      }
      valuel.forEach(element => {
        if (element.serialNo == '') {
          element.valid = true;
          count++;
        }
        else {
          element.valid = false;
        }
      });
      if (count == 0) {
        let serial = ''; let ids = ''; let serialNext = [];
        valuel.forEach(partLst => {
          sameSerial.push(partLst.serialNo);
          var ss = partLst.SerialItemId == undefined ? '0' : partLst.SerialItemId;
          serial += partLst.serialNo + ':' + ss + ',';
        });

        let findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) != index);
        const duplicate = [...new Set(findDuplicates(sameSerial))];
        if (duplicate.length == 0) {
          if (serial) {
            serial = serial.slice(0, -1);
          }
          this.loader = true;
          this.partService.GetSerialExist(serial, this.id == undefined ? 0 : this.id, this.Tracking).subscribe(
            data => {
              this.data = data;
              const list = this.data.dataList;
              this.loader = false;
              if (list.length > 0) {
                list.forEach(element => {
                  const getSerial = valuel.filter(x => x.serialNo == element.serialNo);
                  getSerial[0].message = this.Tracking == "1" ? 'Serial No already exists for this part' : 'Lot # already exists for this part';
                  messageCount = messageCount + 1;
                });
              }
              if (messageCount == 0) {
                this.EditDetail1(id);
              }
              else {

              }
            });
        }
        else {
          if (duplicate.length > 0) {
            duplicate.forEach(element => {
              const getSerial = valuel.filter(x => x.serialNo == element);
              getSerial.forEach(element => {
                element.message = this.Tracking == "1" ? 'Serial No already exists for this part' : 'Lot # already exists for this part';
              });

            });
          }
        }

      }
      else {
        if (this.qtyLabel.length > 15) {
          $('#GeneralModel').show();
          var s = this.InvPartTracking == "1" ? " Serial #" : " Lot#";
          this.SerialComment = "Please Add " + count + s;
        }
        // $('#ExitModel').show();
        return;
      }

    }
    else {
      $('#SerialModel').hide();
    }

  }
  EditDetail1(id: any) {
    this.loader = true; let checkDetail;
    checkDetail = this.InvList.filter(x => x.id == id);
    if (checkDetail.length == 0) {
      checkDetail = this.InvList.filter(x => x.InvSno == id);
    }
    const oldSerial = []; const newSerial = [];
    checkDetail[0].obj1.forEach(element => {
      oldSerial.push(element.serialNo);
    });
    this.qtyLabel.forEach(element => {
      newSerial.push(element.serialNo);
    });
    const A1 = String(oldSerial);
    const A2 = String(newSerial);
    if (A1 == A2) {
      checkDetail[0].isChange = "false";
    }
    else {
      checkDetail[0].obj1 = this.qtyLabel;
      checkDetail[0].isChange = "true";
    }

    this.qtyLabel = [];
    this.loader = false;
    $('#SerialModel').hide();
  }

  AddInventoryRecord() {
    $('#SerialModel').hide();
    this.qtyLabelNew = this.qtyLabel;
    this.qtyLabel = [];
    let obj = {
      InvSno: this.InvSno,
      partId: this.id,
      InvQty: Number(this.InvQty),
      InvQtyOrdered: Number(this.InvQty),
      InvDate: this.InvDate,
      InvCost: Number(this.InvCost),
      InvType: Number(this.InvType),
      InvType2: this.InvType2,
      InvCustRMA: this.InvCustRMA,
      InvVendor: Number(this.InvVendor),
      InvPartLocation: this.InvPartLocation,
      isActive: true,
      createdBy: this.userId,
      createdDate: this.now = new Date(),
      InvLoc: this.InvLocName,
      InvLoc1: this.InvLocName1,
      InvTracking: this.InvPartTracking,
      obj1: this.qtyLabelNew,
      TrackingUpdate: 0
    }
    if (obj.InvType == 6 || obj.InvType == 7) {
      this.loader = true;
      this.sharedService.GetCheckInventory(obj.InvType2, obj.InvQty, obj.partId).subscribe(
        data => {
          this.data = data;
          this.loader = false;
          if (this.data.data == "0") {
            obj.obj1.forEach(element => {
              element.isSale = true;
            });

            this.InvList.push(obj);
            this.InvSno += 1;
            this.InvQty = '';
            this.InvDate = '';
            this.InvCost = '';
            this.InvType = '1';
            this.InvType2 = '';
            this.InvServNo = '';
            this.InvPartOrder = '';
            this.InvProductOrder = '';
            this.InvCustRMA = '';
            this.InvVendor = '';
            this.InvPartLocation = '';
            this.InvPartTracking = '';
            this.InvLocName = this.SelectedLocation;
            this.invlocationLevelList = [];
            this.invlocationLevelList = this.locationLevelList;
            this.InvLocName1 = this.locationText;
            this.InvPartTracking = this.partTracking == "3" ? '' : this.partTracking;
          }
          else {
            this.InvProp = "You can assign only " + this.data.data + " qty on this order ";
            $('#InvExist1').show();
          }
        });
    }
    else {
      this.InvList.push(obj);
      this.InvSno += 1;
      this.InvQty = '';
      this.InvDate = '';
      this.InvCost = '';
      this.InvType = '1';
      this.InvType2 = '';
      this.InvServNo = '';
      this.InvPartOrder = '';
      this.InvProductOrder = '';
      this.InvCustRMA = '';
      this.InvVendor = '';
      this.InvPartLocation = '';
      this.InvPartLocation = '';
      this.InvPartTracking = '';
      this.InvLocName = this.SelectedLocation;
      this.invlocationLevelList = [];
      this.invlocationLevelList = this.locationLevelList;
      this.InvLocName1 = this.locationText;
      this.InvPartTracking = this.partTracking == "3" ? '' : this.partTracking;
    }
  }

  CloseSerialModel() {
    $('#SerialModel').hide();
  }
  CloseExitModel() {
    $('#ExitModel').hide();
  }

  InvExist1() {
    $('#InvExist1').hide();
  }
  InvExist() {
    $('#InvExist').hide();
  }

  ChangeTCreateUpdate(value) {
    this.TCreateUpdate = value;

    if (this.TCreateUpdate == "1") {
      this.isTrue = false;
      this.onlyOption = true;
      if (this.getOptionWP == 2) {
        this.WordPressData = '2'
      } else {
        this.WordPressData = '1'
      }
      this.controlWPData = false;
      this.controlWPData1 = true;
      this.SearchControl = false;
      this.ListShow = false;
      this.ListDiv = false;
      this.clearbutton = true;
      this.buttonValue = this.constants.Save;
      this.resetvendorData();
      this.CheckId = 0;
      this.CheckCategory = false;
      this.PartHistory = false;
      this.ResetPopModelData();
      this.id = null;
      this.IsUpdate = false;
      this.IsSave = true;

      if (this.CompanyValue != undefined || this.CompanyValue != 0) {
        this.WordpressCategoryLoad(this.CompanyValue);
      }

    }
    else if (this.TCreateUpdate == "0") {
      this.isTrue = true;
      this.onlyOption = false;

      if (this.getOptionWP == 3) {
        this.WordPressData = '3'
      } else {
        this.WordPressData = '2'
      }
      this.controlWPData = true;
      this.controlWPData1 = false;
      this.SearchControl = true;
      this.userSearch = "";
      this.CheckId = 0;
      this.CheckCategory = false;
      this.PartHistory = false;
      this.ResetPopModelData();
      this.id = null;
      this.CheckId = 0;
      this.IsUpdate = true;
      this.IsSave = false;

    }
  }

  ResetPopModelData1() {
    this.NewPartialArray = [];
  }

  ResetPopModelData() {
    this.WordpressCategoryList = [];
    this.WordpressCategoryCrimpToolsList = [];
    this.WordpressCategoryRelatedItemList = [];
    this.NewArray2 = [];
    this.NewArray = [];
    this.NewArray1 = [];
    this.CrimpNewArray = [];
    this.CrimpNewArray1 = [];
    this.RelatedNewArray = [];
    this.RelatedNewArray1 = [];
    this.CustomerPartNameList = [];
    this.CustomerPartNameCrimpList = [];
    this.CustomerPartNameRelatedList = [];
    this.shortDescription = "";
    this.InfoName = "More Information";
    this.MatingName = "Mating Connectors";
    this.CrimpName = "Crimp Tools";
    this.RelatedName = "Related Items";
  }

  ListDivShowHide() {

    if (this.ListDiv == true) {
      this.ListDiv = false;
      this.ListAngle = "down";
    }
    else if (this.ListDiv == false) {
      this.ListDiv = true;
      this.ListAngle = "up";
    }
  }
  searchUser() {
    this.datalist = [];
    this.SearchType = false;
    if (!this.userSearch || this.value_search == 0) {
      this.SearchValidation = true;
      this.GetAllPart("1");
      return;
    }

    if (this.fieldToSearch == "3") {
      this.ListShow = true;
      this.ListDiv = true;
      this.GetAllPart("2");
    }
    else if (this.fieldToSearch == "2") {
      this.ListShow = true;
      this.ListDiv = true;
      this.GetAllPart("3");
    }
    else if (this.fieldToSearch == "1") {
      this.ListShow = true;
      this.ListDiv = true;
      this.GetAllPart("4");
    }
    else {
      this.ListShow = true;
      this.ListDiv = true;
      this.GetAllPart("1");
    }
  }
  GetAllPart1() {
    this.partService.GetAllPart1(this.value_search, this.userSearch ? this.userSearch : '', 0, 10)
      .subscribe(resp => {
        this.loader = false;
        let totalRows = null;
        this.data = resp;
        if (this.data.dataList.length > 0) {
          this.datalist = this.data.dataList;
          totalRows = [...new Set(this.datalist.map(item => item.totalRows))];
        }
        else {
          this.datalist = null;
          totalRows = 0;
        }


      });
  }

  AllUser(event) {
    this.datalist = null;
    this.userSearch = '';
    this.SearchType = true;
    if (this.value_search == 0) {
      this.SearchValidation = true;
      return;
    }
    this.ListShow = true;
    this.ListDiv = true;
    this.ListAngle = "up";
    this.GetAllPart("1");
    this.resetvendorData();
    this.CheckId = 0;
    this.CheckCategory = false;
    this.PartHistory = false;
    this.ResetPopModelData();
    this.id = null;
    this.IsUpdate = true;
    this.IsSave = false;
    this.validation = false;
    this.recordAlreadyExist = false;
    this.recordPartNoExist = false;
    this.validation = false;
    this.popvalidation = false;
    this.validationImage = false;
  }

  hidemodalpopup1() {
    $("#Imagevlaidation").hide();
  }

  onValueChange_search(value: any): void {
    this.value_search = value;
    this.User();
    this.UserRole();
    this.GetCatList(value);
  }

  GetAllImageTypeList() {
    this.partService.GetAllImageTypeList().subscribe(
      data => {
        this.data = data;
        this.ImageTypelist = this.data.dataList;
      }
    )
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
    //this.dtTrigger.next();
    this.dtTrigger.next('');
    this.settingDetailCSS = localStorage.getItem("settingDetailCSS");
    this.settingLogoImage = localStorage.getItem("settingImageLogo");
    this.SetSettingCSS(this.settingDetailCSS, this.settingLogoImage);
  }


  public b64toBlob(b64Data, contentType) {
    contentType = contentType || '';
    let sliceSize = 512;

    var byteCharacters = window.atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  viewImage(value, documenttype) {
    if (value != null && documenttype == "PDF") {
      value = value.changingThisBreaksApplicationSecurity;
      var value1 = value.split(",");
      var blob = this.b64toBlob(value1[1], "application/pdf");
      let a = document.createElement("a");
      document.body.appendChild(a);
      var url = window.URL.createObjectURL(blob);
      a.href = url;
      let pdfWindow = window.open("")
      pdfWindow.document.write("<iframe width='100%' height='100%' src='" + encodeURI(url) + "'></iframe>");
    }
    else {
      this.viewimagesshow = value;
      $("#myModal").show();
    }
  }


  TimekeyPress() {
    var field = this.partLeadTime;
    var decimal_pos = field.indexOf(".");
    if (field.length >= 3 && decimal_pos == -1) {
      let maskingTime = '';
      maskingTime = field.substring(0, 2) + "." + field.substring(3, 4);
      this.partLeadTime = maskingTime;
    }

  }

  keyPressAlphaNumericWithCharacters(event) {

    var inp = String.fromCharCode(event.keyCode);
    // Allow numbers, alpahbets, space, underscore
    if (/[a-zA-Z0-9-_ ]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  HideImage() {
    $("#myModal").hide();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  addsuccess() {
    $("#add_success").hide();
  }

  updateitem() {
    $("#add_update").hide();
  }

  deleteitem() {
    $("#delete_success").hide();
  }

  msgitem() {
    $("#wrong_msg").hide();
  }

  ChangeOrderDropdown(value) {
    if (value) {
      this.mQtyMoveMsg = false;
      this.mInvQtyValidation = false;
      this.loader = true;
      this.sharedService.GetOrderPartQtyToFulfill(value, this.id).subscribe(
        data => {
          this.loader = false;
          this.data = data;
          this.mInvQtyToMove = this.data.data;
          this.mValidateQtyToFulfill = this.data.data;
        }
      );
    }
  }

  MoveInvRow(InvItem) {
    this.MoveInvClr();
    this.MainInvRowNo = InvItem.InvSno;
    this.mInvQty = InvItem.InvQty;
    this.mInvDate = InvItem.InvDate;
    this.mInvCost = InvItem.InvCost;
    this.mInvType = InvItem.InvType;
    this.mInvLocation2 = InvItem.InvLoc == undefined ? '' : InvItem.InvLoc;
    this.mInvLocation1 = InvItem.InvLoc1 == "undefined" ? '' : InvItem.InvLoc1;
    this.mInvCustRMA = InvItem.InvCustRMA;
    this.mInvLocation2 = '';
    this.mInvLocation1 = '';
    this.displayOldLocation = InvItem.InvPartLocation;
    this.modalService.dismissAll();
    this.modalService.open(this.itemModel, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false, size: 'xl' });

  }

  MoveInv() {
    if (this.formPopup.controls.mInvQty.status === this.constants.Invalid ||
      this.formPopup.controls.mInvQtyToMove.status === this.constants.Invalid ||
      this.formPopup.controls.mInvDate.status === this.constants.Invalid ||
      this.formPopup.controls.mInvType.status === this.constants.Invalid ||
      this.formPopup.controls.mInvLocation1.status === this.constants.Invalid ||
      this.formPopup.controls.mInvLocation2.status === this.constants.Invalid
    ) {
      this.mInvValidation = true;
      return;
    }
    else {
      if (this.mInvType == "5" && this.formPopup.controls.mInvServNo.status === this.constants.Invalid) {
        this.mInvValidation = true;
        return;
      }
      if (this.mInvType == "6" && this.formPopup.controls.mInvPartOrder.status === this.constants.Invalid) {
        this.mInvValidation = true;
        return;
      }
      if (this.mInvType == "7" && this.formPopup.controls.mInvProductOrder.status === this.constants.Invalid) {
        this.mInvValidation = true;
        return;
      }

      if ((this.mInvType == "6" || this.mInvType == "7") && Number(this.mInvQtyToMove) > Number(this.mValidateQtyToFulfill)) {
        this.mQtyMoveMsg = true;
        this.mInvQtyValidation = true;
        this.mInvValidation = true;
        return;
      }
      else {
        if (Number(this.mInvQtyToMove) > Number(this.mInvQty)) {
          this.mQtyMoveMsg = false;
          this.mInvQtyValidation = true;
          this.mInvValidation = true;
          return;
        }
      }

      this.mQtyMoveMsg = false;
      this.mInvQtyValidation = false;
      this.mInvValidation = false;

      if (this.mInvType == "5") {
        this.mInvType2 = this.mInvServNo;
      }
      else if (this.mInvType == "6") {
        this.mInvType2 = Number(this.mInvPartOrder);

      }
      else if (this.mInvType == "7") {
        this.mInvType2 = Number(this.mInvProductOrder);
      }
      else {
        this.mInvType2 = '';
      }
      this.moveDiff = Number(this.mInvQtyToMove);
      const InvData = this.InvList.filter(item => item.InvSno == this.MainInvRowNo);
      if (InvData.length > 0) {
        if (InvData[0].InvTracking != "3" && InvData[0].InvTracking != "0") {
          this.MoveSerialArr = InvData[0].obj1.filter(x => x.isActive == true);
          this.DSerialTracking = InvData[0].InvTracking;
          $('#MoveSerials').show();
        }
        else {
          this.MoveInventoryMethod();
        }
      }

      //this.MoveInventoryMethod();
    }
  }
  CloseMoveSerials() {
    const checkDeleteSerial = this.MoveSerialArr.filter(x => x.isActive == false);
    if (checkDeleteSerial.length > 0) {
      checkDeleteSerial.forEach(element => {
        element.isActive = true;
      });
    }
    $('#MoveSerials').hide();
  }

  MoveSerialNum(id: any, tracking: any) {
    const checkDeleteSerial = this.MoveSerialArr.filter(x => x.isActive == false);
    if (checkDeleteSerial.length < this.moveDiff) {
      const value = this.MoveSerialArr.filter(x => x.id == id);
      value.forEach(element => {
        element.isActive = false;
      });
    }
    else {
      const trackingDetails = tracking == "1" ? " Serial#" : " Lot#";
      this.SerialComment = "You can only move " + this.moveDiff + trackingDetails;
      $('#GeneralModel').show();
    }

  }

  MoveSerial(tracking: any) {
    const checkDeleteSerial = this.MoveSerialArr.filter(x => x.isActive == false);
    if (checkDeleteSerial.length == this.moveDiff) {
      $('#MoveSerials').hide();
      this.MoveInventoryMethod();
    }
    else {
      const trackingDetails = tracking == "1" ? " Serial#" : " Lot#";
      this.SerialComment = "Please Move " + this.moveDiff + trackingDetails;
      $('#GeneralModel').show();
    }
  }
  MoveInventoryMethod() {
    const newArr = [];
    const InvData = this.InvList.filter(item => item.InvSno == this.MainInvRowNo);
    if (InvData.length > 0) {

      if (Number(this.mInvQty) == Number(this.mInvQtyToMove)) {
        InvData[0].InvType = Number(this.mInvType);
        InvData[0].InvType2 = this.mInvType2;
        InvData[0].InvPartLocation = this.mInvLocation;
        InvData[0].InvLoc = this.mInvLocation2;
        InvData[0].InvLoc1 = this.mInvLocation1;
        InvData[0].InvReason = this.mInvReason;
        const A1 = this.MoveSerialArr.filter(x => x.isActive == false);
        A1.forEach(flrArray => {
          if (InvData[0].InvType == "6" || InvData[0].InvType == "7") {
            flrArray.isSale = true;
          }
          flrArray.isActive = true;
        });

      }
      else {
        let obj = {
          InvSno: this.InvSno,
          partId: this.id,
          pInvSno: this.MainInvRowNo,
          InvQty: Number(this.mInvQtyToMove),
          InvQtyOrdered: Number(this.mInvQtyToMove),
          InvDate: this.mInvDate,
          InvCost: Number(this.mInvCost),
          InvType: Number(this.mInvType),
          InvType2: this.mInvType2,
          InvCustRMA: this.mInvCustRMA,
          isActive: true,
          InvPartLocation: this.mInvLocation,
          InvReason: this.mInvReason,
          InvLoc: this.mInvLocation2,
          InvLoc1: this.mInvLocation1,
          InvTracking: InvData[0].InvTracking,
          InvVendor: Number(InvData[0].InvVendor),
          MovePid: InvData[0].id == undefined ? null : InvData[0].id.toString(),
          InvCustomPartId: Number(InvData[0].InvCustomPartId),
        }
        const filterArr = [];
        this.InvList.push(obj);
        this.InvList.forEach(element => {
          if (element.MovePid != undefined) {
            const list = this.InvList.filter(x => x.id == parseInt(element.MovePid));
            const a = this.MoveSerialArr.filter(x => x.isActive == true);
            const b = this.MoveSerialArr.filter(x => x.isActive == false);
            b.forEach(flrArray => {
              if (element.InvType == "6" || element.InvType == "7") {
                flrArray.isSale = true;
              }
              flrArray.isActive = true;
            });
            element.obj1 = b;

            list[0].obj1 = a;
          }
        });

        this.InvSno += 1;
        InvData[0].InvQty = Number(InvData[0].InvQty) - Number(this.mInvQtyToMove);
        this.mInvQty = '';
        this.mInvQtyToMove = '';
        this.mInvDate = '';
        this.mInvCost = '';
        this.mInvType = '';
        this.mInvType2 = '';
        this.mInvServNo = '';
        this.mInvPartOrder = '';
        this.mInvProductOrder = '';
        this.mInvCustRMA = '';
        this.mInvLocation = '';
        this.mInvReason = '';
        this.mInvLocation2 = '';
        this.mInvLocation1 = '';
      }
      this.modalService.dismissAll();
      this.mMsg = this.constants.QtyMoved;
      this.mMsgClass = this.constants.SuccessPng;
      this.modalService.open(this.QtyMoveMsgModel, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
    }
  }

  MoveInvClr() {
    this.mQtyMoveMsg = false;
    this.mInvQtyValidation = false;
    this.mInvValidation = false;
    this.mInvQty = '';
    this.mInvQtyToMove = '';
    this.mInvDate = '';
    this.mInvCost = '';
    this.mInvType = '';
    this.mInvType2 = '';
    this.mInvServNo = '';
    this.mInvPartOrder = '';
    this.mInvProductOrder = '';
    this.mInvCustRMA = '';
    this.mInvLocation = '';
    this.mInvReason = '';
    this.mMsg = '';
    this.mMsgClass = '';
  }

  AdjustInvRow(InvItem) {
    this.AdjustInvClr();
    this.MainInvRowNo = InvItem.InvSno;
    this.adjInvQty = InvItem.InvQty;
    this.modalService.dismissAll();
    this.modalService.open(this.itemModel2, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false, size: 'xl' });
  }

  AdjustInv() {
    if (this.formPopupAdjustQty.controls.adjInvQty.status === this.constants.Invalid ||
      this.formPopupAdjustQty.controls.adjInvQtyToAdjust.status === this.constants.Invalid
    ) {
      this.adjInvValidation = true;
      return;
    }
    else {

      if (Number(this.adjInvQty) == Number(this.adjInvQtyToAdjust)) {
        this.adjInvValidation = true;
        return;
      }

      if (Number(this.adjInvQty) > Number(this.adjInvQtyToAdjust)) {
        this.serialNumDiff = Number(this.adjInvQty) - Number(this.adjInvQtyToAdjust);
        this.AddSerial = false;
      }
      else if (Number(this.adjInvQty) < Number(this.adjInvQtyToAdjust)) {
        this.serialNumDiff = Number(this.adjInvQtyToAdjust) - Number(this.adjInvQty);
        this.AddSerial = true;
      }
      const InvData = this.InvList.filter(item => item.InvSno == this.MainInvRowNo);
      if (InvData.length > 0) {
        this.DeleteSerialArr = InvData[0].obj1.filter(x => x.isActive == true);
        this.DeleteSerialArr.forEach(element => {
          element.IsSerialDisabled = true;
          element.message = "";
        });
      }
      this.DSerialTracking = InvData[0].InvTracking;
      if (this.DSerialTracking != "3" && this.DSerialTracking != "0") {
        this.AddSerials = [];
        if (this.AddSerial == true) {
          let len = this.DeleteSerialArr.length;
          var s = len + 1;
          for (var i = 1; i <= this.serialNumDiff; i++) {
            var addSerial = {
              ids: len + 1, part: this.name + ' ' + s, id: 0,
              serialNo: '', isActive: true, isSale: false, ordering: s
            }
            len = len + 1;
            s = s + 1;
            this.AddSerials.push(addSerial);
          }
        }
        $('#DeleteSerials').show();
      }
      else {
        this.MoveAdjustFuns();
      }

    }
  }
  deleteSerialNum(id: any, tracking: any) {
    const checkDeleteSerial = this.DeleteSerialArr.filter(x => x.isActive == false);
    if (checkDeleteSerial.length < this.serialNumDiff) {
      const value = this.DeleteSerialArr.filter(x => x.id == id);
      value.forEach(element => {
        element.isActive = false;
      });
    }
    else {
      const trackingDetails = tracking == "1" ? " Serial#" : " Lot#";
      this.SerialComment = "You can only remove " + this.serialNumDiff + trackingDetails;
      $('#GeneralModel').show();
    }

  }

  addSerialNum(id: any, tracking: any) {
    const value = this.DeleteSerialArr.filter(x => x.id == id);
    value.forEach(element => {
      element.isActive = true;
    });
  }

  CloseGeneralModel() {
    $('#GeneralModel').hide();
  }
  CloseDSerials() {
    const checkDeleteSerial = this.DeleteSerialArr.filter(x => x.isActive == false);
    if (checkDeleteSerial.length > 0) {
      checkDeleteSerial.forEach(element => {
        element.isActive = true;
      });
    }
    $('#DeleteSerials').hide();
  }
  SaveDSerial(tracking: any, addSerial: boolean) {
    if (addSerial == false) {
      const checkDeleteSerial = this.DeleteSerialArr.filter(x => x.isActive == false);
      if (checkDeleteSerial.length == this.serialNumDiff) {
        $('#DeleteSerials').hide();
        this.modalService.dismissAll();
        this.MoveAdjustFun();
      }
      else {
        const trackingDetails = tracking == "1" ? " Serial#" : " Lot#";
        this.SerialComment = "Please Remove " + this.serialNumDiff + trackingDetails;
        $('#GeneralModel').show();
      }
    }
    else {
      let count = 0; let messageCount = 0;
      this.AddSerials.forEach(element => {
        if (element.serialNo == '') {
          element.valid = true;
          count++;
        }
        else {
          element.valid = false;
        }
      });
      if (count == 0) {
        let serial = ''; const sameSerial = [];
        let newArr = []; let newArr1 = [];
        newArr1 = this.AddSerials;
        newArr1.forEach(element => {
          element.id = 0;
        });
        newArr.push(...this.DeleteSerialArr, ...this.AddSerials);
        newArr.forEach(partLst => {
          sameSerial.push(partLst.serialNo);
          var sid = partLst.id == 0 ? '' : partLst.id;
          serial += partLst.serialNo + ':' + sid + ',';
        });
        let findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) != index);
        const duplicate = [...new Set(findDuplicates(sameSerial))];
        if (duplicate.length == 0) {
          this.loader = true;
          this.partService.GetSerialExist(serial.trim(), this.id == undefined ? 0 : this.id, "1").subscribe(
            data => {
              this.data = data;
              const list = this.data.dataList;
              this.loader = false;
              if (list.length > 0) {
                list.forEach(element => {
                  this.loader = false;
                  const getSerial = newArr.filter(x => x.serialNo == element.serialNo);
                  getSerial[0].message = tracking == "1" ? 'Serial No already exists for this part' : 'Lot # already exists for this part';
                  messageCount = messageCount + 1;
                });
              }
              if (messageCount == 0) {
                const InvData = this.InvList.filter(item => item.InvSno == this.MainInvRowNo);
                if (InvData.length > 0) {
                  this.AddSerials.forEach(element => {
                    element.partId = InvData[0].partId;
                    element.partInventoryId = InvData[0].id;
                    element.type = "Serial";
                    InvData[0].obj1.push(element);
                  });
                }
                $('#DeleteSerials').hide();
                this.modalService.dismissAll();
                this.MoveAdjustFun();
              }
              else {

              }
            });
        }
        else {
          if (duplicate.length > 0) {
            duplicate.forEach(element => {
              newArr.forEach(element => {
                element.message = "";
              });
              const getSerial = newArr.filter(x => x.serialNo == element);
              getSerial.forEach(element => {

                element.message = tracking == "1" ? 'Serial No already exists for this part' : 'Lot # already exists for this part';
              });


            });
          }
        }
      }
      else {
        if (this.DeleteSerialArr.length > 10) {
          $('#GeneralModel').show();
          var s = this.InvPartTracking == "1" ? " Serial #" : " Lot#";
          this.SerialComment = "Please Add " + count + s;
        }
      }
    }
  }

  MoveAdjustFuns() {

    const InvData = this.InvList.filter(item => item.InvSno == this.MainInvRowNo);
    if (InvData.length > 0) {
      InvData[0].InvQty = Number(this.adjInvQtyToAdjust);
      InvData[0].InvReason = this.adjInvReason;
    }

    this.modalService.dismissAll();
    this.mMsg = this.constants.QtyAdjusted;
    this.mMsgClass = this.constants.SuccessPng;
    this.modalService.open(this.QtyMoveMsgModel, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });


  }

  MoveAdjustFun() {
    this.adjInvValidation = false;
    // const AdvQtyLabel = [];
    const InvData = this.InvList.filter(item => item.InvSno == this.MainInvRowNo);
    if (InvData.length > 0) {
      const newValue = InvData[0].obj1.filter(x => x.isActive == true);
      newValue.forEach(element => {
        element.isAdjust = null;
      });
      InvData[0].InvQty = Number(this.adjInvQtyToAdjust);
      InvData[0].InvReason = this.adjInvReason;

      for (var i = 0; i < this.adjInvQtyToAdjust; i++) {
        newValue[i].isAdjust = true;
        // AdvQtyLabel.push(InvData[0].obj1[i]);
      }
      this.inventoryQty = this.adjInvQtyToAdjust;
      newValue.forEach(element => {
        if (element.isAdjust == true) {
          element.isActive = true;
        }
        else {
          element.isActive = false;
        }
      });
      //  InvData[0].obj1 =  InvData[0].obj1.filter(x=>x.isActive == true);
    }
    this.modalService.dismissAll();
    this.mMsg = this.constants.QtyAdjusted;
    this.mMsgClass = this.constants.SuccessPng;
    this.modalService.open(this.QtyMoveMsgModel, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
  }


  AdjustInvClr() {
    this.adjInvValidation = false;
    this.adjInvQty = '';
    this.adjInvQtyToAdjust = '';
    this.adjInvReason = '';
    this.mMsg = '';
    this.mMsgClass = '';
  }
  viewLogFun() {
    if (this.value_search == 0) {
      this.SearchValidation = true;
      return;
    }
    else {

      this.TotalIn = 0;
      this.TotalOut = 0;
      this.loader = true;
      this.sharedService.GetLog(this.value_search, this.id).subscribe(
        data => {
          this.data = data;
          if (this.data.dataList != null && this.data.dataList != undefined) {
            if (this.data.dataList.length > 0) {
              this.viewLog = !this.viewLog;
              this.logList = this.data.dataList;
              if (this.NonInventory == true) {
                const invLogListing = this.logList.filter(x => x.type == 'Part SO Order' || x.type == 'Product SO Order');
                this.logList = invLogListing;
              }
              this.logList.forEach(logLst => {
                let inQty = logLst.inQty == null || logLst.inQty == "" ? 0 : logLst.inQty;
                let outQty = logLst.outQty == null || logLst.outQty == "" ? 0 : logLst.outQty;

                if (logLst.isActive == true) {
                  this.TotalIn += inQty;
                  this.TotalOut += outQty;
                }

                logLst.id = logLst.id;
                logLst.action = logLst.action;
                logLst.type = logLst.type;
                logLst.typeValue = logLst.typeValue;
                logLst.salesOrderNo = logLst.salesOrderNo;
                logLst.inQty = inQty;
                logLst.outQty = outQty;
                logLst.effectDate = this.datepipe.transform(logLst.effectDate, 'MMM-dd-yyyy');
                logLst.remark = logLst.remark;
                logLst.location = logLst.location;
                logLst.isActive = logLst.isActive;
                if (logLst.isActive == false) {
                  logLst.bcolor = this.constants.FireBrick;
                }
                else {
                  logLst.bcolor = '';
                }
              });

            }
            else {
              this.viewLog = false;
              this.logList = [];
            }
            this.loader = false;
          }
        }
      )
    }
  }


  onValueChange1(event) {
    var a = event.target.value.split("-");
    if (a != "") {
      this.partCategoryId = a[1];
      this.wordpressid = a[0];
      this.sharedService.GetMarkup(this.CompanyValue, this.partCategoryId).subscribe(
        (data: any) => {
          if (data.dataList[0].markupMargin == null) {
            this.MarkupMargin = 0;
          }
          else {
            this.MarkupMargin = data.dataList[0].markupMargin;

          }

          if (this.MarkupCheck) {
            var calcMargin = 1 + Number(this.MarkupMargin);
            this.cost = Number(this.VendorPartCost * calcMargin).toFixed(5);
            if (this.cost == "NaN") {
              this.cost = 0;
            }
          }

          if (a[0] != "0" && a != "" && a[0] != "null") {
            if (this.NotActive == false) {
              this.CheckCategory = true;
              this.CheckCategoryDisable = false;
            }
            else {
              this.CheckCategory = false;
              this.CheckCategoryDisable = true;
            }

          }
          else {
            this.CheckCategory = false;
            this.CheckCategoryDisable = false;

          }
        });

    }
    else {
      this.MarkupMargin = '';
      this.CheckCategory = false;
      this.CheckCategoryDisable = false;
    }


  }

  Getdata(id, name) {

    if (this.CheckId == 0) {
      this.loader = false;
      $("#CheckCategoryModal").show();

    }
    else {
      this.loader = false;
      $("#CheckCategoryModal").show();
      this.IsUpdate = true;
      this.IsSave = false;
      this.IsOpen = false;
      this.loader = false;
    }
  }

  togglePopModel(name) {

    this.NewArray1 = [];
    this.partService.GetApiDetailsById(this.CheckId, this.CompanyValue).subscribe(
      (data: any) => {
        if (data.data.apiWordpressDetails.length > 0) {
          for (var itemimage = 0; itemimage < data.data.apiWordpressDetails.length; itemimage++) {
            if (itemimage == 0) {
              this.shortDescription = data.data.apiWordpressDetails[itemimage].moreInformation;
              this.InfoName = data.data.apiWordpressDetails[itemimage].informationName;
              this.MatingName = data.data.apiWordpressDetails[itemimage].matingName;
              this.CrimpName = data.data.apiWordpressDetails[itemimage].crimpName;
              this.RelatedName = data.data.apiWordpressDetails[itemimage].relatedName;
              this.GetCategory(data.data.apiWordpressDetails[itemimage].id, name);

            }

          }
        }
        else {
          this.loader = false;
        }


      });

  }

  Getdata2(Id) {
    this.loader = true;
    this.partService.GetAllWordpressProduct(this.CompanyValue).subscribe(
      data => {
        this.data = data;
        this.data.dataList.forEach(e => {
          this.WordpressAllProductList.push(e);
        });
        this.CustomerPartNameList = this.WordpressAllProductList;

        this.loader = false;
      }
    )
  }
  Getdata1(Id, rowIndex, name) {
    this.loader = true;
    this.partService.GetAllCustomerPartNameByCompanyId(this.CompanyValue, Id).subscribe(
      (data: any) => {
        switch (data.statusCode) {
          case 200:
            this.data = data;
            this.data.dataList.forEach(e => {
              this.CustomerPartNameList.push(e);
            });

            //  this.CustomerPartNameList = [...new Map(this.CustomerPartNameList.map((item) => [item["id"], item])).values()];

            this.loader = false;
            break;
          case 404:
            $('#wrong_mssg').show();
            this.loader = false;
            break;
          case 400:
            this.noproduct = "No Company Part Name Found in '" + name + "' Part Category";
            $('#noproduct').show();
            const index: number = this.NewArray.indexOf(Id);
            this.NewArray.splice(index, 1);
            this.loader = false;
            this.WordpressCategoryList[rowIndex].IsChecked = false;

            break;
          default:
            $('#wrong_mssg').show();
            this.loader = false;
        }
      }
    )
  }
  mssgitem() {
    $('#wrong_mssg').hide();
  }
  GetdataLoad(Id, data1, id1, data: any) {
    this.loader = true;
    const ab = data;
    this.partService.GetAllCustomerPartNameSelected(this.CompanyValue, Id, id1, 'ApiWordpressCategory').subscribe(
      data => {
        this.data = data;
        this.data.dataList.forEach(e => {
          this.CustomerPartNameList.push(e);
        });
        const Iddata = this.CustomerPartNameList.filter(arr => arr.isActive === true && arr.partCategoryId === Id);
        for (let index = 0; index < Iddata.length; index++) {
          var obj = { 'id': Iddata[index].wordpressId, 'id1': Iddata[index].partCategoryId };
          this.NewArray1.push(obj);

        }
        const apiWordpressProduct = ab.dataList[0].apiWordpressProduct.filter(x => x.type == 'Crimp' && x.isChecked == true);
        if (apiWordpressProduct.length > 0) {
          apiWordpressProduct.forEach(e => {
            this.WordpressCategoryCrimpToolsList.filter(f => f.id == e.categoryId)[0].IsChecked = true;
            this.CrimpNewArray.push(e.categoryId);
            this.GetdataLoadCrimp(e.categoryId, apiWordpressProduct, id1, ab);
          });
        }
        else {
          this.GetdataLoadCrimp(Id, apiWordpressProduct, id1, ab);
        }



        this.loader = false;
      }
    )
  }
  GetdataLoadCrimp(Id, data1, id1, data: any) {
    this.loader = true;
    const ac = data;
    this.partService.GetAllCustomerPartNameSelected(this.CompanyValue, Id, id1, 'ApiWordpressProduct').subscribe(
      data => {
        this.data = data;
        this.data.dataList.forEach(e => {
          this.CustomerPartNameCrimpList.push(e);
        });
        const Iddata = this.CustomerPartNameCrimpList.filter(arr => arr.isActive === true && arr.partCategoryId === Id);
        for (let index = 0; index < Iddata.length; index++) {
          var obj = { 'id': Iddata[index].wordpressId, 'id1': Iddata[index].partCategoryId };
          this.CrimpNewArray1.push(obj);

        }
        const apiWordpressRelProduct = ac.dataList[0].apiWordpressProduct.filter(x => x.type == 'Related' && x.isChecked == true);

        apiWordpressRelProduct.forEach(e => {
          this.WordpressCategoryRelatedItemList.filter(f => f.id == e.categoryId)[0].IsChecked = true;
          this.RelatedNewArray.push(e.categoryId);
          this.GetdataLoadRelated(e.categoryId, apiWordpressRelProduct, id1);
        });
        this.loader = false;
      }
    )
  }
  GetdataLoadRelated(Id, data1, id1) {
    this.loader = true;
    this.partService.GetAllCustomerPartNameSelected(this.CompanyValue, Id, id1, 'ApiWordpressRelatedProduct').subscribe(
      data => {
        this.data = data;
        this.data.dataList.forEach(e => {
          this.CustomerPartNameRelatedList.push(e);
        });
        const Iddata = this.CustomerPartNameRelatedList.filter(arr => arr.isActive === true && arr.partCategoryId === Id);
        for (let index = 0; index < Iddata.length; index++) {
          var obj = { 'id': Iddata[index].wordpressId, 'id1': Iddata[index].partCategoryId };
          this.RelatedNewArray1.push(obj);

        }

        this.loader = false;
      }
    )
  }
  Getdata1Crimp(Id, rowIndex, name) {
    this.loader = true;
    this.partService.GetAllCustomerPartNameByCompanyId(this.CompanyValue, Id).subscribe(
      (data: any) => {
        switch (data.statusCode) {
          case 200:
            this.data = data;
            this.data.dataList.forEach(e => {
              this.CustomerPartNameCrimpList.push(e);
            });
            this.loader = false;
            break;
          case 404:
            $('#wrong_mssg').show();
            this.loader = false;
            break;
          case 400:
            this.noproduct = "No Company Part Name Found in '" + name + "' Part Category";
            $('#noproduct').show();
            const index: number = this.CrimpNewArray.indexOf(Id);
            this.CrimpNewArray.splice(index, 1);
            this.loader = false;
            this.WordpressCategoryCrimpToolsList[rowIndex].IsChecked = false;

            break;
          default:
            $('#wrong_mssg').show();
            this.loader = false;
        }
      }
    )
  }
  GetdataRelatedItem(Id, rowIndex, name) {
    this.loader = true;
    this.partService.GetAllCustomerPartNameByCompanyId(this.CompanyValue, Id).subscribe(
      (data: any) => {
        switch (data.statusCode) {
          case 200:
            this.data = data;
            this.data.dataList.forEach(e => {
              this.CustomerPartNameRelatedList.push(e);
            });
            this.loader = false;
            break;
          case 404:
            $('#wrong_mssg').show();
            this.loader = false;
            break;
          case 400:
            this.noproduct = "No Company Part Name Found in '" + name + "' Part Category";
            $('#noproduct').show();
            const index: number = this.RelatedNewArray.indexOf(Id);
            this.RelatedNewArray.splice(index, 1);
            this.loader = false;
            this.WordpressCategoryRelatedItemList[rowIndex].IsChecked = false;

            break;
          default:
            $('#wrong_mssg').show();
            this.loader = false;
        }
      }
    )
  }

  noprod() {
    $('#noproduct').hide();
  }

  chngIsInv(event, rowIndex, id, name) {
    if (event == true) {

      if (this.NewArray.length <= 3) {
        this.NewArray.push(id);
        this.WordpressCategoryList[rowIndex].IsChecked = true;
        this.Getdata1(id, rowIndex, name);
      }
      else {
        //this.toastr.error('Only 4 items Could be Selected in Mating Connectors');
        $('#add_Items').show();
        this.MainProp = "Only 4 part category could be selected in Mating Connectors";
        return false;
      }
    }
    else {
      const index: number = this.NewArray.indexOf(id);
      this.NewArray.splice(index, 1);

      if (this.CheckId == 0) {
        this.CustomerPartNameList = this.CustomerPartNameList.filter(item => !(item.partCategoryId === id));
      }
      else {
        const list = this.CustomerPartNameList.filter(item => (item.partCategoryId === id));
        this.MatingDisableList = list.filter(x => x.isActive == true);
        this.CustomerPartNameList = this.CustomerPartNameList.filter(item => !(item.partCategoryId === id));
      }
      this.NewArray1 = this.NewArray1.filter(item => !(item.id1 === id));
      this.WordpressCategoryList[rowIndex].IsChecked = false;
      this.CustomeList = [];
    }
  }
  chngIsInvCrimp(event, rowIndex, id, name) {
    if (event == true) {
      if (this.CrimpNewArray.length <= 3) {
        this.CrimpNewArray.push(id);
        this.WordpressCategoryCrimpToolsList[rowIndex].IsChecked = true;
        this.Getdata1Crimp(id, rowIndex, name);
      }
      else {
        $('#add_Items').show();
        this.MainProp = "Only 4 part category could be selected in Crimp Tools";
        return false;
      }
    }
    else {
      const index: number = this.CrimpNewArray.indexOf(id);
      this.CrimpNewArray.splice(index, 1);

      if (this.CheckId == 0) {
        this.CustomerPartNameCrimpList = this.CustomerPartNameCrimpList.filter(item => !(item.partCategoryId === id));
      }
      else {
        const list = this.CustomerPartNameCrimpList.filter(item => (item.partCategoryId === id));
        this.CrimpDisableList = list.filter(x => x.isActive == true);
        this.CustomerPartNameCrimpList = this.CustomerPartNameCrimpList.filter(item => !(item.partCategoryId === id));
      }

      this.CrimpNewArray1 = this.CrimpNewArray1.filter(item => !(item.id1 === id));
      this.WordpressCategoryCrimpToolsList[rowIndex].IsChecked = false;
    }
  }
  chngIsInvRelated(event, rowIndex, id, name) {
    if (event == true) {
      if (this.RelatedNewArray.length <= 3) {
        this.RelatedNewArray.push(id);
        this.WordpressCategoryRelatedItemList[rowIndex].IsChecked = true;
        this.GetdataRelatedItem(id, rowIndex, name);
      }
      else {
        $('#add_Items').show();
        this.MainProp = "Only 4 part category could be selected in Related Items";
        return false;
      }
    }
    else {
      const index: number = this.RelatedNewArray.indexOf(id);
      this.RelatedNewArray.splice(index, 1);
      if (this.CheckId == 0) {
        this.CustomerPartNameRelatedList = this.CustomerPartNameRelatedList.filter(item => !(item.partCategoryId === id));
      }
      else {
        const list = this.CustomerPartNameRelatedList.filter(item => (item.partCategoryId === id));
        this.RelatedDisableList = list.filter(x => x.isActive == true);
        this.CustomerPartNameRelatedList = this.CustomerPartNameRelatedList.filter(item => !(item.partCategoryId === id));
      }

      this.RelatedNewArray1 = this.RelatedNewArray1.filter(item => !(item.id1 === id));
      this.WordpressCategoryRelatedItemList[rowIndex].IsChecked = false;
    }
  }
  chngIsInv1(event, rowIndex, id, id1) {
    if (event == true) {

      if (this.NewArray1.length <= 3) {
        var obj = { 'id': id, 'id1': id1 };
        this.NewArray1.push(obj);
        this.CustomerPartNameList[rowIndex].IsChecked = true;

      }
      else {
        $("#add_Items").show();
        this.MainProp = "Only 4 customer part name could be selected in Mating Connectors";
        return false;
      }
    }
    else {
      const index: number = this.NewArray1.indexOf(id);
      this.NewArray1.splice(index, 1);
      if (this.CheckId == 0) {
        this.CustomerPartNameList[rowIndex].IsChecked = false;
      }
      else {
        this.CustomerPartNameList[rowIndex].IsChecked = false;
        this.CustomerPartNameList[rowIndex].isActive = false;
      }

    }
  }

  chngIsInv1Crimp(event, rowIndex, id, id1) {
    if (event == true) {
      if (this.CrimpNewArray1.length <= 3) {
        var obj = { 'id': id, 'id1': id1 };
        this.CrimpNewArray1.push(obj);
        this.CustomerPartNameCrimpList[rowIndex].IsChecked = true;
      }
      else {
        $("#add_Items").show();
        this.MainProp = "Only 4 customer part name could be selected in Crimp Tools";
        return false;
      }
    }
    else {
      const index: number = this.CrimpNewArray1.indexOf(id);
      this.CrimpNewArray1.splice(index, 1);
      if (this.CheckId == 0) {
        this.CustomerPartNameCrimpList[rowIndex].IsChecked = false;
      }
      else {
        this.CustomerPartNameCrimpList[rowIndex].IsChecked = false;
        this.CustomerPartNameCrimpList[rowIndex].isActive = false;
      }

    }
  }

  chngIsInv1Related(event, rowIndex, id, id1) {
    if (event == true) {
      if (this.RelatedNewArray1.length <= 3) {
        var obj = { 'id': id, 'id1': id1 };
        this.RelatedNewArray1.push(obj);
        this.CustomerPartNameRelatedList[rowIndex].IsChecked = true;

      }
      else {
        $("#add_Items").show();
        this.MainProp = "Only 4 customer part name could be selected in Related Items";
        return false;
      }
    }
    else {
      const index: number = this.RelatedNewArray1.indexOf(id);
      this.RelatedNewArray1.splice(index, 1);
      if (this.CheckId == 0) {
        this.CustomerPartNameRelatedList[rowIndex].IsChecked = false;
      }
      else {
        this.CustomerPartNameRelatedList[rowIndex].isActive = false;
        this.CustomerPartNameRelatedList[rowIndex].IsChecked = false;
      }

    }
  }

  async openModal() {
    if (this.CheckId == 0) {
      this.loader = true;
      this.Getdata(this.CompanyValue, '');
    }
    else {
      this.loader = true;
      this.IsOpen = true;
      this.Getdata(this.CompanyValue, 'ok');

    }
  }

  GetCategory(id: any, name: string) {
    this.partService.GetCategoryDetails(id).subscribe(
      (data: any) => {
        const datas = data;

        const apiWordpress = data.dataList[0].apiWordpressProduct.filter(x => x.type == 'Mating' && x.isChecked == true);
        if (apiWordpress.length > 0) {
          apiWordpress.forEach(e => {
            if (this.WordpressCategoryList.length > 0) {
              this.WordpressCategoryList.filter(f => f.id == e.categoryId)[0].IsChecked = true;
              this.NewArray.push(e.categoryId);
              this.GetdataLoad(e.categoryId, apiWordpress, id, datas);
            }

          });
        }
        else {
          this.GetdataLoad(id, apiWordpress, id, datas);
        }
      });
    this.loader = false;
  }
  closeModal() {
    $("#CheckCategoryModal").hide();
  }


  changeDimentions(e) {
    this.DimentionTypeL = Number(e.target.value);
    this.DimentionTypeW = Number(e.target.value);
    this.DimentionTypeH = Number(e.target.value);
  }

  changeMargin(event: any) {

    if (event.target.checked == true) {
      this.IsMarginDisabled = false;
      this.IsPricePart = true;

      var calcMargin = 1 + Number(this.MarkupMargin);
      this.cost = Number.isNaN(Number(this.VendorPartCost * calcMargin)) ? 0 : Number(this.VendorPartCost * calcMargin).toFixed(5);

      if (Number.isNaN(this.cost) || this.cost == "NaN") {
        this.cost = 0;
      }
    }
    else {
      this.IsMarginDisabled = true;
      this.IsPricePart = false;
      this.cost = '';

    }
    this.formatCurrencymargin();
  }

  changeNonInventory(event: any) {
    const invListing = this.InvList.filter(x => x.InvType != 6 && x.InvType != 7);
    if (event.target.checked == true) {
      if (this.id != undefined && !Number.isNaN(this.id)) {
        if (invListing.length == 0) {
          this.NonInventory = true;
        }
        else {
          $('#InventoryExist').show();
          this.NonInventory = false;
          event.target.checked = false;
        }
      }
      else {
        if (invListing.length == 0) {
          this.NonInventory = true;
        }
        else {
          $('#InventoryExist').show();
          this.NonInventory = false;
          event.target.checked = false;

        }
      }
      let count = 0;
      const catList = this.CategoryArr.filter(x => x.isActive == true);
      for (let i = 0; i < catList.length; i++) {
        var str = String(catList[i].partcategoryid);
        var includeStr = "-";
        var wid;
        if (str.includes(includeStr)) {
          var spilted = catList[i].partcategoryid.split('-');
          wid = spilted[1];
          var pid = spilted[0];
        }
        else {
          wid = catList[i].partWordpressid;
          var pid = catList[i].partcategoryid;
        }
        if (wid != 0 && wid != null) {
          count = count + 1;
        }

      }
      if (count > 0) {
        if (event.target.checked == false && this.NotActive == false) {
          this.CheckCategory = true;
          this.CheckCategoryDisable = false;
        }
        else {
          this.CheckCategory = false;
          this.CheckCategoryDisable = true;
        }
      }
      else {
        this.CheckCategory = false;
        this.CheckCategoryDisable = false;
      }


    }
    else {
      if (this.id != undefined && !Number.isNaN(this.id)) {
        if (this.SaveInvList.length > 0) {
          this.InvList = this.SaveInvList;
          this.TotalAllType = this.SaveTotalAllType;
          this.TotalTypeInventory = this.SaveTotalTypeInventory;
          this.TotalInvLog = this.SaveTotalInvLog;
          //  this.logList = this.SavelogList;
          this.isTotalInvQty = true;
        }

        if (this.InvList.length > 0) {
          this.isInvFind = true;
          this.viewLog = false;
        }

      }
      this.NonInventory = false;
      let count = 0;
      const catList = this.CategoryArr.filter(x => x.isActive == true);
      for (let i = 0; i < catList.length; i++) {
        var str = String(catList[i].partcategoryid);
        var includeStr = "-";
        var wid;
        if (str.includes(includeStr)) {
          var spilted = catList[i].partcategoryid.split('-');
          wid = spilted[1];
          var pid = spilted[0];
        }
        else {
          wid = catList[i].partWordpressid;
          var pid = catList[i].partcategoryid;
        }
        if (wid != 0 && wid != null) {
          count = count + 1;
        }

      }
      if (count > 0) {
        if (event.target.checked == false && this.NotActive == false) {
          this.CheckCategory = true;
          this.CheckCategoryDisable = false;
        }
        else {
          this.CheckCategory = false;
          this.CheckCategoryDisable = true;
        }
      }
      else {
        this.CheckCategory = false;
        this.CheckCategoryDisable = false;
      }


    }
  }

  InventoryExist() {
    $('#InventoryExist').hide();
  }


  RemoveInventory() {
    if (this.id != undefined && !Number.isNaN(this.id)) {
      this.SaveInvList = this.InvList;
      this.SaveTotalAllType = this.TotalAllType;
      this.SaveTotalTypeInventory = this.TotalTypeInventory;
      this.SaveTotalInvLog = this.TotalInvLog;
      const invListing = this.InvList.filter(x => x.InvType == 6 || x.InvType == 7);
      this.InvList = invListing;
      if (invListing.length == 0) {
        this.viewLog = false;
        this.TotalAllType = 0;
        this.TotalTypeInventory = 0;
        this.TotalInvLog = 0;
        this.isTotalInvQty = false;
        this.isInvFind = false;
      }
      else {
        this.TotalAllType = 0;
        this.TotalTypeInventory = 0;
        this.TotalInvLog = 0;
        this.viewLog = false;
        this.isTotalInvQty = true;
        this.isInvFind = true;
        invListing.forEach(element => {

          this.TotalInvLog = this.TotalInvLog + element.InvQty;
          this.TotalAllType = this.TotalAllType + element.InvQty;
        });
      }
      $('#InventoryExist').hide();
      $('#InventoryRemove').show();
      this.NonInventory = true;

    }
    else {
      const invListing = this.InvList.filter(x => x.InvType != 6 || x.InvType != 7);
      this.InvList = invListing;
      if (invListing.length == 0) {
        this.viewLog = false;
        this.isInvFind = false;
        this.TotalAllType = 0;
        this.TotalTypeInventory = 0;
        this.TotalInvLog = 0;
        this.isTotalInvQty = false;
        this.isInvFind = false;
      }
      $('#InventoryExist').hide();
      $('#InventoryRemove').show();
      this.NonInventory = true;
    }

    let count = 0;
    const catList = this.CategoryArr.filter(x => x.isActive == true);
    for (let i = 0; i < catList.length; i++) {
      var str = String(catList[i].partcategoryid);
      var includeStr = "-";
      var wid;
      if (str.includes(includeStr)) {
        var spilted = catList[i].partcategoryid.split('-');
        wid = spilted[1];
        var pid = spilted[0];
      }
      else {
        wid = catList[i].partWordpressid;
        var pid = catList[i].partcategoryid;
      }
      if (wid != 0 && wid != null) {
        count = count + 1;
      }

    }
    if (count > 0) {
      if (this.NotActive == false) {
        if (this.NonInventory == true) {
          this.CheckCategory = false;
          this.CheckCategoryDisable = true;
        }
        else {
          this.CheckCategory = true;
          this.CheckCategoryDisable = false;
        }

      }
      else {
        this.CheckCategory = false;
        this.CheckCategoryDisable = true;
      }
    }
    else {
      this.CheckCategory = false;
      this.CheckCategoryDisable = false;
    }
  }
  InventoryRemove() {
    $('#InventoryRemove').hide();
  }

  changeActive(event: any) {
    let count = 0;
    const catList = this.CategoryArr.filter(x => x.isActive == true);
    for (let i = 0; i < catList.length; i++) {
      var str = String(catList[i].partcategoryid);
      var includeStr = "-";
      var wid;
      if (str.includes(includeStr)) {
        var spilted = catList[i].partcategoryid.split('-');
        wid = spilted[1];
        var pid = spilted[0];
      }
      else {
        wid = catList[i].partWordpressid;
        var pid = catList[i].partcategoryid;
      }
      if (wid != 0 && wid != null) {
        count = count + 1;
      }

    }
    if (count > 0) {
      if (event.target.checked == false && this.NonInventory == false) {
        this.CheckCategory = true;
        this.CheckCategoryDisable = false;
      }
      else {
        this.CheckCategory = false;
        this.CheckCategoryDisable = true;
      }
    }
    else {
      this.CheckCategory = false;
      this.CheckCategoryDisable = false;
    }
  }


  UpdatePrice() {

    if (this.MarkupCheck) {
      var calcMargin = 1 + Number(this.MarkupMargin);
      this.cost = Number(this.VendorPartCost * calcMargin).toFixed(5);
      if (this.cost == "NaN") {
        this.cost = 0;
      }
    }
    this.formatCurrencymargin();
  }
  UpdatePricePart() {

    if (this.PartMarkupCheck) {
      var calcMargin = 1 + Number(this.PartMarkupMargin);
      this.PPartPrice = Number(this.PVendorCost * calcMargin).toFixed(5);
      if (this.PPartPrice == "NaN") {
        this.PPartPrice = 0;
      }
    }
  }

  keyPressNumbers(event) {
    var character = String.fromCharCode(event.keyCode);
    if (this.MarkupMargin == undefined) {
      this.MarkupMargin = '';
    }
    var newValue = this.MarkupMargin + character;
    if (newValue != ".") {
      if (isNaN(Number(newValue)) || this.hasDecimalPlace(newValue, 4)) {
        event.preventDefault();
        return false;
      }
    }

  }
  keyPressPartNumbers(event) {
    var character = String.fromCharCode(event.keyCode);
    if (this.PartMarkupMargin == undefined) {
      this.PartMarkupMargin = '';
    }
    var newValue = this.PartMarkupMargin + character;
    if (newValue != ".") {
      if (isNaN(Number(newValue)) || this.hasDecimalPlace(newValue, 4)) {
        event.preventDefault();
        return false;
      }
    }

  }
  hasDecimalPlace(value, x) {
    var pointIndex = value.indexOf('.');
    return pointIndex >= 0 && pointIndex < value.length - x;
  }

  // openHistoryModal() {
  //   this.PartHistoryList = [];
  //   this.loader = true;
  //   this.partService.GetPartHistoryDetails(this.ManualPartId).subscribe(
  //     (data: any) => {
  //       data.dataList.forEach(e => {
  //         this.PartHistoryList.push(e);
  //       });
  //       this.loader = false;
  //       if (this.PartHistoryList.length == 0) {
  //         this.CheckHistoryList = false;
  //       }
  //       else {
  //         this.CheckHistoryList = true;
  //       }
  //       $('#history').show();
  //     });
  // }

  openVendorModal() {
    if (this.name == "" || this.name == undefined) {
      this.partNameProperty = "Please Enter List Part Name";
      $('#PartNameModel').show();
    }
    else {
      if (this.partNo == "" || this.partNo == undefined) {
        this.partNameProperty = "Please Enter List Part Number";
        $('#PartNameModel').show();
      }
      else {
        if (this.selectedVendorValue == "" || this.selectedVendorValue == undefined) {
          this.partNameProperty = "Please Select Primary Vendor";
          $('#PartNameModel').show();
        }
        else {
          this.PartName1 = this.name;
          this.PartNumber1 = this.partNo;
          var spilted = this.selectedVendorValue.split("-");
          const vendorValue = spilted[0];
          this.vendorlist2 = this.vendorlist1.filter(x => x.id != vendorValue);
          if (this.CategoryArr.length > 0) {
            this.partmodelVendorValue = '';
            this.PartMarkupMargin = this.MarkupMargin;
            this.PVendorCost = this.VendorPartCost;
            this.PPartPrice = this.cost;
            this.PartMarkupCheck = this.MarkupCheck;
            this.PartMarkupMargin = this.MarkupMargin;
            this.PVendorNumber = this.VendorPartNumber;
            this.PVendorPartName = this.VendorPartName;
            this.IsPartSave = true;
            this.IsPartUpdate = false;
            this.partvalidation = false;
            $('#vendorModel').show();
          }
          else {
            this.partNameProperty = "Please Select Part Category";
            $('#PartNameModel').show();
          }
        }

      }

    }

  }
  PartNameModel() {
    $('#PartNameModel').hide();
  }

  // historyclose() {
  //   $('#history').hide();
  // }


  vendorclose() {
    $('#vendorModel').hide();
  }

  WordpressCategoryLoad(id) {
    if (id == undefined) {
      id = 0;
    }
    this.partService.GetAllWordpressCategory(id).subscribe(
      (data: any) => {
        if (id > 0) {
          switch (data.statusCode) {
            case 200:
              this.data = data;
              this.WordpressCategoryPartialList = this.data.dataList;
              this.WordpressCategoryList = this.data.dataList.map(x => Object.assign({}, x));
              this.WordpressCategoryCrimpToolsList = this.data.dataList.map(x => Object.assign({}, x));
              this.WordpressCategoryRelatedItemList = this.data.dataList.map(x => Object.assign({}, x));
          }
        }
        else {
          this.WordpressCategoryPartialList = [];
          this.WordpressCategoryList = [];
          this.WordpressCategoryCrimpToolsList = [];
          this.WordpressCategoryRelatedItemList = [];
        }

      });

  }

  crossitem() {
    $('#add_Items').hide();
  }

  onItemSelect(item: any) {
    if (this.CategoryArr.length > 0) {
      const insertedData = this.CategoryArr.filter(x => x.partcategoryid == item.partcategoryid && x.IsActive == false);
      if (insertedData.length > 0) {
        insertedData[0].IsActive = true;
      }
      else {
        item.isActive = true;
        this.CategoryArr.push(item);
      }
    }
    else {
      item.isActive = true;
      this.CategoryArr.push(item);
    }
    const catList = this.CategoryArr.filter(x => x.isActive == true);
    let count = 0;
    for (let i = 0; i < catList.length; i++) {
      var str = String(catList[i].partcategoryid);
      var includeStr = "-";
      var wid;
      if (str.includes(includeStr)) {
        var spilted = catList[i].partcategoryid.split('-');
        wid = spilted[1];
        var pid = spilted[0];
      }
      else {
        wid = catList[i].partWordpressid;
        var pid = catList[i].partcategoryid;
      }
      if (wid != 0 && wid != null) {
        count = count + 1;
      }
    }

    if (count > 0) {
      if (this.NotActive == false && this.NonInventory == false) {
        this.CheckCategory = true;
        this.CheckCategoryDisable = false;
      }
      else {
        this.CheckCategory = false;
        this.CheckCategoryDisable = true;
      }
    }
    else {
      this.CheckCategory = false;
      this.CheckCategoryDisable = false;
    }
    const validCatList = this.CategoryArr.filter(x => x.isActive == true);
    if (validCatList.length == 1) {
      var category = validCatList[0].partcategoryid;
      var str = String(category);
      var includeStr = "-";
      var pids;
      if (str.includes(includeStr)) {
        var spilted1 = validCatList[0].partcategoryid.split('-');
        pids = Number(spilted1[0]);
      }
      else {
        pids = Number(validCatList[0].partcategoryid);
      }
      this.sharedService.GetMarkup(this.CompanyValue, pids).subscribe(
        (data: any) => {
          if (data.dataList[0].markupMargin == null) {
            this.MarkupMargin = 0;
          }
          else {
            this.MarkupMargin = data.dataList[0].markupMargin;
          }

          if (this.MarkupCheck) {
            var calcMargin = 1 + Number(this.MarkupMargin);
            this.cost = Number(this.VendorPartCost * calcMargin).toFixed(5);
            if (this.cost == "NaN") {
              this.cost = 0;
            }
          }


        });
    }
    else {
      this.MarkupMargin = 0;
      if (this.MarkupCheck) {
        var calcMargin = 1 + Number(this.MarkupMargin);
        this.cost = Number(this.VendorPartCost * calcMargin).toFixed(5);
        if (this.cost == "NaN") {
          this.cost = 0;
        }
      }
    }
  }

  onItemDeSelect(item: any) {
    if (this.CategoryArr.length > 0) {
      this.CategoryArr.forEach((itemRemove, index) => {
        var str = String(item.partcategoryid);
        var includeStr = "-";
        var pids;
        var pids1;
        var str1 = String(itemRemove.partcategoryid);
        if (str1.includes(includeStr)) {
          var spilted1 = itemRemove.partcategoryid.split('-');
          pids1 = Number(spilted1[0]);
        }
        else {
          pids1 = itemRemove.partcategoryid;
        }
        if (str.includes(includeStr)) {
          var spilted = item.partcategoryid.split('-');
          pids = Number(spilted[0]);
        }
        else {
          pids = item.partcategoryid;
        }
        if (pids === pids1) {
          itemRemove.isActive = false;
        }
      });
      let count = 0;
      const catList = this.CategoryArr.filter(x => x.isActive == true);
      for (let i = 0; i < catList.length; i++) {
        var str = String(catList[i].partcategoryid);
        var includeStr = "-";
        var wid;
        if (str.includes(includeStr)) {
          var spilted = catList[i].partcategoryid.split('-');
          wid = spilted[1];
          var pid = spilted[0];
        }
        else {
          wid = catList[i].partWordpressid;
          var pid = catList[i].partcategoryid;
        }
        if (wid != 0 && wid != null) {
          count = count + 1;
        }

      }
      if (count > 0) {
        if (this.NotActive == false && this.NonInventory == false) {
          this.CheckCategory = true;
          this.CheckCategoryDisable = false;
        }
        else {
          this.CheckCategory = false;
          this.CheckCategoryDisable = true;
        }
      }
      else {
        this.CheckCategory = false;
        this.CheckCategoryDisable = false;
      }

      const validCatList = this.CategoryArr.filter(x => x.isActive == true);
      if (validCatList.length == 1) {
        var category = validCatList[0].partcategoryid;
        var str = String(category);
        var includeStr = "-";
        var pids;
        if (str.includes(includeStr)) {
          var spilted1 = validCatList[0].partcategoryid.split('-');
          pids = Number(spilted1[0]);
        }
        else {
          pids = Number(validCatList[0].partcategoryid);
        }
        this.sharedService.GetMarkup(this.CompanyValue, pids).subscribe(
          (data: any) => {
            if (data.dataList[0].markupMargin == null) {
              this.MarkupMargin = 0;
            }
            else {
              this.MarkupMargin = data.dataList[0].markupMargin;
            }

            if (this.MarkupCheck) {
              var calcMargin = 1 + Number(this.MarkupMargin);
              this.cost = Number(this.VendorPartCost * calcMargin).toFixed(5);
              if (this.cost == "NaN") {
                this.cost = 0;
              }
            }


          });
      }
      else {
        this.MarkupMargin = 0;
        if (this.MarkupCheck) {
          var calcMargin = 1 + Number(this.MarkupMargin);
          this.cost = Number(this.VendorPartCost * calcMargin).toFixed(5);
          if (this.cost == "NaN") {
            this.cost = 0;
          }
        }
      }

    }
  }

  onDeSelectAll(items: any) {
    if (this.CategoryArr.length > 0) {
      this.CategoryArr.forEach((itemInner, index) => {
        itemInner.isActive = false;
        this.CheckCategory = false;
        this.CheckCategoryDisable = false;
      });
      this.MarkupMargin = 0;
    }
  }
  onSelectAll(items: any) {
    let oldCategoryArr = items;
    this.CategoryArr = [];
    items.forEach((itemInner, index) => {
      if (oldCategoryArr.length > 0) {
        itemInner.Id = oldCategoryArr[index].Id;
      }
      itemInner.isActive = true;
      this.CategoryArr.push(itemInner);
    });
    let count = 0;
    const catList = this.CategoryArr.filter(x => x.isActive == true);
    for (let i = 0; i < catList.length; i++) {
      var str = String(catList[i].partcategoryid);
      var includeStr = "-";
      var wid;
      if (str.includes(includeStr)) {
        var spilted = catList[i].partcategoryid.split('-');
        wid = spilted[1];
        var pid = spilted[0];
      }
      else {
        wid = catList[i].partWordpressid;
        var pid = catList[i].partcategoryid;
      }
      if (wid != 0 && wid != null) {
        count = count + 1;
      }

    }

    if (count > 0) {
      if (this.NotActive == false && this.NonInventory == false) {
        this.CheckCategory = true;
        this.CheckCategoryDisable = false;
      }
      else {
        this.CheckCategory = false;
        this.CheckCategoryDisable = true;
      }
    }
    else {
      this.CheckCategory = false;
      this.CheckCategoryDisable = false;
    }
    this.MarkupMargin = 0;
    if (this.MarkupCheck) {
      var calcMargin = 1 + Number(this.MarkupMargin);
      this.cost = Number(this.VendorPartCost * calcMargin).toFixed(5);
      if (this.cost == "NaN") {
        this.cost = 0;
      }
    }
  }

  partChangeMargin(event: any) {

    if (event.target.checked == true) {
      this.IsPartMarginDisabled = false;
      this.IsPPricePart = true;
      var calcMargin = 1 + Number(this.PartMarkupMargin);
      this.PPartPrice = Number.isNaN(Number(this.PVendorCost * calcMargin)) ? 0 : Number(this.PVendorCost * calcMargin).toFixed(5);
      if (Number.isNaN(this.PPartPrice) || this.PPartPrice == "NaN") {
        this.PPartPrice = 0;
      }
    }
    else {
      this.IsPartMarginDisabled = true;
      this.IsPPricePart = false;
      this.PPartPrice = Number('');
    }
  }
  DisablePrice() {
    if (this.MarkupCheck == true) {
      const trueValue = this.MarkupMargin;
      if (trueValue == undefined) {
        $('#DisableModel').show();
      }

    }

  }

  DisableModel() {
    $('#DisableModel').hide();
  }

  GetVendorNameById(vId) {
    const vData = this.partVendorsList.filter(x => x.Id == vId);
    if (vData.length > 0) {
      return vData[0].name;
    }
    else {
      return '';
    }
  }

  GetLocName(lid) {
    var num = parseInt(lid);
    if (this.locationNameList != null) {
      const vData = this.globalLocationNameList.filter(x => x.id == num);
      if (vData.length > 0) {
        return vData[0].locationName;
      }
      else {
        return '';
      }
    }

  }

  GetLocName1(lid) {
    var num = parseInt(lid);
    if (this.newlocationLevelList != null) {
      const vData = this.newlocationLevelList.filter(x => x.id == num);
      if (vData.length > 0) {
        return vData[0].levelsName;
      }
      else {
        return '';
      }
    }

  }


  GetPartVendors(cid, partid) {
    this.loader = true;
    this.sharedService.GetPartVendors(cid, partid).subscribe(
      data => {
        this.loader = false;
        this.data = data;
        this.partVendorsList = [];
        if (Number(cid) > 0) {
          const vendorDatas = this.data.dataList;
          vendorDatas.forEach(element => {
            var obj = {
              Id: element.id,
              name: element.name,
              type: element.defaultUI == null ? "2" : "1"

            }
            this.partVendorsList.push(obj);
          });

        }
        else {
          this.partVendorsList = null;
        }
      }
    )
  }

  InvProductOrderDropdownList1(cid: any, partId: any) {
    // Pass 1 For Product Order
    this.partService.PartInvOrderDropdownList1(1, cid, partId).subscribe(
      data => {
        this.data = data;
        this.InvProductOrderDDL = this.data.dataList;
        this.mInvProductOrderDDL = this.data.dataList;
      }
    );
  }


  InvPartOrderDropdownList1(cid: any, partId: any) {
    // Pass 2 For Part Order
    this.partService.PartInvOrderDropdownList1(2, cid, partId).subscribe(
      data => {
        this.data = data;
        this.InvPartOrderDDL = this.data.dataList;
        this.mInvPartOrderDDL = this.data.dataList;
      }
    );
  }
  // addRow() {
  //   this.dynamicArray.push({ 'Qty': '', 'DiscountPrice': '', 'cost': '', 'id': '0', 'isActive': true, 'isReadOnly': false, 'CreateDate': '', 'CreatedBy': '', 'IsDReadonly': false });
  // }
  deleteRow(index) {
    this.dynamicArray[index].isActive = false;

  }

  // costcalc1(id, val) {
  //   if (this.VendorPartCost != undefined) {
  //     this.dynamicArray[id].Qty = val;
  //     let qtystr = this.dynamicArray.map(x => Number(x.Qty)).join(",");
  //     let numArr = qtystr.split(',').map(Number);
  //     this.max = numArr.reduce((a, b) => Math.max(a, b));


  //     if (this.dynamicArray[id].Qty != "") {
  //       if (Number(this.dynamicArray[id].Qty) > 1) {
  //         if (Number(this.dynamicArray[id].Qty) >= this.max) {
  //           if (this.dynamicArray[id].cost != "") {
  //             const val = this.dynamicArray[id];
  //             if (this.hasDecimalPlace(val.DiscountPrice, 1)) {
  //               this.dynamicArray[id].isReadOnly = true;
  //               this.dynamicArray[id].IsDReadonly = false;
  //             }
  //             else {
  //               this.dynamicArray[id].isReadOnly = true;
  //               this.dynamicArray[id].IsDReadonly = false;

  //             }
  //           }
  //           else {
  //             this.dynamicArray[id].cost = '';
  //           }
  //         }
  //         else {
  //           this.DiscountModel = "Please enter greater than of previous quantity.";
  //           $('#DiscountModel').show();
  //           this.dynamicArray[id].Qty = " ";

  //         }
  //       }
  //       else {
  //         this.DiscountModel = "Please enter more than one quantity";
  //         $('#DiscountModel').show();
  //       }

  //     }
  //     else {
  //       this.DiscountModel = "Please Enter Qty1";
  //       $('#DiscountModel').show();
  //     }

  //   }
  //   else {
  //     this.DiscountModel = "Please Enter Vendor Part Cost";
  //     this.dynamicArray[id].DiscountPrice = '';
  //     $('#DiscountModel').show();
  //   }
  // }

  // costcalc(id, vals) {
  //   if (this.VendorPartCost != undefined) {
  //     this.dynamicArray[id].DiscountPrice = vals;
  //     if (this.dynamicArray[id].Qty != "" && this.dynamicArray[id].Qty != undefined) {
  //       const val = this.dynamicArray[id];
  //       if (this.dynamicArray[id].DiscountPrice != "") {
  //         if (this.hasDecimalPlace(val.DiscountPrice, 1)) {
  //           const value = (parseFloat(val.DiscountPrice) * this.VendorPartCost).toFixed(2);
  //           const stringvDiscperPart = (parseFloat(this.VendorPartCost) - parseFloat(value)).toFixed(2);
  //           this.dynamicArray[id].cost = stringvDiscperPart;
  //           this.dynamicArray[id].isReadOnly = false;
  //           this.dynamicArray[id].IsDReadonly = false;
  //         }
  //         else {
  //           const disVlaue = parseFloat(val.DiscountPrice) / 100;
  //           const value = (disVlaue * this.VendorPartCost).toFixed(2);
  //           const stringvDiscperPart = (parseFloat(this.VendorPartCost) - parseFloat(value)).toFixed(2);
  //           this.dynamicArray[id].cost = stringvDiscperPart;
  //           this.dynamicArray[id].isReadOnly = false;
  //           this.dynamicArray[id].IsDReadonly = false;
  //         }
  //       }
  //       else {
  //         this.dynamicArray[id].cost = '';
  //       }

  //     }

  //     else {
  //       this.DiscountModel = "Please Enter Qty2";
  //       this.dynamicArray[id].DiscountPrice = '';
  //       $('#DiscountModel').show();
  //     }

  //   }
  //   else {
  //     this.DiscountModel = "Please Enter Vendor Part Cost";
  //     $('#DiscountModel').show();
  //   }

  // }


  // priceChanged(id, vals) {
  //   if (this.VendorPartCost != undefined) {
  //     this.dynamicArray[id].cost = vals;
  //     this.dynamicArray[id].id = id;
  //     if (this.dynamicArray[id].Qty != "") {
  //       //this.dynamicArray[id].cost = this.dynamicArray[id].cost + '.00';
  //       if (Number(this.dynamicArray[id].cost) != (Number(this.dynamicArray[id].cost))) {
  //         this.dynamicArray[id].DiscountPrice = '';
  //       }
  //       else {
  //         this.dynamicArray[id].cost = String(vals);
  //         this.dynamicArray[id].DiscountPrice = '';
  //       }
  //     }
  //     else {
  //       this.DiscountModel = "Please Enter Qty3";
  //       $('#DiscountModel').show();
  //     }
  //   }
  //   else {
  //     this.DiscountModel = "Please Enter Vendor Part Cost";
  //     this.dynamicArray[id].DiscountPrice = '';
  //     $('#DiscountModel').show();
  //   }
  // }

  CloseDiscountModel() {
    $('#DiscountModel').hide();

  }
  CloseDiscountModel1() {
    $('#DiscountModel1').hide();
  }

  addCustomerDiscount() {
    //this.dynamicCustomerDiscount.push({ 'Qty': '', 'DiscountPrice': '', 'cost': '', 'id': '0', 'isActive': true, 'CreateDate': '', 'CreatedBy': '', 'isReadOnly': false, 'IsDReadonly': false });
    this.dynamicCustomerDiscount.push({ 'minQty': '', 'Qty': '', 'DiscountType': '', 'DiscountPrice': '', 'cost': '', 'description': '', 'id': '0', 'isActive': true, 'CreateDate': '', 'CreatedBy': '', 'isReadOnly': false, 'IsDReadonly': false, 'wordPressId': '0' });
  }

  deleteCustomerDiscount(index) {
    // this.dynamicCustomerDiscount.splice(index, 1);
    this.dynamicCustomerDiscount[index].isActive = false;

  }

  CustomerCalc(id, vals) {
    if (this.cost != undefined && this.cost != 0) {
      this.dynamicCustomerDiscount[id].DiscountPrice = vals;
      // if (this.dynamicCustomerDiscount[id].Qty != "") {
      const val = this.dynamicCustomerDiscount[id];
      if (this.dynamicCustomerDiscount[id].DiscountPrice != "") {
        const discounttype = this.dynamicCustomerDiscount[id].DiscountType;
        if (discounttype == 'percentage') {
          if (this.hasDecimalPlace(val.DiscountPrice, 1)) {
            const value = ((parseFloat(val.DiscountPrice) * this.cost) / 100).toFixed(2);
            const stringvDiscperPart = (parseFloat(this.cost) - parseFloat(value)).toFixed(2);
            this.dynamicCustomerDiscount[id].cost = String(stringvDiscperPart);
            this.dynamicCustomerDiscount[id].isReadOnly = false;
            this.dynamicCustomerDiscount[id].IsDReadonly = false;
          }
          else {
            const disVlaue = parseFloat(val.DiscountPrice) / 100;
            const value = (disVlaue * this.cost).toFixed(2);
            const stringvDiscperPart = (parseFloat(this.cost) - parseFloat(value)).toFixed(2);
            this.dynamicCustomerDiscount[id].cost = String(stringvDiscperPart);
            this.dynamicCustomerDiscount[id].isReadOnly = false;
            this.dynamicCustomerDiscount[id].IsDReadonly = false;
          }
        } else if (discounttype == 'flat') {
          this.dynamicCustomerDiscount[id].cost = (Number(this.cost) - Number(vals)).toString();
        } else if (discounttype == 'fixed_price') {
          this.dynamicCustomerDiscount[id].cost = vals;
        } else {

        }
      }
      else {
        //this.dynamicArray[id].cost = '';
      }
      this.RemoveVolumePriceDiscount();
      //}
      // else {
      //   this.DiscountModel = "Please Enter Qty";
      //   $('#DiscountModel').show();
      // }
    }
    else {
      this.DiscountModel = "Please Enter List Part Price";
      $('#DiscountModel').show();
    }

  }
  RemoveVolumePriceDiscount() {
    this.dynamicCustomerDiscount.forEach(element => {
      if (element.DiscountPrice == "") {
        element.cost = '';
      }
    });
  }

  priceChangedCust(id, vals) {
    if (this.VendorPartCost != undefined) {
      this.dynamicCustomerDiscount[id].cost = vals;
      this.dynamicCustomerDiscount[id].id = id;
      //if (this.dynamicCustomerDiscount[id].Qty != "") {
      if (Number(this.dynamicCustomerDiscount[id].cost) != (Number(this.dynamicCustomerDiscount[id].cost))) {
        this.dynamicCustomerDiscount[id].DiscountPrice = '';
      }
      else {
        this.dynamicCustomerDiscount[id].cost = String(vals);
        this.dynamicCustomerDiscount[id].DiscountPrice = '';
      }
    }
    else {
      this.DiscountModel = "Please Enter List Part Price";
      this.dynamicCustomerDiscount[id].DiscountPrice = '';
      $('#DiscountModel').show();
    }

  }

  vpriceChangedCust(id, vals) {

    if (this.VendorPartCost != undefined) {
      this.dynamicArray[id].cost = vals;
      this.dynamicArray[id].id = id;
      //if (this.dynamicCustomerDiscount[id].Qty != "") {
      if (Number(this.dynamicArray[id].cost) != (Number(this.dynamicArray[id].cost))) {
        this.dynamicArray[id].DiscountPrice = '';
      }
      else {
        this.dynamicArray[id].cost = String(vals);
        this.dynamicArray[id].DiscountPrice = '';
      }
    }
    else {
      this.DiscountModel = "Please Enter Vendor Part Price";
      this.dynamicArray[id].DiscountPrice = '';
      $('#DiscountModel').show();
    }

  }


  CloseModel() {
    $('#SerialModel').hide();
  }
  SortString(name) {
    var newstr = name.substring(0, 24);
    var newStr1 = newstr + "....";
    return newStr1;

  }

  getAllCategories_search(companyId) {
    this.loader = true;
    this.sharedService.GetDropdownCategoryHierarchyModel(companyId).subscribe(
      data => {
        this.loader = false;
        this.data = data;
        this.programs = this.data.dataList;
      });
  }

  onParentCatChange(value: any): void {
    this.CategoryArr = value.value;
    let partcatarr = [];
    if (this.CategoryArr.length > 0) {
      this.CategoryArr.forEach(element => {
        const catList = this.arr.filter(x => x.id == element && x.wordpressId > 0);
        if (catList.length > 0) {
          partcatarr.push(catList)
        }
      });
    }
    if (partcatarr.length > 0) {
      this.CheckCategory = true;
      this.CheckCategoryDisable = false;
      partcatarr = [];
    }
    else {
      this.CheckCategory = false;
      this.CheckCategoryDisable = true;
    }
  }
  //ankit implementation

  addRow1() {
    if (this.dynamicCustomerDiscount.length > 0) {
      const prevMaxQty = this.dynamicCustomerDiscount[this.dynamicCustomerDiscount.length - 1].Qty;
      if (prevMaxQty != undefined && prevMaxQty != null && prevMaxQty != "") {
        const nextMinQty = (Number(prevMaxQty) + 1).toString();
        this.dynamicCustomerDiscount.push({ 'minQty': nextMinQty, 'Qty': '', 'DiscountType': '', 'DiscountPrice': '', 'cost': '', 'description': '', 'id': '0', 'isActive': true, 'CreateDate': '', 'CreatedBy': '', 'isReadOnly': false, 'IsDReadonly': false, 'wordPressId': '0' });
      } else {
        this.dynamicCustomerDiscount.push({ 'minQty': '', 'Qty': '', 'DiscountType': '', 'DiscountPrice': '', 'cost': '', 'description': '', 'id': '0', 'isActive': true, 'CreateDate': '', 'CreatedBy': '', 'isReadOnly': false, 'IsDReadonly': false, 'wordPressId': '0' });
      }
    } else {
      this.dynamicCustomerDiscount.push({ 'minQty': '', 'Qty': '', 'DiscountType': '', 'DiscountPrice': '', 'cost': '', 'description': '', 'id': '0', 'isActive': true, 'CreateDate': '', 'CreatedBy': '', 'isReadOnly': false, 'IsDReadonly': false, 'wordPressId': '0' });
    }
  }

  addvRow1() {
    if (this.dynamicArray.length > 0) {
      const prevMaxQty = this.dynamicArray[this.dynamicArray.length - 1].Qty;
      if (prevMaxQty != undefined && prevMaxQty != null && prevMaxQty != "") {
        const nextMinQty = (Number(prevMaxQty) + 1).toString();
        this.dynamicArray.push({ 'minQty': nextMinQty, 'Qty': '', 'DiscountType': '', 'DiscountPrice': '', 'cost': '', 'description': '', 'id': '0', 'isActive': true, 'CreateDate': '', 'CreatedBy': '', 'isReadOnly': false, 'IsDReadonly': false });
      } else {
        this.dynamicArray.push({ 'minQty': '', 'Qty': '', 'DiscountType': '', 'DiscountPrice': '', 'cost': '', 'description': '', 'id': '0', 'isActive': true, 'CreateDate': '', 'CreatedBy': '', 'isReadOnly': false, 'IsDReadonly': false });
      }
    } else {
      this.dynamicArray.push({ 'minQty': '', 'Qty': '', 'DiscountType': '', 'DiscountPrice': '', 'cost': '', 'description': '', 'id': '0', 'isActive': true, 'CreateDate': '', 'CreatedBy': '', 'isReadOnly': false, 'IsDReadonly': false });
    }
  }

  deleteRow1(index) {
    this.dynamicCustomerDiscount[index].isActive = false;
  }

  addRow2() {
    this.dynamicCustomerRoleDiscount.push({ 'DiscountType': '', 'DiscountValue': '', 'CustomerType': '', 'CustomerValue': '', 'cost': '', 'description': '', 'id': '0', 'isActive': true, 'CreateDate': '', 'CreatedBy': '', 'isReadOnly': false, 'IsDReadonly': false, 'wordPressId': '0' });
  }
  deleteRow2(index) {
    this.dynamicCustomerRoleDiscount[index].isActive = false;
  }

  //min quantity calculation
  CustomerCalc3(id, val) {
    const listPrice = this.cost;
    const minQty = val;

    if (listPrice != undefined && listPrice != null && listPrice != "") {
      if (Number(minQty) > 0) {
        this.dynamicCustomerDiscount[id].minQty = minQty;
      } else {
        this.dynamicCustomerDiscount = [];
        this.DiscountModel = "Minimum Quantity should greater than 0";
        $('#DiscountModel').show();
      }
    } else {
      this.dynamicCustomerDiscount = [];
      this.DiscountModel = "Please Enter List Part Price";
      $('#DiscountModel').show();
    }
  }

  VendorCalc3(id, val) {
    const vendorPrice = this.VendorPartCost;
    const minQty = val;

    if (vendorPrice != undefined && vendorPrice != null && vendorPrice != "") {
      if (Number(minQty) > 0) {
        this.dynamicArray[id].minQty = minQty;
      } else {
        this.dynamicArray = [];
        this.DiscountModel = "Minimum Quantity should greater than 0";
        $('#DiscountModel').show();
      }
    } else {
      this.dynamicArray = [];
      this.DiscountModel = "Please Enter Vendor Part Price";
      $('#DiscountModel').show();
    }
  }

  CustomerCalc1(id, val) {
    const listPrice = this.cost;
    const maxQty = val;
    const minQty = this.dynamicCustomerDiscount[id].minQty;
    if (listPrice != undefined && listPrice != null) {
      if (minQty != undefined && minQty != null && minQty != '') {
        //if(Number(maxQty)>Number(minQty))
        if (Number(maxQty) != Number(minQty)) {
          this.dynamicCustomerDiscount[id].Qty = maxQty;
        } else if (maxQty == '') {
          this.dynamicCustomerDiscount[id].Qty = maxQty;
        } else {
          //this.deleteRow1(id);
          this.dynamicCustomerDiscount = [];
          this.DiscountModel = "Maximum Quantity should greater than Minimum Quantity";
          $('#DiscountModel').show();
        }
      } else {
        //this.deleteRow1(id);
        this.dynamicCustomerDiscount = [];
        this.DiscountModel = "Please Enter Minimum Quantity first";
        $('#DiscountModel').show();
      }
    }
    else {
      this.dynamicCustomerDiscount = [];
      this.DiscountModel = "Please Enter List Part Price";
      $('#DiscountModel').show();
    }
  }

  VendorCalc1(id, val) {
    const listPrice = this.VendorPartCost;
    const maxQty = val;
    const minQty = this.dynamicArray[id].minQty;
    if (listPrice != undefined && listPrice != null) {
      if (minQty != undefined && minQty != null && minQty != '') {
        if (Number(maxQty) != Number(minQty)) {
          this.dynamicArray[id].Qty = maxQty;
        } else if (maxQty == '') {
          this.dynamicArray[id].Qty = maxQty;
        } else {
          this.dynamicArray = [];
          this.DiscountModel = "Maximum Quantity should greater than Minimum Quantity";
          $('#DiscountModel').show();
        }
      } else {
        this.dynamicArray = [];
        this.DiscountModel = "Please Enter Minimum Quantity first";
        $('#DiscountModel').show();
      }
    }
    else {
      this.dynamicArray = [];
      this.DiscountModel = "Please Enter Vendor Part Price";
      $('#DiscountModel').show();
    }
  }

  descriptionChangedCust(id, val) {
    this.dynamicCustomerDiscount[id].description = val;
  }

  onDiscountTypeChange(id, val) {
    this.dynamicCustomerDiscount[id].DiscountType = val;
    const discount = this.dynamicCustomerDiscount[id].DiscountPrice;
    if (this.cost != null && this.cost != '' && this.cost != undefined) {
      if (discount != null && discount != '' && discount != undefined) {
        if (val == 'percentage') {
          this.CustomerCalc(id, discount);
        } else if (val == 'flat') {
          this.dynamicCustomerDiscount[id].cost = (parseFloat(this.cost) - parseFloat(discount)).toFixed(2);
        } else {
          this.dynamicCustomerDiscount[id].cost = discount;
        }
      }
    } else {
      this.dynamicCustomerDiscount = [];
      this.DiscountModel = "Please Enter List Part Price";
      $('#DiscountModel').show();
    }
  }

  onVDiscountTypeChange(id, val) {

    const vendorPrice = this.VendorPartCost;
    this.dynamicArray[id].DiscountType = val;
    const discount = this.dynamicArray[id].DiscountPrice;
    if (vendorPrice != null && vendorPrice != '' && vendorPrice != undefined) {
      if (discount != null && discount != '' && discount != undefined) {
        if (val == 'percentage') {
          this.VendorCalc(id, discount);
        } else if (val == 'flat') {
          this.dynamicArray[id].cost = (parseFloat(vendorPrice) - parseFloat(discount)).toFixed(2);
        } else {
          this.dynamicArray[id].cost = discount;
        }
      }
    } else {
      this.dynamicArray = [];
      this.DiscountModel = "Please Enter Vendor Part Price";
      $('#DiscountModel').show();
    }
  }

  VendorCalc(id, vals) {
    if (this.VendorPartCost != undefined && this.VendorPartCost != 0) {
      this.dynamicArray[id].DiscountPrice = vals;
      // if (this.dynamicArray[id].Qty != "") {
      const val = this.dynamicArray[id];
      if (this.dynamicArray[id].DiscountPrice != "") {
        const discounttype = this.dynamicArray[id].DiscountType;
        if (discounttype == 'percentage') {
          if (this.hasDecimalPlace(val.DiscountPrice, 1)) {
            const value = ((parseFloat(val.DiscountPrice) * this.VendorPartCost) / 100).toFixed(2);
            const stringvDiscperPart = (parseFloat(this.VendorPartCost) - parseFloat(value)).toFixed(2);
            this.dynamicArray[id].cost = String(stringvDiscperPart);
            this.dynamicArray[id].isReadOnly = false;
            this.dynamicArray[id].IsDReadonly = false;
          }
          else {
            const disVlaue = parseFloat(val.DiscountPrice) / 100;
            const value = (disVlaue * this.VendorPartCost).toFixed(2);
            const stringvDiscperPart = (parseFloat(this.VendorPartCost) - parseFloat(value)).toFixed(2);
            this.dynamicArray[id].cost = String(stringvDiscperPart);
            this.dynamicArray[id].isReadOnly = false;
            this.dynamicArray[id].IsDReadonly = false;
          }
        } else if (discounttype == 'flat') {
          this.dynamicArray[id].cost = (Number(this.VendorPartCost) - Number(vals)).toString();
        } else if (discounttype == 'fixed_price') {
          this.dynamicArray[id].cost = vals;
        } else {

        }
      }
      else {
        //this.dynamicArray[id].cost = '';
      }
      this.RemoveVolumePriceDiscount();
      //}
      // else {
      //   this.DiscountModel = "Please Enter Qty";
      //   $('#DiscountModel').show();
      // }
    }
    else {
      this.DiscountModel = "Please Enter List Part Price";
      $('#DiscountModel').show();
    }

  }

  VendorDescription(id, val) {
    this.dynamicArray[id].description = val;
  }

  RoleDiscountType(id, val) {
    this.dynamicCustomerRoleDiscount[id].DiscountType = val;
    const discount = this.dynamicCustomerRoleDiscount[id].DiscountValue;
    if (discount != null && discount != undefined && discount != '') {
      if (this.cost != null && this.cost != undefined && this.cost != '') {
        if (val != null && val != undefined && val != '') {
          if (val == '1') {
            this.dynamicCustomerRoleDiscount[id].cost = (parseFloat(this.cost) - ((parseFloat(this.cost) * parseFloat(discount)) / 100)).toFixed(2);
          } else if (val == '2') {
            this.dynamicCustomerRoleDiscount[id].cost = (parseFloat(this.cost) - parseFloat(discount)).toString();
          } else if (val == '3') {
            this.dynamicCustomerRoleDiscount[id].cost = discount;
          } else {
            this.dynamicCustomerRoleDiscount[id].cost = '';
          }
        }
      } else {
        this.dynamicCustomerDiscount = [];
        this.DiscountModel = "Please Enter List Part Price";
        $('#DiscountModel').show();
      }
    }
  }
  RoleDiscountValue(id, val) {
    this.dynamicCustomerRoleDiscount[id].DiscountValue = val;
    const DiscountType = this.dynamicCustomerRoleDiscount[id].DiscountType;
    if (this.cost != null && this.cost != undefined && this.cost != '') {
      if (DiscountType != null && DiscountType != undefined && DiscountType != '') {
        if (DiscountType == '1') {
          this.dynamicCustomerRoleDiscount[id].cost = (parseFloat(this.cost) - ((parseFloat(this.cost) * parseFloat(val)) / 100)).toFixed(2);
        } else if (DiscountType == '2') {
          this.dynamicCustomerRoleDiscount[id].cost = (parseFloat(this.cost) - parseFloat(val)).toFixed(2);
        } else if (DiscountType == '3') {
          this.dynamicCustomerRoleDiscount[id].cost = val;
        } else {
          this.dynamicCustomerRoleDiscount[id].cost = '';
        }
      }
    } else {
      this.dynamicCustomerDiscount = [];
      this.DiscountModel = "Please Enter List Part Price";
      $('#DiscountModel').show();
    }
  }

  RoleCustomerType(id, val) {
    this.dynamicCustomerRoleDiscount[id].CustomerType = val;
    if (this.CompanyValue != null && this.CompanyValue != undefined && this.CompanyValue != '' && this.CompanyValue != 0) {

    } else {
      this.DiscountModel = "Please Select Company First";
      $('#DiscountModel').show();
    }
  }
  RoleCustomerValue(id, val) {
    this.dynamicCustomerRoleDiscount[id].CustomerValue = val;
  }

  RoleDescription(id, val) {
    this.dynamicCustomerRoleDiscount[id].description = val;
  }

  UserRole() {
    if (this.CompanyValue > 0 && this.CompanyValue != undefined) {
      this.loader = true;
      this.customerService.GetRoles(Number(this.CompanyValue)).subscribe(
        (data: any) => {
          if (data.data != '' && data.data != null) {
            this.CustomerTypeList = [];
            this.CustomerTypeList = JSON.parse(data.data);
            if (this.CustomerTypeList.length > 0) {
              this.CustomerTypeList = this.CustomerTypeList.filter(x => x.status);
            }
            this.loader = false;
          }
        }
      )
    }
    // this.customerService.GetAllCustomerTypeList().subscribe(
    //   data => {
    //     this.data = data;
    //     this.loader = false;
    //     this.CustomerTypeList = this.data.dataList.filter(x => x.isActive == true);
    //   }
    // )
  }
  User() {
    this.loader = true;
    this.customerService.GetAllCustomer(this.CompanyValue, '').subscribe(
      data => {
        this.data = data;
        this.loader = false;
        this.CustomerTypeList1 = this.data.dataList;
      }
    )
  }

  GetCatList(value) {
    this.loader = true;
    this.sharedService.getAllCatogaryWithProduction(value, '').subscribe((data: any) => {
      this.arr = data.dataList;
      this.loader = false;
    })
  }

  getWordPress(event) {
    this.getOptionWP = event.target.value;
    this.WordPressData = this.getOptionWP;
    if (this.WordPressData == 3) {
      this.getDataFromWP();
    } else if (this.WordPressData == 2) {
      this.name = this.name;
      this.description = this.description;
      this.ShortDescription = this.shortDesc;
    }
  }

  AddSpecifications() {
    let count = 0;
    if (this.dynamicSpecificArray != undefined && this.dynamicSpecificArray.length >= 1) {
      count = this.dynamicSpecificArray.length - 1;
    }
    this.dynamicSpecificArray.push({ 'id': '0', 'Name': '', 'Value': '', 'Ordering': count, 'isActive': true, 'isReadOnly': false, 'CreateDate': '', 'CreatedBy': '', 'IsDReadonly': false });
  }
  RemoveSpecifications(index: any) {
    this.dynamicSpecificArray[index].isActive = false;
  }

  getDataFromWP() {
    this.SearchValidation = true;
    this.loader = true;
    this.partService.UpdatePartWp(this.CheckId).subscribe((data: any) => {
      if (data != null) {
        this.name = data.name ? data.name : '';
        this.description = data.description ? data.description : '';
        this.ShortDescription = data.short_description ? data.short_description : '';
      }
      this.loader = false;
    }, (error => {
      $('#wrong_mssg').show();
      this.loader = false;
    })
    )
  }

  Filedcalc1(id, value) {
    this.dynamicSpecificArray[id].Name = value;
  }

  Filedcalc2(id, value) {
    this.dynamicSpecificArray[id].Value = value;
  }

  //ankit implemenation
  ErrorModal(title: string, message: string) {
    Swal.fire({
      title: title,
      text: message,
      showCancelButton: true,
      cancelButtonText: 'Close',
    })
  }

  onChgeCommerceStatus(value: string) {
    this.ECommerceStatus = value;
  }

  GetAllPartsList(companyID) {
    this.loader = true;
    this.options = [];
    this.partService.GetPartsByCompanyId(companyID)
      .subscribe((data: any) => {
        this.loader = false;
        if (data.length > 0) {
          data.forEach((element: any) => {
            this.options.push(this.Part = {
              Id: '0',
              PartName: element.name,
              PartNumber: element.partNo,
              WPID: (element.wordpressId == undefined || element.wordpressId == null) ? '' : element.wordpressId.toString()
            });
          });
        }
      });
  }
  onRPChange(event: any, index, type: string) {
    this.onRPInput(event.source.value, index, type, true)
  }
  onRPInput(value, index, type: string, IsSelected: boolean) {
    let a = [];
    this.filteredOptions = this.options;
    if (type == 'PartNumber') {
      a = this.options.filter(option =>
        option.PartNumber.toLowerCase().includes(value.toLowerCase())
      )
    } else if (type == 'PartName') {
      a = this.options.filter(option =>
        option.PartName.toLowerCase().includes(value.toLowerCase())
      )
    } else if (type == 'WPID') {
      a = this.options.filter(option =>
        option.WPID.toLowerCase().includes(value.toLowerCase())
      )
    }
    this.filteredOptions = a;
    if (IsSelected) {
      this.myForm.value.listOfItems[index].PartName = a[0].PartName
      this.myForm.value.listOfItems[index].PartNumber = a[0].PartNumber
      this.myForm.value.listOfItems[index].WPID = a[0].WPID
      this.myForm.patchValue({ listOfItems: this.myForm.value.listOfItems })
      this.filteredOptions = this.options;
    }
  }

  createForm() {
    this.myForm = this.fb.group({
      listOfItems: this.initItems()
    });
    if (this.myForm.value.listOfItems.length == 1 && this.myForm.value.listOfItems[0].PartName == '') {
      this.removeItem(0)
    }
  }
  initItems() {
    var formArray: FormArray = this.fb.array([]);
    formArray.push(this.fb.group({
      Id: [""],
      PartNumber: [""],
      PartName: [""],
      WPID: [""],
    }));
    return formArray;
  }

  addNewItem() {
    const controls = <FormArray>this.myForm.controls["listOfItems"];
    let formGroup = this.fb.group({
      Id: [""],
      PartNumber: [""],
      PartName: [""],
      WPID: [""],
    });
    controls.push(formGroup);
  }
  removeItem(i: number) {
    const controls = <FormArray>this.myForm.controls["listOfItems"];
    controls.removeAt(i);
    this.filteredOptions.splice(i, 1);
  }

  _filter(itemName: string, type: string): Part[] {
    const filterValue = itemName.toLowerCase().replace(/\s/g, "");
    if (type == 'PartNumber') {
      return this.options.filter(option =>
        option.PartNumber.toLowerCase().includes(filterValue)
      );
    }
    if (type == 'PartName') {
      return this.options.filter(option =>
        option.PartName.toLowerCase().includes(filterValue)
      );
    }
    if (type == 'WPID') {
      return this.options.filter(option =>
        option.WPID.toLowerCase().includes(filterValue)
      );
    }
  }
  GetAPIKey() {
    return this.constants.EditorKeyTinyMCE
  }
  //Sales Questions Start
  get Questions() {
    return (<FormArray>this.form.get('Questions'));
  }
  addQuestion(q: any) {

    this.Questions.push(new FormGroup({
      id: new FormControl(q.length == 0 ? 0 : q.id),
      answerTypeId: new FormControl(q.length == 0 ? 1 : q.answerTypeId),
      questionTitle: new FormControl(q.questionTitle),
      isRequired: new FormControl(q.length == 0 ? false : q.isRequired),
      partId: new FormControl(q.length == 0 ? 0 : q.partId),
    }))
  }
  delQuestion(i: number) {
    this.Questions.removeAt(i);
  }
  BindQuestions(value: any) {

    this.Questions.clear();
    value.forEach((q) => {
      this.addQuestion(q);
    });
  }
  //Sales Questions End

  openHistoryModal() {
    this.inventoryService.GetInventoryList(this.companyId, this.id).subscribe(
      (data: any) => {
        this.GetInventoryList(data);
      },
      err => {
        this.popupModal(this.constants.APIError, this.constants.SomethingWentWrong);
      }
    );
  }

  GetInventoryList(response: any) {
    this.invListDetails = [];
    this.invListDetails = response.data;
    this.showViewHistory = true;
  }

  historyclose(status: boolean) {
    this.showViewHistory = status;
  }

  popupModal(title: string, message: string) {
    Swal.fire({
      title: title,
      text: message,
      showCancelButton: true,
      cancelButtonText: 'Close',
    })
  }
}

