import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConstantsService } from 'src/app/constants.service';
import { PartConstantsService } from 'src/app/constants/part-constants.service';
import { purposeEnum, sourceEnum, trackingTypeEnum, unitofMeasurementEnum } from 'src/app/enums/partenums';
import { CustomerService } from 'src/app/services/customer/customer.service';
import { GlobalChangeService } from 'src/app/services/global-change.service';
import { InventoryService } from 'src/app/services/inventory/inventory.service';
import { PartService } from 'src/app/services/part/part.service';
import { SharedService } from 'src/app/services/shared.service';
import { VendorService } from 'src/app/services/vendor/vendor.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop'

interface Program {
  value: number;
  text: string;
  isActive?: boolean;
  isEcommerece?: boolean;
  wordpressId?: number;
  children?: Program[];
}

@Component({
  selector: 'app-part-details',
  templateUrl: './part-details.component.html',
  styleUrls: ['./part-details.component.css']
})
export class PartDetailsComponent implements OnInit {
  loader: boolean = false;
  previousPage: string = 'Back to List';
  currentPage: string = 'Part Details';
  form: FormGroup;
  companyId: number = 0;
  invTypeList: any = [];
  uomList: any = [];
  trackingList: any = [];
  statusList: any = this.constants.Status;
  purposeList: any = this.partconstants.Purpose;
  sourceList: any = this.partconstants.Source;
  pakageTypeList: any = this.partconstants.PakageTypeList;
  selected = '';
  categoryArray = [];
  programs: Program[] = [];
  filteredVendors: any;
  getStateFromCountry: any[] = [];
  enumPurpose = purposeEnum;
  CustomerTypeList: any;
  showEOQCalculatorFlyout: boolean = false;
  selectedIndex: number = 0;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private globalChangeService: GlobalChangeService,
    private partconstants: PartConstantsService,
    private constants: ConstantsService,
    private partService: PartService,
    private sharedService: SharedService,
    private inventoryService: InventoryService,
    private vendorService: VendorService,
    private customerService: CustomerService
  ) {

  }

  ngOnInit(): void {
    this.CreateForm();
    this.LoadInventoryTypeList();
    this.LoadUOMList();
    this.GetTrackingList();
    this.UserRole();

    const id = Number(this.route.snapshot.params['id']);
    this.companyId = Number(this.route.snapshot.params['companyId']);
    // this.companyId = this.globalChangeService.getGlobalCompany();
    if (this.companyId > 0) {
      if (id > 0) {
        this.GetbyId(Number(id));
        this.GetAllCategories();
      }
    } else {
      this.sharedService.GetSwalpopupModel(this.constants.APIError, this.constants.SelectCompany);
    }
    //on change function 
    this.globalChangeService.dropdownChange$.subscribe((data) => {
      this.router.navigate(['/part-list'])
    })
  }

  CreateForm() {
    this.form = new FormGroup({
      id: new FormControl(0),
      vendorId: new FormControl(0),
      name: new FormControl('', Validators.required),
      description: new FormControl(''),
      shortDescription: new FormControl(''),
      cost: new FormControl(0),
      hours: new FormControl(''),
      imagePath: new FormControl(null),
      laborCharge: new FormControl(null),
      search: new FormControl(null),
      customPartId: new FormControl(null),
      customPartImagePath: new FormControl(null),
      notes: new FormControl(null),
      partCategoryId: new FormControl(0),
      companyId: new FormControl(0),
      productId: new FormControl(0),
      isActive: new FormControl(true),
      createdDate: new FormControl(null),
      modifiedDate: new FormControl(null),
      createdBy: new FormControl(null),
      modifiedBy: new FormControl(null),
      partId: new FormControl(0),
      partNo: new FormControl('', Validators.required),
      vendorName: new FormControl(''),
      partCategoryName: new FormControl(null),
      partQuestions: new FormArray([]),
      partInventory: new FormArray([]),
      partImageUpload: new FormArray([]),
      partImage: new FormControl(null),
      vendorPartCost: new FormControl(0),
      isCustompart: new FormControl(false),
      vendorPartName: new FormControl(''),
      vendorPartNumber: new FormControl(''),
      unitofMeasureId: new FormControl(unitofMeasurementEnum.ea),
      minInventory: new FormControl(0),
      skillLevel: new FormControl(0),
      productionRole: new FormControl(''),
      partLocation: new FormControl(''),
      partLeadTime: new FormControl(0),
      reorderAmount: new FormControl(0),
      workflowStep: new FormControl(0),
      companyName: new FormControl(null),
      installationNotes: new FormControl(null),
      totalAllType: new FormControl(0),
      totalTypeInventory: new FormControl(0),
      totalInvLog: new FormControl(0),
      wordpressId: new FormControl(null),
      height: new FormControl(''),
      length: new FormControl(''),
      width: new FormControl(''),
      weight: new FormControl(0, Validators.required),
      dimentionType: new FormControl(''),
      weightType: new FormControl(''),
      autoMarkup: new FormControl(false),
      markupMargin: new FormControl(0),
      isNotActive: new FormControl(false),
      isDiscount: new FormControl(null),
      discount: new FormControl(0),
      apiWordpressDetails: new FormArray([]),
      partProduct: new FormArray([]),
      partVendorList: new FormArray([]),
      multiPartCategory: new FormArray([]),
      totalRows: new FormControl(null),
      nonInventory: new FormControl(false),
      vendorCount: new FormControl(null),
      editrow: new FormControl(null),
      customId: new FormControl(null),
      partTracking: new FormControl(trackingTypeEnum.None),
      partLocationName: new FormControl(''),
      locationName: new FormControl(''),
      location: new FormControl(''),
      vendorDiscount: new FormArray([]),
      customerDiscount: new FormArray([]),
      roleBasePricingDiscount: new FormArray([]),
      partSpecifications: new FormArray([]),
      invLocationName: new FormControl(null),
      invLocation: new FormControl(null),
      qty: new FormControl(0),
      totalAmountAll: new FormControl(0),
      customerPonumber: new FormControl(null),
      inVDate: new FormControl(null),
      typeName: new FormControl(null),
      oemDiscount: new FormControl(null),
      shippedBox: new FormControl(null),
      wpData: new FormControl(null),
      lastWPSyncDate: new FormControl(null),
      eCommerceStatus: new FormControl(''),
      relatedProducts: new FormArray([]),
      inventoryType: new FormControl(1),
      availableInventory: new FormControl(0),
      onHandInventory: new FormControl(0),
      committedInventory: new FormControl(0),
      backOrderedInventory: new FormControl(0),
      question: new FormArray([]),
      defaultInventoryLocationId: new FormControl(null),

      tabs: new FormArray([]),
      gtinupc: new FormControl(''),
      brandName: new FormControl(''),
      purpose: new FormControl(purposeEnum.InternalUse),
      source: new FormControl(sourceEnum.Vendor),
      packageType: new FormControl(0),
      reorderQty: new FormControl(0),
      leadUnit: new FormControl(0),
    });
  }

  get tabs(): FormArray {
    return this.form.get('tabs') as FormArray;
  }

  get vendorDiscountArray(): FormArray {
    return this.form.get('vendorDiscount') as FormArray;
  }

  get volumePricingArray(): FormArray {
    return this.form.get('customerDiscount') as FormArray;
  }

  get roleDiscountArray(): FormArray {
    return this.form.get('roleBasePricingDiscount') as FormArray;
  }

  get filesGet(): FormArray {
    return this.form.controls.partImageUpload as FormArray
  }

  GetbyId(id: number) {
    this.loader = true;
    this.partService.GetPartById(id).subscribe(
      (data: any) => {
        this.loader = false;
        if (data.data != null) {
          this.CreateForm();
          this.form.patchValue(data.data);
          this.addpartCategory(data.data.multiPartCategory);
          this.addvendorDiscount(data.data.vendorDiscount);
          this.addvolDiscount(data.data.customerDiscount);
          this.addrolebasedDiscount(data.data.roleBasePricingDiscount);
          this.addpartImages(data.data.partImageUpload);
        }
      },
      (error: any) => {
        this.loader = false;
        this.sharedService.GetSwalpopupModel(this.constants.APIError, this.constants.SomethingWentWrong);
      }
    );
  }

  addpartCategory(data: any): void {
    debugger
    if (data.length > 0) {
      this.categoryArray = [];
      data.forEach(element => {
        this.categoryArray.push(element.partcategoryid);
      });
    }
  }

  addpartImages(data: any): void {
    if (data.length > 0) {
      const items = this.form.get('partImageUpload') as FormArray;
      data.forEach(element => {
        items.push(this.pushImages(element));
      });
    }
  }

  pushImages(element: any): FormGroup {
    return this.fb.group({
      id: element.id,
      partId: element.partId,
      partType: element.partType,
      imagePath: element.imagePath,
      isActive: element.isActive,
      createdDate: element.createdDate,
      modifiedDate: element.modifiedDate,
      createdBy: element.createdBy,
      modifiedBy: element.modifiedBy,
      partImage: element.partImage,
      documentType: element.documentType,
      wPid: element.wPid,
      wpDateCreated: element.wpDateCreated,
      wpDateModified: element.wpDateModified,
      wpSrc: element.wpSrc,
      wpName: element.wpName,
      wpAlt: element.wpAlt
    });
  }

  addvendorDiscount(data: any): void {
    if (data.length > 0) {
      const items = this.form.get('vendorDiscount') as FormArray;
      data.forEach(element => {
        console.log(element)
        items.push(this.pushVendorDiscount(element));
      });
    }
  }

  addNewVendorDiscount(): void {
    const obj = {
      id: 0,
      partId: this.form.value.id,
      qty: 0,
      discount: 0,
      vendorCost: 0,
      isActive: true,
      companyId: this.companyId,
      minQty: 0,
      discountType: 'percentage',
      description: ''
    }
    this.vendorDiscountArray.push(this.pushVendorDiscount(obj));
  }

  addNewVolumnePricingDiscount(): void {
    const obj = {
      id: 0,
      partId: this.form.value.id,
      qty: 0,
      discount: 0,
      vendorCost: 0,
      isActive: true,
      companyId: this.companyId,
      minQty: 0,
      discountType: 'percentage',
      description: ''
    }
    this.volumePricingArray.push(this.pushVolPricingDiscount(obj));
  }
  addNewRoleBasedPricingDiscount(): void {
    const obj = {
      id: 0,
      partId: this.form.value.id,
      companyId: this.companyId,
      discountType: '1',
      discountValue: 0,
      customerType: '1',
      customerValue: 1,
      cost: 0,
      description: '',
      isActive: true,
      wordPressId: 0
    }
    this.roleDiscountArray.push(this.pushRoleBasedPricingDiscount(obj));
  }

  removeVendorDiscount(index: number) {
    this.vendorDiscountArray.removeAt(index);
  }
  removeVolumnePricingDiscount(index: number) {
    this.volumePricingArray.removeAt(index);
  }
  removeRoleBasedPricingDiscount(index: number) {
    this.roleDiscountArray.removeAt(index);
  }


  addvolDiscount(data: any): void {
    if (data.length > 0) {
      const items = this.form.get('customerDiscount') as FormArray;
      data.forEach(element => {
        items.push(this.pushVolPricingDiscount(element));
      });
    }
  }

  addrolebasedDiscount(data: any): void {
    if (data.length > 0) {
      const items = this.form.get('roleBasePricingDiscount') as FormArray;
      data.forEach(element => {
        items.push(this.pushRoleBasedPricingDiscount(element));
      });
    }
  }

  pushVendorDiscount(element: any): FormGroup {
    return this.fb.group({
      id: element.id,
      partId: element.partId,
      qty: element.qty,
      discount: element.discount,
      vendorCost: element.vendorCost,
      isActive: element.isActive,
      createdDate: element.createdDate,
      modifiedDate: element.modifiedDate,
      createdBy: element.createdBy,
      modifiedBy: element.modifiedBy,
      companyId: element.companyId,
      minQty: element.minQty,
      discountType: element.discountType,
      description: element.description
    });
  }

  pushVolPricingDiscount(element: any): FormGroup {
    return this.fb.group({
      id: element.id,
      partId: element.partId,
      minQty: element.minQty,
      qty: element.qty,
      discountType: element.discountType,
      discount: element.discount,
      customerCost: element.customerCost,
      description: element.description,
      isActive: element.isActive,
      createdDate: element.createdDate,
      modifiedDate: element.modifiedDate,
      createdBy: element.createdBy,
      modifiedBy: element.modifiedBy,
      companyId: element.companyId,
      originalPartPrice: element.originalPartPrice,
      wordPressId: element.wordPressId
    });
  }

  pushRoleBasedPricingDiscount(element: any): FormGroup {
    return this.fb.group({
      id: element.id,
      partId: element.partId,
      companyId: element.companyId,
      discountType: element.discountType,
      discountValue: element.discountValue,
      customerType: element.customerType,
      customerValue: Number(element.customerValue),
      cost: element.cost,
      description: element.description,
      isActive: element.isActive,
      createdDate: element.createdDate,
      modifiedDate: element.modifiedDate,
      createdBy: element.createdBy,
      modifiedBy: element.modifiedBy,
      originalPartPrice: element.originalPartPrice,
      wordPressId: element.wordPressId
    });
  }

  Back() {
    this.router.navigate(['/part-list']);
  }
  Cancel() {
  }
  Save() {
    if (this.form.valid) {

    } else {
      alert('Invalid')
    }
  }

  LoadInventoryTypeList() {
    this.inventoryService.GetInventoryType().subscribe(
      (data: any) => {
        this.invTypeList = data.data;
      }
    )
  }

  LoadUOMList() {
    this.partService.UnitofMeasurement().subscribe(
      (data: any) => {
        this.uomList = data.dataList;
      }
    )
  }

  UserRole() {
    this.customerService.GetAllCustomerTypeList().subscribe(
      (data: any) => {
        this.CustomerTypeList = data.dataList.filter(x => x.isActive == true);
        console.log(this.CustomerTypeList)
      }
    )
  }

  GetAllCategories() {
    this.sharedService.GetCategoryDropdown(this.companyId).subscribe(
      (data: any) => {
        this.programs = data.dataList;
      });
  }

  OnParentCatChange(value: any): void {

  }

  GetTrackingList() {
    this.partService.GetPartTrackingList().subscribe(
      (data: any) => {
        if (data.length > 0) {
          this.trackingList = data;
        }
      }
    )
  }

  onVendorInput(value: any) {
    this.filteredVendors = [];
    if (this.companyId > 0 && this.companyId != null && this.companyId != undefined) {
      this.vendorService.GetAllVendor(this.companyId, value).subscribe(
        (data: any) => {
          let vendors = data.dataList;
          //old
          let arr = vendors.filter(option =>
            option.vendorName.toLowerCase().includes(value.toLowerCase())
          )
          this.filteredVendors = arr;
        }
      )
    }
  }

  getVendor(obj: any) {
    this.form.patchValue({
      vendorId: obj.id,
      vendorName: obj.vendorName
    });
  }

  GetCountryId($event) {
    this.sharedService.getAllState($event.value).subscribe((data: any) => {
      this.getStateFromCountry = data.dataList;
    })
  }

  onFileChange(event: any) {
    const files = Object.keys(event.target.files).map(key => event.target.files[key]);
    files.forEach(element => {
      let fileToUpload = element;
      const fileName = fileToUpload.name;
      var reader = new FileReader()
      reader.onload = (event: any) => {
        let x = {
          id: 0,
          partId: this.form.value.id,
          partType: 4,
          imagePath: event.target.result,
          isActive: true,
          partImage: fileToUpload.name,
          documentType: fileToUpload.type
        };
        this.filesGet.push(this.fb.group(x));
      }
      reader.readAsDataURL(fileToUpload);
    });
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.filesGet.value, event.previousIndex, event.currentIndex);
  }

  openEOQCalculatorFlyout() {
    this.showEOQCalculatorFlyout = true;
  }

  closeEOQCalculatorFlyout(event: any) {
    this.showEOQCalculatorFlyout = event;
  }

  // addTab() {
  //   const newIndex = this.tabs.length + 1;
  //   this.tabs.push(this.fb.group({
  //     label: `Tab ${newIndex}`,
  //     content: `Content for tab ${newIndex}`
  //   }))
  //   this.selectedIndex = this.tabs.length - 1;
  // }

  addTab() {
    const newIndex = this.tabs.length + 1;
    this.tabs.push(this.fb.group({
      label: `Tab ${newIndex}`,
      content: `Content for tab ${newIndex}`,
      vendorPartCost: new FormControl(''),
      vendorPartNumber: new FormControl(''),
      vendorPartName: new FormControl(''),
      partLeadTime: new FormControl(''),
      reorderAmount: new FormControl(''),
      reorderQty: new FormControl(''),
      vendorDiscount: new FormArray([])
    }))
    this.selectedIndex = this.tabs.length - 1;
  }

  removeTab(index: number) {
    if (this.tabs.length > 1) {
      this.tabs.removeAt(index);
      if (this.selectedIndex >= this.tabs.length) {
        this.selectedIndex = this.tabs.length - 1;
      }
    }
  }

  openTab(index: number) {
    if (index >= 0 && index < this.tabs.length) {
      this.selectedIndex = index;
    }
  }
}