export interface DefaultCompany {
    value: number;
    text: string;
    isActive?: boolean;
    isEcommerece?: boolean;
    wordpressId?: number;
    children?: DefaultCompany[];
}
// Company Start
export interface Company {
    id: number;
    companyName: string;
    parentCompanyName: string;
    addressLine1: string;
    addressLine2: string;
    parentId: string;
    itemNo: string;
    modifiedDate: Date;
    modifiedBy: string;
    isActive?: string;
    children?: Company[];
}
export interface CompanyTreeControl {
    expandable: boolean;
    id: number;
    companyName: string;
    parentCompanyName: string;
    addressLine1: string;
    addressLine2: string;
    parentId: string;
    itemNo: string;
    modifiedDate: Date;
    modifiedBy: string;
    isActive?: string;
    level: number;
}
//Company End
//Sales Order Start
export class DiscountVM {
    CustomerType: string = '';
    CustDiscountCost: number = 0;
}
export class FilterListRiquest {
    FilterTypeList: any = []
    FilterTypeId: number = 1;
    StatusList: any = []
    StatusIds: any = [1, 8, 10, 11];
    TimePeriodId: number = 1
    CustomerIds: any = [];
    FromDate: Date = null;
    ToDate: Date = null;
    PageName: string = ''
    Source: string = ''
}
export class ListRiquest {
    companyId: number = Number(localStorage.getItem('GlobalSelectedCompanyId'));
    IsActiveStatus: string = '1'
    searchTerm: string = ''
}
//Sales Order End
