export enum unitofMeasurementEnum {
    ea = 1,
    ft = 2,
    lbs = 3,
    hr = 4,
    gal = 5,
    floz = 6,
    inch = 7
}

export enum purposeEnum {
    InternalUse = 1,
    Resale = 2,
    ECommerece = 3
}

export enum sourceEnum {
    Vendor = 1,
    InternalAssembly = 2
}

export enum trackingTypeEnum {
    Serial = 1,
    Lot = 2,
    None = 3
}