<section class="content-section-2" id="new-layout" style="background-color:#f4f3f7;">
    <form [formGroup]="FulfillmentForm">
        <app-loader [loader]="loader"></app-loader>
        <!-- --------------Bredcumps start here------------- -->
        <div class="top-bredcumps">
            <div class="cstmbreadcum paddingmng-right">
                <div class="page_title_cstms">
                    <a (click)="BackToList()" class="backbtns" *ngIf="IsFulfillmentList">
                        <mat-icon>keyboard_arrow_left</mat-icon>
                        <span>Back to List</span>
                    </a>
                    <ul class="link_current">
                        <li>
                            <span *ngIf="!IsFulfillmentList">Fulfillment List</span>
                            <span *ngIf="IsFulfillmentList">Fulfillment Details</span>
                        </li>
                    </ul>
                </div>
                <div class="top-button d-flex justify-content-between align-items-center gap-3"
                    *ngIf="IsFulfillmentList">
                    <button class="btn cancel-btn update-btn" (click)="Cancel()">
                        Cancel
                    </button>
                    <button class="save-btn btn update-btn" (click)="FulFillOrder()">
                        Fulfill Order
                    </button>
                    <button class="btn save-btn update-btn" (click)="Save()">
                        <span>Save</span>
                    </button>
                </div>
            </div>
        </div>
        <!-- --------------Bredcumps end here------------- -->
        <div class="content-section-1">
            <div class="container-fluid" *ngIf="!IsFulfillmentList">
                <!--------------------------------------------home main section start here------  -->
                <div class="main-section">
                    <!-- ----card sectionn start -->
                    <div class="row" *ngIf="FulfillmentStatusCount != undefined">
                        <div class="col-xl-3 col-md-6 col-6 mb-4 first-card fullfillment-card">
                            <mat-card class="example-card p-0">
                                <mat-card-content class="mat-card-pd">
                                    <div class="main-card p-0">
                                        <h6>Fulfillment Status</h6>
                                    </div>
                                    <div class="row m-0">
                                        <div class="col-md-3 col-12 text-center">
                                            <span class="items-value">{{FulfillmentStatusCount.readyToFullFill}}</span>
                                        </div>
                                        <div class="col-md-9 col-12 mb-2 p-0">
                                            <span class="item-name">Ready to Pick</span>
                                        </div>
                                    </div>
                                    <div class="row m-0">
                                        <div class="col-md-3 col-12 text-center">
                                            <span class="items-value">{{FulfillmentStatusCount.backOrdered}}</span>
                                        </div>
                                        <div class="col-md-9 col-12 mb-2 p-0">
                                            <span class="item-name">Back Ordered</span>
                                        </div>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                        </div>
                        <div class="col-xl-3 col-md-6 col-6 mb-4 second-card fullfillment-card">
                            <mat-card class="example-card p-0">
                                <mat-card-content class="mat-card-pd">
                                    <div class="main-card p-0">
                                        <h6>Ship As</h6>
                                    </div>
                                    <div class="row m-0">
                                        <div class="col-md-3 col-12 text-center">
                                            <span class="items-value">{{FulfillmentStatusCount.partial}}</span>
                                        </div>
                                        <div class="col-md-9 col-12 mb-2 p-0">
                                            <span class="item-name">Partial</span>
                                        </div>
                                    </div>
                                    <div class="row m-0">
                                        <div class="col-md-3 col-12 text-center">
                                            <span class="items-value">{{FulfillmentStatusCount.completed}}</span>
                                        </div>
                                        <div class="col-md-9 col-12 mb-2 p-0">
                                            <span class="item-name">Complete</span>
                                        </div>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                        </div>
                        <div class="col-xl-3 col-md-6 col-6 mb-4 third-card fullfillment-card">
                            <mat-card class="example-card p-0">
                                <mat-card-content class="mat-card-pd">
                                    <div class="main-card p-0">
                                        <h6>Fulfillment Type</h6>
                                    </div>
                                    <div class="row m-0">
                                        <div class="col-md-3 col-12 text-center">
                                            <span class="items-value">{{FulfillmentStatusCount.manual}}</span>
                                        </div>
                                        <div class="col-md-9 col-12 mb-2 p-0">
                                            <span class="item-name">Manual</span>
                                        </div>
                                    </div>
                                    <div class="row m-0">
                                        <div class="col-md-3 col-12 text-center">
                                            <span class="items-value">{{FulfillmentStatusCount.automatic}}</span>
                                        </div>
                                        <div class="col-md-9 col-12 mb-2 p-0">
                                            <span class="item-name">Automatic</span>
                                        </div>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                        </div>
                        <div class="col-xl-3 col-md-6 col-6 mb-4 fourth-card fullfillment-card">
                            <mat-card class="example-card p-0">
                                <mat-card-content class="mat-card-pd">
                                    <div class="main-card p-0">
                                        <h6>Back Orders</h6>
                                    </div>
                                    <div class="row m-0">
                                        <div class="col-md-3 col-12 text-center">
                                            <span class="items-value">{{FulfillmentStatusCount.ipo}}</span>
                                        </div>
                                        <div class="col-md-9 col-12 mb-2 p-0">
                                            <span class="item-name">Issued Purchase Orders</span>
                                        </div>
                                    </div>
                                    <div class="row m-0">
                                        <div class="col-md-3 col-12 text-center">
                                            <span class="items-value">{{FulfillmentStatusCount.npo}}</span>
                                        </div>
                                        <div class="col-md-9 col-12 mb-2 p-0">
                                            <span class="item-name">New Purchase Orders</span>
                                        </div>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                        </div>
                    </div>
                    <!-- ----card sectionn end -->
                    <!-- -------------search input section start -->
                    <div class=" col-md-12 col-12 pd-left">
                        <div class="row">
                            <div class="col-10">
                                <div class="row justify-content-center mb-3">
                                    <div class="col-md-8">
                                        <app-search-bar [placeholder]="'Search'" [searchddl]="constants.allSearch"
                                            [dropDownList]="constants.SearchStatus"
                                            (searchEvent)="Search($event)"></app-search-bar>
                                    </div>
                                </div>
                            </div>
                            <div class="col-2">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12" *ngIf="filter!= undefined"> <app-search-filter [Data]="filter"
                                    (DateFilter)="ApplyFilter($event)"></app-search-filter></div>
                        </div>
                    </div>
                    <!-- -------------search input section end -->
                    <!-- ---------------------------------table section start here-------------------- -->
                    <div class="fullfillment-table" *ngIf="!IsFulfillmentList">
                        <!-- -----------------table section fullfillment--- -->
                        <div class="heading-section">
                            <mat-table [dataSource]="FulfillmentDS" class="table-material table-responsive">
                                <ng-container matColumnDef="menu">
                                    <mat-header-cell *matHeaderCellDef> Menu </mat-header-cell>
                                    <mat-cell *matCellDef="let element;" (click)="$event.stopPropagation()"
                                        data-label="Menu"> <mat-icon class="icon_fix">more_vert</mat-icon> </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="Order">
                                    <mat-header-cell *matHeaderCellDef> Order </mat-header-cell>
                                    <mat-cell *matCellDef="let element;let i = index;" data-label="Order Date"> {{i+1 }}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="OrderDate">
                                    <mat-header-cell *matHeaderCellDef> Order Date </mat-header-cell>
                                    <mat-cell *matCellDef="let element" data-label="Order Date">
                                        {{element.orderDate | date : 'MM/dd/yyyy'}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="OrderNumber">
                                    <mat-header-cell *matHeaderCellDef>Order Number </mat-header-cell>
                                    <mat-cell *matCellDef="let element"
                                        data-label="Order Number">{{element.orderNumber}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="CustomerName">
                                    <mat-header-cell *matHeaderCellDef> Customer Name </mat-header-cell>
                                    <mat-cell *matCellDef="let element" data-label="Customer Name">
                                        {{element.customerName}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="NewPOs">
                                    <mat-header-cell *matHeaderCellDef> New PO’s </mat-header-cell>
                                    <mat-cell *matCellDef="let element" data-label="New PO’s">{{element.npo}}</mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="IssuedPOs">
                                    <mat-header-cell *matHeaderCellDef> Issued PO’s </mat-header-cell>
                                    <mat-cell *matCellDef="let element" data-label="Issued PO’s"> {{element.ipo}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="LatestArrivalDate">
                                    <mat-header-cell *matHeaderCellDef> Latest Arrival Date </mat-header-cell>
                                    <mat-cell *matCellDef="let element" data-label="Latest Arrival Date">{{element.ead
                                        == null ? '-':
                                        element.ead | date :'dd/MM/yyyy'}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="FulfillmentType">
                                    <mat-header-cell *matHeaderCellDef> Fulfillment Type </mat-header-cell>
                                    <mat-cell *matCellDef="let element" data-label="Fulfillment Type">
                                        {{element.fulfilmentType ==
                                        'Auto'? 'Automatic' :'Manual'}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="ShipAs">
                                    <mat-header-cell *matHeaderCellDef> Ship As </mat-header-cell>
                                    <mat-cell *matCellDef="let element" data-label="Ship As"> {{element.partialShipment
                                        ==
                                        1? 'Partial' :'Complete'}} </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="FulfillmentStatus">
                                    <mat-header-cell *matHeaderCellDef> Fulfillment Status </mat-header-cell>
                                    <mat-cell *matCellDef="let element" data-label="Fulfillment Status">
                                        {{this.GetStatusName(element.statusId)}}
                                    </mat-cell>
                                </ng-container>
                                <mat-header-row *matHeaderRowDef="constants.FulfillmentDSColumn sticky: true"
                                    class="header-style table-responsive-header">
                                    <div class="mat-header-cell header-group" role="columnheader" style="flex: 3">Group1
                                    </div>
                                </mat-header-row>
                                <mat-row *matRowDef="let row; columns: constants.FulfillmentDSColumn;let i= index;"
                                    (click)="selectedRow(row.id)"></mat-row>
                            </mat-table>
                        </div>
                    </div>
                    <!-- ---------------------------------table section end here-------------------- -->
                </div>
                <!-------------------------------------------- home main section end here------  -->
            </div>
        </div>


        <!-- --------------------------------------fullfiullment details section start here------------------------------ -->
        <div class="content-section-1">
            <div class="container-fluid">
                <div class="main-section" *ngIf="IsFulfillmentList">
                    <!-- ------------- top cards row section start here------------- -->
                    <div class="row top-section" *ngIf="FulfillmentById != undefined">
                        <div class="col-xl-4 col-md-6 col-12 mb-3 first-card">
                            <mat-card class="mat-card-custom p-0">
                                <mat-card-header class="align-items-center gap-3 change-mat-header">
                                    <div class="main-heading">Bill to</div>
                                    <div class="form-fields-custom">
                                        <mat-form-field appearance="outline" class="height-change">
                                            <mat-icon matSuffix class="p-2">search</mat-icon>
                                            <input matInput placeholder="Search Customer" #autoCompleteInput disabled>
                                        </mat-form-field>
                                    </div>
                                    <div class="new-btn">
                                        <button mat-stroked-button>
                                            <mat-icon class="add-btn">add</mat-icon>
                                            New</button>
                                    </div>
                                </mat-card-header>
                                <mat-card-content class="mat-card-pd mt-2">
                                    <div class="product-name mb-2">
                                        <span>{{FulfillmentById.billAddress.name}}</span>
                                    </div>
                                    <div class="address">
                                        <span>{{FulfillmentById.billAddress.addressLine1}}</span>
                                        <span> {{FulfillmentById.billAddress.city}} &nbsp;
                                            {{this.GetStateCode(FulfillmentById.billAddress.stateId)}} &nbsp;</span>
                                        <span>{{FulfillmentById.billAddress.zip}}</span>
                                        <span>{{FulfillmentById.billAddress.email}} &nbsp;
                                            {{FulfillmentById.billAddress.phone}}</span>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                        </div>
                        <div class="col-xl-4 col-md-6 col-12 mb-3 second-card">
                            <mat-card class="mat-card-custom p-0">
                                <mat-card-header class="align-items-center gap-3 change-mat-header">
                                    <div class="main-heading">Ship to</div>
                                    <div class="form-fields-custom">
                                        <mat-form-field appearance="outline" class="height-change">
                                            <mat-icon matSuffix class="p-2">search</mat-icon>
                                            <input matInput placeholder="Search Customer" #autoCompleteInput disabled>
                                        </mat-form-field>
                                    </div>
                                    <div class="new-btn">
                                        <button mat-stroked-button>
                                            <mat-icon class="add-btn">add</mat-icon>
                                            New</button>
                                    </div>
                                </mat-card-header>
                                <mat-card-content class="mat-card-pd mt-2">
                                    <div *ngIf="FulfillmentById.shipAddress.shipVal==3"
                                        class="product-name mb-2 addres-section">
                                        <span>{{FulfillmentById.shipAddress.name}}</span>
                                        <mat-form-field appearance="outline" class="example-full-width">
                                            <span><input matInput placeholder="Enter Customer"
                                                    value="{{FulfillmentById.shipAddress.attention}}"></span>
                                        </mat-form-field>
                                        <span>{{FulfillmentById.shipAddress.addressLine1}}</span>
                                        <span>{{FulfillmentById.shipAddress.city}}
                                            {{GetStateCode(this.FulfillmentById.shipAddress.stateId)}}
                                            {{FulfillmentById.shipAddress.zip}}</span>
                                    </div>
                                    <div class="addres-section" *ngIf="FulfillmentById.shipAddress.shipVal==2"
                                        class="card-input">
                                        <span>{{FulfillmentById.shipAddress.name}}</span>
                                        <span>{{FulfillmentById.shipAddress.addressLine1}}</span>
                                        <span>{{FulfillmentById.shipAddress.city}}
                                            {{GetStateCode(this.FulfillmentById.shipAddress.stateId)}}
                                            {{FulfillmentById.shipAddress.zip}}</span>
                                    </div>
                                    <div class="address-section"
                                        *ngIf="FulfillmentById != null && FulfillmentById != undefined && FulfillmentById.shipAddress.shipVal ==0">
                                        <span>{{FulfillmentById.shipAddress.name}}</span>
                                        <span>{{FulfillmentById.shipAddress.addressLine1}}</span>
                                        <span> {{FulfillmentById.shipAddress.city}}
                                            {{GetStateCode(this.FulfillmentById.shipAddress.stateId)}}
                                            {{FulfillmentById.shipAddress.zip}}</span>
                                        <span>{{FulfillmentById.shipAddress.email}}</span> &nbsp;
                                        <span>{{FulfillmentById.shipAddress.phone}}</span>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                        </div>
                        <div class="col-xl-4 col-md-12 col-12 mb-3 fourth-card mat-header-change">
                            <mat-card class="mat-card-custom p-0">
                                <mat-card-header
                                    class="align-items-center gap-3 change-mat-header justify-content-between">
                                    <div class="order-info">Order Information</div>
                                    <div class="history-btn" (click)="AddHistoryFlyOut()"><a>View History</a></div>
                                </mat-card-header>
                                <mat-card-content class="mat-card-pd mt-2">
                                    <div class="row mt-1">
                                        <div class="col-6 mb-4 first-card">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Order Status</mat-label>
                                                <mat-select [(ngModel)]="FulfillmentById.salesOrder.statusId"
                                                    [ngModelOptions]="{standalone: true}" disabled>
                                                    <mat-option *ngFor="let statusList of StatusList"
                                                        [value]="statusList.statusId">
                                                        {{statusList.statusName}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-6 mb-4 fourth-card">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Fulfillment Type</mat-label>
                                                <mat-select [(ngModel)]="FulfillmentById.salesOrder.fulfilmentType"
                                                    [ngModelOptions]="{standalone: true}" disabled>
                                                    <mat-option value="Auto">Auto </mat-option>
                                                    <mat-option value="Manual">Manual</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6 mb-4 first-card">
                                            <mat-form-field appearance="outline" class="example-full-width">
                                                <mat-label>Order Number</mat-label>
                                                <input matInput readonly
                                                    value="{{FulfillmentById.salesOrder.orderNumber}}" disabled>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-6 mb-3 fourth-card">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Order Date</mat-label>
                                                <input matInput
                                                    value="{{FulfillmentById.salesOrder.orderDate | date: 'MMM-dd-yyyy'}}"
                                                    readonly>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6 first-card">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Hold Reason</mat-label>
                                                <mat-select [(ngModel)]="FulfillmentById.salesOrder.holdReason"
                                                    [ngModelOptions]="{standalone: true}" disabled>
                                                    <mat-option value="CreditHold">Credit Hold</mat-option>
                                                    <mat-option value="PaymentHold">Payment Hold</mat-option>
                                                    <mat-option value="DelayedShipment">Delayed Shipment</mat-option>
                                                    <mat-option value="Other">Other</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-6 fourth-card">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Hold Until Date</mat-label>
                                                <input matInput [matDatepicker]="picker" [min]="todayDate" disabled
                                                    value="{{FulfillmentById.salesOrder.holdUntilDate}}">
                                                <mat-hint>MM/DD/YYYY</mat-hint>
                                                <mat-datepicker-toggle matIconSuffix
                                                    [for]="picker"></mat-datepicker-toggle>
                                                <mat-datepicker #picker></mat-datepicker>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </mat-card-content>

                            </mat-card>
                        </div>
                    </div>
                    <!-- ------------- top cards row section end here------------- -->

                    <!-- ---------------second row section------------------ -->
                    <div class="section-2 mt-3" *ngIf="FulfillmentById != undefined">
                        <div class="row cards-padding">
                            <div class="col-xl-6">
                                <div class="row">
                                    <div class="col-md-3 col-6 first-card mb-sm-3">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>Customer Role</mat-label>
                                            <input matInput value="{{FulfillmentById.salesOrder.customerRole}}"
                                                disabled>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-3 col-6 second-card mb-sm-3">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>Business Channel</mat-label>
                                            <input matInput value="{{FulfillmentById.salesOrder.businessChannel}}"
                                                disabled>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-3 col-6 second-card mb-sm-3">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>Payment Terms</mat-label>
                                            <mat-select [(ngModel)]="FulfillmentById.salesOrder.netTerms"
                                                [ngModelOptions]="{standalone: true}" disabled>
                                                <mat-option *ngFor="let nt of NetTermsList" [value]="nt.id"
                                                    [disabled]="nt.IsDisable">{{ (nt.id == 0) ? nt.name : 'Net
                                                    '+nt.name}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-3 col-6 second-card mb-sm-3">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>Available Credit</mat-label>
                                            <input matInput
                                                value="{{this.AddCurrency(FulfillmentById.salesOrder.availableCredit)}}"
                                                disabled>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6">
                                <div class="row">
                                    <div class="col-md-3 col-6 first-card mb-sm-3">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>Sales Tax</mat-label>
                                            <mat-select [(ngModel)]="FulfillmentById.salesOrder.taxable"
                                                [ngModelOptions]="{standalone: true}" disabled
                                                (selectionChange)="ChangeTaxable($event.value)">
                                                <mat-option value="NonTaxable">Non-Taxable</mat-option>
                                                <mat-option value="Taxable">Taxable</mat-option>
                                                <mat-option value="Exempt">Exempt</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-3 col-6 second-card mb-sm-3">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>Customer PO#</mat-label>
                                            <input matInput value="{{FulfillmentById.salesOrder.purchaseOrder}}"
                                                disabled>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-3 col-6 second-card mb-sm-3">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>Ship As</mat-label>
                                            <mat-select [(ngModel)]="FulfillmentById.salesOrder.isPartialShipment"
                                                [ngModelOptions]="{standalone: true}" disabled>
                                                <mat-option [value]=true>Partial</mat-option>
                                                <mat-option [value]=false>Complete</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-3 col-6 fourth-card mb-sm-3">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>Collect Shipping</mat-label>
                                            <mat-select [(ngModel)]="FulfillmentById.salesOrder.collectAccount"
                                                [ngModelOptions]="{standalone: true}" disabled>
                                                <mat-option value="">None</mat-option>
                                                <mat-option value="Fedex">Fedex -
                                                    {{FulfillmentById.salesOrder.collectAmount}}</mat-option>
                                                <mat-option value="UPS">UPS -
                                                    {{FulfillmentById.salesOrder.collectAmount}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>

                            </div>
                            <div class="col-xl-6">
                                <div class="row">
                                    <div class="col-12 first-card md-mb-3">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>Notes for Warehouse</mat-label>
                                            <input matInput value="{{FulfillmentById.salesOrder.wareHouseNotes}}"
                                                disabled>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6">
                                <div class="row">
                                    <div class="col-12 first-card-1">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>Notes for Customer</mat-label>
                                            <input matInput value="{{FulfillmentById.salesOrder.customerNotes}}"
                                                disabled>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- ---------------third  section------------------ -->
                    <div class="third-section d-flex justify-content-end align-items-center mt-3">
                        <div class="collapse-section d-flex align-items-center">
                            <div class="button-1 btn link" (click)="IsCollapseAll(false)">Collapse all</div>
                            <div class="button-2 btn link" (click)="IsCollapseAll(true)">Expand All</div>
                        </div>
                    </div>

                    <!-- -----------------FulfillPart List section Start--------------- -->
                    <div class="card-desc example-container cstm_scrollbar_theme company-table">
                        <ul class="heading-section">
                            <li class="w-5 p-li">Menu</li>
                            <li class="w-5 p-li">Item</li>
                            <li class="p-li arrow-header"></li>
                            <li class="w-8 p-li">Part Number</li>
                            <li class="wd-25 p-li text-start">Description</li>
                            <li class="w-8 p-li">Ordered</li>
                            <li class="w-5 p-li">Needed</li>
                            <li class="w-5 p-li">Shipped</li>
                            <li class="w-5 p-li">Available</li>
                            <li class="w-5 p-li">B/O</li>
                            <li class="w-10 p-li">Est. Arrival Date</li>
                            <li class="w-5 p-li head-bg">Inventory</li>
                            <li class="w-8 p-li head-bg">Back Order</li>
                            <li class="w-5 p-li text-end head-bg">New PO</li>
                            <li class="w-5 p-li text-end btn-link" (click)="ShipAll(true)">Ship All</li>
                            <li class="w-8 p-li text-end">Status</li>
                        </ul>
                        <div formArrayName="FulfillPart">
                            <div *ngFor="let d of FulfillPart.controls; let i = index;" [formGroupName]="i">
                                <ng-container>
                                    <ul class="value-section">
                                        <li class="w-5 p-li d-flex align-items-center" data-label="Menu">
                                            <button mat-icon-button>
                                                <mat-icon class="icon_fix">more_vert</mat-icon>
                                            </button>
                                        </li>
                                        <li class="w-5 p-li" data-label="Item">{{i+1}}</li>
                                        <li class="p-li arrow-header" data-label=""><span
                                                *ngIf="childFulfillParts(i).length >0">
                                                <mat-icon class="mat-icon-rtl-mirror"
                                                    *ngIf="childFulfillParts(i).value[0].IsShow"
                                                    (click)="ShowRow(i,false)">
                                                    expand_more
                                                </mat-icon>
                                                <mat-icon class="mat-icon-rtl-mirror"
                                                    *ngIf="!childFulfillParts(i).value[0].IsShow"
                                                    (click)="ShowRow(i,true)">
                                                    keyboard_arrow_right
                                                </mat-icon>
                                            </span></li>
                                        <li class="w-8 p-li" data-label="Part Number">
                                            {{FulfillPart.value[i].partNumber}}</li>
                                        <li class="wd-25 p-li text-start" data-label="Description">
                                            {{FulfillPart.value[i].partName}}</li>
                                        <li class="w-8 p-li" data-label="Ordered">{{FulfillPart.value[i].quantity}}</li>
                                        <li class="w-5 p-li" data-label="Needed">{{FulfillPart.value[i].needed}}</li>
                                        <li class="w-5 p-li" data-label="Shipped">{{FulfillPart.value[i].shipped}}</li>
                                        <li class="w-5 p-li" data-label="Available">{{FulfillPart.value[i].available}}
                                        </li>
                                        <li class="w-5 p-li" data-label="B/O">{{FulfillPart.value[i].bo}}</li>
                                        <li class="w-10 p-li" data-label="Est. Arrival Date">{{(FulfillPart.value[i].bo
                                            ==0 ? '-'
                                            :
                                            FulfillPart.value[i].ead | date :'MM/dd/yy')}}</li>
                                        <ng-container *ngIf="FulfillPart.value[i].needed ==0">
                                            <li class="w-5 p-li">-</li>
                                            <li class="w-8 p-li">-</li>
                                            <li class="w-5 p-li text-end">-</li>
                                        </ng-container>
                                        <ng-container *ngIf="FulfillPart.value[i].needed !=0">
                                            <li class="w-5 p-li d-flex align-items-center  quantity-div"
                                                data-label="Inventory">
                                                <mat-form-field appearance="outline" class="width-change">
                                                    <input matInput type="number"
                                                        (input)="InvQty($event.target.value,'invQty',i)"
                                                        formControlName="invQty">
                                                </mat-form-field>
                                            </li>
                                            <li class="w-5 p-li d-flex align-items-center  quantity-div"
                                                data-label="Fulfilled from Back Order">
                                                <mat-form-field appearance="outline" class="width-change">
                                                    <input matInput type="number"
                                                        (input)="InvQty($event.target.value,'boQty',i)"
                                                        formControlName="boQty">
                                                </mat-form-field>
                                            </li>
                                            <li class="w-5 p-li text-end position-relative d-flex align-items-center  quantity-div"
                                                data-label="New PO">
                                                <mat-form-field appearance="outline" class="width-change">
                                                    <input matInput type="number"
                                                        (input)="InvQty($event.target.value,'npoQty',i)"
                                                        formControlName="npoQty">
                                                </mat-form-field>
                                                <mat-error class="total-quantity position-absolute"
                                                    *ngIf="d.get('invQty').hasError('Exceed'); else other">
                                                    Total exceeds the quantity needed.
                                                </mat-error>
                                                <ng-template #other>
                                                    <mat-error class="total-quantity position-absolute"
                                                        *ngIf="d.get('boQty').hasError('Exceed')">
                                                        Total exceeds the quantity needed.
                                                    </mat-error>
                                                    <mat-error class="total-quantity position-absolute"
                                                        *ngIf="d.get('npoQty').hasError('Exceed')">
                                                        Total exceeds the quantity needed.
                                                    </mat-error>
                                                </ng-template>
                                            </li>
                                        </ng-container>
                                        <li class="w-5 p-li text-end" data-label="Ship All"><input type="checkbox"
                                                formControlName="shipAll"
                                                (input)="ShipAll(false,i,$event.target.checked)" />
                                        </li>
                                        <li class="w-8 p-li text-end" data-label="Status">
                                            {{this.GetStatusName(FulfillPart.value[i].statusId)}}
                                        </li>
                                    </ul>
                                </ng-container>
                                <!-- Questions Section Start -->
                                <ng-container formArrayName="childFulfillParts"
                                    *ngFor="let q of childFulfillParts(i).controls; let j = index;">
                                    <ul class="value-section" [formGroupName]="j"
                                        *ngIf="childFulfillParts(i).value[j].IsShow">
                                        <li class="w-5 p-li"> <button mat-icon-button>
                                                <mat-icon class="icon_fix">more_vert</mat-icon>
                                            </button></li>
                                        <li class="w-5 p-li">{{i+1}}.{{j+1}}</li>
                                        <li class="p-li arrow-header"></li>
                                        <li class="w-8 p-li"></li>
                                        <li class="wd-25 p-li text-start"></li>
                                        <li class="w-8 p-li"></li>
                                        <li class="w-5 p-li"></li>
                                        <li class="w-5 p-li">{{childFulfillParts(i).value[j].shipped}}</li>
                                        <li class="w-5 p-li">Shipped:</li>
                                        <li class="w-5 p-li">{{childFulfillParts(i).value[j].shippedDate | date
                                            :'MM/dd/yy'}}</li>
                                        <li class="w-10 p-li">Tracking:</li>
                                        <li class="w-5 p-li head-bg">{{childFulfillParts(i).value[j].tracking}}</li>
                                        <li class="w-8 p-li head-bg"></li>
                                        <li class="w-5 p-li text-end head-bg"></li>
                                        <li class="w-5 p-li text-end"><input type="checkbox" checked disabled /></li>
                                        <li class="w-8 p-li text-end" data-label="Status">
                                            {{this.GetStatusName(childFulfillParts(i).value[j].statusId)}}</li>
                                    </ul>
                                </ng-container>
                                <!-- Questions Section End -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- --------------------------------------fullfiullment details section end here------------------------------ -->
        <!-- --------------------------------------------------- Order History flyout Start--------------------------------------------------->
        <diV *ngIf="IsHistoryFlyOut"> <app-sales-order-history-fly-out [HistoryFlyOutVal]="HistoryFlyOutVal"
                (CloseHistoryFlyOut)="CloseHistoryFlyOut()"></app-sales-order-history-fly-out>
        </diV>

        <!-- -- -------------------------------------------------Order History flyout End-------------------------------------------------->
    </form>
</section>