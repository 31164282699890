<section class="content-section-2" id="new-layout">
    <app-loader [loader]="loader"></app-loader>
    <div class="top-bredcumps">
        <div class="cstmbreadcum paddingmng-right">
            <div class="page_title_cstms">
                <a class="backbtns" *ngIf="IsAllCategory" (click)="Back()">
                    <mat-icon>keyboard_arrow_left</mat-icon>
                    <span>Back to List</span>
                </a>
                <ul class="link_current">
                    <li>
                        <span *ngIf="!IsAllCategory">Part Category List</span>
                        <span *ngIf="IsAllCategory">Part Category Details</span>
                    </li>
                </ul>
            </div>
            <div class="top-button">
                <div *ngIf="IsAllCategory">
                    <button class="cancel-button">
                        <span>Cancel</span>
                    </button>
                    <button class="save-button" (click)="ErrorVM()">
                        <span>Save</span>
                    </button>
                </div>
                <div *ngIf="!IsAllCategory">
                    <button class="save-button" (click)="GetCategoryById(null)">+ Add Category</button>
                </div>
            </div>
        </div>
    </div>
    <div class="content-section-1">
        <div class="container-fluid" *ngIf="!IsAllCategory">
            <div class="main-section">
                <div class="white-box1 cust-label">
                    <div class="card-desc1">
                        <div class="row detailSection">
                            <div class="col-md-12 filter-column">
                                <div class="row  ipad-search align-items-center">
                                    <div class="col-xl-6 col-lg-5 col-sm-6 col-6 search-filter-order">
                                        <app-search-bar [placeholder]="'Search'" [searchddl]="constants.allSearch"
                                            [dropDownList]="constants.SearchStatus"
                                            (searchEvent)="SearchCategory($event)"></app-search-bar>
                                    </div>
                                    <div class="collapse-section d-flex align-items-center">
                                        <div class="button-1 btn link" (click)="IsExpandAll(false)">Collapse All</div>
                                        <div class="button-2 btn link" (click)="IsExpandAll(true)">Expand All</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="white-box1 mt-12">
                    <div class="packaging-input-layout mt-4">
                        <div class="shipping-section">
                            <ul class="heading-section">
                                <li class="menu">Menu</li>
                                <li class="item">Item</li>
                                <li class="item"></li>
                                <li class="desc">Category Name</li>
                                <li class="part-n">Description</li>
                                <li class="uom">Parent</li>
                                <li class="ready">Category Type</li>
                                <li class="shiped">Purpose</li>
                                <li class="package">Status</li>
                                <li class="weight">Last Modified</li>
                                <li class="size">Modified By</li>
                            </ul>
                            <div class="shipping-row">
                                <div>
                                    <div class="shipping-box" *ngFor="let element of CategoryList;let i = index;">
                                        <ul class="value-section" *ngIf="element.level==1 || element.isShow"
                                            (click)="GetCategoryById(element)">
                                            <li class="menu" (click)="$event.stopPropagation()">
                                                <button mat-icon-button [matMenuTriggerFor]="DltCatFor"
                                                    #DltCat="matMenuTrigger" (click)="DltCat.openMenu()">
                                                    <mat-icon class="icon_fix">more_vert</mat-icon>
                                                </button>
                                                <mat-menu #DltCatFor="matMenu">
                                                    <span (mouseleave)="DltCat.closeMenu()">
                                                        <button mat-menu-item
                                                            (click)="DeleteWareHouseLocation(element.id)">
                                                            Delete Category
                                                        </button>
                                                    </span>
                                                </mat-menu>
                                            </li>
                                            <li class="item" data-label="item" (click)="$event.stopPropagation()">
                                                {{element.itemNo}}</li>
                                            <li class="item" data-label="item" (click)="$event.stopPropagation()">
                                                <mat-icon *ngIf="element.isChild" (click)="ExpandMore(element)">{{
                                                    element.collapsed?
                                                    'expand_more' :
                                                    'keyboard_arrow_right'}}</mat-icon>
                                            </li>
                                            <li class="desc">
                                                {{element.name}}
                                            </li>
                                            <li class="part-n">
                                                {{element.description}}
                                            </li>

                                            <li class="uom" data-label="UOM">{{element.level==1 ? 'None':
                                                element.parentName}}</li>
                                            <li class="ready" data-label="Ready"> Category Type </li>
                                            <li class="shiped">{{element.websiteName == ''? 'Internal
                                                Use':element.websiteName}}
                                            </li>
                                            <li class="package">
                                                {{element.isActive?'Active':'InActive'}}
                                            </li>
                                            <li class="weight" data-label="Weight">
                                                {{element.modifiedDate}}</li>
                                            <li class="size" data-label="Size">
                                                {{element.modifiedBy}}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- ----------------------------------------------------------table- section----------------------------- -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Detail Section Start -->
    <div class="content-section-1">
        <div class="container-fluid" *ngIf="IsAllCategory">
            <form [formGroup]="CategoryForm">
                <div class="row additional-section">
                    <div class="col-12 p-0">
                        <div class="card">
                            <mat-card class="example-card h-100 card_cst_one">
                                <mat-card-header class="addition-info-card-header">
                                    <mat-card-title class="mt-card-title addition-info-heading">Category
                                        Information</mat-card-title>
                                </mat-card-header>
                                <mat-card-content class="card_cstm-mngsd additional-card-content mt-2">
                                    <div class="row">
                                        <div
                                            class="col-xl-6 col-lg-12 col-12 col-pd-left col-pd-right desktop-padding-right ipad-card-1">
                                            <mat-form-field class="forShipping mb-3 p-0 w-100" appearance="outline">
                                                <mat-label>Part Category Name</mat-label>
                                                <input matInput type="text" formControlName="name">
                                            </mat-form-field>
                                        </div>
                                        <div
                                            class="col-xl-3 col-lg-12 col-12 col-pd-left col-pd-right desktop-padding-right ipad-card-1">
                                            <app-category-dropdown [CompanyId]="companyId"
                                                [CatId]="CategoryForm.value.parentId"
                                                (CategoryIds)="ParentCategory($event)"></app-category-dropdown>
                                        </div>
                                        <div
                                            class="col-xl-3 col-lg-12 col-12 col-pd-left col-pd-right desktop-padding-right ipad-card-1">
                                            <mat-form-field class="w-100 icon-change-input" appearance="outline">
                                                <mat-label>Status</mat-label>
                                                <mat-select placeholder="Shipping Terms" formControlName="isActive">
                                                    <mat-option [value]=true>
                                                        Active
                                                    </mat-option>
                                                    <mat-option [value]=false>
                                                        InActive
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div
                                            class="col-xl-6 col-lg-12 col-12 col-pd-left col-pd-right desktop-padding-right ipad-card-1">
                                            <mat-form-field class="forShipping mb-3 p-0 w-100" appearance="outline">
                                                <mat-label>Description</mat-label>
                                                <input matInput type="text" formControlName="description">
                                            </mat-form-field>
                                        </div>
                                        <div
                                            class="col-xl-3 col-lg-12 col-12 col-pd-left col-pd-right desktop-padding-right ipad-card-1">
                                            <mat-form-field class="w-100 icon-change-input" appearance="outline">
                                                <mat-label>Category Type</mat-label>
                                                <mat-select placeholder="Category Type"
                                                    formControlName="categoryTypeId">
                                                    <mat-option *ngFor="let ct of CategoryType" [value]="ct.id">
                                                        {{ct.type}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div
                                            class="col-xl-3 col-lg-12 col-12 col-pd-left col-pd-right desktop-padding-right ipad-card-1">
                                            <mat-form-field class="w-100 icon-change-input" appearance="outline">
                                                <mat-label>Purpose</mat-label>
                                                <mat-select placeholder="Purpose " formControlName="purpose"
                                                    (selectionChange)="changePurpose($event.value)">
                                                    <mat-option *ngFor="let p of Purpose" [value]="p.id">
                                                        {{p.type}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                        </div>
                        <div class="card">
                            <mat-card class="example-card h-100 card_cst_one">
                                <mat-card-header class="addition-info-card-header">
                                    <mat-card-title class="mt-card-title addition-info-heading">Category
                                        Information</mat-card-title>
                                </mat-card-header>
                                <mat-card-content class="card_cstm-mngsd additional-card-content mt-2">
                                    <div formArrayName="Ecommarce">
                                        <div class="row" *ngFor="let d of Ecommarce.controls; let i = index;"
                                            [formGroupName]="i">
                                            <div
                                                class="col-xl-6 col-lg-12 col-12 col-pd-left col-pd-right desktop-padding-right ipad-card-1">
                                                <mat-form-field class="w-100 icon-change-input" appearance="outline">
                                                    <mat-label>Website</mat-label>
                                                    <mat-select placeholder="Shipping Terms"
                                                        formControlName="websiteId">
                                                        <mat-option [value]="0">
                                                            Select One
                                                        </mat-option>
                                                        <mat-option *ngFor="let ecom of EcoomerceAPIList"
                                                            [value]="ecom.id">
                                                            {{ecom.website}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                            <div class="col-xl-6">
                                                <div class="col-12">
                                                    <div class="row">
                                                        <div class="col-5">
                                                            <mat-form-field class="w-100 icon-change-input"
                                                                appearance="outline">
                                                                <mat-label>Plateform Type</mat-label>
                                                                <mat-select placeholder="Plateform Type"
                                                                    formControlName="plateformTypeId">
                                                                    <mat-option [value]="0">
                                                                        Select One
                                                                    </mat-option>
                                                                    <mat-option *ngFor="let pt of PlateformType"
                                                                        [value]="pt.id">
                                                                        {{pt.type}}
                                                                    </mat-option>
                                                                </mat-select>
                                                            </mat-form-field>
                                                        </div>
                                                        <div class="col-5">
                                                            <mat-form-field class="forShipping mb-3 p-0 w-100"
                                                                appearance="outline">
                                                                <mat-label>eCommerce Id</mat-label>
                                                                <input matInput type="text"
                                                                    formControlName="wordpressId" readonly>
                                                            </mat-form-field>
                                                        </div>
                                                        <div class="col-2">
                                                            <button mat-icon-button [matMenuTriggerFor]="EcommFor"
                                                                #EComm="matMenuTrigger" (click)="EComm.openMenu()">
                                                                <mat-icon class="icon_fix">more_vert</mat-icon>
                                                            </button>
                                                            <mat-menu #EcommFor="matMenu">
                                                                <span (mouseleave)="EComm.closeMenu()">
                                                                    <button mat-menu-item (click)="FailedMsg()">
                                                                        Export to eCommerce Site
                                                                    </button>
                                                                    <button mat-menu-item (click)="Warning()"
                                                                        [disabled]="Ecommarce.value[i].wordpressId==0">
                                                                        Import from eCommerce Site
                                                                    </button>
                                                                    <button mat-menu-item (click)="SuccessMsg()">
                                                                        Delete from eCommerce Site
                                                                    </button>
                                                                </span>
                                                            </mat-menu>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button disabled (click)="AddEcommerce()">+ Add Item</button>
                                </mat-card-content>
                            </mat-card>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <!-- Detail Section End -->
</section>