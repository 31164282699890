import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ConstantsService } from 'src/app/constants.service';
import { ISearchFilters } from 'src/app/shared/commonInterfaces';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.css']
})
export class SearchBarComponent {
  @Input() placeholder: string = 'Search';
  @Input() dropDownList: any;
  @Input() searchddl: any;
  @Output() searchEvent = new EventEmitter<any>();
  @Output() searchStatus = new EventEmitter<string>();
  //searchddl: any = this.constants.masterSearchFilterList.filter(x => x.id == searchfiltersEnum.All)[0].id;
  searchText: string = '';

  constructor(private constants: ConstantsService) {

  }
  search(text) {
    const obj: ISearchFilters ={
      SearchText: text,
      searchStatus: this.searchddl
    }
    this.searchEvent.emit(obj);
  }
}
