import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { error } from 'console';
import { forkJoin, map, switchMap } from 'rxjs';
import { ConstantsService } from 'src/app/constants.service';
import { CompanyService } from 'src/app/services/company/company.service';
import { GlobalChangeService } from 'src/app/services/global-change.service';
import { InventoryService } from 'src/app/services/inventory/inventory.service';
import { PartService } from 'src/app/services/part/part.service';
import { SharedService } from 'src/app/services/shared.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-inventory-detail',
  templateUrl: './inventory-detail.component.html',
  styleUrls: ['./inventory-detail.component.css', '../inventory-details.component.css']
})
export class InventoryDetailComponent implements OnInit {
  rolePermissionsList: any;
  permissionMessage: string;
  companyId: number;
  data: any;
  loader: boolean = false;
  showViewHistory: boolean = false;
  TrackingObj: any = [];
  invListHistory: any = [];
  invListHistoryfilter: any = [];
  // InvTypeList: any = [];
  InvActionList: any = [];
  InvActionListFull: any = [];
  InvActiveList: any = [];
  InvStatusList: any = [];
  InvDocTypeList: any = [];
  InvLocationList: any = [];
  locationLevelList: any = [];
  businessChannelList: any = [];
  PartTrackingList: any = [];
  CompanyWareHouseList: any = [];
  invReasonList: any = [];
  invlossReasonList: any = [];
  invgainReasonList: any = [];
  invmoveTypeList: any = [];
  BatchList: any = [];
  HBatchList: any = [];
  InventoryType: any = this.constants.InventoryActionType;
  batchId: any = this.constants.all;
  showNewTransaction: boolean = false;
  // IsScan: boolean = false;
  // ScanIndex: number = 0;
  isUpdate: boolean = false;
  invListDetails: any = [];
  BOListDetails: any = [];
  POListDetails: any = [];
  invDetailsColumns: string[] = this.constants.invDetailsColumns;
  BODetailsColumns: string[] = this.constants.BODetailsColumns;
  PODetailsColumns: string[] = this.constants.PODetailsColumns;
  defaulLocationForm: FormGroup;
  locationForm: FormGroup;
  partId: number;
  seletedLocation: any = {};
  seletedDefaultLocation: any = {};
  moveFromLocation: any = {};
  moveToLocation: any = {};
  SKU: string;
  infoItemDescription: string = '';
  infoTracking: any;
  l1List: string[] = [];
  l2List: string[] = [];
  l3List: string[] = [];
  l4List: string[] = [];
  level1List: string[] = [];
  level2List: string[] = [];
  level3List: string[] = [];
  level4List: string[] = [];
  serialNumberList: number[] = [];
  isSerialFlyout: boolean = false;
  partOnHand: any = 0;
  partAvailable: any = 0;
  partCommitted: any = 0;
  partBackOrdered: any = 0;
  flyoutObject: any = {};
  seriallotflyoutObj: any = {};
  LocationSetUp: any;
  HBatch: string;

  constructor(
    public inventoryService: InventoryService,
    public fb: FormBuilder,
    private router: Router,
    private globalChangeService: GlobalChangeService,
    private sharedService: SharedService,
    private companyService: CompanyService,
    private constants: ConstantsService,
    private partService: PartService,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
  ) {
    this.getTrackingList();
    // this.GetInventoryType();
    this.GetInventoryAction();
    this.GetInventoryActive();
    this.GetInventoryStatus();
    this.GetInventoryDocType();
    this.GetInventoryReason();
    //form creates
    this.locationFormCreate();
    this.defaultLocationFormCreate();
  }

  ngOnInit(): void {
    //For this we have to create this page in pages table
    if (localStorage.getItem('AuthHeader') === null || localStorage.getItem('AuthHeader') === undefined) {
      this.router.navigate(['']);
    }
    this.rolePermissionsList = JSON.parse(localStorage.getItem('rolePermissionsList'));
    this.rolePermissionsList = this.rolePermissionsList.filter(x => x.pageId == 1018);
    this.permissionMessage = '';
    if (this.rolePermissionsList != null && this.rolePermissionsList != undefined) {
      if (!this.rolePermissionsList[0].isView) {
        this.permissionMessage += 'view';
      } if (!this.rolePermissionsList[0].isInsert) {
        if (this.permissionMessage != '') {
          this.permissionMessage += ', ';
        }
        this.permissionMessage += 'add';
      } if (!this.rolePermissionsList[0].isUpdate) {
        if (this.permissionMessage != '') {
          this.permissionMessage += ', ';
        }
        this.permissionMessage += 'edit';
      } if (!this.rolePermissionsList[0].isDelete) {
        if (this.permissionMessage != '') {
          this.permissionMessage += ', ';
        }
        this.permissionMessage += 'delete';
      }
    }
    if (this.permissionMessage != '') {
      this.permissionMessage = 'You do not have access to ' + this.permissionMessage + '. Please contact to administrator!';
    }

    this.route.params.subscribe(
      params => {
        const id = Number(params['id']);
        const companyId = Number(params['companyId']);
        this.companyId = companyId;
        //this.companyId = this.globalChangeService.getGlobalCompany();
        if (this.companyId > 0) {
          if (id > 0) {
            this.CompanyDepedsFn(this.companyId, id);
          }
        } else {
          this.popupModal(this.constants.APIError, this.constants.SelectCompany);
        }
        //on change function 
        this.globalChangeService.dropdownChange$.subscribe((data) => {
          this.router.navigate(['/inventory-list'])
        })
      },
      error => {
        this.router.navigate(['/inventory-list']);
      }
    );

  }

  getTrackingList() {
    this.partService.GetPartTrackingList().subscribe(
      (data: any) => {
        if (data.length > 0) {
          this.PartTrackingList = data;
        }
      }
    )
  }

  CompanyDepedsFn(companyId: number, partId: number) {
    this.companyId = companyId;
    this.loader = true;
    this.companyService.GetCompanyById(companyId).pipe(switchMap((res1) =>
      this.companyService.GetWareHouseWithChild(companyId).pipe(switchMap((res2) =>
        this.sharedService.getLocationLevel(companyId, '').pipe(switchMap((res3) =>
          this.partService.GetPartsByCompanyId(companyId).pipe(
            map(res4 => [res1, res2, res3, res4])
          )
        ))
      ))
    )).subscribe(
      data => {
        this.loader = false;
        this.data = data;
        this.GetCompanyDetails(this.data[0]);
        if (this.data[1].data != null) {
          this.CompanyWareHouseList = this.data[1].data;
        }
        this.InvLocationList = this.data[2].dataList;
        this.selectedRow(this.data[3], partId);
      },
      err => {
        this.loader = false;
        this.popupModal(this.constants.APIError, this.constants.SomethingWentWrong);
      }
    );
  }

  GetCompanyDetails(data: any) {
    const response: any = data;
    if (response.statusCode == 200) {
      if (response.data.companyWareHouse != null) {
        const warehouseName = response.data.companyWareHouse.name;
        this.locationForm.patchValue({ WareHouseId: warehouseName });
      }
      if (response.data.company != null) {
        this.businessChannelList = JSON.parse(response.data.company.businessChannels);
      }
    }
  }

  CalculateInventoryQuantityById(data: any) {
    const response = data.data;
    if (response != null) {
      this.partOnHand = response.onHand;
      this.partAvailable = response.available;
      this.partCommitted = response.committed;
      this.partBackOrdered = response.backOrdered;
    }
  }

  defaultLocationFormCreate() {
    this.defaulLocationForm = this.fb.group({
      partId: new FormControl(0),
      wareHouseId: new FormControl(0),
      locationId: new FormControl(0),
      level1: new FormControl(''),
      level2: new FormControl(''),
      level3: new FormControl(''),
      level4: new FormControl(''),
      locationCapacity: new FormControl(0),
    });
  }

  locationFormCreate() {
    this.locationForm = this.fb.group({
      partId: new FormControl({ value: 0, disabled: true }),
      wareHouseId: new FormControl({ value: 0, disabled: true }),
      wareHouseName: new FormControl({ value: '', disabled: true }),
      locationId: new FormControl({ value: 0, disabled: true }),
      locationName: new FormControl({ value: '', disabled: true }),
      level1: new FormControl({ value: '', disabled: true }),
      level2: new FormControl({ value: '', disabled: true }),
      level3: new FormControl({ value: '', disabled: true }),
      level4: new FormControl({ value: '', disabled: true }),
      locationCapacity: new FormControl({ value: 0, disabled: true }),
    });
  }

  selectedRow(data: any, partId: number) {
    this.data = data;
    this.data = this.data.filter(part => part.id == partId)[0];
    if (this.data != undefined) {
      this.partId = this.data.id;
      this.SKU = this.data.partNo;
      this.infoItemDescription = this.data.name;
      this.infoTracking = this.data.partTracking;
      this.setDefaultLocation(this.data);
      if (Number(this.partId) > 0) {
        this.PostOperations(Number(this.partId));
      }
    } else {
      this.popupModal(this.constants.Alert, this.constants.SomethingWentWrong);
      this.router.navigate(['/inventory-list'])
    }
  }

  PostOperations(partId: number) {
    this.inventoryService.GetInventoryList(this.companyId, partId).pipe(switchMap((res1) =>
      this.inventoryService.GetPOList(this.companyId, partId).pipe(switchMap((res2) =>
        this.inventoryService.GetBOList(this.companyId, partId).pipe(switchMap((res3) =>
          this.inventoryService.GetBatchList(this.companyId, partId).pipe(switchMap((res4) =>
            this.inventoryService.GetCalculateInventoryQuantityById(this.companyId, partId).pipe(
              map(res5 => [res1, res2, res3, res4, res5])
            )
          ))
        ))
      ))
    )).subscribe(
      data => {
        this.loader = false;
        this.data = data;
        this.GetInventoryList(this.data[0]);
        this.GetPOList(this.data[1]);
        this.GetBOList(this.data[2]);
        this.GetBatchList(this.data[3]);
        this.CalculateInventoryQuantityById(this.data[4])
      },
      err => {
        this.loader = false;
        this.popupModal(this.constants.APIError, this.constants.SomethingWentWrong);
      }
    );
  }

  setDefaultLocation(object: any) {
    if (object.defaultInventoryLocationId != null && object.defaultInventoryLocationId > 0) {
      this.inventoryService.GetDefaultInventoryLocation(object.defaultInventoryLocationId).subscribe(
        (response: any) => {
          if (response.data != null) {
            this.setLocationLabels(response.data.locationId, 0)
            this.setDefaultFormValue(response.data);
          }
        },
        (error) => {
          this.popupModal(this.constants.APIError, this.constants.SomethingWentWrong);
        }
      );
    }
  }

  setDefaultFormValue(data: any) {
    this.locationForm.patchValue({
      partId: data.partId,
      wareHouseId: data.wareHouseId,
      wareHouseName: this.GetWareHouseValue(data.wareHouseId),
      locationId: data.locationId,
      locationName: this.GetLocationValue(data.locationId),
      level1: data.level1,
      level2: data.level2,
      level3: data.level3,
      level4: data.level4,
      locationCapacity: data.locationCapacity
    });
  }

  setLocationLabels(locationId: number, flag: number) {
    if (locationId > 0) {
      if (flag == 0) {
        this.seletedLocation = this.InvLocationList.filter(x => x.id == locationId)[0];
      } else if (flag == 1) {
        this.seletedDefaultLocation = this.InvLocationList.filter(x => x.id == locationId)[0];
      } else if (flag == 2) {
        this.moveFromLocation = this.InvLocationList.filter(x => x.id == locationId)[0];
      } else if (flag == 3) {
        this.moveToLocation = this.InvLocationList.filter(x => x.id == locationId)[0];
      }
    }
  }

  GetInventoryList(response: any) {
    this.invListDetails = [];
    this.invListDetails = response.data;
    let TotalInvBalance = 0;
    this.invListDetails.forEach(element => {
      TotalInvBalance = TotalInvBalance + element.balance;
    });
  }

  GetBOList(response: any) {
    this.BOListDetails = [];
    this.BOListDetails = response.data;
    let TotalBOBalance = 0;
    this.BOListDetails.forEach(element => {
      TotalBOBalance = TotalBOBalance + element.balance;
    });
  }

  GetPOList(response: any) {
    this.POListDetails = [];
    this.POListDetails = response.data;
    let TotalPOBalance = 0;
    this.POListDetails.forEach(element => {
      TotalPOBalance = TotalPOBalance + element.balance;
    });
  }

  GetBatch(trackingId): string {
    const indexOfDecimal = trackingId.indexOf('.');
    if (indexOfDecimal !== -1) {
      return trackingId.substring(0, indexOfDecimal);
    }
    return trackingId;
  }
  GetActionName(id): String {
    if (id != null && this.InvActionListFull.length > 0 && id > 0) {
      const actionList = this.InvActionListFull.filter(x => x.actionId == Number(id));
      if (actionList.length > 0) {
        return actionList[0].actionName;
      }
    }
    return '';
  }
  GetStatusName(id): String {
    if (id != null && this.InvStatusList.length > 0) {
      const statusList = this.InvStatusList.filter(x => x.statusId == Number(id));
      if (statusList.length > 0) {
        return statusList[0].statusValue;
      }
    }
    return '';
  }
  GetWareHouseValue(id): String {
    if (id != null && this.CompanyWareHouseList.length > 0) {
      const wareHouseList = this.CompanyWareHouseList.filter(x => x.id == Number(id));
      if (wareHouseList.length > 0) {
        return wareHouseList[0].name;
      }
    }
    return '';
  }
  GetLocationValue(id): String {
    if (id != null && this.InvLocationList.length > 0) {
      const locationList = this.InvLocationList.filter(x => x.id == Number(id));
      if (locationList.length > 0) {
        return locationList[0].locationName;
      }
    }
    return '';
  }

  GetReason(id): String {
    if (id != null && this.invReasonList.length > 0) {
      const reasonList = this.invReasonList.filter(x => x.reasonId == Number(id));
      if (reasonList.length > 0) {
        return reasonList[0].reasonCode;
      }
    }
    return '';
  }
  GetTrackingLabel(id): String {
    if (id != null && this.PartTrackingList.length > 0) {
      const trackingList = this.PartTrackingList.filter(x => x.id == Number(id));
      if (trackingList.length > 0) {
        return trackingList[0].name;
      }
    }
    return '';
  }

  GetSerialLotFlyout(inventoryId, trackingId, balance) {
    this.seriallotflyoutObj = {
      page: 'inventory',
      companyId: this.companyId,
      partId: this.partId,
      SKU: this.SKU,
      partName: this.infoItemDescription,
      inventoryId: inventoryId,
      trackingId: trackingId,
      balance: balance
    }
    if (trackingId == 3 || Number(balance) <= 0) {
      this.isSerialFlyout = false;
    } else {
      this.isSerialFlyout = true;
      // this.GetSerialLotList(inventoryId, Number(trackingId), Number(balance));
    }
  }

  SearchPart(event: any) {
  }

  historyclose(status: boolean) {
    this.showViewHistory = status;
  }

  ViewHistory(batchId: string) {
    this.batchId = batchId;
    if (this.batchId != '') {
      const batch = this.GetBatch(batchId);
      this.invListHistory = this.invListDetails.filter(x => x.transID.startsWith(batch));
      this.HBatch = batchId;
      this.showViewHistory = true;
    } else {
      this.invListHistory = this.invListDetails;
      this.showViewHistory = true;
    }
  }

  OpenTransFlyout(value: number) {
    this.showNewTransaction = true;
    if (this.companyId > 0 && this.partId > 0) {
      this.flyoutObject = {
        companyId: this.companyId,
        partId: this.partId,
        SKU: this.SKU,
        infoTracking: this.infoTracking,
        transactionTypeVal: value,
        isSerialFlyout: this.isSerialFlyout,
        defaulLocationValues: this.locationForm.getRawValue(),
      }
    }
  }

  closeTransFlyout(isShow) {
    this.showNewTransaction = isShow;
    this.PostOperations(Number(this.partId));
  }

  BackToList() {
    this.router.navigate(['/inventory-list'])
  }

  // GetInventoryType() {
  //   this.inventoryService.GetInventoryType().subscribe(
  //     (data: any) => {
  //       this.InvTypeList = data.data;
  //     }
  //   )
  // }
  GetInventoryAction() {
    this.inventoryService.GetInventoryAction().subscribe(
      (data: any) => {
        this.InvActionListFull = data.data;
        const actionIds = [1, 4, 9, 10];
        this.InvActionList = this.InvActionListFull.filter(item => actionIds.includes(item.actionId));
      }
    )
  }
  GetInventoryActive() {
    this.inventoryService.GetInventoryActive().subscribe(
      (data: any) => {
        this.InvActiveList = data.data;
      }
    )
  }
  GetInventoryStatus() {
    this.inventoryService.GetInventoryStatus().subscribe(
      (data: any) => {
        this.InvStatusList = data.data;
      }
    )
  }
  GetInventoryDocType() {
    this.inventoryService.GetInventoryDocType().subscribe(
      (data: any) => {
        this.InvDocTypeList = data.data;
      }
    )
  }
  GetInventoryReason() {
    this.inventoryService.GetInventoryReason().subscribe(
      (data: any) => {
        if (data.data != null) {
          this.invReasonList = data.data;
          this.invlossReasonList = data.data.filter(x => x.reasonFor == this.constants.reasonList.loss);
          this.invgainReasonList = data.data.filter(x => x.reasonFor == this.constants.reasonList.gain);
          this.invmoveTypeList = data.data.filter(x => x.reasonFor == this.constants.reasonList.movetype);
        }
      }
    )
  }

  popupModal(title: string, message: string) {
    Swal.fire({
      title: title,
      text: message,
      showCancelButton: true,
      cancelButtonText: 'Close',
    })
  }

  clearFormArray(formArray) {
    while (formArray.length !== 0) {
      formArray.removeAt(0)
    }
  }

  locNameChange(value, flag: number) {
    this.resetDefaultLocationLevels();
    this.setLocationLabels(value, flag);
    this.setLocationLevels(this.companyId, value, flag);
  }

  resetDefaultLocationLevels() {
    this.defaulLocationForm.patchValue({ level1: '', level2: '', level3: '', level4: '' });
  }

  setLocationLevels(companyId: number, locationId: number, flag: number) {
    this.inventoryService.GetLocationLevelsPositions(companyId, locationId).subscribe(
      (data: any) => {
        this.locationLevelList = data.dataList;
        this.loader = false;
        if (data.dataList.length > 0) {
          const level1 = data.dataList.map(function (item) { return item['aileName']; });
          const level2 = data.dataList.map(function (item) { return item['sectionName']; });
          const level3 = data.dataList.map(function (item) { return item['shelfName']; });
          const level4 = data.dataList.map(function (item) { return item['positionName']; });
          if (flag == 0) {
            this.l1List = [];
            this.l2List = [];
            this.l3List = [];
            this.l4List = [];
            this.l1List.push(...(level1.filter((value, index, array) => array.indexOf(value) === index)));
            this.l2List.push(...(level2.filter((value, index, array) => array.indexOf(value) === index)));
            this.l3List.push(...(level3.filter((value, index, array) => array.indexOf(value) === index)));
            this.l4List.push(...(level4.filter((value, index, array) => array.indexOf(value) === index)));
          } else if (flag == 1) {
            this.level1List = [];
            this.level2List = [];
            this.level3List = [];
            this.level4List = [];
            this.level1List.push(...(level1.filter((value, index, array) => array.indexOf(value) === index)));
            this.level2List.push(...(level2.filter((value, index, array) => array.indexOf(value) === index)));
            this.level3List.push(...(level3.filter((value, index, array) => array.indexOf(value) === index)));
            this.level4List.push(...(level4.filter((value, index, array) => array.indexOf(value) === index)));
          }
        }
      },
      (error: any) => {
        this.loader = false;
        this.popupModal(this.constants.APIError, this.constants.SomethingWentWrong);
      }
    );
  }

  closeSerialLotFlyout(object) {
    // this.isSerialFlyout = isShow;
    this.TrackingObj = object.trackingObj;
    this.isSerialFlyout = object.isShow;
  }

  GetBatchList(data: any) {
    this.BatchList = [];
    if (data.data.length > 0) {
      this.BatchList = data.data;
      this.HBatchList = data.data;
    }
  }

  onCapacityChange() {
    const capacity = this.defaulLocationForm.get('locationCapacity').value;
    this.defaulLocationForm.get('locationCapacity').setValue(this.isWholeNumber(capacity) ? Number(capacity) : 0);
  }

  isWholeNumber(input: any) {
    const value = Number(input);
    if (!isNaN(value) && value > 0) {
      return true;
    }
    return false;
  }

  editDefaultLocation() {
    //Navneet Start
    this.LocationSetUp = {
      "locationId": this.locationForm.getRawValue().locationId,
      "wareHouseId": this.locationForm.getRawValue().wareHouseId,
      "level1": this.locationForm.getRawValue().level1,
      "level2": this.locationForm.getRawValue().level2,
      "level3": this.locationForm.getRawValue().level3,
      "level4": this.locationForm.getRawValue().level4,
      "locationCapacity": this.locationForm.getRawValue().locationCapacity,
      "companyId": this.companyId,
      "pageName": 'Inv',
      "Heading":'Inventory Location',
      "SubHeading":'Please edit the Inventory Location for Default Warehouse Location.'
    }
    //Navneet End

    // $("#LocationModal").show();
    // if (this.locationForm.getRawValue().locationId > 0) {
    //   this.locNameChange(this.locationForm.getRawValue().locationId, 1);
    // }
    // this.defaulLocationForm.patchValue(this.locationForm.getRawValue());
  }

  closeLocationModel() {
    $("#LocationModal").hide();
  }

  saveDefaultLocation(value: any) {
    if (value != undefined && value != null) {
      this.loader = true;
      this.defaulLocationForm.get('partId').setValue(this.partId);
      this.defaulLocationForm.patchValue(value);
      this.inventoryService.AddInventoryLocation(this.defaulLocationForm.value, true).subscribe(
        (response: any) => {
          this.loader = false;
          if (response.data != null) {
            this.setLocationLabels(response.data.locationId, 0)
            this.setDefaultFormValue(response.data);
            this.LocationSetUp = undefined;
            // this.closeLocationModel();
          }
        },
        (error: any) => {
          this.loader = false;
        }
      )
    } else {
      this.LocationSetUp = undefined;
    }
  }

  validateNumber() {
    const quantity = this.defaulLocationForm.value.locationCapacity;
    this.defaulLocationForm.patchValue({ locationCapacity: (this.isWholeNumber(quantity) ? Number(quantity) : 1) });
  }
}
