<section class="content-section-2" id="new-layout">
  <div class="loading" *ngIf="loader">Loading&#8230;</div>
  <!-- -----------BREDCUMPS START HERE--------- -->
  <div class="top-bredcumps">
    <div class="page_title_cstms">
        <ul class="link_current">
          <li>
            <span>Inventory List</span>
          </li>
        </ul>
  </div>
  </div>
  <!-- -----------BREDCUMPS end HERE--------- -->
   <div class="content-section-1">
  <div class="container-fluid">
    <div class="row mb-3 inventory-row">
      <div class="col-xl-11 col-12">
        <div class="row">
          <div class="col-md-3 col-6 first-card">
            <mat-card class="example-card p-0">
              <mat-card-content>
                <div class="col-lg-12 main-card">
                  <span>On Hand</span>
                </div>
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-12 d-flex flex-column">
                    <div class="count-title">{{OnHand}}</div>
                    <div class="count-subtitle"><span>Total Quantity</span></div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-12 d-flex flex-column">
                    <div class="count-title">{{OnHandTotal | currency}}</div>
                    <div class="count-subtitle"><span>Total Cost</span></div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>

          <div class="col-md-3 col-6 mb-3 second-card">
            <mat-card class="example-card p-0">
              <mat-card-content>
                <div class="col-lg-12 main-card">
                  <span>Available</span>
                </div>
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-12 d-flex flex-column">
                    <div class="count-title">{{Available}}</div>
                    <div class="count-subtitle"><span>Total Quantity</span></div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-12 d-flex flex-column">
                    <div class="count-title">{{AvailableTotal | currency}}</div>
                    <div class="count-subtitle"><span>Total Cost</span></div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>

          <div class="col-md-3 col-6 mb-3 third-card">
            <mat-card class="example-card p-0">
              <mat-card-content>
                <div class="col-lg-12 main-card">
                  <span>Committed</span>
                </div>
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-12 d-flex flex-column">
                    <div class="count-title">{{Committed}}</div>
                    <div class="count-subtitle"><span>Total Quantity</span></div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-12 d-flex flex-column">
                    <div class="count-title">{{CommittedTotal | currency}}</div>
                    <div class="count-subtitle"><span>Total Cost</span></div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>

          <div class="col-md-3 col-6 mb-3 fourth-card">
            <mat-card class="example-card p-0">
              <mat-card-content>
                <div class="col-lg-12 main-card">
                  <span>Back Ordered</span>
                </div>
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-12 d-flex flex-column">
                    <div class="count-title">{{BackOrdered}}</div>
                    <div class="count-subtitle"><span>Total Quantity</span></div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-12 d-flex flex-column">
                    <div class="count-title">{{BackOrderedTotal | currency}}</div>
                    <div class="count-subtitle"><span>Total Cost</span></div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </div>

    </div>
    <div class="row mb-3">
      <div class="col-xl-11 col-12">
        <div class="row align-items-center">
          <div class="col-lg-3 col-md-4 desktop-order-2 select-arrow-change-icon">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Filter by Inventory Type</mat-label>
              <mat-select placeholder="Filter by Inventory Type" [(ngModel)]="MasterInvType"
                (selectionChange)="OnMaterInvTypeChange()" multiple>
                <mat-option [value]="0">All</mat-option>
                <mat-option *ngFor="let ilt of InvTypeList" [value]="ilt.typeId">{{ilt.typeValue}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-lg-3 col-md-6 desktop-order-3 ipad-none">
          </div>
          <div class="col-lg-6 col-md-8 desktop-order-1 select-arrow-change-icon">
            <app-search-bar [placeholder]="InventorySearchPlaceHolder" [searchddl]="defaultSearchValue" [dropDownList]="SearchTypes" (searchEvent)="Search($event)"></app-search-bar>
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-3 inventory-table">
      <div class="col-xl-11 col-12">
        <div class="table-scroll mat-elevation-z8 master-table box-shadow-none">
          <mat-table [dataSource]="invMasterList" class="table-inventory">
            <ng-container matColumnDef="item">
              <mat-header-cell *matHeaderCellDef class="inventory-table-header"> Item </mat-header-cell>
              <mat-cell *matCellDef="let element; let i = index;" data-label="Item" class="inventory-mat-cell"> {{i+1}}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="itemNumber">
              <mat-header-cell *matHeaderCellDef class="inventory-table-header"> Item Number </mat-header-cell>
              <mat-cell *matCellDef="let element" data-label="Item Number" class="inventory-mat-cell">
                {{element.itemNumber}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="itemDescription">
              <mat-header-cell *matHeaderCellDef class="inventory-table-header"> Item Description </mat-header-cell>
              <mat-cell *matCellDef="let element" data-label="Item Description" class="inventory-mat-cell">
                {{element.itemDescription}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="uom">
              <mat-header-cell *matHeaderCellDef class="inventory-table-header"> UOM </mat-header-cell>
              <mat-cell *matCellDef="let element" data-label="UOM" class="inventory-mat-cell"> {{element.uom}}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="inventoryType">
              <mat-header-cell *matHeaderCellDef class="inventory-table-header"> Inventory Type </mat-header-cell>
              <mat-cell *matCellDef="let element" data-label="Inventory Type" class="inventory-mat-cell">
                {{GetInventoryTypeValue(element.inventoryType)}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="onHand">
              <mat-header-cell *matHeaderCellDef class="inventory-table-header"> On Hand </mat-header-cell>
              <mat-cell *matCellDef="let element" data-label="On Hand" class="inventory-mat-cell"> {{element.onHand}}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="available">
              <mat-header-cell *matHeaderCellDef class="inventory-table-header"> Available </mat-header-cell>
              <mat-cell *matCellDef="let element" data-label="Available" class="inventory-mat-cell">
                {{element.available}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="committed">
              <mat-header-cell *matHeaderCellDef class="inventory-table-header"> Committed </mat-header-cell>
              <mat-cell *matCellDef="let element" data-label="Committed" class="inventory-mat-cell">
                {{element.committed}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="totalCost">
              <mat-header-cell *matHeaderCellDef class="inventory-table-header"> Total Cost </mat-header-cell>
              <mat-cell *matCellDef="let element" data-label="Total cost" class="inventory-mat-cell">
                {{element.totalCost | currency}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="backOrdered">
              <mat-header-cell *matHeaderCellDef class="inventory-table-header"> Back Ordered </mat-header-cell>
              <mat-cell *matCellDef="let element" data-label="Back Ordered" class="inventory-mat-cell">
                {{element.backOrdered}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="boAvailable">
              <mat-header-cell *matHeaderCellDef class="inventory-table-header"> B/O Available </mat-header-cell>
              <mat-cell *matCellDef="let element" data-label="B/O Available" class="inventory-mat-cell">
                {{element.boAvailable}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="boCommitted">
              <mat-header-cell *matHeaderCellDef class="inventory-table-header"> B/O Committed </mat-header-cell>
              <mat-cell *matCellDef="let element" data-label="B/O Committed" class="inventory-mat-cell">
                {{element.boCommitted}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="boTotalCost">
              <mat-header-cell *matHeaderCellDef class="inventory-table-header"> B/O TotalCost </mat-header-cell>
              <mat-cell *matCellDef="let element" data-label="B/O TotalCost" class="inventory-mat-cell">
                {{element.boTotalCost | currency}} </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="invListColumns sticky: true"
              class="header-style inventory-header"></mat-header-row>
            <mat-row *matRowDef="let row; columns: invListColumns;let i= index;" (click)="selectedRow(row)"
              [ngClass]="{'highlightTableColor': selectedRowIndex == i}" class="material-inventory-row"></mat-row>
          </mat-table>
        </div>
        <span class="fs-14">Total Count: {{invMasterList.length + ' of ' + PartsCount}}</span>
      </div>
    </div>

  </div>
</div>
</section>
