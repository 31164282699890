<section class="content-section pt-2" id="new-layout">
    <div class="loading" *ngIf="loader">Loading&#8230;</div>
    <div col-sm-12>
        <div class="row">
            <div class="col-6 mb-2 d-flex align-items-baseline text-xs-left">
                <div class="page-title">
                    <div class="bread-crumb">
                        <a [routerLink]="['/adminDashboard/']">Home</a>
                        <span class="mx-1">-</span>
                        <span class="active">Vendors</span>
                        <div *ngIf="permissionMessage!=''" class='btn-danger text-center'>{{permissionMessage}} </div>
                    </div>
                </div>
            </div>
            <div class="col-6 mb-2 text-right align-items-baseline">

            </div>
        </div>
    </div>



    <div class="row">
        <div class="col-md-12">
            <div class="white-box1 no_bg1">
                <div class="card-desc1">
                    <form [formGroup]="formSearch">
                        <div class="row detailSection">
                            <div class="col-md-12 container">
                                <div class="row mt-3">
                                    <div class="col-lg-2 col-md-6">
                                        <div class="d-flex align-items-center">
                                            <label class="customLabel mr-1" for="Type">Type</label>
                                            <select class="customInput" (change)="ChangeTCreateUpdate($event)"
                                                [(ngModel)]="TCreateUpdate" id="TCreateUpdate"
                                                formControlName="TCreateUpdate">
                                                <option value="1">Create</option>
                                                <option value="0">Update</option>
                                            </select>
                                        </div>
                                    </div>

                                    <!-- <div class="col-lg-3 col-md-6" [style.display]="SearchControl==true ? 'block' : 'none'">
                                        <div class="d-flex align-items-center required borderNone" [ngClass]="((value_search==0) && (SearchValidation)) ? 'validation_border': ''">
                                            <label class="customLabel mr-1"
                                                for="Type">Company</label>
                                            <ngx-dropdown-treeview-select [config]="config"
                                                class="dropdown-company customInput-dropdown" [items]="userGroupObjects_search"
                                                [(value)]="value_search" (valueChange)="onValueChange_search($event)">
                                            </ngx-dropdown-treeview-select>
                                        </div>
                                    </div> -->
                                    <div class="col-lg-5 col-md-12 mt-lg-0 mt-md-1"
                                        [style.display]="SearchControl==true ? 'block' : 'none'">

                                        <div class="d-flex align-items-center">
                                            <label class="customLabel mr-1 ws-nobreak" for="Vendor Name">Vendor
                                                Name</label>
                                            <input type="text" placeholder="Search Vendor Name" class="customInput"
                                                [(ngModel)]="vendorSearch" formControlName="vendorSearch"
                                                autocomplete="off" required
                                                [ngClass]="((!vendorSearch && SearchType==false) && (SearchValidation)) ? 'validation_border': ''"
                                                (keyup.enter)="searchVendor()">
                                            <!-- <div *ngIf="((!vendorSearch && SearchType==false) && (SearchValidation))"
                                                class="text-danger input-error err-fontSize header_error"
                                                style="">
                                                Enter Vendor Name.</div> -->
                                            <i class="fas fa-search cpointer mx-2 cpointer"
                                                (click)="searchVendor()"></i>
                                            <button type="button" class="btn blue-bg round-btn m-0"
                                                (click)="AllVendor()">All
                                                Vendors</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <ng-container *ngIf="rolePermissionsList && rolePermissionsList[0].isView">
                <div class="white-box1" [style.display]="ListShow==true ? 'block' : 'none'">
                    <div class="white-box-title1">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="customHeading mt-3">
                                    Vendor List<i class="fa fa-angle-{{ListAngle}} cpointer ml-2" aria-hidden="true"
                                        (click)="ListDivShowHide()"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-desc1 mt-2" [style.display]="ListDiv==true ? 'block' : 'none'">
                        <div>
                            <table class="row-border hover" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                                <thead>
                                    <tr>
                                        <th style="display:none;"></th>
                                        <th>Company Name</th>
                                        <th>Vendor Name</th>
                                        <th>Contact Person</th>
                                        <th>Phone No</th>
                                        <th>Email</th>
                                        <th data-orderable="false"> Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let data of vendorList">
                                        <th style="display:none;">{{data.id}}</th>
                                        <td>{{data.companyName}}</td>
                                        <td>{{data.vendorName}}</td>
                                        <td>{{data.name}}</td>
                                        <td>{{data.phoneNumber}}</td>
                                        <td>{{data.email}}</td>
                                        <td>
                                            <a *ngIf="rolePermissionsList && rolePermissionsList[0].isView"
                                                class="blue-text"><i class="fas fa-eye" data-toggle="modal"
                                                    data-target="#addhierarchy" (click)="viewUserDetails(data)"></i></a>
                                            <a *ngIf="rolePermissionsList && rolePermissionsList[0].isUpdate"
                                                class="green-text"><i class="fas fa-edit"
                                                    (click)="toggleEdit(data)"></i></a>
                                            <a *ngIf="rolePermissionsList && rolePermissionsList[0].isDelete"
                                                class="red-text"><i class="fas fa-trash-alt"
                                                    (click)="deletePopup(data,content)"></i></a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </ng-container>

            <div class="white-box1 no_bg1">
                <div class="customHeading mt-3">
                    Vendor Details
                </div>
                <div class="card-desc1">
                    <form [formGroup]="form">
                        <div class="row mt-2">
                            <!-- <div class="col-lg-4 col-md-12">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp required">
                                        <label class="customLabel">Company</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10 margin-phone borderNone" [ngClass]="((value==0) && (validation)) ? 'validation_border': ''">
                                        <ngx-dropdown-treeview-select [config]="config" class="dropdown-company customInput-dropdown"
                                                [items]="userGroupObjects" [(value)]="value"
                                                (valueChange)="onValueChange($event)">
                                            </ngx-dropdown-treeview-select>
                                    </div>
                                </div>
                            </div> -->
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp required">
                                        <label class="customLabel">Vendor Name</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="text" class="customInput" [(ngModel)]="vendorName"
                                            placeholder="Enter Vendor Name" name="vendorName"
                                            formControlName='vendorName' autocomplete="off" required
                                            [ngClass]="((!form.controls.vendorName.valid) && (validation)) ? 'validation_border': ''">
                                        <!-- <div *ngIf="((!form.controls.vendorName.valid) && (validation))"
                                                class="text-danger input-error err-fontSize">
                                                Enter Vendor Name.
                                            </div> -->
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">Vendor #</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="text" class="customInput" [(ngModel)]="VendorHash"
                                            placeholder="This will be auto generated." name="VendorHash" formControlName='VendorHash'
                                            autocomplete="off" maxlength="100" readonly>
                                        <!-- <input type="text" class="customInput" [(ngModel)]="VendorHash"
                                                placeholder="Enter Vendor #" name="VendorHash"
                                                formControlName='VendorHash' autocomplete="off" required
                                                maxlength="100" [ngClass]="((!form.controls.VendorHash.valid) && (validation)) ? 'validation_border': ''" readonly="true"> -->
                                        <!-- <div *ngIf="((!form.controls.VendorHash.valid) && (validation))"
                                                class="text-danger input-error err-fontSize">
                                                Enter Vendor #.
                                            </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-md-1 mt-0">
                            <div class="col-lg-4 col-md-12">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">Account # </label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="text" class="customInput" [(ngModel)]="account"
                                            placeholder="Enter Account" name="firstName" formControlName='account'
                                            autocomplete="off" maxlength="20">
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">WebSite URL </label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="text" class="customInput" [(ngModel)]="website"
                                            placeholder="Enter website" name="lastName" formControlName='website'
                                            autocomplete="off" maxlength="50">
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">Terms</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <select class="customInput pl-1px" formControlName='termsId'
                                            (ngModelChange)="changeTerms($event)" [(ngModel)]="selectedtermsValue">
                                            <option value=''>Select Terms</option>
                                            <option *ngFor="let item of termsData" [value]="item.id">
                                                {{item.name}}
                                            </option>
                                        </select>
                                    </div>



                                </div>
                            </div>
                        </div>
                        <div class="row mt-md-1 mt-0">
                            <div class="col-lg-4 col-md-12">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">Shipping</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <select class="customInput pl-1px" formControlName='shippingId'
                                            (ngModelChange)="changeShipping($event)"
                                            [(ngModel)]="selectedshippingValue">
                                            <option value=''>Select Shipping</option>
                                            <option *ngFor="let item of shippingData" [value]="item.id">
                                                {{item.name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">Carrier</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <select class="customInput pl-1px" formControlName='carrierId'
                                            (ngModelChange)="changeCarrier($event)" [(ngModel)]="selectedCarrierValue">
                                            <option value=''>Select Carrier</option>
                                            <option *ngFor="let item of carrierData" [value]="item.id">
                                                {{item.name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">FOB</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <select class="customInput pl-1px" formControlName='FobPointId'
                                            [(ngModel)]="selectedFobPointValue">
                                            <option value=''>Select FOB</option>
                                            <option *ngFor="let item of FobPoint" [value]="item.id">
                                                {{item.name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-md-1 mt-0">
                            <div class="col-lg-4 col-md-12">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">Contact First Name</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="text" class="customInput" [(ngModel)]="firstName"
                                            placeholder="Enter First Name" name="firstName" formControlName='firstName'
                                            autocomplete="off" maxlength="50">
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">Contact Last Name</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="text" class="customInput" [(ngModel)]="lastName"
                                            placeholder="Enter Last Name" name="lastName" formControlName='lastName'
                                            autocomplete="off" maxlength="50">
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">Email</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="text" class="customInput" [(ngModel)]="email"
                                            placeholder="Enter Email" name="email" formControlName='email'
                                            autocomplete="off"
                                            pattern="^[0-9a-zA-Z._%+-]+@[0-9a-zA-Z.-]+\.[a-zA-Z]{2,3}$" maxlength="50"
                                            [ngClass]="((!form.controls.email.valid) && (validation)) ? 'validation_border': ''">
                                        <div *ngIf="((!form.controls.email.valid && form.controls.email.value!='' && form.controls.email.value!=undefined) && (validation))"
                                            class="text-danger input-error">Enter Valid Email</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-md-1 mt-0">
                            <div class="col-lg-4 col-md-12">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">Phone Number</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="text" class="customInput PhoneValidation"
                                            (keypress)="validatePhoneNo($event.target)" [(ngModel)]="phoneNumber"
                                            placeholder="Enter Phone Number (xxx)xxx-xxx" name="phoneNumber"
                                            formControlName='phoneNumber' autocomplete="off" maxlength="14"
                                            pattern="^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$">
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">Extension #</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="text" class="customInput" [(ngModel)]="extension"
                                            placeholder="Enter Extension " name="extension" formControlName="extension"
                                            autocomplete="disabled" maxlength="10">
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">Fax #</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="text" class="customInput PhoneValidation"
                                            (keypress)="validateFax($event.target)" [(ngModel)]="Fax"
                                            placeholder="Enter Fax (xxx)xxx-xxx" name="Fax" formControlName='Fax'
                                            autocomplete="off" maxlength="14"
                                            pattern="^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-md-1 mt-0">
                            <div class="col-lg-4 col-md-12">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">Address Line 1</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="text" class="customInput" [(ngModel)]="addressLine1"
                                            placeholder="Enter Address Line 1" name="addressLine1"
                                            formControlName='addressLine1' autocomplete="off" maxlength="50">
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">Address Line 2</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="text" class="customInput" [(ngModel)]="addressLine2"
                                            placeholder="Enter Address Line 2" name="addressLine2"
                                            formControlName='addressLine2' autocomplete="off" maxlength="50">
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">Zip</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <div *ngIf="(countrySelected=='231')">
                                            <input type="text" class="customInput NumberOnly" [(ngModel)]="zipCode"
                                                placeholder="Enter Zip xxxxx-xxxx" name="zipCode"
                                                pattern="\d{5}-?(\d{4})?" (keypress)="validateZipcode($event.target)"
                                                formControlName="zipCode" autocomplete="disabled" maxlength="10">

                                            <div *ngIf="((!form.controls.zipCode.valid && form.controls.zipCode.value!='' && form.controls.zipCode.value!=undefined) && (validation))"
                                                class="text-danger input-error">Enter Valid Zip.eg-xxxxx-xxxx</div>
                                        </div>
                                        <div *ngIf="(countrySelected!='231')">
                                            <input type="text" class="customInput" [(ngModel)]="zipCode"
                                                placeholder="Enter Zip xxxxx-xxxx" name="zipCode"
                                                formControlName="zipCode" autocomplete="disabled" maxlength="10" />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-md-1 mt-0">
                            <div class="col-lg-4 col-md-12">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">City</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="text" class="customInput" [(ngModel)]="city"
                                            placeholder="Enter City" name="city" formControlName='city'
                                            autocomplete="off" maxlength="50">
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">Country</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <select class="customInput" formControlName='countrySelected'
                                            (change)="changeCountry($event)" [(ngModel)]="countrySelected">
                                            <option value=''>Select Country</option>
                                            <option *ngFor="let item of countrList" [value]="item.id">
                                                {{item.name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">State</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <select class="customInput" formControlName='stateId'
                                            (ngModelChange)="changeState($event)" [(ngModel)]="selectedStateValue"
                                            required>
                                            <option value=''>Select State</option>
                                            <option *ngFor="let item of stateData" [value]="item.id">
                                                {{item.name}}
                                            </option>
                                        </select>
                                        <!-- <div *ngIf="((!form.controls.stateId.valid) && (validation))"
                                            class="text-danger input-error">
                                            Select State.</div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-md-1 mt-0">
                            <div class="col-lg-2 col-md-2 trp">
                                <label class="customLabel padding-r">Descriptions</label>
                            </div>
                            <div class="col-lg-10 col-md-10">
                                <input type="text" class="customInput customDescription customDescription2"
                                    [(ngModel)]="descriptions" placeholder="Description" name="descriptions"
                                    formControlName='descriptions' maxlength="100">
                            </div>
                        </div>
                        <div class="row mt-1">
                            <div class="col-lg-2 col-md-2 trp">
                                <label class="customLabel padding-r">Notes</label>
                            </div>
                            <div class="col-lg-10 col-md-10">
                                <textarea [(ngModel)]="VendorNotes" rows="3" cols="5"
                                    class="customInput customerTextarea customerTextarea2" placeholder="Enter Notes"
                                    formControlName="VendorNotes"></textarea>
                            </div>
                        </div>
                        <div class="row mt-0">
                            <div class="col-lg-4 col-md-12">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">PO Email</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="text" class="customInput" [(ngModel)]="poEmail"
                                            placeholder="Enter PO Email Address" name="poEmail"
                                            formControlName='poEmail' autocomplete="off" maxlength="50">
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">Addt'l Discount</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="text" class="customInput" [(ngModel)]="AdditionalDiscount"
                                            placeholder="Additional Discount" name="AdditionalDiscount"
                                            (keypress)="keyPressNumbers($event)" formControlName='AdditionalDiscount'
                                            autocomplete="off" maxlength="6">
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">Free Shipping</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="text" class="customInput" [(ngModel)]="FreeShipping"
                                            placeholder="$ Free Shipping" name="FreeShipping"
                                            formControlName='FreeShipping' autocomplete="off" maxlength="6">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="submit-from text-right mt-1 p-0">
                            <button type="button" *ngIf="rolePermissionsList && rolePermissionsList[0].isInsert"
                                class="btn blue-bg round-btn" (click)="addNewUser()"><i class="fas fa-save"
                                    aria-hidden="true"></i>{{buttonValue}}</button>
                            <button type="button" class="btn darkred-bg round-btn" *ngIf="clearbutton==true"
                                (click)="resetvendorData()"><i class="fas fa-redo" aria-hidden="true"></i>
                                Clear </button>
                            <button type="button" class="btn darkred-bg round-btn" *ngIf="clearbutton==false"
                                (click)="Vendorback()"><i class="fas fa" aria-hidden="true"></i>
                                Exit </button>
                        </div>
                    </form>
                </div>

            </div>



        </div>
    </div>

</section>

<div class="modal fade" id="addhierarchy" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog  modal-dialog-centered modal-lg" role="document">
        <div class="modal-content border-none">
            <div class="modal-header info-popup-header p-2">
                <h5 class="modal-title info-popup-title" id="exampleModalLabel">Vendor Details</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="card-desc1">
                    <div class="modal-body bread-crumb">
                        <form [formGroup]="form">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <label for="" class="customLabel">Company Name :</label>
                                        </div>
                                        <div class="col-md-8">
                                            <span>{{data.companyName}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <label for="" class="customLabel">Vendor Name:</label>
                                        </div>
                                        <div class="col-md-8">
                                            <span>{{data.vendorName}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-1">
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <label for="" class="customLabel">Phone Number :</label>
                                        </div>
                                        <div class="col-md-8">
                                            <span>{{data.phoneNumber}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <label for="" class="customLabel">Address 1 :</label>
                                        </div>
                                        <div class="col-md-8">
                                            <span>{{data.addressLine1}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-1">
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <label for="" class="customLabel">Address 2:</label>
                                        </div>
                                        <div class="col-md-8">
                                            <span>{{data.addressLine2}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <label for="" class="customLabel">Email :</label>
                                        </div>
                                        <div class="col-md-8">
                                            <span>{{data.email}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-1">
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <label for="" class="customLabel">State :</label>
                                        </div>
                                        <div class="col-md-8">
                                            <span>{{data.stateName}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <label for="" class="customLabel">City :</label>
                                        </div>
                                        <div class="col-md-8">
                                            <span>{{data.city}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-1">
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <label for="" class="customLabel"> Zip Code :</label>
                                        </div>
                                        <div class="col-md-8">
                                            <span>{{data.zipCode}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <label for="" class="customLabel">Vendor # :</label>
                                        </div>
                                        <div class="col-md-8">
                                            <span>{{data.vendorHash}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-1">
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <label for="" class="customLabel">Fax #:</label>
                                        </div>
                                        <div class="col-md-8">
                                            <span>{{data.fax}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <label for="" class="customLabel">Descriptions :</label>
                                        </div>
                                        <div class="col-md-8">
                                            <span>{{data.descriptions}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-1">
                                <div class="col-md-2">
                                    <label for="" class="customLabel">Notes :</label>
                                </div>
                                <div class="col-md-10">
                                    <span>{{data.vendorNotes}}</span>
                                </div>
                            </div>
                            <div class="row mt-1">
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <label for="" class="customLabel">PO Email :</label>
                                        </div>
                                        <div class="col-md-8">
                                            <span>{{data.poEmail}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="modal-footer p-1">
                <button type="button" class="btn darkred-bg round-btn" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<ng-template #content let-c="close" let-d="dismiss">
    <h4 class="modal-title darkred-bg alert_heading sweet-heading"></h4>
    <button type="button" class="close btn alert_modal" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body">
        <div class="thank-you-pop">
            <img src="./assets/image/popup-alert.png" alt="">
            <h1>Confirmation</h1>
            <p class="mt-1">{{deleteDetail}}</p>
        </div>
    </div>
    <div class="modal-footer sweet-footer text-center p-1">
        <button type="button" class="btn green-bg round-btn m-0 mr-1"
            (click)="deleteVendor(deletedItem); c('Close click')">Yes</button>
        <button type="button" class="btn red-bg round-btn m-0" (click)="c('Close click')">No</button>
    </div>
</ng-template>
<div class="modal" id="add_success">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="addsuccess()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You!</h1>
                    <p>Record Added Successfully</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="addsuccess()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="add_update">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="updateitem()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You!</h1>
                    <p>Record Update Successfully</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="updateitem()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="delete_success">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="deleteitem()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You!</h1>
                    <p>Record Deleted Successfully</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="deleteitem()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="wrong_msg">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="msgitem()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p>Something went wrong</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="msgitem()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="modalExist">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="closeExist()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p>Vendor already exist.</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="closeExist()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>