<section>
    <div class="container">
        <form [formGroup]="eoqform">
            <div class="row">
                <div class="col-lg-12 mb-3 border-bottom">
                    <h1>EOQ Calculator</h1>
                    <p>Complete the form below to calculate the Economic Order Quantity</p>
                </div>
                <div class="col-lg-3 mb-3">
                    <mat-form-field appearance="outline">
                        <mat-label>Annual Usage in Units</mat-label>
                        <input matInput class="w-100" formControlName="units"
                             placeholder="Annual Usage in Units" />
                    </mat-form-field>
                </div>
                <div class="col-lg-9 mb-3">
                    <p>This is the number of units historically used or sold in a year or anticipate using or selling in one year.</p>
                </div>
                <div class="col-lg-3 mb-3">
                    <mat-form-field appearance="outline">
                        <mat-label>Ordering Cost</mat-label>
                        <input matInput class="w-100" formControlName="oc"
                             placeholder="Ordering Cost" />
                        <span matTextPrefix>$&nbsp;</span>
                    </mat-form-field>
                </div>
                <div class="col-lg-9 mb-3">
                    <p>This is the Ordering Cost and is the sum of all costs of placing a Purchase Order (e.g., administrative costs, shipping fees, etc)</p>
                </div>
                <div class="col-lg-3 mb-3">
                    <mat-form-field appearance="outline">
                        <mat-label>Annualized Unit Cost</mat-label>
                        <input matInput class="w-100" formControlName="auc"
                             placeholder="Annualized Unit Cost" />
                        <span matTextPrefix>$&nbsp;</span>
                    </mat-form-field>
                </div>
                <div class="col-lg-9 mb-3">
                    <p>This is the Annualized Average Cost to purchase one Unit including all volumne pricing discounts. (i.e. Buy 100 Units &#64; $5.00 and 250 Units &#64; $4.00 you would calculate: <br/>(100x5= 500) + (250x4= 1000) = 1500/ (100+250)=$4.28)</p>
                </div>
                <div class="col-lg-3 mb-3">
                    <mat-form-field appearance="outline">
                        <mat-label>Annual Carrying Cost per Unit</mat-label>
                        <input matInput class="w-100" formControlName="acc"
                             placeholder="Annual Carrying Cost per Unit" />
                        <span matTextPrefix>$&nbsp;</span>
                    </mat-form-field>
                </div>
                <div class="col-lg-9 mb-3">
                    <p>This is the cost of storing one unit of inventory for a year. It inclues costs such as: <br/>Storage space rental or utilities, Insurance, Taxes, Obsolescence and shrinkage, or other relevant costs.</p>
                </div>
                <div class="col-lg-3 mb-3">
                    <mat-form-field appearance="outline">
                        <mat-label>Economic Order Quantity</mat-label>
                        <input matInput class="w-100" formControlName="eoq"
                             placeholder="Economic Order Quantity"/>
                        <span matTextPrefix>$&nbsp;</span>
                    </mat-form-field>
                </div>
                <div class="col-lg-9 mb-3">
                    <p>This is the calculated quantity that should be ordered to minimize the Cost of Goods Sold.<br/> sqrt[2* Ann. Usage in Units * Ordering Cost / (Ann. Unit Cost + Ann. Carrying Cost/Unit)]</p>
                </div>
                <div class="col-lg-12">
                    <div class="footerbtnswrps">
                        <button type="button" (click)="CloseFlyout()" class="saveclosebnt">
                            Close</button>
                    </div>
                </div>
            </div>
        </form>
        
    </div>
</section>
