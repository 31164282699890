import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Init } from 'v8';

@Component({
  selector: 'app-eoq-calculator',
  templateUrl: './eoq-calculator.component.html',
  styleUrls: ['./eoq-calculator.component.css']
})
export class EoqCalculatorComponent implements OnInit{
  @Output() closeEvent = new EventEmitter<boolean>();
  eoqform: FormGroup;

  constructor(private fb: FormBuilder){

  }

  ngOnInit(): void {
    this.eoqform = this.fb.group({
      units: new FormControl(''),
      oc: new FormControl(''),
      auc: new FormControl(''),
      acc: new FormControl(''),
      eoq: new FormControl(''),
    });
  }
  CloseFlyout(){
    this.closeEvent.emit(false);
  }
}
