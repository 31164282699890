import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ConstantsService } from 'src/app/constants.service';
import { CustomerService } from 'src/app/services/customer/customer.service';

@Component({
  selector: 'app-search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.css']
})
export class SearchFilterComponent implements OnInit {
  AllCustomerList: any = [];
  StatusList: any = [];
  loader: boolean = false;
  Search: FormGroup;
  minToDate: any;
  maxToDate = new Date();
  minFromDate: any;
  maxFromDate = new Date();
  @Input() Data: any;
  @Output() DateFilter = new EventEmitter<any>();
  constructor(public customerService: CustomerService,
    private constants: ConstantsService,
  ) {

  }
  ngOnInit(): void {
    this.createSerchForm();
    this.BindData();
    if (this.Data.PageName == 'SOFilter') {
      this.GetCustomerList();
    }
  }
  createSerchForm() {
    this.Search = new FormGroup({
      "FilterTypeId": new FormControl(1),
      "StatusIds": new FormControl(),
      "CustomerIds": new FormControl(),
      "TimePeriodId": new FormControl(1),
      "FromDate": new FormControl(),
      "ToDate": new FormControl(),
      "PageName": new FormControl(''),
      "Source": new FormControl(''),
    });
  }
  BindData() {
    this.Search.patchValue({
      FilterTypeId: this.Data.FilterTypeId,
      StatusIds: this.Data.StatusIds,
      TimePeriodId: this.Data.TimePeriodId,
      FromDate: this.Data.FromDate,
      ToDate: this.Data.ToDate,
      CustomerIds: this.Data.CustomerIds,
      PageName: this.Data.PageName,
      Source: this.Data.Source
    })
    this.StatusList = this.Data.StatusList;
    this.changeFilterTypeId(this.Search.value.FilterTypeId);
  }
  changeTimePeriod(value: number) {
    switch (value) {
      case 2:
        //Last 30 Days
        this.SetDate(new Date(new Date().setDate(new Date().getDate() - 30)), new Date());
        break;
      case 3:
        //This Month
        this.SetDate(new Date(new Date().setDate(new Date().getMonth() + 1)), new Date());
        break;
      case 4:
        //This Week
        this.SetDate(new Date(new Date().setDate(new Date().getDate() + 1 - new Date().getDay())), new Date());
        break;
      case 5:
        //Today
        this.SetDate(new Date(), new Date());
        break;
      default:
        //Custom Range
        this.SetDate(null, null);
        break;
    }
  }
  SetDate(fromDate: any, toDate: any) {
    this.Search.patchValue({
      FromDate: fromDate,
      ToDate: toDate
    })
  }
  Apply() {
    localStorage.removeItem(this.Search.value.PageName);
    localStorage.setItem(this.Search.value.PageName, JSON.stringify(this.Search.value));
    this.DateFilter.emit(this.Search.value);
  }
  fromDateChange(value: any) {
    this.minToDate = value;
  }
  ToDateChange(value: any) {
    this.maxFromDate = value;
  }
  GetCustomerList() {
    this.customerService.GetAllCustomer(Number(localStorage.getItem('GlobalSelectedCompanyId')), '')
      .subscribe({
        next: (data: any) => {
          this.AllCustomerList = data.dataList;
          this.loader = false;
        },
        error: (err) => {
          this.AllCustomerList = [];
          this.loader = false;
        },
      });
  }
  changeFilterTypeId(value: number) {
    if (this.Search.value.PageName == 'FFilter') {
      switch (value) {
        case 2:
          this.StatusList = this.constants.ShipAs;
          break;
        case 3:
          this.StatusList = this.constants.FulfillmentType;
          break;
        default:
          this.StatusList = this.Data.StatusList;
          break;
      }
    } else if (this.Search.value.PageName == 'PickingFilter' && value == 2) {
      this.Search.patchValue({ StatusIds: 14 })
    }
    if (value == 1) {
      this.Search.patchValue({ TimePeriodId: value });
      this.changeTimePeriod(value)
    }
  }
  Source(): string {
    let val = this.Data.FilterTypeList.filter(v => v.id == this.Search.value.FilterTypeId);
    let name = '';
    if (val.length > 0) {
      name = this.Data.FilterTypeList.filter(v => v.id == this.Search.value.FilterTypeId)[0].value;
    }
    return name;
  }
}
