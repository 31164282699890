import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
// import { DropdownTreeviewComponent, TreeviewConfig, TreeviewItem } from 'ngx-treeview';
import { Subject } from 'rxjs';
import { ConstantsService } from '../../constants.service';
import { SharedService } from '../../services/shared.service';
import { PasswordStrengthValidator } from '../../users/PasswordStrengthValidator';
import { ConfirmationDialogService } from 'src/app/ConfirmationDialog/confirmation-dialog.service'
import { GlobalChangeService } from '../../services/global-change.service';
import Swal from 'sweetalert2';
import { CompanyService } from '../../services/company/company.service';
import { CustomerService } from '../../services/customer/customer.service';
import { debug } from 'console';

// interface Customer {
//   value: number;
//   text: string;
//   isActive?: boolean;
//   isEcommerece?: boolean;
//   wordpressId?: number;
//   children?: Customer[];
// }

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.css']
})
export class CustomersComponent implements AfterViewInit, OnDestroy, OnInit {
  [x: string]: any;
  // @ViewChild(DropdownTreeviewComponent, { static: false }) dropdownTreeviewComponent: DropdownTreeviewComponent;
  // @ViewChild(DataTableDirective, { static: false })
  // dtElement: DataTableDirective;
  @ViewChildren(DataTableDirective)
  dtElements: QueryList<DataTableDirective>;
  // config = TreeviewConfig.create({
  //   hasAllCheckBox: true,
  //   hasFilter: true,
  //   hasCollapseExpand: true,
  //   decoupleChildFromParent: false,
  //   maxHeight: 400
  // });
  isEmailExist: boolean = false;
  // items: TreeviewItem[];
  isGuestType: boolean = false;
  showCount: boolean = false;
  values: number[];
  arrayRows = [];
  arrayPath = {};
  tableRows = [];
  selectedCustomer: number = 0;
  selectedItem = '';
  data: any;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions1: DataTables.Settings = {};
  dtTrigger1: Subject<any> = new Subject<any>();
  customerList: any;
  buttonValue: string;
  btnCancelUpdate: string;
  value = 0;
  CompanyData: any;
  form: FormGroup;
  formSearch: FormGroup;
  userForm: FormGroup;
  title = 'modal2';
  DetailProfileForm: FormGroup;
  id: any;
  userId: any;
  MarkupMargin: any;
  deletedItem: string;
  deleteDetail: string;
  sampleform: any;
  companyName: any;
  errorMessage: any;
  companyList: any;
  userGroupObjects = new Array();
  stateData: any;
  stateUserData: any;
  selectedStateId: any;
  stateId: any;
  settingDetailCSS: any;
  settingLogoImage: any;
  recordAlreadyExist = false;
  loader: boolean = false;
  rolePermissionsList: any;
  userList: any;
  roleId: any;
  clearbutton: boolean = true;
  permissionMessage = '';
  countrList: any;
  validation = false;
  companyId: any;
  i: Number = 0;
  //Contact Details
  firstName: any;
  lastName: any;
  contactEmail: any;
  contactPhone: any;
  contactExtension: any;
  contactFax: any;
  contactAddressLine1: any;
  contactAddressLine2: any;
  contactZip: any;
  contactCity: any;
  selectedContactCountry: any = "231";
  selectedContactState: any = '';
  selectedCustomerType: any = '1';
  customerNameModal: '';
  countryDataContact: any;
  CustomerContacttypeId: any;
  stateDataContact: any;
  isUserEdit: boolean = false;
  //Bill Details
  billName: any;
  billEmail: any;
  billPhone: any;
  billExtension: any;
  billFax: any;
  billAddressLine1: any;
  billAddressLine2: any;
  NetTerms: any;
  CreditCard: any;
  PurchaseOrder: any;
  taxable: any;
  customerTypeVal: any;
  taxId: any;
  billinfo: any;
  billZip: any;
  billCity: any;
  billAtten: any;
  selectedBillCountry: any = "231";
  selectedBillState: any = '';
  countryDataBill: any;
  stateDataBill: any;
  statusFlag: boolean = false;
  //Ship Details
  shipName: any;
  shipEmail: any;
  shipPhone: any;
  shipExtension: any;
  shipFax: any;
  shipAddressLine1: any;
  shipAddressLine2: any;
  shipZip: any;
  shipCity: any;
  shipAtten: any;
  selectedShipCountry: any = "231";
  selectedShipState: any = '';
  countryDataShip: any;
  stateDataShip: any;
  isEcoomUser: boolean = false;
  Issocontact: boolean = false;
  //Customer Details
  customerName: any;
  CustomerHash: any;
  CustomerTypeList: any;
  RolesList: any = this.constants.RolesList;
  CustomerTypeId: any;
  enableCustomerRoleCancelBtn: boolean = false;
  enableCustomerRoleSaveBtn: boolean = false;
  enableCustomerRoleAddBtn: boolean = true;
  enableCustomerRoleEditBtn: boolean = true;
  selectedCustomerinModal: any;
  enableCustomerRoleEdit: boolean = false;
  customerEditableData: any;
  CustomerTypeIsActive: boolean;
  //email: any;
  phone: any;
  extension: any;
  Fax: any;
  addressLine1: any;
  addressLine2: any;
  zipCode: any;
  city: any;
  AdditionalDiscount: string;
  CollectAmount: any;
  countrySelected: any = "231";
  selectedStateValue = '';
  selectedUserValue = '';
  selectedValueStatus = '';
  selectedCustomerContactType: any = "0";
  stateName: any;
  customerNotes: any;
  DefaultLogoPDF: any;
  // User Details
  userfirstName: any;
  userlastName: any;
  useremail: any;
  userphone: any;
  usercity: any;
  userstate: any;
  useraddress: any;
  useraddress1: any;
  userpassword: any;
  changepassword: any;
  userpassworConfirm: any;
  changepassworConfirm: any;
  userextention: any;
  userzip: any;
  userfax: any;
  usercountry: any;
  customerContacttype: any = '';
  addPdf: boolean = false;
  salePdf: boolean = false;
  //Search
  TCreateUpdate: any = 1;
  customerSearch: any;
  SearchValidation: any;
  SearchType: any;
  SearchControl: boolean = false;
  ListShow: boolean = false;
  ListDiv: boolean = false;
  ListAngle: any;
  userGroupObjects_search = new Array();
  value_search: number;
  companyList_search: any;
  // items_search: TreeviewItem[];
  createdDateEdit: any;
  IsNonTaxable: Boolean = false;
  IsNonTaxable1: Boolean = false;
  NetTermsList: { id: number; name: string; }[];
  PurchaseOptionList: { id: number; name: string; }[];
  applicatioDocument: string;
  imageUrl: any = "/assets/image/add-part.png";
  ApplicationName: any;
  imageUrl1: any = "/assets/image/add-part.png";
  SalesTaxName: any
  MultipleImage = [];
  RestoreMultipleImage = [];
  MultipleImage1 = [];
  RestoreMultipleImage1 = [];
  fileToUpload: File;
  fileToUpload1: File;
  @ViewChild('Image') myInputVariable: ElementRef;
  @ViewChild('Image1') myInputVariable1: ElementRef;
  deletedItemImage: any;
  deleteimageType: any;
  IsUsertSave: boolean = true;
  IsUserUpdate: boolean = false;
  ImageType: string;
  customerProp: string;
  CustomerPartName: any;
  uservalidation: boolean = false;
  userPasswordvalidation: boolean = false;
  multiUserList: any = [];
  SaveModel: string;
  AlreadyEmail: string;
  EditUserId: any;
  AddMultiUser1: any = [];
  userdeleteDetail: string;
  userdeletedItem: any;
  isGuest: boolean = false;
  PartialShipment: boolean = false;
  OEM: boolean = false;
  CustomerWordPressId: any;
  selectedLink: string = "taxable";
  isSaveOrUpdate: boolean = false;
  //Address Validation Property
  address_line1: string;
  city_locality: string;
  state_province: string;
  postal_code: string;
  country_code: string;
  shipingcode: any;
  stateAbribationList: any;
  countryCodeAbri: any;
  addressValArr = [];
  addressValData: any;
  IsCollectAmount: boolean = false;
  FedexCollect: string = '';
  UPSCollect: string = '';
  IsCollectFedex: boolean = false;
  IsCollectUPS: boolean = false;
  disabledCollectFedex: boolean = true;
  disabledCollectUPS: boolean = true;
  showCustomerType: boolean = false;
  objShiping = {};
  savedisable: boolean = false;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  multiContactUserRoles: any = [];
  _CustomerRole: string = '';
  _CustomerChannel: string = '';

  constructor(
    public sharedService: SharedService,
    public companyService: CompanyService,
    public customerService: CustomerService,
    private constants: ConstantsService,
    private modalService: NgbModal,
    private router: Router, private route: ActivatedRoute, private _sanitizer: DomSanitizer,
    public confirmation: ConfirmationDialogService,
    private globalChangeService: GlobalChangeService

  ) {

  }

  ngOnInit(): void {
    if (localStorage.getItem('AuthHeader') === null || localStorage.getItem('AuthHeader') === undefined) {
      this.router.navigate(['']);
    }
    this.rolePermissionsList = JSON.parse(localStorage.getItem('rolePermissionsList'));
    this.rolePermissionsList = this.rolePermissionsList.filter(x => x.pageId == 10);
    this.permissionMessage = '';
    if (this.rolePermissionsList != null && this.rolePermissionsList != undefined) {
      if (!this.rolePermissionsList[0].isView) {
        this.permissionMessage += 'view';
      } if (!this.rolePermissionsList[0].isInsert) {
        if (this.permissionMessage != '') {
          this.permissionMessage += ', ';
        }
        this.permissionMessage += 'add';
      } if (!this.rolePermissionsList[0].isUpdate) {
        if (this.permissionMessage != '') {
          this.permissionMessage += ', ';
        }
        this.permissionMessage += 'edit';
      } if (!this.rolePermissionsList[0].isDelete) {
        if (this.permissionMessage != '') {
          this.permissionMessage += ', ';
        }
        this.permissionMessage += 'delete';
      }
    }
    if (this.permissionMessage != '') {
      this.permissionMessage = 'You do not have access to ' + this.permissionMessage + '. Please contact to administrator!';
    }

    this.userId = localStorage.getItem(this.constants.userId);
    this.companyId = localStorage.getItem(this.constants.companyId);
    this.settingDetailCSS = localStorage.getItem("settingDetailCSS");
    this.settingLogoImage = localStorage.getItem("settingImageLogo");
    this.SetSettingCSS(this.settingDetailCSS, this.settingLogoImage);

    // ankit commented
    // this.getAllCompany(this.companyId);
    // this.getAllCompany_search(this.companyId);
    this.GetAllCountryDetails();
    this.GetAllCountryContact();
    this.GetAllCountryBill();
    this.GetAllCountryShip();
    this.GetAllCustomerTypeList();
    this.GetRoles();
    this.dtOptions = {
      pagingType: this.constants.full_numbers,
      pageLength: 10,
      order: [[1, 'asc']]
    };
    this.dtOptions1 = {
      pagingType: this.constants.full_numbers,
      pageLength: 10,
      ordering: false
    };

    this.form = new FormGroup({
      'companyId': new FormControl(),
      'customerName': new FormControl(),
      'phone': new FormControl(),
      'extension': new FormControl(),
      //  'email': new FormControl('',Validators.compose([Validators.required, Validators.email])),
      'addressLine1': new FormControl(),
      'addressLine2': new FormControl(),
      'city': new FormControl(),
      'stateName': new FormControl('', Validators.compose([Validators.required, Validators.minLength(1)])),
      'zipCode': new FormControl(),
      'AdditionalDiscount': new FormControl(),
      'customerNotes': new FormControl(),
      'CustomerHash': new FormControl(),
      'CustomerWordPressId': new FormControl(),
      'Fax': new FormControl(),
      'countrySelected': new FormControl(),

      //Contact Information Property
      'firstName': new FormControl('', Validators.compose([Validators.required, Validators.minLength(1)])),
      'lastName': new FormControl(),
      'contactEmail': new FormControl('', Validators.compose([Validators.required, Validators.email])),
      'contactPhone': new FormControl(),
      'contactExtension': new FormControl(),
      'contactFax': new FormControl(),
      'contactAddressLine1': new FormControl(),
      'contactAddressLine2': new FormControl(),
      'contactZip': new FormControl(),
      'contactCity': new FormControl(),
      'contactCountry': new FormControl(),
      'contactState': new FormControl(),
      'customerType': new FormControl(),
      'selectedCustomerContactType': new FormControl(),
      'Issocontact': new FormControl(),
      'selectedItems': new FormControl(),


      // for role seloection
      'roleName': new FormControl('', Validators.required),
      'displayRoleName': new FormControl('', Validators.required),
      'editIcon': new FormControl(''),
      'status': new FormControl(''),
      'customerTypeName': new FormControl('', Validators.required),
      'addCustomerTypeName': new FormControl('', Validators.required),
      'addCustomerTypeIsActive': new FormControl(),
      'CustomerTypeIsActive': new FormControl(Validators.required),
      //Bill Information Property

      'billName': new FormControl('', Validators.compose([Validators.required, Validators.minLength(1)])),
      'billEmail': new FormControl('', Validators.compose([Validators.email])),
      'billPhone': new FormControl(),
      'billExtension': new FormControl(),
      'billFax': new FormControl(),
      'billAddressLine1': new FormControl(),
      'billAddressLine2': new FormControl(),
      'billZip': new FormControl(),
      'billCity': new FormControl(),
      'billAtten': new FormControl(),
      'billCountry': new FormControl(),
      'billState': new FormControl(),

      //Ship Information Property
      'shipName': new FormControl('', Validators.compose([Validators.required, Validators.minLength(1)])),
      'shipEmail': new FormControl('', Validators.compose([Validators.email])),
      'shipPhone': new FormControl(),
      'shipExtension': new FormControl(),
      'shipFax': new FormControl(),
      'shipAddressLine1': new FormControl(),
      'shipAddressLine2': new FormControl(),
      'shipZip': new FormControl(),
      'shipCity': new FormControl(),
      'shipAtten': new FormControl(),
      'shipCountry': new FormControl(),
      'shipState': new FormControl(),
      'NetTerms': new FormControl(),
      'CreditCard': new FormControl(),
      'PurchaseOrder': new FormControl(),
      'taxable': new FormControl(),
      'billinfo': new FormControl(),
      'taxId': new FormControl(),
      'applicatioDocument': new FormControl(),
      'salesTax': new FormControl(),
      'isGuest': new FormControl(),
      'PartialShipment': new FormControl(),
      'OEM': new FormControl(),
      'CollectAmount': new FormControl(),
      'IsCollectAmount': new FormControl(),
      'FedexCollect': new FormControl(),
      'UPSCollect': new FormControl(),
      // 'IsCollectFedex': new FormControl(),
      // 'IsCollectUPS': new FormControl(),
    });

    this.formSearch = new FormGroup({
      'TCreateUpdate': new FormControl(),
      'customerSearch': new FormControl(),
    });

    this.userForm = new FormGroup({
      'userfirstName': new FormControl('', Validators.compose([Validators.required, Validators.minLength(1)])),
      'userlastName': new FormControl(),
      'useremail': new FormControl('', Validators.compose([Validators.required, Validators.email])),
      'userphone': new FormControl(),
      'usercity': new FormControl(),
      'userstate': new FormControl(),
      'useraddress': new FormControl(),
      'useraddress1': new FormControl(),
      'userextention': new FormControl(),
      'userfax': new FormControl(),
      'usercountry': new FormControl(),
      'userzip': new FormControl(),
      'isEcoomUser': new FormControl(),
      'userpassword': new FormControl(null, Validators.compose([
        Validators.required, Validators.minLength(8), PasswordStrengthValidator])),
      'changepassword': new FormControl(null, Validators.compose([
        Validators.required, Validators.minLength(8), PasswordStrengthValidator])),
      'userpassworConfirm': new FormControl(),
      'changepassworConfirm': new FormControl(),

      'selectedCustomerContactType': new FormControl(),
      'Issocontact': new FormControl(),
      'selectedItems': new FormControl(),
    });

    this.buttonValue = this.constants.Save;
    if (this.route.snapshot.params['id']) {
      const Id = this.route.snapshot.params['id'];
      this.GetValue(Id);
    }
    else {
      // this.router.navigate(['/vendors/' + 0], { skipLocationChange: true });
      // this.value = 0;
    }
    this.NetTermsList = this.constants.NetTermsList;
    this.NetTerms = '';
    this.PurchaseOptionList = this.constants.OptionList;
    this.PurchaseOrder = '';
    this.DefaultLogoPDF = "/assets/image/PDFFile1.png";
    this.selectedCustomerType == "1";

    // this.dropdownList = [
    //   { item_id: 1, item_text: 'Mumbai' },
    //   { item_id: 2, item_text: 'Bangaluru' },
    //   { item_id: 3, item_text: 'Pune' },
    //   { item_id: 4, item_text: 'Navsari' },
    //   { item_id: 5, item_text: 'New Delhi' }
    // ];
    // this.selectedItems = [
    //   { item_id: 3, item_text: 'Pune' },
    //   { item_id: 4, item_text: 'Navsari' }
    // ];
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'customerType',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 2,
      // allowSearchFilter: true
    };

    //ankit implementaion
    // get company by default set value
    var company = this.globalChangeService.getGlobalCompany();
    if (company > 0) {
      this.value = company;
      this.value_search = company;
      this.companyId = this.value;
      this.getHashCode(this.value);
      this.GetAllCustomerContactType(this.companyId);
      this.GetAllCountryDetails();
      this.GetAllCountryContact();
      this.GetAllCountryBill();
      this.GetAllCountryShip();
      this.GetAllCustomerTypeList();
      this.GetRoles();
    }
    else {
      this.ErrorModal('Error', 'Please select company.');
    }

    //on change function 
    this.globalChangeService.dropdownChange$.subscribe((data) => {
      this.onValueChange(data);
      this.onValueChange_search(data);
    })
    this.getAllState(this.countrySelected);
  }

  GetAllCustomerTypeList() {
    this.customerService.GetAllCustomerTypeList().subscribe(
      data => {
        this.data = data;
        this.CustomerTypeList = this.data.dataList.filter(x => x.isActive == true);
        this.dropdownList = this.CustomerTypeList;
        const AllCustomerType = this.data.dataList;
        this.customerEditableData = AllCustomerType.map(obj => ({ ...obj, enabled: false }));
      }
    )
  }
  changeRoles(event: any) {
    this._CustomerRole = event;
    this._CustomerChannel = this.RolesList.filter(x => x.rolesName == this._CustomerRole)[0].chanels;
  }
  GetRoles() {
    if (this.companyId > 0 && this.companyId != undefined) {
      this.customerService.GetRoles(Number(this.companyId)).subscribe(
        (data: any) => {
          if (data.data != '' && data.data != null) {
            this.RolesList = [];
            this.RolesList = JSON.parse(data.data);
            if (this.RolesList.length > 0) {
              this.RolesList = this.RolesList.filter(x => x.status);
            }
          }
        }
      )
    }
  }

  async canDeactivate(): Promise<Promise<boolean> | boolean> {
    if (!this.form.pristine && !this.isSaveOrUpdate) {
      const confirmResult = await this.confirmation.confirm('Confirmation', 'Are you sure you want to leave this page ?', 'Cancel', 'OK', 'lg');
      if (confirmResult === true) {
        return true;
      } else {
        return false;
      }
    }
    else {
      return true;
    }
  }

  GetValue(Id) {
    this.loader = true;
    this.ListDiv = false;
    this.ListAngle = "down";
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    this.clearbutton = false;
    this.customerService.GetAllCustomerByCustomerId(Id).subscribe
      (
        (data: any) => {
          this.loader = false;
          if (data.statusCode == 200) {
            this._CustomerRole = data.data.customerRoles;
            this._CustomerChannel = data.data.businessChannels;
            this.buttonValue = this.constants.Update;
            this.id = Id;
            this.selectedCustomer = data.data.parentCustomerId;
            this.TCreateUpdate = 0;
            this.SearchControl = true;
            this.ListDiv = false;
            this.ListAngle = "down";
            document.body.scrollTop = document.documentElement.scrollTop = 0;
            this.buttonValue = this.constants.Update;
            this.id = Number(data.data.id);
            this.customerName = data.data.customerName;
            this.phone = data.data.phone;
            this.extension = data.data.extension;
            this.companyId = data.data.companyId;
            this.GetAllCustomerContactType(data.data.companyId);
            // this.email = data.data.email;
            this.addressLine1 = data.data.addressLine1;
            this.addressLine2 = data.data.addressLine2;
            this.AdditionalDiscount = (data.data.discount == null || data.data.discount == undefined) ? '' : data.data.discount;
            this.stateId = data.data.stateName == null ? '' : data.data.stateName;
            this.city = data.data.city;
            this.value = Number(data.data.companyId);
            this.value_search = Number(data.data.companyId);
            this.countrySelected = data.data.countryId == null ? 231 : data.data.countryId;
            this.selectedStateValue = data.data.stateId == null ? '' : data.data.stateId;
            this.zipCode = data.data.zipCode;
            this.customerNotes = data.data.customerNotes;
            this.recordAlreadyExist = false;
            this.clearbutton = false;
            this.CustomerHash = data.data.customerHash;
            this.Fax = data.data.fax;
            this.countrySelected = data.data.countryId == null ? '' : data.data.countryId;
            // this.selectedStateValue = data.data.sname;
            this.CollectAmount = data.data.collectAmount;
            this.selectedCustomerContactType = data.data.CustomerContacttypeId == null ? '' : data.data.CustomerContacttypeId == " " ? '' : data.data.CustomerContacttypeId == undefined ? '' : data.data.CustomerContacttypeId;
            this.createdDateEdit = data.data.createdDate == null ? '' : data.data.createdDate == undefined ? '' : data.data.createdDate;
            //this.getAllState(data.data.countryId);
            this.stateData.filter(x => x.countryId == data.data.countryId);
            this.CustomerWordPressId = data.data.wordpressId;
            //Contact Details
            this.firstName = data.data.firstName;
            this.lastName = data.data.lastName;
            this.contactEmail = data.data.cEmail;
            this.contactPhone = data.data.cPhone;
            this.contactExtension = data.data.cExtension;
            this.contactFax = data.data.cFax;
            this.contactAddressLine1 = data.data.cAddressLine1;
            this.contactAddressLine2 = data.data.cAddressLine2;
            this.contactZip = data.data.cZipCode;
            this.contactCity = data.data.cCity;
            this.selectedContactCountry = data.data.cCountry == null ? 231 : data.data.cCountry;
            this.selectedContactState = data.data.cState == null ? '' : data.data.cState;


            //Bill Details
            this.billName = data.data.billName;
            this.billEmail = data.data.billEmail;
            this.billPhone = data.data.billPhone;
            this.billExtension = data.data.billExtension;
            this.billFax = data.data.billFax;
            this.billAddressLine1 = data.data.billAddressLine1;
            this.billAddressLine2 = data.data.billAddressLine2;
            this.billZip = data.data.billZipCode;
            this.billCity = data.data.billCity;
            this.billAtten = data.data.billAtten;
            this.selectedBillCountry = data.data.billCountry == null ? 231 : data.data.billCountry;
            this.selectedBillState = data.data.billState == null ? '' : data.data.billState;

            //Ship All Details
            this.shipName = data.data.shipName;
            this.shipEmail = data.data.shipEmail;
            this.shipPhone = data.data.shipPhone;
            this.shipExtension = data.data.shipExtension;
            this.shipFax = data.data.shipFax;
            this.shipAddressLine1 = data.data.shipAddressLine1;
            this.shipAddressLine2 = data.data.shipAddressLine2;
            this.shipZip = data.data.shipZipCode;
            this.shipCity = data.data.shipCity;
            this.shipAtten = data.data.shipAtten;
            this.selectedShipCountry = data.data.shipCountry == null ? 231 : data.data.shipCountry;
            this.selectedShipState = data.data.shipState == null ? '' : data.data.shipState;
            this.NetTerms = data.data.netTerms == null ? '' : data.data.netTerms == 0 ? "No Terms" : data.data.netTerms;
            this.CreditCard = data.data.creditCardLimit == "" ? "" : data.data.creditCardLimit;
            this.PurchaseOrder = data.data.purchaseOrder == "" ? '' : data.data.purchaseOrder == null ? '' : data.data.purchaseOrder;
            this.taxable = data.data.taxable;
            this.selectedLink = data.data.taxable;
            this.taxId = data.data.taxId;

            //Get Collect Amount Checkbox Value (Ankit Modified)
            this.IsCollectAmount = data.data.isCollectAmount == null ? false : data.data.isCollectAmount;
            this.FedexCollect = data.data.fedexCollect == '' ? null : data.data.fedexCollect;
            this.UPSCollect = data.data.upsCollect == '' ? null : data.data.upsCollect;
            if (this.FedexCollect != '' || this.FedexCollect == null && this.UPSCollect != '' || this.UPSCollect == null) {
              this.disabledCollectFedex = false;
              this.disabledCollectUPS = false;
            } else if (this.FedexCollect != '' && this.UPSCollect == '' || this.UPSCollect == null) {
              this.disabledCollectFedex = false;
              this.disabledCollectUPS = true;
            } else if (this.UPSCollect != '' && this.FedexCollect == '' || this.FedexCollect == null) {
              this.disabledCollectFedex = true;
              this.disabledCollectUPS = false;
            } else {
              this.disabledCollectFedex = true;
              this.disabledCollectUPS = true;
            }
            if (this.IsCollectAmount) {
              if (this.CollectAmount == 'Fedex') {
                this.IsCollectFedex = true;
                this.IsCollectUPS = false;
              } else if (this.CollectAmount == 'UPS') {
                this.IsCollectFedex = false;
                this.IsCollectUPS = true;
              } else {
                this.IsCollectFedex = false;
                this.IsCollectUPS = false;
              }
            } else {
              this.IsCollectFedex = false;
              this.IsCollectUPS = false;
              this.disabledCollectFedex = true ? (this.FedexCollect == '') : false;
              this.disabledCollectUPS = true ? (this.UPSCollect == '') : false;
            }

            this.selectedCustomerType = data.data.customerType;

            this.MultipleImage = [];
            this.MultipleImage1 = [];
            if (data.data.applicationDocument != "") {
              this.imageUrl = data.data.applicationDocument;
              let ojtmultiplepart = {
                imageUrl: this._sanitizer.bypassSecurityTrustUrl(data.data.imageAppPath),
                imageName: data.data.applicationName
              }
              if (this.imageUrl != null) {
                this.MultipleImage.push(ojtmultiplepart);
              }
            }


            if (data.data.salesCertificate != "") {
              this.imageUrl1 = data.data.salesCertificate;
              let ojtmultiplepart1 = {
                imageUrl1: this._sanitizer.bypassSecurityTrustUrl(data.data.imageSalesPath),
                imageName1: data.data.salesCertificateName
              }

              if (this.imageUrl1 != null) {
                this.MultipleImage1.push(ojtmultiplepart1);
              }
            }
            this.addPdf = false;
            this.salePdf = false;
            if (data.data.userType == 'Guest') {
              this.isGuest = true;
              this.isGuestType = true;
            }
            else {
              this.isGuestType = false;
              this.isGuest = false;
            }
            if (data.data.taxable == "nontaxable") {
              if (this.MultipleImage.length == 0) {
                this.IsNonTaxable = true;
              }
              else {
                this.IsNonTaxable = false;
              }
              if (this.MultipleImage1.length == 0) {
                this.IsNonTaxable1 = true;
              }
              else {
                this.IsNonTaxable1 = false;
              }

            }
            else {
              this.IsNonTaxable = false;
              this.IsNonTaxable1 = false;
              this.MultipleImage1 = [];
              this.MultipleImage = [];
            }

            this.multiUserList = [];
            this.AddMultiUser1 = [];
            const MutltiUser = data.data.multiContactCustomer;
            for (var j = 0; j < MutltiUser.length; j++) {

              let multiUserObj = {};
              this.GetAllCustomerContactType(Number(MutltiUser[j].companyId));
              multiUserObj['firstname'] = MutltiUser[j].firstname;
              multiUserObj['lastname'] = MutltiUser[j].lastname;
              multiUserObj['email'] = MutltiUser[j].email;
              multiUserObj['phone'] = MutltiUser[j].phone;
              // multiUserObj['address'] = MutltiUser[j].address;
              multiUserObj['address1'] = MutltiUser[j].address1;
              multiUserObj['address2'] = MutltiUser[j].address2;
              multiUserObj['password'] = MutltiUser[j].password;
              multiUserObj['confirmpassword'] = MutltiUser[j].confirmPassword;
              multiUserObj['city'] = MutltiUser[j].city;
              multiUserObj['state'] = Number(MutltiUser[j].stateId) == 0 ? '' : Number(MutltiUser[j].stateId);
              multiUserObj["CreatedDate"] = MutltiUser[j].createdDate;
              multiUserObj["ModifiedDate"] = MutltiUser[j].modifiedDate;
              multiUserObj["IsActive"] = MutltiUser[j].isActive;
              multiUserObj['ModifiedBy'] = MutltiUser[j].modifiedBy;
              multiUserObj['CreatedBy'] = MutltiUser[j].createdBy;
              multiUserObj['extention'] = MutltiUser[j].extention;
              multiUserObj['zip'] = MutltiUser[j].zip;
              multiUserObj['fax'] = MutltiUser[j].fax;
              multiUserObj["id"] = MutltiUser[j].id;
              multiUserObj['country'] = Number(MutltiUser[j].countryId);
              multiUserObj["CompanyId"] = Number(MutltiUser[j].companyId);
              multiUserObj["Customerid"] = Number(MutltiUser[j].customerId);
              multiUserObj["IsEcoomerce"] = MutltiUser[j].isEcoomerce;
              multiUserObj["customerTypeVal"] = MutltiUser[j].contactName;
              multiUserObj["CustomerContacttypeId"] = Number(MutltiUser[j].selectedCustomerContactType) == undefined ? '' : Number(MutltiUser[j].selectedCustomerContactType) == null ? '' : Number(MutltiUser[j].customerContacttypeId);
              multiUserObj["Issocontact"] = MutltiUser[j].issocontact;
              multiUserObj["_MultiContactUserRoles"] = MutltiUser[j].otherRoles;
              // const RoleArr = [];
              // if (MutltiUser[j].otherRoles != null) {
              //   const MutltiUserRole = JSON.parse(MutltiUser[j].otherRoles);
              //   if (MutltiUserRole.length > 0 && MutltiUserRole != null) {
              //     for (var m = 0; m < MutltiUserRole.length; m++) {
              //       let multiUserRuleObj = {};
              //       multiUserRuleObj['id'] = MutltiUserRole[m];
              //       multiUserRuleObj['customerType'] = this.CustomerTypeList.filter(x => x.id == Number(MutltiUserRole[m]))[0].customerType;
              //       RoleArr.push(multiUserRuleObj)
              //     }
              //   }
              // }

              //multiUserObj["MultiContactUserRoles"] = RoleArr;
              this.multiContactUserRoles = [];
              this.selectedItems = [];

              this.i = multiUserObj["id"];
              this.AddMultiUser1.push(multiUserObj);
            }

            this.multiUserList = this.AddMultiUser1;
            this.GetMultiUserList();
          }
          else {
            this.TCreateUpdate = 1;
            this.SearchControl = false;
            this.buttonValue = this.constants.Save;
            this.router.navigate(['/customers/' + 0], { skipLocationChange: true });
            this.clearDetails();

          }

        });

  }

  ///Getmethod
  GetMultiUserList() {

    if (this.multiUserList.filter(x => x.IsActive == true).length > 0) {
      this.showCount = true;
    }
    else {
      this.showCount = false;
    }
  }

  ///GetmethodUpdate
  ChangeTCreateUpdate(event) {
    this.TCreateUpdate = event.target.value;
    if (this.TCreateUpdate == "1") {
      this.SearchControl = false;
      this.ListShow = false;
      this.ListDiv = false;
      this.clearbutton = true;
      this.buttonValue = this.constants.Save;
      this.clearDetails();
    }
    else if (this.TCreateUpdate == "0") {
      this.SearchControl = true;
      this.customerSearch = "";
    }
  }
  ///ListDiv
  ListDivShowHide() {
    if (this.ListDiv == true) {
      this.ListDiv = false;
      this.ListAngle = "down";
    }
    else if (this.ListDiv == false) {
      this.ListDiv = true;
      this.ListAngle = "up";
    }
  }

  searchCustomer() {
    this.SearchType = false;
    if (!this.customerSearch || this.value_search == 0) {
      this.SearchValidation = true;
      return;
    }

    this.GetAllCustomer(this.value_search, this.customerSearch);
  }

  AllCustomer() {
    this.SearchType = true;
    if (this.value_search == 0) {
      this.SearchValidation = true;
      return;
    }
    this.isGuest = false;
    this.isGuestType = false;
    // this.SearchValidation = false;
    this.GetAllCustomer(this.value_search, this.customerSearch);
  }



  // getAllCompany(companyId) {
  //   this.companyService.GetDropdownCompanyHierarchyModel(companyId).subscribe(
  //     data => {
  //       this.data = data;
  //       this.items = this.data.dataList;
  //       this.companyList = this.data.dataList;
  //       this.companyList.forEach((x) => {
  //         // this.userGroupObjects.push(new TreeviewItem(x, false));
  //       });
  //       //this.getAllState(this.countrySelected);
  //       this.stateData.filter( x => x.countryId == this.countrySelected);
  //     });
  // }

  getAllCompany_search(companyId) {
    this.companyService.GetDropdownCompanyHierarchyModel(this.companyId).subscribe(
      data => {

        this.data = data;
        this.items_search = this.data.dataList;
        this.companyList_search = this.data.dataList;
        this.companyList_search.forEach((x) => {
          // this.userGroupObjects_search.push(new TreeviewItem(x, false));
        });
        this.value_search = Number(companyId);
        this.sharedService.CustomerLoadedComponent.subscribe(value => {
          if (value != 0 && value != undefined) {
            this.route.params.subscribe(
              (params) => {
                if (params.id) {
                  this.GetValue(value);


                }
              }
            );
          }

        });
        //this.getAllState();
      });
  }


  onFilterChange(value: string): void {
  }
  onValueChange(value: any): void {
    this.value = value;
    this.companyId = this.value;
    this.getHashCode(this.value);
    this.GetAllCustomerContactType(this.companyId);
  }

  getHashCode(value) {
    this.customerService.GetCustomerHash(value).subscribe(
      (data: any) => {
        this.CustomerHash = data.data.customerHash;
      });
  }

  onValueChange_search(value: any): void {
    this.value_search = value;
  }


  viewCustomerDetails(item: any) {
    this.value = item.companyName;
    this.firstName = item.firstname;
    this.lastName = item.lastname;
    this.customerName = item.customerName;
    this.phone = item.phone;
    this.extension = item.extension;
    // this.email = item.email;
    this.addressLine1 = item.addressLine1;
    this.addressLine2 = item.addressLine2;
    this.city = item.city;
    this.stateName = item.stateName;
    this.zipCode = item.zipCode;
    this.customerNotes = item.customerNotes;
    this.CustomerHash = item.customerHash;
    this.Fax = item.fax;
  }


  GetAllCustomer(companySearch, searchTerm) {
    $('#datatable').DataTable().destroy();
    this.dtTrigger.next('');
    if (companySearch == 0 || companySearch == undefined) {
      companySearch = 0;
    }
    this.loader = true;
    this.customerService.GetAllCustomer(companySearch, searchTerm == undefined ? "" : searchTerm).subscribe(
      data => {
        this.ListShow = true;
        this.ListDiv = true;
        this.ListAngle = "up";
        this.data = data;
        if (this.data.dataList != null && this.data.dataList != undefined) {
          this.customerList = this.data.dataList;
        }
        this.loader = false;
        this.rerender('one');
      }
    )
  }

  clearDetails() {
    this.id = '';
    this.selectedCustomer = 0;
    this.customerName = '';
    this.phone = '';
    this.extension = '';
    //this.email = '';
    this.addressLine1 = '';
    this.addressLine2 = '';
    this.AdditionalDiscount = '';
    this.selectedStateValue = '';
    this.city = '';
    this.value = 0;
    this.zipCode = '';
    this.customerNotes = '';
    this.buttonValue = this.constants.Save;
    this.CustomerHash = '';
    this.Fax = '';
    this.isEmailExist = false;
    //Contact Information
    this.firstName = '';
    this.lastName = '';
    this.contactEmail = '';
    this.contactPhone = '';
    this.contactExtension = '';
    this.contactFax = '';
    this.contactAddressLine1 = '';
    this.contactAddressLine2 = '';
    this.contactZip = '';
    this.contactCity = '';
    this.selectedContactState = '';
    this.selectedCustomerContactType = '';
    this.isEcoomUser = false;
    this.Issocontact = false;
    //Bill Information
    this.billName = '';
    this.billEmail = '';
    this.billPhone = '';
    this.billExtension = '';
    this.billFax = '';
    this.billAddressLine1 = '';
    this.billAddressLine2 = '';
    this.billZip = '';
    this.billCity = '';
    this.billAtten = '';
    this.selectedBillState = '';

    //Ship Information
    this.shipName = '';
    this.shipEmail = '';
    this.shipPhone = '';
    this.shipExtension = '';
    this.shipFax = '';
    this.shipAddressLine1 = '';
    this.shipAddressLine2 = '';
    this.shipZip = '';
    this.shipCity = '';
    this.shipAtten = '';
    this.selectedShipState = '';
    this.multiUserList = [];
    this.isGuest = false;
    this.isGuestType = false;
    this.taxId = "";
    this.NetTerms = "";
    this.PurchaseOrder = "";
    this.CreditCard = "";
    this.taxable = "";
    this.imageUrl = "";
    this.imageUrl1 = "";
    this.MultipleImage = [];
    this.MultipleImage1 = [];
    this.RestoreMultipleImage = [];
    this.RestoreMultipleImage1 = [];
    this.isEmailExist = false;
    if (this.companyId > 0) {
      this.value = Number(this.companyId);
    }
    else {
      this.value = 0;
    }

    this.GetMultiUserList();
    this.getHashCode(this.value);
    this.PartialShipment = false;
    this.OEM = false;
    this.IsCollectAmount = false;
    this.FedexCollect = '';
    this.UPSCollect = '';
    this.IsCollectFedex = false;
    this.IsCollectUPS = false;
    this.disabledCollectFedex = true;
    this.disabledCollectUPS = true;
    this.selectedCustomerType = '1';
  }


  deletePopup(item, content, e) {
    this.deletedItem = '';
    this.modalService.open(content, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
    this.deleteDetail = 'Are you sure, you want to delete?';
    this.deletedItem = item;
  }

  deleteUserPopup(item: any) {
    if (this.multiUserList.filter(x => x.IsActive == true).length == 1) {
      this.userdeleteDetail = 'Customer should have at least one contacts';
      this.userdeletedItem = 0;
      $('#DeletePopModel').show();
    }
    else {
      this.userdeleteDetail = 'Are you sure, you want to delete?';
      this.userdeletedItem = item.id;
      $('#DeletePopModel').show();
    }

  }

  DeletePopModel() {
    $('#DeletePopModel').hide();
  }

  closeModal() {
    this.firstName = '';
    this.lastName = '';
    this.customerName = '';
    this.phone = '';
    this.extension = '';
    // this.email = '';
    this.addressLine1 = '';
    this.addressLine2 = '';
    this.AdditionalDiscount = '';
    this.stateName = '';
    this.city = '';
    this.value = 0;
    this.zipCode = '';
    this.Fax = '';
    this.CustomerHash = '';
    this.customerNotes = '';
    this.selectedCustomerContactType = '';
  }


  deleteCustomer(item) {
    this.loader = true;
    this.customerService.deleteCustomer(item).subscribe(
      data => {
        this.data = data;
        switch (data.statusCode) {
          case 200:
            $("#delete_success").show();
            this.GetAllCustomer(this.value_search, this.customerSearch == "" ? "" : this.customerSearch);
            this.rerender('one');
            break;
          case 400:
            $("#wrong_msg").show();
            break;
          default:
            $("#wrong_msg").show();
        }
        this.loader = false;
      });
  }

  CallShippingApi(items: any = [], obj: any) {
    if (this.statusFlag == false) {
      this.sharedService.ValidateAddress(items)
        .subscribe(
          data => {
            this.data = data;
            if (data[0].status == "verified") {
              this.address_line1 = data[0].matched_address.address_line1;
              this.city_locality = data[0].matched_address.city_locality;
              this.postal_code = data[0].matched_address.postal_code;
              $("#validAddress").show();
            }
            else {
              $("#invalidAddress").show();
            }
            this.loader = false;
          }
        );
    }
    else {
      this.hideValidModel();
      this.AddCustomerApi(obj);
    }

  }
  CloseContactModel() {
    $("#ContactModel").hide();
  }
  autofillAddress() {
    this.shipAddressLine1 = this.address_line1;
    this.shipZip = this.postal_code;
    this.shipCity = this.city_locality;
    this.statusFlag = true;
    this.CallShippingApi([], {});
  }
  hideValidModel() {
    $("#validAddress").hide();
  }

  addCustomer() {
    this.validation = true;
    this.clearbutton = true;
    if (
      this.value === 0 ||
      this.form.controls.customerName.status === this.constants.Invalid ||
      this.form.controls.CustomerHash.status === this.constants.Invalid ||
      this.form.controls.shipEmail.status === this.constants.Invalid ||
      this.form.controls.billEmail.status === this.constants.Invalid
    ) {
      return;
    }

    if (this.isGuest == true) {
      if (this.form.controls.contactEmail.status === this.constants.Invalid
        || this.form.controls.firstName.status === this.constants.Invalid
        || this.form.controls.lastName.status === this.constants.Invalid) {
        return;
      }
    }

    if (this.zipCode != null && this.zipCode != undefined && this.zipCode != "") {
      let zipDigits = this.zipCode;
      this.isValidFlg = (zipDigits?.length == 0 || zipDigits?.length == 10);
      if (zipDigits?.length >= 5) {
        zipDigits = zipDigits.substring(0, 5) + "-" + zipDigits.substring(6, 10);
      }
      else {
        return;
      }
    }
    if (this.isGuest == true) {
      if (this.contactZip != null && this.contactZip != undefined && this.contactZip != "") {
        let zipDigits = this.contactZip;
        this.isValidFlg = (zipDigits?.length == 0 || zipDigits?.length == 10);
        if (zipDigits?.length >= 5) {
          zipDigits = zipDigits.substring(0, 5) + "-" + zipDigits.substring(6, 10);
        }
        else {
          return;
        }
      }
    }
    if (this.billZip != null && this.billZip != undefined && this.billZip != "") {
      let zipDigits1 = this.billZip;
      this.isValidFlg = (zipDigits1?.length == 0 || zipDigits1?.length == 10);
      if (zipDigits1?.length >= 5) {
        zipDigits1 = zipDigits1.substring(0, 5) + "-" + zipDigits1.substring(6, 10);
      }
      else {
        return;
      }
    }

    if (this.shipZip != null && this.shipZip != undefined && this.shipZip != "") {
      let zipDigits2 = this.shipZip;
      this.isValidFlg = (zipDigits2?.length == 0 || zipDigits2?.length == 10);
      if (zipDigits2?.length >= 5) {
        zipDigits2 = zipDigits2.substring(0, 5) + "-" + zipDigits2.substring(6, 10);
      }
      else {
        return;
      }
    }
    if (this.selectedCustomerType === "") {
      this.customerProp = 'Please Select Customer Type';
      $('#customerInvalidModel').show();
      return;
    }


    // if (this.isGuest == false) {
    //   if (this.multiUserList.length == 0 || this.multiUserList == null) {
    //     $('#ContactModel').show();
    //     return;
    //   }
    //   else {

    //   }
    // }
    this.getAllStateShip(parseInt(this.selectedShipCountry));
    const obj = {};
    const newType = {};
    // Start Roles
    obj['CustomerRoles'] = this._CustomerRole;
    obj['BusinessChannels'] = this._CustomerChannel;
    // End Roles
    obj['customerName'] = this.customerName;
    obj['Phone'] = this.phone;
    obj['extension'] = this.extension;
    obj['Email'] = "";
    obj['AddressLine1'] = this.addressLine1;
    obj['AddressLine2'] = this.addressLine2;
    obj['City'] = this.city;
    obj['CompanyId'] = String(this.value);
    obj['ZipCode'] = this.zipCode;
    obj['StateId'] = this.selectedStateId == "" ? 0 : parseInt(this.selectedStateId);
    obj['CustomerNotes'] = this.customerNotes;
    obj['CustomerHash'] = this.CustomerHash;
    obj['Fax'] = this.Fax;
    obj["CountryId"] = parseInt(this.countrySelected);
    obj['discount'] = Number(this.AdditionalDiscount);

    //Contact Details
    obj['FirstName'] = this.firstName;
    obj['LastName'] = this.lastName;
    obj['CEmail'] = this.contactEmail;
    obj['CPhone'] = this.contactPhone;
    obj['CExtension'] = this.contactExtension;
    obj['CFax'] = this.contactFax;
    obj['CAddressLine1'] = this.contactAddressLine1;
    obj['CAddressLine2'] = this.contactAddressLine2;
    obj['CZipCode'] = this.contactZip;
    obj['CCity'] = this.contactCity;
    obj['CCountry'] = parseInt(this.selectedContactCountry);
    obj['CState'] = parseInt(this.selectedContactState);
    obj['CustomerContacttypeId'] = this.selectedCustomerContactType == undefined ? '' : this.selectedCustomerContactType == null ? '' : parseInt(this.selectedCustomerContactType);
    obj['Issocontact'] = this.Issocontact;


    //Bill Details
    obj['BillName'] = this.billName;
    obj['BillEmail'] = this.billEmail;
    obj['BillPhone'] = this.billPhone;
    obj['BillExtension'] = this.billExtension;
    obj['BillFax'] = this.billFax;
    obj['BillAddressLine1'] = this.billAddressLine1;
    obj['BillAddressLine2'] = this.billAddressLine2;
    obj['BillZipCode'] = this.billZip;
    obj['BillCity'] = this.billCity;
    obj['BillAtten'] = this.billAtten == undefined ? '' : String(this.billAtten);
    obj['BillCountry'] = parseInt(this.selectedBillCountry);
    obj['BillState'] = parseInt(this.selectedBillState);

    //Ship Details
    obj['ShipName'] = this.shipName;
    obj['ShipEmail'] = this.shipEmail;
    obj['ShipPhone'] = this.shipPhone;
    obj['ShipExtension'] = this.shipExtension;
    obj['ShipFax'] = this.shipFax;
    obj['ShipAddressLine1'] = this.shipAddressLine1;
    obj['ShipAddressLine2'] = this.shipAddressLine2;
    obj['ShipZipCode'] = this.shipZip;
    obj['ShipCity'] = this.shipCity;
    obj['ShipAtten'] = this.shipAtten == undefined ? '' : String(this.shipAtten);
    obj['ShipCountry'] = parseInt(this.selectedShipCountry);
    obj['ShipState'] = parseInt(this.selectedShipState);
    obj['IsEcoomerce'] = this.isEcoomUser;
    obj['NetTerms'] = this.NetTerms == "No Terms" ? 0 : this.NetTerms == "" ? null : Number(this.NetTerms);
    obj['CreditCardLimit'] = parseFloat(this.CreditCard);
    obj['PurchaseOrder'] = String(this.PurchaseOrder);
    obj['TaxId'] = this.taxId == undefined ? 0 : Number(this.taxId);
    obj['Taxable'] = this.selectedLink;
    obj['PartialShipment'] = this.PartialShipment;
    if (this.IsCollectAmount) {
      if (this.IsCollectFedex) {
        this.CollectAmount = 'Fedex';
      } else if (this.IsCollectUPS) {
        this.CollectAmount = 'UPS';
      } else {
        this.CollectAmount = '';
      }
    } else {
      this.CollectAmount = '';
    }
    obj['CollectAmount'] = this.CollectAmount;
    obj['OEM'] = this.OEM;

    //Collect Amount check box fields (Ankit Implemented)
    obj['IsCollectAmount'] = this.IsCollectAmount;
    obj['FedexCollect'] = this.FedexCollect;
    obj['UPSCollect'] = this.UPSCollect;
    obj['CustomerType'] = Number(this.selectedCustomerType);
    obj['ParentCustomerId'] = this.selectedCustomer;

    // this.countryCode= this.selectedShipCountry.split("-");
    if (this.selectedShipState != '') {
      this.stateAbribationList = this.stateData.filter(x => x.id == parseInt(this.selectedShipState));
    }


    for (var j = 0; j < this.MultipleImage.length; j++) {
      if (j == 0) {
        obj['ApplicationDocument'] = this.MultipleImage[j].imageUrl.changingThisBreaksApplicationSecurity;
        obj['ApplicationName'] = this.MultipleImage[j].imageName;
        obj['AddPdf'] = this.addPdf;
      }
    }

    for (var j = 0; j < this.MultipleImage1.length; j++) {
      if (j == 0) {
        obj['SalesCertificate'] = this.MultipleImage1[j].imageUrl1.changingThisBreaksApplicationSecurity;
        obj['SalesCertificateName'] = this.MultipleImage1[j].imageName1;
        obj['SalePdf'] = this.salePdf;
      }
    }

    if (this.isGuest == true) {
      obj['UserType'] = 'Guest';
    }
    obj['WordpressId'] = this.CustomerWordPressId;
    this.AddMultiUser1 = [];
    const multiUserList = this.multiUserList;
    for (var j = 0; j < multiUserList.length; j++) {
      let multiUserObj = {};
      multiUserObj['Firstname'] = multiUserList[j].firstname;
      multiUserObj['Lastname'] = multiUserList[j].lastname;
      multiUserObj['email'] = multiUserList[j].email;
      multiUserObj['phone'] = multiUserList[j].phone;
      // multiUserObj['address'] = multiUserList[j].address;
      multiUserObj['address1'] = multiUserList[j].address1;
      multiUserObj['address2'] = multiUserList[j].address2;
      multiUserObj['Password'] = multiUserList[j].password;
      multiUserObj['city'] = multiUserList[j].city;
      multiUserObj['StateId'] = Number(multiUserList[j].state);
      multiUserObj["CreatedDate"] = multiUserList[j].CreatedDate;
      multiUserObj["ModifiedDate"] = multiUserList[j].ModifiedDate;
      multiUserObj["isActive"] = multiUserList[j].IsActive;
      multiUserObj['ModifiedBy'] = multiUserList[j].ModifiedBy;
      multiUserObj['CreatedBy'] = multiUserList[j].CreatedBy;
      multiUserObj['extention'] = multiUserList[j].extention;
      multiUserObj['zip'] = multiUserList[j].zip;
      multiUserObj['fax'] = multiUserList[j].fax;
      multiUserObj['CountryId'] = Number(multiUserList[j].country);
      multiUserObj['CompanyId'] = multiUserList[j].CompanyId;
      multiUserObj["Customerid"] = multiUserList[j].Customerid;
      multiUserObj["IsEcoomerce"] = multiUserList[j].IsEcoomerce;
      multiUserObj["isPasswordChange"] = multiUserList[j].isPasswordChange;
      multiUserObj["CustomerContacttypeId"] = Number(multiUserList[j].CustomerContacttypeId);
      multiUserObj["Issocontact"] = multiUserList[j].Issocontact;
      multiUserObj["OtherRoles"] = multiUserList[j]._MultiContactUserRoles;
      // this.AddMultiUserRole = [];
      // const multiUserRoleList = multiUserList[j].MultiContactUserRoles;
      // for (let k = 0; k < multiUserRoleList.length; k++) {
      //   this.AddMultiUserRole.push(multiUserRoleList[k].id);
      // }
      // multiUserObj["OtherRoles"] = JSON.stringify(this.AddMultiUserRole);

      if (this.id != undefined && this.id != "" && !Number.isNaN(this.id)) {
        multiUserObj["id"] = multiUserList[j].id;
      }
      else {

      }
      this.AddMultiUser1.push(multiUserObj);
    }
    obj['MultiContactCustomer'] = this.AddMultiUser1;
    this.objShiping['address_line1'] = this.shipAddressLine1;
    this.objShiping['city_locality'] = this.shipCity;
    if (this.stateAbribationList.length > 0) {
      this.objShiping['state_province'] = this.stateAbribationList[0].abbreviation == undefined ? '' : String(this.stateAbribationList[0].abbreviation);
      this.validState = this.stateAbribationList[0].name;
    }

    this.objShiping['postal_code'] = String(this.shipZip);
    this.objShiping['country_code'] = String(this.countryCodeAbri[0].countryCode);
    this.validCountry = this.countryCodeAbri[0].name;
    this.addressValArr.push(this.objShiping);

    this.AddCustomerApi(obj);
  }


  AddCustomerApi(obj: any) {
    this.loader = true;
    if (this.id != undefined && this.id != "" && !Number.isNaN(this.id)) {
      obj['Id'] = this.id;
      obj['ModifiedBy'] = this.userId;
      obj['CreatedDate'] = this.createdDateEdit = new Date();
      obj['CreatedBy'] = this.userId;
      this.customerService.updateCustomer(obj)
        .subscribe(
          data => {
            this.loader = false;
            this.data = data;
            switch (data.statusCode) {
              case 200:
                this.buttonValue = this.constants.Save;
                $("#add_update").show();
                this.validation = false;
                this.recordAlreadyExist = false;
                this.isSaveOrUpdate = true;
                this.clearDetails();
                this.countrySelected = "231";
                this.TCreateUpdate = 1;
                this.SearchControl = false;
                this.ListShow = false;
                this.ListDiv = false;
                this.GetMultiUserList();
                this.rerender('one');
                this.multiUserList = [];
                this.isEmailExist = false;
                this.statusFlag = false;
                this._CustomerRole = '';

                break;
              case 400:
                $("#wrong_msg").show();
                this.isEmailExist = false;
                break;
              case 208:
                $("#modalExist").show();
                this.isEmailExist = false;
                break;
              case 204:
                this.isEmailExist = true;
                break;

              default:
                $("#wrong_msg").show();
                this.isEmailExist = false;
            }
            this.loader = false;

          },
          error => {
            this.loader = false;
            this.popupModal(this.constants.APIError, this.constants.SomethingWentWrong);
          }
        );
    }
    else {
      obj['CreatedBy'] = this.userId;
      this.companyId = String(this.value);

      this.customerService.addCustomer(obj)
        .subscribe(
          data => {
            this.data = data;
            switch (data.statusCode) {
              case 200:
                this.id = Number(data.data);
                $("#add_success").show();
                this.validation = false;
                this.clearDetails();
                this.isSaveOrUpdate = true;
                this.TCreateUpdate = 1;
                this.SearchControl = false;
                this.ListShow = false;
                this.ListDiv = false;
                this.isEmailExist = false;
                this.GetMultiUserList();
                this.multiUserList = [];
                this._CustomerRole = '';
                this.rerender('one');
                break;
              case 400:
                $("#wrong_msg").show();
                this.isEmailExist = false;
                break;
              case 208:
                $("#modalExist").show();
                this.isEmailExist = false;
                break;
              case 204:
                this.isEmailExist = true;
                break;
              default:
                $("#wrong_msg").show();
                this.isEmailExist = false;
                this.loader = false;
            }
            this.loader = false;
          },
          error => {
            this.loader = false;
            this.popupModal(this.constants.APIError, this.constants.SomethingWentWrong);
          }
        );

    }
  }

  public isValidFlg: boolean = true;
  validatePhoneNo(field) {
    var phoneNumDigits = field.value.replace(/\D/g, '');
    this.isValidFlg = (phoneNumDigits.length == 0 || phoneNumDigits.length == 10);
    var formattedNumber = phoneNumDigits;
    if (phoneNumDigits.length >= 6)
      formattedNumber = '(' + phoneNumDigits.substring(0, 3) + ') ' + phoneNumDigits.substring(3, 6) + '-' + phoneNumDigits.substring(6, 10);
    else if (phoneNumDigits.length >= 3)
      formattedNumber = '(' + phoneNumDigits.substring(0, 3) + ') ' + phoneNumDigits.substring(3);
    field.value = formattedNumber;
  }

  validateZipcode(field) {
    // let zipDigits = this.zipCode;
    let zipDigits = field.value;
    this.isValidFlg = (zipDigits?.length == 0 || zipDigits?.length == 10);
    if (zipDigits?.length >= 5) {
      field.value = zipDigits.substring(0, 5) + "-" + zipDigits.substring(6, 10);
    }
    // this.zipCode = field.value;
  }

  CustomerExit() {
    this.clearbutton = true;
    this.id = null;
    this.buttonValue = this.constants.Save;
    this.TCreateUpdate = 1;
    this.SearchControl = false;
    this.ListShow = false;
    this.ListDiv = false;
    this.clearDetails();
  }


  SetSettingCSS(settingCSS, logoImagePath) {
    if (settingCSS != null) {
      let headerCss = document.getElementsByClassName(this.constants.CssHealerClass) as HTMLCollectionOf<HTMLElement>;
      if (headerCss.length != 0) {
        headerCss[0].style.backgroundColor = settingCSS.split(',')[0];
      }
      let saveButtonCss = document.getElementsByClassName(this.constants.CssSaveBtnClass) as HTMLCollectionOf<HTMLElement>;
      if (saveButtonCss.length != 0) {
        saveButtonCss[0].style.backgroundColor = settingCSS.split(',')[1];
        $(".blue-bg").css("background", settingCSS.split(',')[1]);
      }
      let cencelButtonCss = document.getElementsByClassName(this.constants.CssCancelBtnClass) as HTMLCollectionOf<HTMLElement>;
      if (cencelButtonCss.length != 0) {
        cencelButtonCss[0].style.backgroundColor = settingCSS.split(',')[2];
        $(".darkred-bg").css("background", settingCSS.split(',')[2]);
      }
    }

    if (logoImagePath != null && logoImagePath != "") {
      (document.getElementById('hrdlogoImage') as HTMLImageElement).src = logoImagePath;
    }

  }

  //Customer Country, State Dropdowns and Change Event
  GetAllCountryDetails() {
    this.sharedService.GetCountry().subscribe(
      data => {
        this.data = data;
        this.countrList = this.data.dataList;
        // this.getAllTerms();
      }
    )
  }

  changeCountry(event) {
    this.countrySelected = event.target.value;
    //this.getAllState(event.target.value);
    this.stateData.filter(x => x.countryId == event.target.value);
    this.selectedStateValue = '';
  }

  getAllState(countryId) {
    this.sharedService.getAllState(countryId == undefined ? 0 : countryId).subscribe(
      data => {
        this.data = data;
        this.stateData = this.data.dataList;
        // this.GetAllCustomer(this.companyId);
      });
  }

  getAllUserState(countryId) {
    this.sharedService.getAllState(countryId == undefined ? 0 : countryId).subscribe(
      data => {
        this.data = data;
        this.stateUserData = this.data.dataList;
        // this.GetAllCustomer(this.companyId);
      });
  }


  changeState(event) {
    this.selectedStateId = event;
  }


  //Contact Country, State Dropdowns and Change Event
  GetAllCountryContact() {
    this.sharedService.GetCountry().subscribe(
      data => {
        this.data = data;
        this.countryDataContact = this.data.dataList;
        // this.getAllTerms();
      }
    );
    this.getAllStateContact(this.selectedContactCountry);
  }


  GetAllCustomerContactType(companyId) {
    this.customerService.GetAllCustomerContactType(companyId).subscribe(
      data => {
        this.data = data;
        this.CustomerContacttypeId = this.data.dataList;
        // this.getAllTerms();
      }
    );
  }

  changeCountryContact(event) {
    this.selectedContactCountry = event.target.value;
    this.getAllStateUserContact(event.target.value);
  }

  changeUserCountryContact(event) {
    this.selectedContactCountry = event.target.value;
    this.getAllUserState(event.target.value);
  }

  getAllStateUserContact(countryId) {
    this.sharedService.getAllState(countryId).subscribe(
      data => {
        this.data = data;
        this.stateData = this.data.dataList;
        // this.GetAllCustomer(this.companyId);
      });
  }
  getAllStateContact(countryId) {
    this.sharedService.getAllState(countryId).subscribe(
      data => {
        this.data = data;
        this.stateDataContact = this.data.dataList;
        // this.GetAllCustomer(this.companyId);
      });
  }

  changeStateContact(event) {
    this.selectedContactState = event;
  }
  // // changes by shehwaz
  changeCustomerType(event) {
    if (this.selectedCustomerType === "1") {
      this.isGuestType = false;
      let objs = {};
      objs["id"] = Number(this.i) + 1;
      this.i = objs["id"];
      this.userfirstName = this.firstName;
      this.userlastName = this.lastName;
      this.useremail = this.contactEmail;
      this.userphone = this.contactPhone;
      this.usercity = this.contactCity;
      this.userstate = this.selectedContactState;
      this.usercountry = this.selectedContactCountry;
      this.userextention = this.contactExtension;
      this.userzip = this.contactZip;
      this.userfax = this.contactFax;
      this.useraddress = this.contactAddressLine1;
      this.useraddress1 = this.contactAddressLine2;
      this.isEcoomUser = true;
      //  this.customerContacttype=this.customerContacttype;
      if (this.id != undefined && this.id != "" && !Number.isNaN(this.id)) {
        $('#customerModel').show();
      }
    }
    else if (this.selectedCustomerType === "2") {
      if (this.id != undefined && this.id != "" && !Number.isNaN(this.id)) {
        this.loader = true;
        this.customerService.GetAllCustomerByCustomerId(this.id).subscribe(
          (data: any) => {
            this.loader = false;
            if (data.data.userType == 'Guest') {
              this.isGuestType = true;
              this.multiUserList = [];
              this.GetMultiUserList();
            }
            else {
              $('#notGuest').show();
              event.target.checked = false;
              this.isGuest = false;
            }
          });
      }
      else {
        this.isGuestType = true;
        this.multiUserList = [];
        this.GetMultiUserList();
      }
    }
    else {
      this.isGuestType = false;
      this.isGuest = false;
      if (this.selectedCustomerType === "2") {
        this.OEM = true;
      } else {
        this.OEM = false;
      }
    }
  }

  //Bill Country, State Dropdowns and Change Event
  GetAllCountryBill() {
    this.sharedService.GetCountry().subscribe(
      data => {
        this.data = data;
        this.countryDataBill = this.data.dataList;
        // this.getAllTerms();
      }
    );
    this.getAllStateBill(this.selectedBillCountry);
  }

  changeCountryBill(event) {
    this.selectedBillCountry = event.target.value;
    this.getAllStateBill(event.target.value);
  }

  getAllStateBill(countryId) {
    this.sharedService.getAllState(countryId).subscribe(
      data => {
        this.data = data;
        this.stateDataBill = this.data.dataList;
        // this.GetAllCustomer(this.companyId);
      });
  }

  changeStateBill(event) {
    this.selectedBillState = event;
  }

  //Ship Country, State Dropdowns and Change Event
  GetAllCountryShip() {
    this.sharedService.GetCountry().subscribe(
      data => {
        this.data = data;
        this.countryDataShip = this.data.dataList;
        this.countryCodeAbri = this.countryDataShip.filter(x => x.id == parseInt(this.selectedShipCountry));
        // this.getAllTerms();
      }
    );
    this.getAllStateShip(this.selectedShipCountry);
  }

  changeCountryShip(event) {
    this.selectedShipCountry = event.target.value;
    //  this.shipingcode = code;
    this.getAllStateShip(event.target.value);
  }

  getAllStateShip(countryId) {
    if (countryId == null || countryId == undefined) {
      countryId = 0;
    }
    this.sharedService.getAllState(countryId).subscribe(
      data => {
        this.data = data;
        this.stateDataShip = this.data.dataList;
        this.stateAbribationList = this.stateDataShip.filter(x => x.id == parseInt(this.selectedShipState));
        // this.GetAllCustomer(this.companyId);
      });
  }

  changeStateShip(event) {
    this.selectedShipState = event;
  }


  rerender(item: any): void {
    if (item == 'one') {
      this.dtElements.first.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next('');
      });
    }
    else {
      this.dtElements.last.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger1.next('');

      });
    }


  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    this.dtTrigger1.unsubscribe();
  }
  ngAfterViewInit(): void {
    this.dtTrigger.next('');
    this.dtTrigger1.next('');
    this.settingDetailCSS = localStorage.getItem("settingDetailCSS");
    this.settingLogoImage = localStorage.getItem("settingImageLogo");

    this.SetSettingCSS(this.settingDetailCSS, this.settingLogoImage);
  }

  addsuccess() {
    $("#add_success").hide();
  }
  updateitem() {
    $("#add_update").hide();
  }
  deleteitem() {
    $("#delete_success").hide();
  }
  msgitem() {
    $("#wrong_msg").hide();
  }
  invalidAddress() {
    $("#invalidAddress").hide();
  }
  closeExist() {
    $("#modalExist").hide();
  }
  ShowDoller() {
    this.CreditCard = Number(this.CreditCard).toFixed(2);
  }
  changeTaxable(event: any) {
    this.selectedLink = event.target.value;
    if (event.target.value == "nontaxable") {
      if (this.RestoreMultipleImage.length != 0) {
        this.MultipleImage = this.RestoreMultipleImage;
        this.MultipleImage1 = this.RestoreMultipleImage1;
      }

      if (this.MultipleImage.length == 0) {
        this.IsNonTaxable = true;
      }
      else {
        this.IsNonTaxable = false;

      }
      if (this.MultipleImage1.length == 0) {
        this.IsNonTaxable1 = true;
      }
      else {
        this.IsNonTaxable1 = false;
      }


    }
    else {
      this.IsNonTaxable = false;
      this.IsNonTaxable1 = false;
      this.RestoreMultipleImage = this.MultipleImage;
      this.RestoreMultipleImage1 = this.MultipleImage1;
      this.MultipleImage = [];
      this.MultipleImage1 = [];
    }
  }
  copyShipInfo(event: any) {
    if (event.target.checked == true) {
      this.shipName = this.billName;
      this.shipEmail = this.billEmail;
      this.shipPhone = this.billPhone;
      this.shipExtension = this.billExtension;
      this.shipFax = this.billFax;
      this.shipAddressLine1 = this.billAddressLine1;
      this.shipAddressLine2 = this.billAddressLine2;
      this.selectedShipCountry = this.selectedBillCountry;
      this.selectedShipState = this.selectedBillState;
      this.shipCity = this.billCity;
      this.shipZip = this.billZip;
    }
    else {
      this.shipName = "";
      this.shipEmail = "";
      this.shipPhone = "";
      this.shipExtension = "";
      this.shipFax = "";
      this.shipAddressLine1 = "";
      this.shipAddressLine2 = "";
      this.selectedShipCountry = "";
      this.selectedShipState = "";
      this.shipCity = "";
      this.shipZip = "";
    }
  }


  ChngSettingImage(files: FileList) {
    this.fileToUpload = files.item(0);
    var reader = new FileReader()
    reader.onload = (event: any) => {
      this.imageUrl = event.target.result;
      this.ApplicationName = this.fileToUpload.name;
      const SalesTax = this.ApplicationName.split(".pdf");
      this.ApplicationName = SalesTax[0];
    }
    reader.readAsDataURL(this.fileToUpload);
  }
  ChngSettingImage1(files: FileList) {
    this.fileToUpload1 = files.item(0);
    var reader = new FileReader()
    reader.onload = (event: any) => {
      this.imageUrl1 = event.target.result;
      this.SalesTaxName = this.fileToUpload1.name;
      const ApplicationName = this.SalesTaxName.split(".pdf");
      this.SalesTaxName = ApplicationName[0];
    }
    reader.readAsDataURL(this.fileToUpload1);
  }

  AddImage() {
    if (this.imageUrl != undefined && this.imageUrl != "" && this.imageUrl != "/assets/image/add-part.png") {
      let ojtmultiplepart = {
        imageUrl: this._sanitizer.bypassSecurityTrustHtml(this.imageUrl),
        imageName: this.ApplicationName

      }
      if (this.MultipleImage.length < 1) {
        this.addPdf = true;
        this.MultipleImage.push(ojtmultiplepart);
        this.imageUrl = '';
        this.ApplicationName = '';
        this.myInputVariable.nativeElement.value = "";
        if (this.selectedLink == "nontaxable") {
          this.IsNonTaxable = false;
        }
      }
      else {
        this.ImageType = "Application Document";
        $("#Imagevlaidation").show();
        this.imageUrl = '';
        this.ApplicationName = '';
        this.myInputVariable.nativeElement.value = "";
      }
    }
  }
  hidemodalpopup1() {

    $("#Imagevlaidation").hide();
  }
  AddImage1() {
    if (this.imageUrl1 != undefined && this.imageUrl1 != "" && this.imageUrl1 != "/assets/image/add-part.png") {
      let ojtmultiplepart = {
        imageUrl1: this._sanitizer.bypassSecurityTrustHtml(this.imageUrl1),
        imageName1: this.SalesTaxName
      }
      if (this.MultipleImage1.length < 1) {
        this.salePdf = true;
        this.MultipleImage1.push(ojtmultiplepart);
        this.imageUrl1 = '';
        this.SalesTaxName = '';
        this.myInputVariable1.nativeElement.value = "";
        if (this.selectedLink == "nontaxable") {
          this.IsNonTaxable1 = false;
        }
      }
      else {
        $("#Imagevlaidation").show();
        this.ImageType = "Sales Tax Certificate";
        this.imageUrl1 = '';
        this.SalesTaxName = '';
        this.myInputVariable1.nativeElement.value = "";
      }
    }

  }


  DeleteImageModalpopup(i, content, e) {
    this.modalService.open(content, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
    this.deleteDetail = 'Are you sure, you want to delete?';
    this.deletedItemImage = i;
    this.deleteimageType = e;
  }
  DeleteImage() {
    let index = this.deletedItemImage;
    if (this.deleteimageType == 1) {

      if (index != null) {
        this.MultipleImage.splice(index, 1);
        if (this.selectedLink == "nontaxable") {
          this.IsNonTaxable = true;
        }

      }
      else {

      }

    }
    else {
      if (index != null) {
        this.MultipleImage1.splice(index, 1);
        if (this.selectedLink == "nontaxable") {
          this.IsNonTaxable1 = true;
        }

      }
      else {

      }
    }

  }


  public b64toBlob(b64Data, contentType) {
    contentType = contentType || '';
    let sliceSize = 512;

    var byteCharacters = window.atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  viewImage(value) {
    let value1 = "";
    value = value.changingThisBreaksApplicationSecurity;
    value1 = value.split(",");
    var blob = this.b64toBlob(value1[1], "application/pdf");
    let a = document.createElement("a");
    document.body.appendChild(a);
    var url = window.URL.createObjectURL(blob);
    a.href = url;
    // let pdfWindow = window.open("")
    // pdfWindow.document.write("<iframe width='100%' height='100%' src='" + encodeURI(url) + "'></iframe>");
    a.download = String("PDF.pdf");
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  openCustomerModal() {
    this.GetAllCustomerContactType(this.companyId);
    this.uservalidation = false;
    this.resetMultiUser();
    this.selectedContactCountry = 231;
    this.getAllUserState(this.selectedContactCountry);
    if (this.value == 0 || this.value == undefined) {
      this.customerProp = "Please Select Company";
      $('#customerInvalidModel').show();
    }
    else {
      if (this.customerName == "" || this.customerName == undefined) {
        this.customerProp = "Please Enter Customer Name";
        $('#customerInvalidModel').show();
      }
      else {
        if (this.CustomerHash == "" || this.CustomerHash == undefined) {
          this.customerProp = "Please Enter Customer #";
          $('#customerInvalidModel').show();
        }
        else {
          this.CustomerPartName = this.customerName;
          // this.selectedItems = this.CustomerTypeList.filter(x => x.id == this.selectedCustomerType);
          this.selectedItems = [];
          this.selectedItems.push(this._CustomerRole);
          this.multiContactUserRoles = this.CustomerTypeList.filter(x => x.id == this.selectedCustomerType);
          $('#customerModel').show();
          this.isUserEdit = false;
        }
      }
    }
  }
  customerInvalidModel() {
    $('#customerInvalidModel').hide();
  }
  customerModel() {
    $('#customerModel').hide();
  }
  userclose() {
    $('#customerModel').hide();
  }
  CloseReturnModel() {
    $('#ReturnModel').hide();
  }

  AddMultiUser() {
    this.uservalidation = true;
    if (this.userForm.controls.userfirstName.status === this.constants.Invalid ||
      this.userForm.controls.userlastName.status === this.constants.Invalid ||
      this.userForm.controls.useremail.status === this.constants.Invalid ||
      this.userForm.controls.userphone.status === this.constants.Invalid
    ) {
      return;
    }

    if ((this.userpassword != this.userpassworConfirm)) {
      return;
    }
    if (this.userzip != null && this.userzip != undefined && this.userzip != "") {
      let zipDigits = this.userzip;
      this.isValidFlg = (zipDigits?.length == 0 || zipDigits?.length == 10);
      if (zipDigits?.length >= 5) {
        zipDigits = zipDigits.substring(0, 5) + "-" + zipDigits.substring(6, 10);
      }
      else {
        return;
      }
    }
    this.loader = true;
    this.customerService.CheckMultiUserEmail(this.useremail, this.value, 0).subscribe(
      data => {
        this.loader = false;
        this.data = data;
        if (this.data.dataList[0].customerName == null) {
          var checkUser = this.multiUserList.find(x => x.email == this.useremail && x.IsActive == true);
          if (checkUser) {
            this.AlreadyEmail = `'${this.useremail}' email Already added in MultiUser Records`;
            $('#AlreadyUserExist').show();
          }
          else {
            var checkUser = this.multiUserList.filter(x => x.Issocontact == true);
            if (checkUser.length != 0) {
              if (checkUser.length <= 1) {
                if (this.Issocontact == true) {
                  this.Issocontact = false;
                  $('#ReturnModel').show();
                  return
                }

              }
            }
            let ids = 0;
            let objs = {};
            objs["id"] = Number(this.i) + 1;
            this.i = objs["id"];
            objs['firstname'] = this.userfirstName;
            objs['lastname'] = this.userlastName;
            objs['email'] = this.useremail;
            objs['phone'] = this.userphone;
            objs['city'] = this.usercity;
            objs['state'] = this.selectedUserValue;
            objs['password'] = this.userpassword;
            objs['confirmpassword'] = this.userpassworConfirm;
            objs['address1'] = this.useraddress;
            objs['address2'] = this.useraddress1;
            objs['extention'] = this.userextention;
            objs['zip'] = this.userzip;
            objs['fax'] = this.userfax;
            objs['country'] = this.selectedContactCountry;
            objs['ModifiedBy'] = this.userId;
            objs['CreatedDate'] = new Date();
            objs['CreatedBy'] = this.userId;
            objs["IsActive"] = true;
            objs["CompanyId"] = Number(this.value);
            objs["IsEcoomerce"] = this.isEcoomUser;
            if (this.selectedItems.length > 0) {
              let role = '';
              this.selectedItems.forEach(element => {
                role = role + element + ', '
              });
              role = role.slice(0, -2);
              objs['_MultiContactUserRoles'] = role
            }
            //objs["MultiContactUserRoles"] = this.multiContactUserRoles;
            this.multiContactUserRoles = [];
            this.selectedItems = [];
            if (Number(this.selectedCustomerContactType) != 0) {
              objs['CustomerContacttypeId'] = parseInt(this.selectedCustomerContactType);
              ids = parseInt(this.selectedCustomerContactType);
              let val = this.CustomerContacttypeId.filter(x => x.id == ids);
              objs["customerTypeVal"] = val[0].name;
            }
            objs['Issocontact'] = this.Issocontact;

            this.multiUserList.push(objs);
            this.SaveModel = "Contact Added Successfully";
            this.GetMultiUserList();
            $('#SaveModel').show();
            $('#customerModel').hide();
            this.resetMultiUser();
          }
        }
        else {
          this.AlreadyEmail = `'${this.useremail}' user email already exist for this Customer :- ${this.data.dataList[0].customerName}. Please change Email Id`;
          $('#AlreadyUserExist').show();
        }

      }
    );

  }


  SavePopModel() {
    $('#SaveModel').hide();
  }

  resetMultiUser() {
    this.uservalidation = false;
    this.userfirstName = "";
    this.userlastName = "";
    this.useremail = "";
    this.userphone = "";
    this.usercity = "";
    this.selectedUserValue = "";
    // this.selectedCustomerContactType=0;
    this.userpassworConfirm = "";
    this.userpassword = "";
    this.useraddress = "";
    this.useraddress1 = "";
    this.userextention = "";
    this.userfax = "";
    this.userzip = "";
    this.selectedContactCountry = "";
    this.IsUserUpdate = false;
    this.IsUsertSave = true;
    this.isEcoomUser = false;
    this.userPasswordvalidation = false;
    this.Issocontact = false;
  }
  AlreadyUserExist() {
    $('#AlreadyUserExist').hide();
  }

  multiUserEdit(item: any) {
    this.GetAllCustomerContactType(this.companyId);
    this.CustomerPartName = this.customerName;
    $('#customerModel').show();
    this.loader = true;
    var data = {};
    data = this.multiUserList.find(x => x.id == item.id && x.IsActive == true);
    this.userfirstName = data["firstname"];
    this.userlastName = data["lastname"];
    this.useremail = data["email"];
    this.userphone = data["phone"];
    this.useraddress = data["address1"];
    this.useraddress1 = data["address2"];
    this.selectedCustomerContactType = data["CustomerContacttypeId"];
    // this.selectedCustomerContactType=data["customerContacttype"]==undefined ?"0":data["customerContacttype"]==null ?"0":data["customerContacttype"];
    this.Issocontact = data["Issocontact"];
    this.userpassword = data["password"];
    this.usercity = data["city"];
    this.userextention = data['extention']
    this.userzip = data['zip'];
    this.userfax = data['fax'];
    this.selectedContactCountry = data['country'] == 0 ? '' : data['country'];
    this.EditUserId = data["id"];
    this.IsUserUpdate = true;
    this.IsUsertSave = false;
    this.isEcoomUser = data['IsEcoomerce'];
    //this.selectedItems = data['MultiContactUserRoles'];
    if (data['_MultiContactUserRoles'].split(',').length > 0) {
      this.selectedItems = [];
      data['_MultiContactUserRoles'].split(',').forEach(element => {
        this.selectedItems.push(element.trim());
      });
    }
    this.multiContactUserRoles = this.selectedItems;
    this.isUserEdit = true;

    if (this.selectedUserValue != "" || this.selectedUserValue != undefined) {
      if (this.selectedContactCountry == "" || this.selectedContactCountry == undefined) {
        this.selectedContactCountry = "231";
      }
    }
    this.getAllUserState(this.selectedContactCountry == "" ? 0 : this.selectedContactCountry);
    this.selectedUserValue = data['state'] == 0 ? '' : data['state'];

    this.loader = false;
  }


  UpdateMultiUser() {
    this.uservalidation = true;
    if (this.userForm.controls.userfirstName.status === this.constants.Invalid ||
      this.userForm.controls.userlastName.status === this.constants.Invalid ||
      this.userForm.controls.useremail.status === this.constants.Invalid ||
      this.userForm.controls.userphone.status === this.constants.Invalid

    ) {
      return;
    }

    if (this.isEcoomUser == true && this.isUserEdit == false) {
      if (
        this.userForm.controls.userpassword.status === this.constants.Invalid ||
        this.userForm.controls.userpassworConfirm.status === this.constants.Invalid
      ) {
        return;
      }

    }

    if (this.userzip != null && this.userzip != undefined && this.userzip != "") {
      let zipDigits = this.userzip;
      this.isValidFlg = (zipDigits?.length == 0 || zipDigits?.length == 10);
      if (zipDigits?.length >= 5) {
        zipDigits = zipDigits.substring(0, 5) + "-" + zipDigits.substring(6, 10);
      }
      else {
        return;
      }
    }
    this.loader = true;
    this.customerService.CheckMultiUserEmail(this.useremail, this.value, this.EditUserId).subscribe(
      data => {
        this.loader = false;
        this.data = data;
        if (this.data.dataList[0].customerName == null) {
          var checkUser = this.multiUserList.find(x => x.email == this.useremail && x.IsActive == true && x.id != this.EditUserId);
          if (checkUser) {
            this.AlreadyEmail = `'${this.useremail}' email Already added in MultiUser Records`;
            $('#AlreadyUserExist').show();
          }
          else {
            var checkUser = this.multiUserList.filter(x => x.Issocontact == true);
            if (checkUser.length != 0) {
              if (checkUser.length <= 1) {
                if (this.Issocontact == true && checkUser[0].id != this.EditUserId) {
                  this.Issocontact = false;
                  $('#ReturnModel').show();
                  return
                }

              }
            }
            let ids = 0;
            var userData = {};
            userData = this.multiUserList.find(x => x.id == this.EditUserId && x.IsActive == true);
            userData['firstname'] = this.userfirstName;
            userData['lastname'] = this.userlastName;
            userData['email'] = this.useremail;
            userData['phone'] = this.userphone;
            userData['address1'] = this.useraddress;
            userData['address2'] = this.useraddress1;
            userData['password'] = userData['password'];
            userData['city'] = this.usercity;
            userData['state'] = this.selectedUserValue;
            userData['extention'] = this.userextention;
            userData['zip'] = this.userzip;
            userData['fax'] = this.userfax;
            userData['country'] = this.selectedContactCountry;
            userData['ModifiedBy'] = this.userId;
            userData['CreatedBy'] = this.userId;
            userData["CreatedDate"] = userData["CreatedDate"];
            userData["ModifiedDate"] = new Date();
            userData["IsActive"] = true;
            userData["CompanyId"] = Number(this.value);
            userData["IsEcoomerce"] = this.isEcoomUser;
            userData['CustomerContacttypeId'] = parseInt(this.selectedCustomerContactType);
            userData['Issocontact'] = this.Issocontact;
            if (this.selectedItems.length > 0) {
              let role = '';
              this.selectedItems.forEach(element => {
                role = role + element + ', '
              });
              role = role.slice(0, -2);
              userData['_MultiContactUserRoles'] = role
            }
            // userData["MultiContactUserRoles"]= this.multiContactUserRoles;
            this.multiContactUserRoles = [];
            this.selectedItems = [];
            if (this.selectedCustomerContactType != 0) {
              ids = parseInt(this.selectedCustomerContactType);
              let val = this.CustomerContacttypeId.filter(x => x.id == ids);
              if (val.length > 0) {
                userData["customerTypeVal"] = val[0].name;
              }

            }

            this.SaveModel = "Update Successfully";
            $('#SaveModel').show();
            $('#customerModel').hide();
            this.GetMultiUserList();
            this.resetMultiUser();
          }
        }
        else {
          this.AlreadyEmail = `'${this.useremail}' user email already exist for this Customer :- ${this.data.dataList[0].customerName}. Please change Email Id`;
          $('#AlreadyUserExist').show();
        }

      }
    );


  }

  multiUserDelete(id: any) {
    var data = {};
    data = this.multiUserList.find(x => x.id == id && x.IsActive == true);
    data['firstname'] = data['firstname'];
    data['lastname'] = data['lastname'];
    data['email'] = data['email'];
    data['phone'] = data['phone'];
    data['address1'] = data['address1'];
    data['address2'] = data['address2'];
    data['password'] = data['password'];
    data['city'] = data['city'];
    data['state'] = data['state'];
    data['ModifiedBy'] = this.userId;
    data['CreatedBy'] = this.userId;
    data["CreatedDate"] = data["CreatedDate"];
    data["ModifiedDate"] = data["ModifiedDate"];
    data["CompanyId"] = data["CompanyId"];
    data["IsEcoomerce"] = data["IsEcoomerce"];
    data["IsActive"] = false;
    data['CustomerContacttypeId'] = data['CustomerContacttypeId'];
    data['Issocontact'] = data['Issocontact'];
    $('#DeletePopModel').hide();
    $('#deleteUserlist').hide();
    this.SaveModel = "Contact Deleted Successfully";
    this.GetMultiUserList();
    $('#SaveModel').show();

  }

  ChangePassword() {
    this.userPasswordvalidation = true;
    this.loader = true;
    let obj = {};
    if (this.userForm.controls.changepassword.value === undefined ||
      this.userForm.controls.changepassword.value === null ||
      this.userForm.controls.changepassword.value === '' ||
      this.userForm.controls.changepassworConfirm.value === undefined ||
      this.userForm.controls.changepassworConfirm.value === null ||
      this.userForm.controls.changepassworConfirm.value === '' ||
      this.changepassword != this.changepassworConfirm
    ) {
      this.loader = false;
      return;
    }
    obj['password'] = this.changepassword;
    obj['Id'] = this.EditUserId;
    const userid = this.EditUserId;
    if (this.changepassword != this.changepassworConfirm) {
      this.loader = false;
      return;
    }
    this.customerService.updateMultiUserPassword(obj)
      .subscribe(
        data => {
          this.data = data;
          switch (this.data.statusCode) {
            case 200:
              $('#ChangePassword').show();
              break;
            case 400:
              $("#ChangePassword").show();
              break;
            default:
              $("#wrong_msg").show();
          }
          var datas = {};
          datas = this.multiUserList.find(x => x.id == this.EditUserId && x.IsActive == true);
          datas['password'] = this.changepassword;
          datas['isPasswordChange'] = true;
          $('#ChangePassword').show();
          this.clearPasswordDetails();
          this.loader = false;
        }
      );
  }
  CloseChangePassword() {
    $('#ChangePassword').hide();
  }

  clearPasswordDetails() {
    this.changepassword = "";
    this.changepassworConfirm = "";
    this.userPasswordvalidation = false;
  }
  changeGuestCustomer(event: any) {
    if (event.target.checked == true) {
      if (this.id != undefined && this.id != "" && !Number.isNaN(this.id)) {
        this.loader = true;
        this.customerService.GetAllCustomerByCustomerId(this.id).subscribe(
          (data: any) => {
            this.loader = false;
            if (data.data.userType == 'Guest') {
              this.isGuestType = true;
              this.multiUserList = [];
              this.GetMultiUserList();
            }
            else {
              $('#notGuest').show();
              event.target.checked = false;
              this.isGuest = false;
            }
          });
      }
      else {
        this.isGuestType = true;
        this.multiUserList = [];
        this.GetMultiUserList();
      }
    }
    else {

      this.isGuestType = false;
      let objs = {};
      objs["id"] = Number(this.i) + 1;
      this.i = objs["id"];
      this.userfirstName = this.firstName;
      this.userlastName = this.lastName;
      this.useremail = this.contactEmail;
      this.userphone = this.contactPhone;
      this.usercity = this.contactCity;
      this.userstate = this.selectedContactState;
      this.usercountry = this.selectedContactCountry;
      this.userextention = this.contactExtension;
      this.userzip = this.contactZip;
      this.userfax = this.contactFax;
      this.useraddress = this.contactAddressLine1;
      this.useraddress1 = this.contactAddressLine2;
      this.isEcoomUser = true;
      //  this.customerContacttype=this.customerContacttype;
      if (this.id != undefined && this.id != "" && !Number.isNaN(this.id)) {
        $('#customerModel').show();
      }
    }
  }
  ClosenotGuest() {
    $('#notGuest').hide();
  }
  omit_special_char(event) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

  keyPressNumbers(event) {
    var character = String.fromCharCode(event.keyCode);
    if (this.AdditionalDiscount == undefined) {
      this.AdditionalDiscount = "";
    }
    var newValue = this.AdditionalDiscount + character;
    if (newValue != ".") {
      if (isNaN(Number(newValue)) || this.hasDecimalPlace(newValue, 3)) {
        event.preventDefault();
        return false;
      }
    }

  }
  hasDecimalPlace(value, x) {
    var pointIndex = value.indexOf('.');
    return pointIndex >= 0 && pointIndex < value.length - x;
  }
  changeEUser(e) {
    if (e.target.value == true) {
      this.isUserEdit = false;
    }
    else {
      this.isUserEdit = false;
    }
  }

  //Ankit Implementation
  OnCollectAmount(event) {
    if (event.currentTarget.checked) {
      this.CollectAmount = event.currentTarget.value;
      this.IsCollectAmount = event.currentTarget.checked;
      if (event.currentTarget.value == 'Fedex') {
        this.IsCollectFedex = true;
        this.IsCollectUPS = false;
      } else {
        this.IsCollectFedex = false;
        this.IsCollectUPS = true;
      }
    } else {
      this.IsCollectAmount = false;
      this.IsCollectFedex = false;
      this.IsCollectUPS = false;
    }
  }

  // Customer role modal
  openCustomerRoleModel() {
    this.showCustomerType = false;
    //this.customerEditableData = this.CustomerTypeList.map(obj => ({...obj,enabled:false}));
    $('#customerRoleModel').show();
  }


  customerModelType() {
    this.showCustomerType = true;
  }

  userRoleclose() {
    this.customerEditableData.forEach(element => {
      element.enabled = false;
    });
    $('#customerRoleModel').hide();
  }
  enableEditMethod(customerRole: any) {
    this.customerEditableData.forEach(element => {
      if (element.id === customerRole.id) {
        element.enabled = true;
      }
      else {
        element.enabled = false;
      }
    });
    this.customerNameModal = customerRole.customerType;
    this.selectedCustomerinModal = customerRole;
    // customerRole.enabled = !customerRole.enabled;
    // this.enableCustomerRoleCancelBtn = true;
    // this.enableCustomerRoleSaveBtn = true;
    // this.enableCustomerRoleAddBtn = false;
    // this.enableCustomerRoleEditBtn = false;
  }
  customerTypeModalCancelBtnClicked() {
    this.customerEditableData.forEach(element => {
      if (element.id === this.selectedCustomerinModal.id) {
        element.enabled = !this.selectedCustomerinModal.enabled;
      }
    });
    // this.enableCustomerRoleCancelBtn = false;
    // this.enableCustomerRoleSaveBtn = false;
    // this.enableCustomerRoleAddBtn = true;
    // this.enableCustomerRoleEditBtn = true;
  }
  customerTypeModalSaveBtnClicked(item) {

    let obj = {};
    obj['CustomerType'] = this.form.controls.customerTypeName.value;
    obj['IsActive'] = this.form.controls.CustomerTypeIsActive.value == 1 ? true : false;
    obj["Id"] = item.id;
    if (this.form.controls.customerTypeName.value != '') {
      item.customerType = this.form.controls.customerTypeName.value;
      item.isActive = this.form.controls.CustomerTypeIsActive.value == 1 ? true : false;
    }
    this.customerEditableData.forEach(element => {
      if (element.id === this.selectedCustomerinModal.id) {
        element.enabled = !this.selectedCustomerinModal.enabled;
      }
    });
    // this.enableCustomerRoleCancelBtn = false;
    // this.enableCustomerRoleSaveBtn = false;
    // this.enableCustomerRoleAddBtn = true;
    // this.enableCustomerRoleEditBtn = true;
    this.customerService.UpdateCustomerTypeList(item)
      .subscribe(
        data => {
          this.data = data;
          this.GetAllCustomerTypeList();
        }
      );
  }
  addCustomerTypeSaveBtnClicked() {
    let obj = {};
    obj['CustomerType'] = this.form.controls.addCustomerTypeName.value;
    obj['IsActive'] = this.form.controls.addCustomerTypeIsActive.value == 1 ? true : false;

    if (this.form.controls.addCustomerTypeName.value == '' || this.form.controls.addCustomerTypeIsActive.value == '') {
    } else {
      this.customerService.AddCustomerTypeList(obj)
        .subscribe(
          data => {
            this.data = data;
            this.GetAllCustomerTypeList();
            $("#add_success").show();
            this.showCustomerType = false;
            this.addCustomerTypeName = '';
            this.addCustomerTypeIsActive = '';
          }
        );
    }

  }


  addCustomerTypeCancelBtnClicked() {
    this.addCustomerTypeName = '';
    this.addCustomerTypeIsActive = '';
    this.showCustomerType = false;
  }


  onItemSelect(item: any) {
    this.multiContactUserRoles.push(item);
  }

  onItemDeSelect(item: any) {
    const obj = this.multiContactUserRoles.filter(x => x.id !== item.id);
    this.multiContactUserRoles = obj;
    // const index= this.multiContactUserRoles.indexOf(item.id);
    // this.multiContactUserRoles.splice(index,1);
  }

  //ankit implemenation
  ErrorModal(title: string, message: string) {
    Swal.fire({
      title: title,
      text: message,
      showCancelButton: true,
      cancelButtonText: 'Close',
    })
  }

  //ankit implementation 16Jan2024
  keyupFedexCollect(event) {
    const value = event.currentTarget.value;
    var pattern = /^\d+$/;
    var matches = pattern.test(value);
    if (!matches) {
      this.FedexCollect = '';
      this.disabledCollectFedex = false;
      return false;
    }
    const ups = this.UPSCollect ? this.UPSCollect : '';
    if (value != '' && ups != '') {
      this.IsCollectAmount = true;
      this.IsCollectFedex = true ? !this.IsCollectUPS : true;
      this.disabledCollectFedex = false;
      this.disabledCollectUPS = false;
    } else if (value != '' && ups == '') {
      this.IsCollectAmount = true;
      this.IsCollectFedex = true ? !this.IsCollectUPS : true;
      this.disabledCollectFedex = false;
      this.disabledCollectUPS = true;
    } else if (value == '' && ups != '') {
      this.IsCollectAmount = true;
      this.IsCollectFedex = false;
      this.IsCollectUPS = true ? (ups != '') : true;
      this.disabledCollectFedex = true;
      this.disabledCollectUPS = false;
    } else {
      this.IsCollectAmount = false;
      this.disabledCollectFedex = true;
      this.disabledCollectUPS = true;
      this.IsCollectFedex = false;
      this.IsCollectUPS = false;
    }
  }
  keyupUPSCollect(event) {
    const value = event.currentTarget.value;
    const fedex = this.FedexCollect ? this.FedexCollect : '';
    if (value != '' && fedex != '') {
      this.IsCollectUPS = true ? !this.IsCollectFedex : true;
      this.IsCollectAmount = true;
      this.disabledCollectFedex = false;
      this.disabledCollectUPS = false;
    } else if (value != '' && fedex == '') {
      this.IsCollectUPS = true ? !this.IsCollectFedex : true;
      this.IsCollectAmount = true;
      this.disabledCollectFedex = true;
      this.disabledCollectUPS = false;
    } else if (value == '' && fedex != '') {
      this.IsCollectAmount = true;
      this.IsCollectUPS = false;
      this.IsCollectFedex = true ? (fedex != '') : true;
      this.disabledCollectFedex = false;
      this.disabledCollectUPS = true;
    } else {
      this.IsCollectAmount = false;
      this.disabledCollectFedex = true;
      this.disabledCollectUPS = true;
      this.IsCollectFedex = false;
      this.IsCollectUPS = false;
    }
  }

  //ankit modified
  onParentCustomerChange(value: number) {
    this.selectedCustomer = value;
  }

  popupModal(title: string, message: string) {
    Swal.fire({
      title: title,
      text: message,
      showCancelButton: true,
      cancelButtonText: 'Close',
    })
  }

  filterParentCustomer(id): any {
    // if(this.customers.length>0 && this.customers.filter(x=>x.value==id).length >0){
    //   return this.customers.filter(x=>x.value==id)[0].text;
    // }else{
    return '';
    //}
  }
}

