import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ConstantsService } from 'src/app/constants.service';
import { SharedService } from 'src/app/services/shared.service';
import { DatePipe } from '@angular/common';
import { ConfirmationDialogService } from 'src/app/ConfirmationDialog/confirmation-dialog.service';
import { MatTableDataSource } from '@angular/material/table';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { GlobalChangeService } from 'src/app/services/global-change.service';
import { BarcodeScannerLivestreamComponent } from 'ngx-barcode-scanner';
import pdfMake from "pdfmake/build/pdfmake";
import Swal from 'sweetalert2';
import { CurrencyPipe } from '@angular/common';
import * as JsBarcode from 'jsbarcode';
import { VendorService } from 'src/app/services/vendor/vendor.service';
import { CustomerService } from 'src/app/services/customer/customer.service';
import { CompanyService } from 'src/app/services/company/company.service';
import { PartService } from 'src/app/services/part/part.service';
import { SalesOrderService } from 'src/app/services/salesOrder/sales-order.service';
import { Router } from '@angular/router';
import { MatSelect } from '@angular/material/select';
import { SelectionModel } from '@angular/cdk/collections';
import { FilterListRiquest, ListRiquest } from 'src/app/CommonInterface';

@Component({
  selector: 'app-shipment',
  templateUrl: './shipment.component.html',
  styleUrls: ['./shipment.component.css'],
})
export class ShipmentComponent implements OnInit {
  shippingList: any = [];
  shippingDS: any = [];
  companyId: any;
  form: FormGroup;
  loader: boolean = false;
  statuslist: any;
  boxSizing: any[] = [];
  _sideNavList: any;
  SerialLotForm: FormGroup;
  ChildReadyForLot: number = 0;
  IsLotBtnShow: boolean = false;
  FlayOutId: number = 0;
  FlyOut: any = 0;
  multiPackageData: any = [];
  qrResultString: string = '';
  IsScan: boolean = false;
  IsBarCodeScan: boolean = false;
  ScanIndex: number = 0;
  IsPrint: boolean = false;
  IsErrorValue: any;
  IsFlyoutErro: boolean = false;
  EqualShippedSerialLotList: any = [];
  IsDuplicateCheckTrackingList: any = [];
  PrinterList: any = [];
  _flyOutPIndex: number = 0;
  _flyOutCIndex: number = 0;
  _IsParent: boolean = false; base64: string;
  FlayOutPackageNo: any;
  hasDevices: boolean;
  _IsBtnFlyOutSave: boolean = false;
  customerDetails: any = [];
  SObarcodeImg: any;
  PObarcodeImg: any;
  @ViewChild('barcode') barcode: ElementRef;
  @ViewChild('scanner') scanner: ZXingScannerComponent;
  @ViewChild('barCodescanner', { static: false }) barCodescanner: BarcodeScannerLivestreamComponent;
  IsShippingList: boolean = false;
  IsFlyOut: boolean = false;
  ShippingDataById: any;
  StateList: any[] = [];
  CountryList: any[] = [];
  specialPartList = [];
  shiprateListnew = [];
  ship_to: any;
  CompanyDetail: any;
  RateDetails: any;
  ShipMinDate: any = new Date();
  printers: any[] = [];
  rolePermissionsList: any;
  permissionMessage = '';
  printerStatus: any;
  filter: any;
  ListRiquest = new ListRiquest();
  FilterListRiquest = new FilterListRiquest;
  ShipmentDSColumn: string[] = ['menu', 'company', 'orderDate', 'orderNumber', 'customerName', 'contactName', 'status', 'carrier', 'shippedDate', 'shipAs'];
  selection = new SelectionModel<any>(true, []);
  @ViewChild('select') select: MatSelect;
  IsMarkAsCompleted: boolean = false;
  constructor(
    public sharedService: SharedService,
    public vendorService: VendorService,
    public customerService: CustomerService,
    private fb: FormBuilder,
    private constants: ConstantsService,
    public datepipe: DatePipe,
    public confirmation: ConfirmationDialogService,
    public dateFormatPipe: DatePipe,
    private globalChangeService: GlobalChangeService,
    public CurrencyPipe: CurrencyPipe,
    private companyService: CompanyService,
    private partService: PartService,
    public salesOrderService: SalesOrderService,
    private router: Router
  ) { }
  ngOnInit(): void {
    this.companyId = localStorage.getItem('GlobalSelectedCompanyId');
    this.CreateForm();
    if (localStorage.getItem('AuthHeader') === null || localStorage.getItem('AuthHeader') === undefined) {
      this.router.navigate(['']);
    } else {
      this.rolePermissionsList = JSON.parse(localStorage.getItem('rolePermissionsList'));
      this.rolePermissionsList = this.rolePermissionsList.filter(x => x.pageId == 2);
      this.permissionMessage = '';
      if (this.rolePermissionsList != null && this.rolePermissionsList != undefined) {
        this.rolePermissions()
        this.GetAllStatus();
        this.getAllState();
        this.GetShippingList(true);
        this.GetCompanyById(Number(this.companyId));
      }
      if (this.permissionMessage != '') {
        this.permissionMessage = 'You do not have access to ' + this.permissionMessage + '. Please contact to administrator!';
      }
    }
    //on change function
    this.globalChangeService.dropdownChange$.subscribe((data) => {
      window.location.reload()
    })

  }
  //Form Creation
  CreateForm() {
    this.SerialLotForm = new FormGroup({
      SerialNumber: this.fb.array([]),
    });
    this.form = new FormGroup({
      'companyId': new FormControl(this.companyId),
      'ShippingPackage': new FormArray([], Validators.minLength(1)),
      'rowItems': new FormArray([]),
      'shippedDate': new FormControl(new Date()),
      'totalShippingPartWeight': new FormControl(0),
      'totalShippingVariance': new FormControl(0),
      'actualShippingWeight': new FormControl(0),
      'masterTrackingNo': new FormControl(''),
      'statusId': new FormControl({ value: 1, disabled: true }),
      'orderId': new FormControl(0),
      'actualShippingType': new FormControl(''),
      'ActualShippingAmount': new FormControl(0),
      'actualPackageType': new FormControl(''),
      'ActualrateId': new FormControl(''),
      'actualShippingOption': new FormControl(0),
      'LabelId': new FormControl(''),
      'IsPartialShipment': new FormControl(''),
      'userId': new FormControl(localStorage.getItem('userId')),
      'LabelPrinter': new FormControl(''),
      'SlipPrinter': new FormControl(''),
    });
  }
  Back() {
    this.SerialNumber.clear();
    this.rowItems.clear();
    this.IsShippingList = false;
    this.specialPartList = [];
    this.IsFlyoutErro = false;
    this.ListRiquest = new ListRiquest();
    this.FilterListRiquest = new FilterListRiquest();
    this.GetShippingList(true);
  }
  GetAllStatus() {
    this.loader = true;
    this.salesOrderService.GetSOStatusList()
      .subscribe({
        next: (data: any) => {
          this.statuslist = data.dataList;
          this.loader = false;
        },
        error: (err) => {
          this.loader = false
        },
      });
  }
  getMultiplePackageFromOrderList(IsLoader: boolean) {
    this.multiPackageData = [];
    this.sharedService.GetMultiPackageShipping(this.ShippingDataById.salesOrder.id, this.form.value.masterTrackingNo).subscribe((data: any) => {
      this.rowItems.clear();
      if (data.length > 0) {
        this.multiPackageData = data;
        this.specialPartList.forEach((sp: any) => {
          const _list = this.multiPackageData.filter(x => x.opId == sp.id && x.parentId == null);
          _list.forEach((element: any) => {
            this.rowItems.push(new FormGroup({
              Id: new FormControl(element.id),
              OpId: new FormControl(element.opId),
              ready: new FormControl(element.ready),
              shipped: new FormControl(element.shipped),
              OrderId: new FormControl(element.orderId),
              package: new FormControl(element.package),
              weight: new FormControl(element.weight),
              PartId: new FormControl(element.partId),
              SubItemList: new FormArray([]),
              tracking: new FormControl(element.tracking),
              parentId: new FormControl(element.parentId),
              PartSerialId: new FormControl(element.partSerialId),
              cost: new FormControl(element.cost),
            }))
          })
        });
      } else {
        this.specialPartList.forEach((element: any) => {
          this.rowItems.push(new FormGroup({
            Id: new FormControl(0),
            OpId: new FormControl(element.id),
            ready: new FormControl(element.qtyReadyToShip),
            shipped: new FormControl(0),
            OrderId: new FormControl(element.salesOrderId),
            package: new FormControl(this.form.value.ShippingPackage[0].id),
            weight: new FormControl(element.weight),
            PartId: new FormControl(element.partId),
            SubItemList: new FormArray([]),
            tracking: new FormControl(),
            parentId: new FormControl(),
            PartSerialId: new FormControl(element.partTracking),
            cost: new FormControl(element.cost),
          }))
        });
      }
      this.AddSubListItemMultipackage(IsLoader);
    })
  }
  Search(searchTerm: any) {
    this.ListRiquest.searchTerm = this.ISNullOrEmpty(searchTerm.SearchText) ? '' : searchTerm.SearchText.trim();
    this.ListRiquest.IsActiveStatus = searchTerm.searchStatus;
    this.GetShippingList(false);
  }
  Filter() {
    let _filter = localStorage.getItem('ShipmentFilter');
    this.FilterListRiquest.StatusIds = this.constants.ShipmentStatusFilterList[0].statusId;
    if (_filter != null) {
      this.FilterListRiquest = JSON.parse(_filter);
      this.SearchFilterList();
    }
    this.FilterListRiquest.FilterTypeList = this.constants.ShipmentFilterTypeList;
    this.FilterListRiquest.StatusList = this.constants.ShipmentStatusFilterList;
    this.FilterListRiquest.PageName = 'ShipmentFilter';
    this.filter = this.FilterListRiquest;
    this.AddColumn();
  }
  ApplyFilter(value: any) {
    if (value != undefined) {
      this.FilterListRiquest = value
      this.AddColumn();
      this.SearchFilterList();
    }
  }
  AddColumn() {
    if (this.FilterListRiquest.StatusIds == 2) {
      this.ShipmentDSColumn.length <= 10 ? this.ShipmentDSColumn.splice(10, 0, 'markascompleted') : false;
    } else {
      this.ShipmentDSColumn.splice(10, 1)
    }
  }
  GetShippingList(IsLoader: boolean) {
    this.loader = IsLoader;
    this.sharedService.GetShippingList(this.ListRiquest).subscribe(
      (data: any) => {
        this.shippingList = data.dataList;
        this.shippingDS = new MatTableDataSource(this.shippingList);
        this.loader = false;
        this.Filter()
      }
    )
  }
  //Get All State
  getAllState() {
    this.salesOrderService.getAllState()
      .subscribe({
        next: (data: any) => {
          this.StateList = data;
        },
        error: (err) => {
          this.loader = false
          this.constants.SwalModal(this.constants.Exception, this.constants.SomethingWentWrong);
        },
      });
  }
  GetStateCode(stateId: number) {
    return (this.StateList.length > 0 && Number(stateId) > 0 && this.StateList.filter(x => x.id == stateId).length > 0) ? this.StateList.filter(x => x.id == stateId)[0].abbreviation : ''
  }
  GetShippingDetailsByOrderId(id: number, masterTrackingNo: string, IsLoader: boolean) {
    this.BindRowData(masterTrackingNo);
    IsLoader ? this.loader = true : false;
    this.sharedService.GetShippingDetailsByOrderId(id, this.form.value.masterTrackingNo)
      .subscribe({
        next: (data: any) => {
          this.IsShippingList = true;
          this.ShippingDataById = data.data;
          this.ship_to = {
            "addressLineOne": this.ShippingDataById.shipAddress.addressLine1,
            "addressLineTwo": this.ISNullOrEmpty(this.ShippingDataById.shipAddress.addressLine2) ? '' : this.ShippingDataById.shipAddress.addressLine2,
            "addressType": 0,
            "cityName": this.ShippingDataById.shipAddress.city,
            "countryCode": this.ShippingDataById.shipAddress.countryCode,
            "stateCode": this.ShippingDataById.shipAddress.stateCode,
            "postalCode": this.ShippingDataById.shipAddress.zip
          }
          this.BindData(IsLoader);
        },
        error: (err) => {
          this.loader = false
          this.constants.SwalModal(this.constants.Exception, this.constants.SomethingWentWrong);
        },
      });
  }
  BindRowData(masterTrackingNo: string) {
    this.form.patchValue({
      masterTrackingNo: this.ISNullOrEmpty(masterTrackingNo) ? '' : masterTrackingNo,
    })
  }
  BindData(IsLoader: boolean) {
    this.form.patchValue({
      actualPackageType: this.ISNullOrEmpty(this.ShippingDataById.salesOrder.packageType) ? 'YOUR_PACKAGING' : this.ShippingDataById.salesOrder.packageType,
      actualShippingType: this.ISNullOrEmpty(this.ShippingDataById.salesOrder.actualShippingType) ? this.ShippingDataById.salesOrder.shippingType : this.ShippingDataById.salesOrder.actualShippingType,
      ActualrateId: this.ShippingDataById.salesOrder.rate_id,
      actualShippingOption: this.ShippingDataById.salesOrder.actualShippingOption > 0 ? this.ShippingDataById.salesOrder.actualShippingOption : this.ShippingDataById.salesOrder.shippingOption,
      IsPartialShipment: this.ShippingDataById.salesOrder.isPartialShipment,
      ActualShippingAmount: this.ShippingDataById.salesOrder.actualShippingAmt,
      totalShippingPartWeight: this.ShippingDataById.salesOrder.totalShippingPartWeight == null ? 0 : this.ShippingDataById.salesOrder.totalShippingPartWeight,
      totalShippingVariance: this.ShippingDataById.salesOrder.totalShippingVariance == null ? 0 : this.ShippingDataById.salesOrder.totalShippingVariance,
      actualShippingWeight: this.ShippingDataById.salesOrder.actualShippingWeight == null ? 0 : this.ShippingDataById.salesOrder.actualShippingWeight,
      statusId: this.ShippingDataById.salesOrder.statusId,
      orderId: this.ShippingDataById.salesOrder.id,
      companyId: this.ShippingDataById.salesOrder.companyId,
      LabelPrinter: this.ShippingDataById.salesOrder.labelPrinter,
      SlipPrinter: this.ShippingDataById.salesOrder.slipPrinter,
    })
    this.specialPartList = this.ShippingDataById.salesOrder.orderPart;
    this.GetShippedCartonByOrderId(IsLoader);
    this.getShippingBoxSizes();
    this.BarcodeGenerate(false);
    if (this.ShippingDataById.salesOrder.purchaseOrder != '') {
      this.BarcodeGenerate(true);
    }
  }
  AddSubListItemMultipackage(IsLoader) {
    this.SerialNumber.clear();
    this.rowItems.value.forEach((pelement: any, index) => {
      if (this.multiPackageData.length > 0) {
        const _list = this.multiPackageData.filter(x => x.parentId === pelement.Id && x.parentId != null);
        _list.forEach((element: any) => {
          this.getRowItemSubList(index).push(new FormGroup({
            Id: new FormControl(element.id),
            ready: new FormControl(element.ready),
            shipped: new FormControl(element.shipped),
            package: new FormControl(element.package),
            weight: new FormControl(element.weight),
            tracking: new FormControl(element.tracking),
            PartSerialId: new FormControl(pelement.PartSerialId == undefined ? 3 : pelement.PartSerialId),
            cost: new FormControl(element.cost),
          }))
        })
      } else {
        this.getRowItemSubList(index).push(new FormGroup({
          Id: new FormControl(0),
          ready: new FormControl(pelement.ready),
          shipped: new FormControl(pelement.shipped),
          package: new FormControl(),
          weight: new FormControl(pelement.weight),
          tracking: new FormControl(pelement.tracking == null ? '' : pelement.tracking),
          PartSerialId: new FormControl(pelement.PartSerialId == undefined ? 3 : pelement.PartSerialId),
          cost: new FormControl(pelement.cost),
        }))
      }
    });
    IsLoader ? this.loader = false : '';
  }
  GetTracking(Id: number): string {
    return this.constants.SerialTracking.filter(t => t.id == Id).length > 0 ? this.constants.SerialTracking.filter(t => t.id == Id)[0].value : 'None';
  }
  GetMesurmentSymbols(Id: any): string {
    let Symbol = '';
    if (Id == 1) {
      Symbol = 'cm'
    } else if (Id == 2) {
      Symbol = '"'
    } else if (Id == 3) {
      Symbol = "'"
    }
    return Symbol;
  }
  GetShippedCartonByOrderId(IsLoader: boolean) {
    this.sharedService.GetShippedCartonByOrderId(this.ShippingDataById.salesOrder.id, this.ShippingDataById.salesOrder.companyId, this.form.value.masterTrackingNo)
      .subscribe({
        next: (data: any) => {
          if (data.dataList.length > 0) {
            this.ShippingPackage.clear();
            data.dataList.forEach((x: any) => {
              this.ShippingPackage.push(new FormGroup({
                id: new FormControl(x.id),
                boxSizes: new FormControl(x.boxSizes == null || x.boxSizes == undefined || x.boxSizes == '' ? 0 : x.boxSizes),
                pLength: new FormControl(x.pLength == null || x.pLength == undefined ? 0 : Number(x.pLength)),
                pLengthD: new FormControl(x.pLengthD == null || x.pLengthD == undefined ? 0 : Number(x.pLengthD)),
                pWidth: new FormControl(x.pWidth == null || x.pWidth == undefined ? 0 : Number(x.pWidth)),
                pWidthD: new FormControl(x.pWidthD == null || x.pWidthD == undefined ? 0 : Number(x.pWidthD)),
                pHeight: new FormControl(x.pHeight == null || x.pHeight == undefined ? 0 : Number(x.pHeight)),
                pHeightD: new FormControl(x.pHeightD == null || x.pHeightD == undefined ? 0 : Number(x.pHeightD)),
                pWeight: new FormControl(x.pWeight == null || x.pWeight == undefined ? 0 : Number(x.pWeight)),
                pWeightD: new FormControl(x.pWeightD == null || x.pWeightD == undefined ? 0 : Number(x.pWeightD)),
                qtyInPackage: new FormControl(x.qtyInPackage == null || x.qtyInPackage == undefined || x.qtyInPackage == '' ? 0 : x.qtyInPackage),
                tracking: new FormControl(x.tracking),
                partWeight: new FormControl(x.partWeight == null || x.partWeight == undefined ? 0 : x.partWeight),
                totalweight: new FormControl(x.totalweight == null || x.totalweight == undefined ? 0 : x.totalweight),
                variance: new FormControl(x.variance == null || x.variance == undefined ? 0 : x.variance),
                isEditable: new FormControl(x.isEditable == null ? false : x.isEditable),
                label_downloadURL: new FormControl(x.label_downloadURL),
                masterLabel_downloadURL: new FormControl(x.masterLabel_downloadURL),
                cost: new FormControl(x.cost),
                packageNo: new FormControl(x.packageNo),
                actualShippingType: new FormControl(x.actualShippingType),
                actualrateId: new FormControl(x.actualrateId),
                actualShippingAmount: new FormControl(x.actualShippingAmount),
                actualShippingOption: new FormControl(x.actualShippingOption)
              }))
            })
            this.getMultiplePackageFromOrderList(IsLoader);
          }
        },
        error: (err) => {
          this.loader = false
          this.constants.SwalModal(this.constants.Exception, this.constants.SomethingWentWrong);
        },
      });
  }
  EditCarton(index: number) {
    this.form.value.ShippingPackage[index].isEditable = false;
    this.form.patchValue({ ShippingPackage: this.form.value.ShippingPackage });
  }
  GetEqualShippedSerialLot(PrintAll: boolean) {
    if (this.PackageFillValidation() == -1) {
      if (this.form.value.ShippingPackage.length > 1 && this.form.value.actualShippingOption == 8) {
        this.loader = false;
        this.constants.SwalModal('Message', 'USPS does not support multi-package shipment.')
      } else {
        this.IsPrint = false;
        this.sharedService.GetEqualShippedSerialLot(this.form.value.orderId).subscribe((res: any) => {
          this.EqualShippedSerialLotList = res.dataList;
          this.IsPrint = res.dataList.filter(x => x.isCountSame == 0 || x.tableCount != this.EqualShippedSerialLotList.length).length > 0 ? true : false;
          this.IsPrint == true ? this.PrintSlipMissingInfo() : this.ShippingPayment(PrintAll);    // need to false
        }), (err) => {
          this.loader = false;
        };
      }
    } else {
      this.PackageFillValidationError();
    }
  }
  RePrintLabel() {
    this.PrintDoc(this.form.value.ShippingPackage[0].masterLabel_downloadURL);
  }
  preferredCurrency() {
    return { "amount": this.form.value.ActualShippingAmount, "type": 67 }
  }
  PackageRequest(): any[] {
    let packages = [];
    this.form.value.ShippingPackage.forEach(element => {
      packages.push(
        {
          "packageId": element.packageNo,
          "groupPackageCount": 1,
          "packageType": "",
          "packageSubType": 0,
          "signatureOptionType": 0,
          "declaredValue": {
            "amount": element.cost,
            "type": 67
          },
          "dimensions": {
            "height": element.pHeight == null ? 0 : Number(element.pHeight),
            "length": element.pLength == null ? 0 : Number(element.pLength),
            "width": element.pWidth == null ? 0 : Number(element.pWidth),
            "unitOfMeasurement": element.pLengthD
          },
          "weight": { "unit": 5, "value": element.totalweight } //element.pWeight }
        }
      )
    });
    return packages;
  }
  RateAPI() {
    if (this.PackageFillValidation() == -1) {
      this.loader = true;
      this.IsFlyoutErro = false;
      let estimateRequestModels = {
        "documentIndicator": false,
        "packageType": "",
        "pickupType": 4,
        "preferredCurrency": 67,
        "providers": [this.form.value.actualShippingOption],
        "rateTypes": [1],
        "shipDate": this.form.value.shippedDate,
        "totalPackageCount": this.form.value.ShippingPackage.length,
        "origin": this.constants.originAddress,
        "recipient": { "address": this.ship_to },
        "totalWeight": { "unit": 5, "value": this.form.value.actualShippingWeight },
        "packages": this.PackageRequest()
      }
      this.sharedService.RateAPI(this.form.value.companyId, estimateRequestModels)
        .subscribe({
          next: (data: any) => {
            this.loader = false;
            this.shiprateListnew = [];
            data.providerRates[0].rates.forEach(element => {
              var obj = {};
              obj["rate_id"] = element.serviceType;
              obj["package_type"] = element.packageType;
              obj["service_type"] = element.serviceName;
              obj["carrier_delivery_days"] = element.operationalDetail.deliveryDay;
              obj["amount"] = element.rateDetails[0].totalNetCharge;
              this.shiprateListnew.push(obj);
            });
            $("#shiprateVM").show();
          },
          error: (err) => {
            this.loader = false
            this.constants.SwalModal(this.constants.Exception, this.constants.SomethingWentWrong);
          },
        });
    } else {
      this.PackageFillValidationError();
    }
  }
  ShippingLabelAPI(PrintAll: boolean) {
    let ShippingLabelRequestModel = {
      "blockInsightVisibilityIndicator": true,
      "individualLabelIndicator": true,
      "labelResponseIndicator": true,
      "packageType": this.form.value.actualPackageType,
      "pickupType": 3,
      "rateRequestTypes": [1],
      "salesOrderId": this.form.value.orderId,
      "serviceType": this.form.value.ActualrateId,
      "shipmentSubmissionIndicator": true,
      "shipDate": this.form.value.shippedDate,
      "totalWeight": this.form.value.actualShippingWeight,
      "labelSpecification": {
        "labelDataOnlyIndicaAtor": true,
        "labelImageType": 2,
        "labelOptionType": 1,
        "labelStockType": 12
      },
      "preferredCurrency": this.preferredCurrency(),
      "shipper": this.constants.originAddress,
      "totalDeclaredValue": this.preferredCurrency(),
      "packages": this.PackageRequest(),
      "recipients": [
        {
          "address": this.ship_to,
          "contact": {
            "companyName": this.CompanyDetail.companyName,
            "name": this.CompanyDetail.compContactName,
            "phoneNumber": this.CompanyDetail.compContactNumber
          }
        }
      ]
    }
    this.sharedService.LabelAPI(this.form.value.companyId, this.form.value.actualShippingOption, ShippingLabelRequestModel)
      .subscribe({
        next: (res: any) => {
          this.loader = false;
          if (res != null && res.shipments != undefined) {
            if (res.shipments.length > 0) {
              //print data
              this.BindRowData(res.shipments[0].masterTrackingNumber)
              if (res.shipments[0].shippingDocuments.length > 0) {
                this.PrintDoc(res.shipments[0].shippingDocuments[0].url);
              } else {
                if (res.shipments[0].shipmentPieces.length > 0) {
                  if (res.shipments[0].shipmentPieces[0].packageDocuments.length > 0) {
                    this.PrintDoc(res.shipments[0].shipmentPieces[0].packageDocuments[0].url);
                  }
                }
              }
              this.GetShippingDetailsByOrderId(this.ShippingDataById.salesOrder.id, this.form.value.masterTrackingNo, true);
            }
          } else {
            this.constants.SwalModal('Message', res.errorMessage)
          }
          //Create So Log
          this.AddSalesOrderLog();
          this.AddUpdVarianceLog();
        },
        error: (err) => {
          this.loader = false;
          this.constants.SwalModal(this.constants.Exception, this.constants.SomethingWentWrong);
        }
      });
  }
  get packageTrackingGet(): FormArray {
    return this.form.controls.ShippingPackage as FormArray
  }
  ShippingPayment(PrintAll: boolean) {
    if (this.ShippingDataById.salesOrder.paymentType == "NetTerms" || this.ShippingDataById.salesOrder.balanceDue <= 0) {
      this.ShippingLabelAPI(this.form.value.LabelPrinter);
    } else {
      const payment = {
        Type: this.ShippingDataById.salesOrder.paymentType,
        orderid: this.ShippingDataById.salesOrder.id,
        customerid: this.ShippingDataById.salesOrder.customerId,
        paymentType: this.ShippingDataById.salesOrder.paymentType,
        amount: this.ShippingDataById.salesOrder.balanceDue * 100
      }
      this.salesOrderService.CompletePayment(payment)
        .subscribe({
          next: (data: any) => {
            data.data != null ? this.PaymentTypeResponse(data.data) : this.Paymentfails();
          },
          error: (err) => {
            this.loader = false
            this.Paymentfails();
          },
        });
    }
  }
  PaymentTypeResponse(value: any) {
    if (value.captureAuthorisedPaymentResponse != null && this.ShippingDataById.salesOrder.paymentType == 'PayPal') {
      if (value.captureAuthorisedPaymentResponse.status === this.constants.Completed && value.salesOrderPaymentLog != null) {
        this.ShippingLabelAPI(this.form.value.LabelPrinter)
      }
      if (value.captureAuthorisedPaymentResponse.status === this.constants.Failed || value.captureAuthorisedPaymentResponse.status === this.constants.Canceled) {
        this.constants.SwalModal('Payment', value.payment.status);
      }
    } else if (value.payment != null && this.ShippingDataById.salesOrder.paymentType == 'Credit Card') {
      if (value.payment.status === this.constants.Completed && value.salesOrderPaymentLog != null) {
        this.ShippingLabelAPI(this.form.value.LabelPrinter)
      }
      if (value.payment.status === this.constants.Failed || value.payment.status === this.constants.Canceled) {
        this.constants.SwalModal('Payment', value.payment.status);
      }
    } else {
      this.Paymentfails();
    }
  }
  Paymentfails() {
    this.loader = false;
    Swal.fire({
      title: 'Payment Error ',
      text: 'The Shipping Labels were not able to be printed because payment. was not able to be completed. Please consult with Sales to get this resolved.',
      showCancelButton: true,
      showConfirmButton: false,
      cancelButtonText: 'Close',
    })
  }
  PrintSlipMissingInfo() {
    this.loader = false;
    Swal.fire({
      title: 'Missing Information',
      text: 'All parts with Lot Numbers or Serial Numbers must be entered before the Shipping Labels and Packing Slip can be printed. Please scan or enter the missing information.',
    }).then((result) => {
      this.IsFlyoutErro = true;
    });
  }
  ReprintLabelByIndex(index: number) {
    Swal.fire({
      title: 'Printing Shipping Label',
      text: 'Please wait while the Package ' + (index + 1) + ' shipping label is printed.Click reprint to try again.',
      showCancelButton: true,
      cancelButtonText: 'Close',
      confirmButtonText: 'Reprint',
    }).then((result) => {
      if (result.value) {
        this.PrintDoc(this.ShippingPackage.value[index].label_downloadURL);
      }
    });
  }
  removePackage(index) {
    if (this.ISNullOrEmpty(this.form.value.masterTrackingNo)) {
      if (this.form.value.ShippingPackage[index].id < 0) {
        this.ShippingPackage.removeAt(index);
      } else {
        this.loader = true;
        this.sharedService.DeleteCartonFromList(this.form.value.ShippingPackage[index].id)
          .subscribe({
            next: (data: any) => {
              this.ShippingPackage.removeAt(index);
              this.loader = false;
            },
            error: (err) => {
              this.loader = false
              this.constants.SwalModal(this.constants.Exception, this.constants.SomethingWentWrong);
            },
          });
      }
    }
  }
  Save(IsModelShow: boolean, IsLoader: boolean) {
    if (this.PackageFillValidation() == -1) {
      if (!this.form.invalid) {
        this.loader = true;
        this.ConvertToDecimal();
        this.sharedService.AddMultiPackageShipping(this.form.value)
          .subscribe({
            next: (data: any) => {
              if (!IsLoader) {
                this.GetEqualShippedSerialLot(false)
              } else {
                this.GetShippingDetailsByOrderId(this.ShippingDataById.salesOrder.id, this.form.value.masterTrackingNo, IsLoader);
              }
              if (IsModelShow) {
                Swal.fire('Success', 'Record Saved Successfully.')
              }
            },
            error: (err) => {
              this.loader = false
              this.constants.SwalModal(this.constants.Exception, this.constants.SomethingWentWrong);
            },
          });
      }
    } else {
      this.PackageFillValidationError();
    }
  }
  ConvertToDecimal() {
    this.form.value.ShippingPackage.forEach((sp) => {
      sp.pWeight = Number(sp.pWeight)
    });
    this.form.patchValue({ ShippingPackage: this.form.value.ShippingPackage })
  }
  createPackage(id: number): FormGroup {
    return new FormGroup({
      id: new FormControl(id),
      boxSizes: new FormControl(0),
      pLength: new FormControl(0),
      pLengthD: new FormControl(),
      pWidth: new FormControl(0),
      pWidthD: new FormControl(),
      pHeight: new FormControl(0),
      pHeightD: new FormControl(),
      pWeight: new FormControl(0, Validators.required),
      pWeightD: new FormControl(),
      qtyInPackage: new FormControl(0),
      tracking: new FormControl(),
      partWeight: new FormControl(),
      variance: new FormControl(0.00),
      isEditable: new FormControl(),
      totalweight: new FormControl(0),
      cost: new FormControl(0),
      packageNo: new FormControl(0),
    })
  }
  AddSinglePackage() {
    if (this.ISNullOrEmpty(this.form.value.masterTrackingNo)) {
      if (this.form.value.ShippingPackage.length >= 1 && this.form.value.actualShippingOption == 8) {
        this.constants.SwalModal('Message', 'USPS does not support multi-package shipment.')
      } else {
        this.loader = true;
        this.sharedService.AddSinglePackage(this.ShippingDataById.salesOrder.id, this.ShippingDataById.salesOrder.companyId)
          .subscribe(
            data => {
              this.ShippingPackage.push(this.createPackage(data.data.id));
              this.loader = false;
            }
          ), (err) => {
            this.loader = false;
          };
      }
    }
  }
  get ShippingPackage(): FormArray {
    return this.form.get('ShippingPackage') as FormArray
  }
  get rowItems(): FormArray {
    return this.form.get('rowItems') as FormArray
  }
  getRowItemSubList(subItemListIndex: number): FormArray {
    return this.rowItems.at(subItemListIndex).get('SubItemList') as FormArray
  }
  PackageFillValidation() {
    return this.form.value.ShippingPackage.findIndex(x => x.boxSizes == null || x.boxSizes == 0 || x.boxSizes == undefined);
  }
  PackageFillValidationError() {
    this.loader = false;
    this.form.value.ShippingPackage.forEach((sp, index) => {
      if (sp.boxSizes == 0 || sp.boxSizes == null || sp.boxSizes == undefined) {
        this.form.get('ShippingPackage')['controls'][index].get('boxSizes').markAsTouched();
        this.form.get('ShippingPackage')['controls'][index].get('boxSizes').setErrors({ 'BoxErrors': true });
      } else {
        this.form.get('ShippingPackage')['controls'][index].get('boxSizes').setErrors(null);
      }
    });
    this.constants.SwalModal('Package', 'Package not created.');
  }
  createItem(row: any, ParentId: number): FormGroup {
    return new FormGroup({
      Id: new FormControl(0),
      ready: new FormControl(),
      shipped: new FormControl(0, Validators.required),
      package: new FormControl(''),
      weight: new FormControl(row.weight * row.shipQty),
      tracking: new FormControl(''),
      PartSerialId: new FormControl(row.partSerialId),
      cost: new FormControl(row.cost * row.shipQty),
      PartId: new FormControl(row.partId),
      OpId: new FormControl(row.id),
      OrderId: new FormControl(this.form.value.orderId),
      ParentId: new FormControl(ParentId),
    })
  }
  createSubItem(row: any, OrderId: number): FormGroup {
    return new FormGroup({
      Id: new FormControl(row.id),
      ready: new FormControl(row.shipQty),
      weight: new FormControl(row.weight * row.shipQty),
      shipped: new FormControl(),
      package: new FormControl(),
      OpId: new FormControl(row.id),
      OrderId: new FormControl(OrderId),
      PartId: new FormControl(row.partId),
      PartSerialId: new FormControl(row.partSerialId),
      cost: new FormControl(row.cost * row.shipQty),
      SubItemList: new FormArray([])
    })
  }
  CalculateWeight(value: any, PI, SI, row: any) {
    this.form.value.rowItems[PI].SubItemList[SI].weight = value.value * row.weight;
    this.form.value.rowItems[PI].SubItemList[SI].cost = value.value * row.cost;
    if (SI == 0) {
      this.form.value.rowItems[PI].SubItemList[SI].ready = row.qtyReadyToShip - this.form.value.rowItems[PI].shipped - value.value;
    } else {
      this.form.value.rowItems[PI].SubItemList[SI].ready = this.form.value.rowItems[PI].SubItemList[SI - 1].ready - value.value;
    }
    for (let i = 0; i < this.form.value.rowItems[PI].SubItemList.length; i++) {
      if (i == 0) {
        this.form.value.rowItems[PI].SubItemList[i].ready = this.form.value.rowItems[PI].ready - (this.form.value.rowItems[PI].shipped + this.form.value.rowItems[PI].SubItemList[i].shipped);
      } else {
        this.form.value.rowItems[PI].SubItemList[i].ready = this.form.value.rowItems[PI].SubItemList[i - 1].ready - this.form.value.rowItems[PI].SubItemList[i].shipped;
      }
    }
    this.form.patchValue({
      rowItems: this.form.value.rowItems
    })
    if (this.form.value.rowItems[PI].SubItemList[SI].ready < 0) {
      this.form.get('rowItems')['controls'][PI].get('SubItemList')['controls'][SI].get('shipped').markAsTouched();
      this.form.get('rowItems')['controls'][PI].get('SubItemList')['controls'][SI].get('shipped').setErrors({ 'ReadyExceed': true });
    } else {
      this.form.get('rowItems')['controls'][PI].get('SubItemList')['controls'][SI].get('shipped').setErrors(null);
    }
    this.CalculatePartWeight();
  }
  addItem(index: number, row: any): void {
    if (this.ISNullOrEmpty(this.form.value.masterTrackingNo)) {
      if (this.form.value.ShippingPackage.length > this.getRowItemSubList(index).length) {
        this.getRowItemSubList(index).push(this.createItem(row, this.form.value.rowItems[index].Id));
      } else {
        if (this.form.value.ShippingPackage.length >= 1 && this.form.value.actualShippingOption == 8) {
          this.constants.SwalModal('Message', 'USPS does not support multi-package shipment.')
        } else {
          this.PackageNeeded();
        }
      }
    }
  }
  getShippingBoxSizes() {
    this.partService.GetAllPartWithoutPagging(this.ShippingDataById.salesOrder.companyId)
      .subscribe({
        next: (data: any) => {
          this.boxSizing = data.dataList;
        },
        error: (err) => {
          this.loader = false
          this.constants.SwalModal(this.constants.Exception, this.constants.SomethingWentWrong);
        },
      });
  }
  onValueChange(event: any, index: number) {
    const boxes = this.boxSizing;
    for (let i = 0; i < boxes.length; i++) {
      if (boxes[i].id == event.value) {
        this.form.value.ShippingPackage[index].boxSizes = boxes[i].id;
        this.form.value.ShippingPackage[index].pLength = (this.IsCustomPackage(boxes[i].name)) ? 0 : Number(boxes[i].length);
        this.form.value.ShippingPackage[index].pLengthD = Number(boxes[i].dimentionType);
        this.form.value.ShippingPackage[index].pWidth = (this.IsCustomPackage(boxes[i].name)) ? 0 : Number(boxes[i].width);
        this.form.value.ShippingPackage[index].pWidthD = Number(boxes[i].dimentionType);
        this.form.value.ShippingPackage[index].pHeight = (this.IsCustomPackage(boxes[i].name)) ? 0 : Number(boxes[i].height);
        this.form.value.ShippingPackage[index].pHeightD = Number(boxes[i].dimentionType);
        this.form.value.ShippingPackage[index].pWeightD = Number(boxes[i].weightType);
        this.form.value.ShippingPackage[index].partWeight = Number(boxes[i].weight);
        this.form.value.ShippingPackage[index].totalweight = Number(boxes[i].weight);
        this.form.value.ShippingPackage[index].cost = Number(boxes[i].cost);
        this.form.value.ShippingPackage[index].isEditable = (this.IsCustomPackage(boxes[i].name)) ? false : true;
        this.form.value.ShippingPackage[index].packageNo = index + 1;
        this.CalculatePartWeight();
      }
    }
  }
  IsCustomPackage(PartName: string) {
    let IsCustom = false;
    if (PartName != '' && PartName.trim().toUpperCase() == 'CUSTOM SIZE' || PartName.trim().toUpperCase() == 'CUSTOM PACKAGE') {
      IsCustom = true;
    }
    return IsCustom;
  }
  CalculateVariance(i: number) {
    if (this.form.value.ShippingPackage[i].pWeight != '') {
      if (!isNaN(this.form.value.ShippingPackage[i].pWeight)) {
        this.form.value.ShippingPackage.at(i).pWeight = this.form.value.ShippingPackage[i].pWeight;
      } else {
        const regex = /[-+]?[0-9]*\.?[0-9]+/g;
        const matches = this.form.value.ShippingPackage[i].pWeight.match(regex);
        this.form.value.ShippingPackage.at(i).pWeight = matches ? matches.map(num => parseFloat(num)) : 0;
      }
    } else {
      this.form.value.ShippingPackage.at(i).pWeight = 0;
    }
    this.CalculatePartWeight();
  }
  TotalLBS() {
    let totalShippingPartWeight = 0;
    this.form.value.ShippingPackage.forEach(element => {
      totalShippingPartWeight = totalShippingPartWeight + element.totalweight;
    });
    this.form.patchValue({ totalShippingPartWeight: totalShippingPartWeight })
  }
  TotalVariance() {
    let totalShippingVariance = 0;
    let actualShippingWeight = 0;
    this.form.value.ShippingPackage.forEach(element => {
      actualShippingWeight = actualShippingWeight + Number(element.pWeight);
      element.variance = ((isNaN(element.variance) == true) ? 0 : element.variance);
      totalShippingVariance = totalShippingVariance + element.variance;
    });
    this.form.patchValue({ totalShippingVariance: this.validateDecimalTwoPlace(totalShippingVariance), actualShippingWeight: actualShippingWeight })
  }
  PackageNeeded() {
    Swal.fire({
      title: 'Package Needed',
      text: 'The rows exceed the number of packagees setup for this shipment. Click Add package to add a new package and select the box size or Cancel.',
      showCancelButton: true,
      cancelButtonText: '<div style="color:red;">Cancel </div>',
      confirmButtonText: '+ Add Package',
    }).then((result) => {
      if (result.value) {
        this.AddPackageBySubListItem();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }
  AddPackageBySubListItem() {
    var model = [];
    let _list = [];
    _list = this.boxSizing;
    this.boxSizing.forEach(element => {
      model.push(element.name);
    });
    Swal.fire({
      position: 'top',
      title: "Add Package",
      confirmButtonText: 'Continue',
      input: "select",
      html: `
    <div class="row">
  <label style="margin:auto;">Please select the Box Size from the menu below.</label> 
    </div>
   `,
      inputOptions: model,
      inputPlaceholder: "Box Size",
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (value) {
            this.loader = true;
            this.sharedService.AddSinglePackage(this.ShippingDataById.salesOrder.id, this.ShippingDataById.salesOrder.companyId)
              .subscribe(
                data => {
                  this.ShippingPackage.push(this.createPackage(data.data.id));
                  this.loader = false;
                  let obj = { "value": _list[value].id }
                  this.onValueChange(obj, this.form.value.ShippingPackage.length - 1)
                  Swal.close()
                }
              ), (err) => {
                this.loader = false;
              };
          } else {
            resolve('You need to select')
          }
        })
      },
      showCancelButton: true,
      showConfirmButton: true
    })
  }
  createLotItem(): FormGroup {
    const index = this.SerialNumber ? this.SerialNumber.length : 0;
    return new FormGroup({
      tracking: new FormControl('')
    })
  }
  OpenFlyout(rowIndex: number, chieldIndex: number, isParent: boolean, value: any) {
    this.FlyOut = undefined;
    if (value.partTracking == 2) {
      this.IsLotBtnShow = true;
      if (isParent == true) {
        this._IsParent = true;
        this._flyOutPIndex = rowIndex;
        this.FlyOut = this.form.value.rowItems[rowIndex];
      } else {
        this._flyOutPIndex = rowIndex;
        this._flyOutCIndex = chieldIndex;
        this._IsParent = false;
        this.FlyOut = this.getRowItemSubList(rowIndex).value[chieldIndex];
      }
      this.getFlayOutValue(this.FlyOut, value.partTracking)
    } else if (value.partTracking == 1) {
      if (isParent == true) {
        this._flyOutPIndex = rowIndex;
        this._IsParent = true;
        this.FlyOut = this.form.value.rowItems[rowIndex];
      } else {
        this._flyOutPIndex = rowIndex;
        this._flyOutCIndex = chieldIndex;
        this._IsParent = false;
        this.FlyOut = this.getRowItemSubList(rowIndex).value[chieldIndex];
      }
      this.getFlayOutValue(this.FlyOut, value.partTracking)
    }
    this.IsFlyOut = true;
    this._sideNavList = value;
  }
  AddTracking() {
    if (this.SerialNumber.length < this.ChildReadyForLot) {
      this.SerialNumber.push(this.createLotItem());
      this.IsLotBtnShow = this.SerialNumber.length == this.ChildReadyForLot ? false : true;
    }
  }
  get SerialNumber(): FormArray {
    return this.SerialLotForm.get('SerialNumber') as FormArray
  }
  closeFlyOut() {
    this.loader = false;
    this.ChildReadyForLot = 0;
    this.SerialNumber.clear();
    this.IsFlyOut = false;
    this.IsLotBtnShow = false;
    this.FlayOutPackageNo = 0;
    this.FlyOut = undefined;
    this.IsScan = false;
    this.ScanIndex = 0;
    this._IsParent = false;
    this._flyOutCIndex = 0;
    this._flyOutCIndex = 0;
    this._IsBtnFlyOutSave = false;
    this.IsBarCodeScan = false;
    this.clearResult();
  }
  saveSerialLot() {
    if (!this.SerialLotForm.invalid && this.ISNullOrEmpty(this.form.value.masterTrackingNo)) {
      this.FlyOut.tracking = JSON.stringify(this.SerialLotForm.value.SerialNumber);
      this.loader = true;
      this.sharedService.SaveFlyOut(this.FlyOut)
        .subscribe(
          data => {
            this.loader = false;
            data.data != null ? this.BindFlyOutResponse(data.data) : null
            this.sharedService.GetEqualShippedSerialLot(this.form.value.orderId).subscribe((res: any) => {
              this.EqualShippedSerialLotList = res.dataList;
            }), (err) => {
              this.loader = false;
            };
            this.closeFlyOut();
          }
        ), (err) => {
          this.loader = false;
        };
    }
  }
  BindFlyOutResponse(data: any) {
    this.FlyOut.Id = data.id;
    this.FlyOut.OpId = data.opId;
    this.FlyOut.ParentId = data.parentId;
    this.FlyOut.PartId = data.partId;
    this.FlyOut.PartSerialId = data.partSerialId;
    this.FlyOut.cost = data.cost;
    this.FlyOut.package = data.package;
    this.FlyOut.ready = data.ready;
    this.FlyOut.shipped = data.shipped;
    this.FlyOut.tracking = data.tracking;
    this.FlyOut.weight = data.weight;
    if (this._IsParent == true) {
      this.form.value.rowItems[this._flyOutPIndex] = this.FlyOut;
    } else {
      this.form.value.rowItems[this._flyOutPIndex].SubItemList[this._flyOutCIndex] = this.FlyOut;
    }
    this.form.patchValue({ rowItems: this.form.value.rowItems });
  }
  getFlayOutValue(value: any, Type: number) {
    this.FlyOut.shipped == 0 ? (this._IsBtnFlyOutSave = true) : (this._IsBtnFlyOutSave = false);
    if (value.Id != 0 && value.Id != null) {
      this.loader = true
      this.sharedService.GetFlyOutById(value.Id)
        .subscribe(
          (data: any) => {
            this.loader = false;
            this.SerialNumber.clear();
            if (data.data.tracking != "" && data.data.tracking != null) {
              let result = JSON.parse(data.data.tracking);
              result.forEach((x: any, i: number) => {
                if (x.tracking != '') {
                  this.SerialNumber.push(new FormGroup({
                    tracking: new FormControl(x.tracking)
                  }))
                }
              })
            }
            this.FlayOutId = this.FlyOut.Id;
            this.FlayOutPackageNo = this.form.value.ShippingPackage.findIndex(x => x.id == this.FlyOut.package) + 1;
            if (Type == 1) {
              this.IsLotBtnShow = false;
              for (var i = this.SerialNumber.length; i < this.FlyOut.shipped; i++) {
                this.SerialNumber.push(this.createLotItem());
              }
            } else if (Type == 2) {
              this.ChildReadyForLot = this.FlyOut.shipped;
              this.IsLotBtnShow = this.SerialNumber.length == this.ChildReadyForLot ? false : true;
              if (this.SerialNumber.length <= this.FlyOut.shipped && this.SerialNumber.length == 0) {
                this.SerialNumber.push(this.createLotItem());
              }
            }
          }
        ), (err) => {
          this.loader = false;
        };
    } else {
      this.FlayOutId = this.FlyOut.Id;
      this.FlayOutPackageNo = this.form.value.ShippingPackage.findIndex(x => x.id == this.FlyOut.package) + 1;
      if (Type == 1) {
        this.IsLotBtnShow = false;
        for (var i = this.SerialNumber.length; i < this.FlyOut.shipped; i++) {
          this.SerialNumber.push(this.createLotItem());
        }
      } else if (Type == 2) {
        this.ChildReadyForLot = this.FlyOut.shipped;
        this.IsLotBtnShow = this.SerialNumber.length == this.ChildReadyForLot ? false : true;
        if (this.SerialNumber.length <= this.FlyOut.shipped && this.SerialNumber.length == 0 && this.FlyOut.shipped != 0) {
          this.SerialNumber.push(this.createLotItem());
        }
      }
      this.loader = false;
    }
  }
  changePackage(event: any, index: number) {
    this.form.value.rowItems[index].package = event.value;
    this.form.patchValue({ rowItems: this.form.value.rowItems });
    this.CalculatePartWeight();
  }

  changeChildPackage(event: any, PIndex: number, CIndex: number) {
    this.form.value.rowItems[PIndex].SubItemList[CIndex].package = event.value;
    this.form.patchValue({ rowItems: this.form.value.rowItems });
    this.CalculatePartWeight();
  }

  ChangeShip(event: any, index: number, row: any) {
    this.IsErrorValue = Number(event.value);
    this.form.value.rowItems[index].shipped = Number(event.value);
    this.BindWeightForParent(index, row);
    this.form.value.rowItems[index].SubItemList[0].ready = row.qtyReadyToShip - this.form.value.rowItems[index].shipped;
    this.form.patchValue({ rowItems: this.form.value.rowItems });
    if (this.form.value.rowItems[index].shipped > this.form.value.rowItems[index].ready) {
      this.form.get('rowItems')['controls'][index].get('shipped').setErrors({ 'ReadyExceed': true });
    } else {
      this.form.get('rowItems')['controls'][index].get('shipped').setErrors(null);
    }
    this.CalculatePartWeight();
  }
  BindWeightForParent(rowItemsIndex: number, row: any): string {
    this.form.value.rowItems[rowItemsIndex].cost = this.form.value.rowItems[rowItemsIndex].shipped * row.cost;
    return this.validateDecimalTwoPlace((this.form.value.rowItems[rowItemsIndex].weight = this.form.value.rowItems[rowItemsIndex].shipped * row.weight).toString());
  }
  BindWeightForChild(rowItemsIndex: number, SubIndex: number, row: any): string {
    this.form.value.rowItems[rowItemsIndex].SubItemList[SubIndex].cost = this.form.value.rowItems[rowItemsIndex].SubItemList[SubIndex].shipped * row.cost;
    return (this.form.value.rowItems[rowItemsIndex].SubItemList[SubIndex].weight = this.form.value.rowItems[rowItemsIndex].SubItemList[SubIndex].shipped * row.weight).toString();
  }
  GetUOM(Id: number): string {
    return this.constants.UnitLength.filter(u => u.id == Id).length > 0 ? this.constants.UnitLength.filter(u => u.id == Id)[0].name : 'in';
  }
  CalculatePartWeight() {
    let temp = [];
    this.form.value.rowItems.forEach(element => {
      temp.push(element)
      element.SubItemList.forEach(s => {
        temp.push(s)
      });
    });
    this.form.value.ShippingPackage.forEach((sp, index) => {
      sp.totalweight = 0;
      sp.totalweight = Number(sp.partWeight);
      sp.qtyInPackage = 0;
      temp.filter(x => x.package == sp.id).forEach(w => {
        sp.totalweight = sp.totalweight + w.weight;
        sp.qtyInPackage = sp.qtyInPackage + w.shipped;
        sp.variance = this.validateDecimalTwoPlace(((Number(sp.pWeight) / Number(sp.totalweight)) - 1) * 100)
      });
      this.form.get('ShippingPackage')['controls'][index].patchValue(sp)
    });
    this.TotalLBS();
    this.TotalVariance();
  }
  BindTracking(rowItemsIndex: number, subIndex: number): string {
    let tracking = [];
    let result = '';
    if (this.form.value.rowItems[rowItemsIndex].SubItemList[subIndex].tracking != '' && this.form.value.rowItems[rowItemsIndex].SubItemList[subIndex].tracking != null) {
      tracking = JSON.parse(this.form.value.rowItems[rowItemsIndex].SubItemList[subIndex].tracking);
      tracking.forEach(element => {
        if (element.tracking != '') {
          result = result.concat(element.tracking + ',');
        }
      });
    }
    return result = result.replace(/,\s*$/, "");
  }
  CheckEqualTracking(value: any): number {
    return this.EqualShippedSerialLotList.filter(x => x.id == value).length > 0 ? this.EqualShippedSerialLotList.filter(x => x.id == value)[0].isCountSame : 0;
  }
  BindTrackingForParent(rowItemsIndex: number): string {
    let ParentTracking = [];
    let ParentResult = '';
    if (this.form.value.rowItems[rowItemsIndex] != undefined && this.form.value.rowItems[rowItemsIndex].tracking != '' && this.form.value.rowItems[rowItemsIndex].tracking != null) {
      ParentTracking = JSON.parse(this.form.value.rowItems[rowItemsIndex].tracking);
      ParentTracking.forEach(element => {
        if (element.tracking != '') {
          ParentResult = ParentResult.concat(element.tracking + ',');
        }
      });
    }
    return ParentResult = ParentResult.replace(/,\s*$/, "");
  }
  removeChildPart(rowItemsIndex: number, subIndex: number) {
    if (this.form.value.rowItems[rowItemsIndex].SubItemList[subIndex].Id == undefined || this.form.value.rowItems[rowItemsIndex].SubItemList[subIndex].Id == 0) {
      this.getRowItemSubList(rowItemsIndex).removeAt(subIndex);
    } else {
      this.loader = true;
      this.sharedService.DeleteShipmentMultiPackageById(this.form.value.rowItems[rowItemsIndex].SubItemList[subIndex].Id).subscribe((res: any) => {
        this.getRowItemSubList(rowItemsIndex).removeAt(subIndex);
        this.loader = false;
      }), (err) => {
        this.loader = false;
      };
    }
  }
  removeFlyOut(rowItemsIndex: number) {
    this.SerialNumber.removeAt(rowItemsIndex);
    for (var i = this.SerialNumber.length; i < this.FlyOut.shipped; i++) {
      this.SerialNumber.push(this.createLotItem());
    }
  }
  clearResult(): void {
    this.qrResultString = '';
    this.IsScan = false;
    this.ScanIndex = 0;
  }
  AvailableDevice(index: number) {
    this.qrResultString = '';
    this.IsScan = true;
    this.ScanIndex = index;
  }
  camerasNotFound(e: Event) {
    Swal.fire({
      text: 'Device not found.',
      showCancelButton: true,
      cancelButtonText: 'Close',
      showConfirmButton: false
    }).then((result) => {
      this.IsScan = false;
    });
  }
  onCodeResult(resultString: string) {
    this.SerialLotForm.value.SerialNumber[this.ScanIndex].tracking = resultString;
    this.SerialLotForm.patchValue({ SerialNumber: this.SerialLotForm.value.SerialNumber })
    this.qrResultString = resultString;
    this.IsScan = false;
  }
  onBarCodeValueChange(result: any) {
    this.IsBarCodeScan = true;
    this.barCodescanner.start();
    this.SerialLotForm.value.SerialNumber[this.ScanIndex].tracking = result.codeResult.code;
    this.SerialLotForm.patchValue({ SerialNumber: this.SerialLotForm.value.SerialNumber })
    this.barCodescanner.stop();
  }
  validateDecimal(event: any): any {
    var arr = event.value.split('.');
    event.value =
      event.value.indexOf('.') >= 0
        ? arr[0] + '.' + arr[1].substr(0, 1) : event.value;
    return event.value;
  }
  validateDecimalTwoPlace(event: any): any {
    if (!this.ISNullOrEmpty(event)) {
      var arr = event.toString().split('.');
      event =
        event.toString().indexOf('.') >= 0
          ? arr[0] + '.' + arr[1].substr(0, 2) : event;
    }
    return Number(event);
  }
  checkTrackingDuplicacy(index, value: string) {
    this.PartsData(this._flyOutPIndex);
    let IsDuplicate = false;
    if (value != '') {
      if (this.IsDuplicateCheckTrackingList.length > 0) {
        if (this.IsDuplicateCheckTrackingList.filter(i => i.tracking.toUpperCase() === value.toUpperCase()).length > 0) {
          this.SerialNumber.controls[index].get('tracking').setErrors({ 'duplicateName': true })
          IsDuplicate = true;
        } else {
          this.SerialNumber.controls[index].get('tracking').setErrors(null)
        }
      }
      if (this.SerialNumber.value.length > 0 && !IsDuplicate) {
        if (this.SerialNumber.value.filter(i => i.tracking.toUpperCase() == value.toUpperCase()).length > 1) {
          this.SerialNumber.controls[index].get('tracking').setErrors({ 'duplicateName': true })
        } else {
          this.SerialNumber.controls[index].get('tracking').setErrors(null)
        }
      }
    }
  }
  getTracking(index: number) {
    return this.SerialNumber
      ? (this.SerialNumber.at(index) as FormGroup).get("tracking")
      : null;
  }
  PartsData(index: number) {
    this.IsDuplicateCheckTrackingList = [];
    let temp = this.form.value.rowItems[index]
    if (temp.tracking != '' && temp.tracking != null) {
      this.IsDuplicateCheckTrackingList.push(JSON.parse(temp.tracking));
    }
    temp.SubItemList.forEach(c => {
      if (c.tracking != '') {
        this.IsDuplicateCheckTrackingList.push(JSON.parse(c.tracking));
      }
    });
    this.IsDuplicateCheckTrackingList = this.IsDuplicateCheckTrackingList.flat();
  }
  SelectPrinter() {
    // this.loader = true;
    // this.PrinterList = [];
    // this.sharedService.GetPrinterList().subscribe(
    //   (data: any) => {
    //     this.loader = false;
    //     this.PrinterList = [];
    //     this.PrinterList = data.dataList[0];
    //   }, (err) => {
    //     this.loader = false;
    //   });
    $("#SelectPrinter").show();
  }
  CloseSelectPrinter() {
    $("#SelectPrinter").hide();
  }
  CloseshiprateVM() {
    $("#shiprateVM").hide();
  }
  ShowshiprateVM() {
    if (this.form.value.actualShippingOption != 0) {
      if (this.form.value.ShippingPackage.length > 1 && this.form.value.actualShippingOption == 8) {
        this.constants.SwalModal('Message', 'USPS does not support multi-package shipment.')
      } else {
        this.RateAPI();
      }
    }
  }
  changeShippingLabelsPrinter(event: any) {
    this.form.patchValue({ LabelPrinter: event.value })
  }
  changePackingSlipPrinter(event: any) {
    this.form.patchValue({ SlipPrinter: event.value })
  }
  SaveSelectPrinter() {
    // this.sharedService.AddUpdPrinter(this.form.value).subscribe(
    //   (data: any) => {
    //     this.ErrorModal('Printer', 'Printer Configured Successfully.');
    //   }, (err) => {
    //     this.ErrorModal('Exception', this.constants.SomethingWentWrong);
    //     this.loader = false;
    //   }
    // );
    this.CloseSelectPrinter();
  }

  ErrorModal(title: string, message: string) {
    Swal.fire({
      title: title,
      text: message,
      showCancelButton: true,
      showConfirmButton: false,
      cancelButtonText: 'Close',
    })
  }
  ExpandMore(index: number, row: any) {
    if (this.form.value.rowItems[index].SubItemList.length == 0) {
      this.addItem(index, row);
    }
    this.specialPartList[index].isShow = !this.specialPartList[index].isShow;
  }
  ISNullOrEmpty(value: any): boolean {
    let result = false;
    if (value === null || value === undefined || value === '') {
      result = true;
    }
    return result;
  }
  PrintPackingSlip() {
    var ShippingDataById = this.ShippingDataById;
    let PackageInfo = [];
    PackageInfo = this.form.value.ShippingPackage;
    var actualShippingType = this.form.value.actualShippingType;
    var PObarcodeImg;
    var SObarcodeImg;
    var PurchaseOrderNumber;
    if (this.PObarcodeImg != undefined && this.PObarcodeImg != null && this.PObarcodeImg != '') {
      PObarcodeImg = this.PObarcodeImg;
      PurchaseOrderNumber = 'Purchase Order Number';
    } else {
      PObarcodeImg = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVQYV2NgYAAAAAMAAWgmWQ0AAAAASUVORK5CYII=";
      PurchaseOrderNumber = '';
    }
    if (this.SObarcodeImg != undefined && this.SObarcodeImg != null && this.SObarcodeImg != '') {
      SObarcodeImg = this.SObarcodeImg;
    } else {
      SObarcodeImg = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVQYV2NgYAAAAAMAAWgmWQ0AAAAASUVORK5CYII=";
    }
    this.CompanyDetail.cLogo = this.ISNullOrEmpty(this.CompanyDetail.base64Logo) ? this.constants.dummyCompanyLogo : this.CompanyDetail.base64Logo;
    var pdFgeneratedate = this.datepipe.transform(new Date(), 'MMMM dd, yyyy, hh:mm:ss a');
    let docDefinition = {
      pageMargins: [15, 30, 15, 50],
      content: [
        {
          columns: [
            [
              {
                image: this.CompanyDetail.cLogo,
                // bold: true,
                // width: '*', // Set width to auto
                height: 30, // Set height to a fixed value
                fit: [175, 100] // Wrap the image in a container with a fixed width
              },
              { text: '', bold: true, fontSize: 15, margin: [0, 10, 0, 0] },
              {

                table: {
                  heights: [80],
                  margin: [0, 0, 0, 0],
                  body: [
                    [
                      {
                        text: (this.CompanyDetail.companyName) + '\n' +
                          (this.CompanyDetail.addressLine1) + '\n' +
                          ((this.ISNullOrEmpty(this.CompanyDetail.city) ? '' : (this.CompanyDetail.city + ',')) + ' ' + (this.ISNullOrEmpty(this.CompanyDetail.stateId) ? '' : this.GetStateCode(this.CompanyDetail.stateId) + ',') + ' ' + (this.ISNullOrEmpty(this.CompanyDetail.zipCode) ? '' : this.CompanyDetail.zipCode)) + '\n' +
                          ('Phone: ' + this.CompanyDetail.phoneNumber) + '\n' +
                          ('Sales Rep Name: ' + localStorage.getItem('username')) + '\n' +
                          ('Email: ' + localStorage.getItem('useremail'))
                        , fontSize: 10, border: [false, false, false, false]
                      }
                    ]
                  ]
                }
              },
              {
                table: {
                  widths: [260],
                  heights: [10, 100],
                  body: [
                    [{ text: 'Bill To:', bold: true }],
                    [
                      {
                        text: (this.ISNullOrEmpty(ShippingDataById.billAddress.name) ? '' : ShippingDataById.billAddress.name + '\n') +
                          (this.ISNullOrEmpty(ShippingDataById.billAddress.addressLine1) ? '' : ShippingDataById.billAddress.addressLine1 + '\n') +
                          (this.ISNullOrEmpty(ShippingDataById.billAddress.addressLine2) ? '' : (ShippingDataById.billAddress.addressLine2 + '\n')) +
                          (this.ISNullOrEmpty(ShippingDataById.billAddress.city) ? '' : (ShippingDataById.billAddress.city + ', ')) +
                          ((this.ISNullOrEmpty(ShippingDataById.billAddress.stateId) ? '' : (this.GetStateCode(ShippingDataById.billAddress.stateId))) + ', ') +
                          (this.ISNullOrEmpty(ShippingDataById.billAddress.zip) ? '' : ShippingDataById.billAddress.zip + '\n') +
                          ('Contact Name: ' + this.ISNullOrEmpty(ShippingDataById.billAddress.cName) ? '' : +ShippingDataById.billAddress.cName + '\n') +
                          ('Email Address: ' + this.ISNullOrEmpty(ShippingDataById.billAddress.email) ? '' : ShippingDataById.billAddress.email + '\n') +
                          ('Phone #: ' + this.ISNullOrEmpty(ShippingDataById.billAddress.phone) ? '' : ShippingDataById.billAddress.phone)
                        , fontSize: 10
                      }
                    ]
                  ]
                },
                layout: {
                  fillColor: function (rowIndex, node, columnIndex) {
                    return (rowIndex % 2 === 0) ? '#edeaea' : null;
                  }
                }
              },
            ],
            [
              {
                text: 'Packing Slip',
                alignment: 'right',
                bold: true,
                fontSize: 19
              }, { text: ' ' },
              {
                alignment: 'right',
                table: {
                  widths: [83, 83, 83],
                  heights: [10, 10, 10],
                  margin: [90, 70, 70, 0],
                  bold: true,
                  body: [
                    [{ text: 'Purchase Order #', bold: true, alignment: 'center', fontSize: 10 }, { text: 'Sales Order #', bold: true, alignment: 'center', fontSize: 10 }, { text: 'Date', bold: true, alignment: 'center', fontSize: 10 }],
                    [
                      { text: this.ISNullOrEmpty(ShippingDataById.salesOrder.purchaseOrder) ? '' : ShippingDataById.salesOrder.purchaseOrder, alignment: 'center', bold: true, fontSize: 10 },
                      { text: ShippingDataById.salesOrder.orderNumber, alignment: 'center', bold: true, fontSize: 10 },
                      { text: this.datepipe.transform(new Date(), 'MM/dd/yyyy'), alignment: 'center', fontSize: 10 }
                    ]
                  ]
                },
                layout: {
                  fillColor: function (rowIndex, node, columnIndex) {
                    return (rowIndex % 2 === 0) ? '#edeaea' : null;
                  }
                }
              }, { text: '    ' + PurchaseOrderNumber + '                     Sales Order Number', fontSize: 10, alignment: 'right', margin: [0, 2, 20, 0] },
              {
                image: SObarcodeImg,
                bold: true,
                width: 133,
                height: 40,
                alignment: 'right',
                margin: [0, 0, 0, 0],
              },
              {
                image: PObarcodeImg,
                bold: true,
                width: 133,
                height: 40,
                alignment: 'left',
                margin: [0, -40, 10, 0]
              },
              {
                margin: [0, 7, 0, 0],
                table: {
                  heights: [12, 100],
                  widths: [270],
                  alignment: 'right',
                  body: [
                    [{ text: 'Ship To:', bold: true }],
                    [
                      {
                        text: (ShippingDataById.shipAddress.name) + '\n' +
                          (this.ISNullOrEmpty(ShippingDataById.shipAddress.attention) ? '' : ('Attn:' + ShippingDataById.shipAddress.attention + '\n')) +
                          (this.ISNullOrEmpty(ShippingDataById.shipAddress.addressLine1) ? '' : (ShippingDataById.shipAddress.addressLine1 + '\n')) +
                          (this.ISNullOrEmpty(ShippingDataById.shipAddress.addressLine2) ? '' : (ShippingDataById.shipAddress.addressLine2 + '\n')) +
                          (this.ISNullOrEmpty(ShippingDataById.shipAddress.city) ? '' : (ShippingDataById.shipAddress.city + ', ')) +
                          (this.ISNullOrEmpty(ShippingDataById.shipAddress.stateId) ? '' : (ShippingDataById.shipAddress.stateId + ', ')) +
                          ((this.ISNullOrEmpty(ShippingDataById.shipAddress.zip) ? '' : (ShippingDataById.shipAddress.zip + '\n')))
                        , fontSize: 10
                      }
                    ]
                  ]
                },
                layout: {
                  fillColor: function (rowIndex, node, columnIndex) {
                    return (rowIndex % 2 === 0) ? '#edeaea' : null;
                  }
                }
              }
            ]
          ]
        },
        {
          style: 'contactTable1',
          table: {
            widths: [181, 181, 173],
            heights: [20, 15],
            alignment: 'center',
            border: 1,
            fontSize: 8,
            body: [
              [
                { text: 'Ship Via', fontSize: 10, bold: true, alignment: 'center' },
                { text: 'Collect Account Info', fontSize: 10, bold: true, alignment: 'center' },
                { text: 'Payment Terms', fontSize: 10, bold: true, alignment: 'center' },
              ]
              ,
              [
                { text: this.form.value.actualShippingType, alignment: 'center', fontSize: 10 },
                { text: this.ISNullOrEmpty(ShippingDataById.salesOrder.collectAccount) ? '' : ShippingDataById.salesOrder.collectAccount, alignment: 'center', fontSize: 10 },
                { text: ShippingDataById.salesOrder.paymentType, alignment: 'center', fontSize: 10 },
              ],
            ]
          },
          layout: {
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex % 2 === 0) ? '#edeaea' : null;
            }
          }
        },
        {
          margin: [0, -1, 0, 0],
          table: {
            widths: [36, 100, 217, 52, 52, 51],
            heights: [20, 15],
            alignment: 'center',
            border: 1,
            fontSize: 8,
            body: [
              [
                { text: 'Item', fontSize: 10, bold: true, alignment: 'center' },
                { text: 'Part Number', fontSize: 10, bold: true, alignment: 'left' },
                { text: 'Description', fontSize: 10, bold: true, alignment: 'left' },
                { text: 'Ordered', fontSize: 10, bold: true, alignment: 'center' },
                { text: 'Shipped', fontSize: 10, bold: true, alignment: 'center' },
                { text: 'B/O', fontSize: 10, bold: true, alignment: 'center' }
              ]
              ,
              ...this.specialPartList.map((p1, i) => ([
                { text: i + 1, fontSize: 10, alignment: 'center' },
                { text: p1.partNumber, fontSize: 10, alignment: 'left' },
                { text: p1.partName, fontSize: 10, alignment: 'left' },
                { text: p1.qtyReadyToShip, fontSize: 10, alignment: 'center' },
                { text: p1.shipedQty == null ? 0 : p1.shipedQty, fontSize: 10, alignment: 'center' },
                { text: p1.boQty, fontSize: 10, alignment: 'center' }])),
            ]
          },
          layout: {
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex === 0) ? '#edeaea' : null;
            }
          }
        },
      ],
      footer: function (currentPage, pageCount) {
        return [
          {
            margin: [10, -170, 0, 0],
            table: {
              heights: [12, "*"],
              widths: [270],
              alignment: 'left',
              style: 'footersTableStyle',
              body: [
                [
                  { text: (actualShippingType != null ? actualShippingType : ' N/A') + ' tracking numbers for this shipment. \nTrack  your shipment at: www.ups.com/tracking/tracking.html', fontSize: 8 },
                ],
                ...PackageInfo.map((element, i) => ([
                  { text: 'Package     ' + (i + 1) + ' :  ' + (element.tracking != null ? element.tracking : 'N/A') + '  -      ' + element.pWeight + '  LBS', fontSize: 8 }])),
              ],
            },
            layout: {
              hLineWidth: function (i, node) {
                return (i === 0 || i === node.table.body.length) ? 2 : 1;
              },
              vLineWidth: function (i, node) {
                return (i === 0 || i === node.table.widths.length) ? 2 : 1;
              },
              hLineColor: function (i, node) {
                return (i === 0 || i === node.table.body.length) ? 'white' : 'white';
              },
              vLineColor: function (i, node) {
                return (i === 0 || i === node.table.widths.length) ? 'white' : 'white';
              },
            },
          },
          {
            margin: [300, -65, 0, 0],
            table: {
              heights: [12, 70],
              widths: [270],
              alignment: 'right',
              body: [
                [{ text: 'Notes', bold: true, alignment: 'center' }],
                [
                  {
                    text: ShippingDataById.salesOrder.customerNotes, fontSize: 10
                  }
                ]
              ]
            },
            layout: {
              fillColor: function (rowIndex, node, columnIndex) {
                return (rowIndex % 2 === 0) ? '#edeaea' : null;
              }
            }
          },
          { text: pdFgeneratedate, fontSize: 8, bold: true, margin: [30, 90, 0, 0] },
          { text: 'Thank you for your Business! ', fontSize: 8, alignment: 'center', bold: true, margin: [20, -10, 0, 0] },
          { text: 'Page: ' + currentPage.toString() + ' of ' + pageCount, fontSize: 8, bold: true, margin: [520, -10, 0, 0] }
        ]
      },
      styles: {
        sectionHeader: {
          bold: true,
          fontSize: 14,
          margin: [0, 15, 0, 15]
        },
        tableExample: {
          margin: [90, 0, 0, 0]
        },
        contactTable: {
          margin: [0, 5, 0, 0]
        },
        contactTable1: {
          margin: [0, 10, 0, 0]
        }
      }
    };
    this.loader = false;
    return docDefinition;
    //pdfMake.createPdf(docDefinition).print();
  }
  BarcodeGenerate(IsPOBarcode: boolean): any {
    this.SObarcodeImg = undefined;
    JsBarcode(this.barcode.nativeElement, this.ShippingDataById.salesOrder.orderNumber, {
      height: 90,
      width: 2.5,
      displayValue: false
    });
    var s = new XMLSerializer().serializeToString(document.getElementById("barcode"));
    this.ImgConvertor(s, IsPOBarcode);
  }
  ImgConvertor(s, IsPOBarcode: boolean): any {
    this.SVGToImage({
      svg: s,
      mimetype: "image/png",
      width: 500,
      quality: 1
    }).then((base64image: any) => {
      let img = document.createElement('img');
      img.src = base64image;
      if (IsPOBarcode) {
        this.PObarcodeImg = img.src;
      } else {
        this.SObarcodeImg = img.src;
      }
    });
  }

  SVGToImage(settings) {
    let _settings = {
      svg: null,
      // Usually all SVG have transparency, so PNG is the way to go by default
      mimetype: "image/png",
      quality: 0.92,
      width: "auto",
      height: "auto",
      outputFormat: "base64"
    };

    // Override default settings
    for (let key in settings) { _settings[key] = settings[key]; }

    return new Promise(function (resolve, reject) {
      let svgNode;

      // Create SVG Node if a plain string has been provided
      if (typeof (_settings.svg) == "string") {
        // Create a non-visible node to render the SVG string
        let SVGContainer = document.createElement("div");
        SVGContainer.style.display = "none";
        SVGContainer.innerHTML = _settings.svg;
        svgNode = SVGContainer.firstElementChild;
      } else {
        svgNode = _settings.svg;
      }

      let canvas = document.createElement('canvas');
      let context = canvas.getContext('2d');

      let svgXml = new XMLSerializer().serializeToString(svgNode);
      let svgBase64 = "data:image/svg+xml;base64," + btoa(svgXml);

      const image = new Image();

      image.onload = function () {
        // Define the canvas intrinsic size
        canvas.width = 800;
        canvas.height = 400;

        // Render image in the canvas
        context.drawImage(image, 0, 0, 800, 400);

        if (_settings.outputFormat == "blob") {
          // Fullfil and Return the Blob image
          canvas.toBlob(function (blob) {
            resolve(blob);
          }, _settings.mimetype, _settings.quality);
        } else {
          // Fullfil and Return the Base64 image
          resolve(canvas.toDataURL(_settings.mimetype, _settings.quality));
        }
      };
      // Load the SVG in Base64 to the image
      image.src = svgBase64;
    });
  }
  CancelLabel() {
    const canelModel = {
      "masterTrackingNumber": this.form.value.masterTrackingNo,
      "pendingShipment": true,
      "salesOrderId": this.form.value.orderId,
      "trackingNumber": ''
    }
    this.loader = true;
    this.sharedService.CancelLabel(this.form.value.companyId, this.form.value.actualShippingOption, canelModel).subscribe(
      (res: any) => {
        this.loader = false;
        res.cancelledIndicator == true ? this.ShipmentCancelMsg() : this.ShipmentCancelErrMsg(res);
        this.AddSalesOrderLog();
        this.AddUpdVarianceLog();
      }
    ), (err) => {
      this.loader = false;
      this.constants.SwalModal(this.constants.Exception, this.constants.SomethingWentWrong);
    };
  }
  ShipmentCancelMsg() {
    this.BindRowData('')
    this.GetShippingDetailsByOrderId(this.ShippingDataById.salesOrder.id, this.form.value.masterTrackingNo, true)
    Swal.fire({
      title: 'Shipment Canceled',
      text: 'The shipment has been canceled and the tracking information will be removed from the order.',
      showCancelButton: true,
      showConfirmButton: false,
      cancelButtonText: 'OK',
    })
  }
  ShipmentCancelErrMsg(res: any) {
    this.loader = false;
    Swal.fire({
      title: 'Denied',
      text: this.constants.SomethingWentWrong,
      showCancelButton: true,
      showConfirmButton: false,
      cancelButtonText: 'OK',
    })
  }
  PrintAll() {
    if (!this.ISNullOrEmpty(this.form.value.masterTrackingNo)) {
      this.PrintDoc(this.form.value.ShippingPackage[0].masterLabel_downloadURL);
      this.printSlip();
    } else {
      this.printSlip();
      this.GetEqualShippedSerialLot(false);
    }
  }
  Cancel() {
    this.BindData(false);
  }
  GetCompanyById(compId: number) {
    this.companyService.GetCompanyById(compId).subscribe(
      (data: any) => {
        this.CompanyDetail = data.data.company;
      }
    ), (err) => {
      this.loader = false;
    };
  }
  MarkAsCompleted() {
    if (this.IsSelected().length > 0) {
      this.loader = true;
      this.sharedService.MarkCompleted(this.IsSelected())
        .subscribe({
          next: (data: any) => {
            this.IsMarkAsCompleted = false;
            this.GetShippingList(true);
          },
          error: (err) => {
            this.GetShippingList(true);
            this.loader = false
            this.constants.SwalModal(this.constants.Exception, this.constants.SomethingWentWrong);
          },
        });
    }
  }
  IsSelected() {
    return this.shippingDS.data.length > 0 ? this.shippingDS.data.filter(d => d.isSelect == true) : []
  }
  IsSelectedAll() {
    return this.shippingDS.data.length > 0 ? this.shippingDS.data.filter(d => d.isSelect == false) : []
  }
  ViewSelected(isSelected) {
    this.IsMarkAsCompleted = isSelected
    if (isSelected) {
      this.shippingDS.data.forEach((item: any) => item.isSelect = true);
    } else {
      this.shippingDS.data.forEach((item: any) => item.isSelect = false);
    }
  }
  toggleSelection(isSelected: boolean, i: number): void {
    this.shippingDS.data[i].isSelect = isSelected;
    this.IsMarkAsCompleted = isSelected;
  }
  SaveRate() {
    if (this.RateDetails != undefined) {
      this.form.patchValue({ ActualShippingAmount: this.RateDetails.amount, ActualrateId: this.RateDetails.rate_id, actualShippingType: this.RateDetails.service_type, actualPackageType: this.RateDetails.package_type })
      this.RateDetails = undefined;
      this.CloseshiprateVM();
    }
  }
  getRateValue(rate) {
    this.RateDetails = rate;
  }
  // Role Permission Start
  rolePermissions() {
    if (!this.rolePermissionsList[0].isView) {
      this.permissionMessage += 'view';
    } if (!this.rolePermissionsList[0].isInsert) {
      if (this.permissionMessage != '') {
        this.permissionMessage += ', ';
      }
      this.permissionMessage += 'add';
    } if (!this.rolePermissionsList[0].isUpdate) {
      if (this.permissionMessage != '') {
        this.permissionMessage += ', ';
      }
      this.permissionMessage += 'edit';
    } if (!this.rolePermissionsList[0].isDelete) {
      if (this.permissionMessage != '') {
        this.permissionMessage += ', ';
      }
      this.permissionMessage += 'delete';
    }
  }
  // Role Permission End
  printSlip() {
    let x = this.PrintPackingSlip();
    let file = new FormData();
    file = pdfMake.createPdf(x)
    pdfMake.createPdf(x).print();
  }
  PrintDoc(doc: string) {
    if (doc != null && doc.length > 0) {
      const ext = doc.substring(doc.lastIndexOf('.') + 1);
      if (ext.toLowerCase() == 'zpl') {
        this.PrintPreviewZPL(doc);
      } else {
        window.open(doc, '_blank').print();
      }
    }
  }
  PrintPreviewZPL(document: any): void {
    this.loader = true;
    let Req = {
      "zplContent": document,
      "format": "png",
      "dpi": 8,
      "width": 4,
      "height": 6,
      "X-Rotation": 180
    }
    this.sharedService.ConvertZpl(Req)
      .subscribe({
        next: (data: Blob) => {
          this.loader = false;
          const url = window.URL.createObjectURL(data);
          window.open(url).print();
        },
        error: (err) => {
          this.loader = false;
          this.constants.SwalModal(this.constants.Exception, this.constants.SomethingWentWrong);
        },
      });
  }
  AddSalesOrderLog() {
    this.sharedService.AddSalesOrderLog(this.ShippingDataById, 'S')
      .subscribe({
        next: (data: any) => {

        },
        error: (err) => {
          this.loader = false;
          this.constants.SwalModal(this.constants.Exception, this.constants.SomethingWentWrong);
        },
      });
  }
  AddUpdVarianceLog() {
    this.sharedService.AddUpdVarianceLog(this.form.value, 'S')
      .subscribe({
        next: (data: any) => {

        },
        error: (err) => {
          this.loader = false;
          this.constants.SwalModal(this.constants.Exception, this.constants.SomethingWentWrong);
        },
      });
  }
  SearchFilterList() {
    let List = this.shippingList.filter((li) => {
      switch (this.FilterListRiquest.FilterTypeId) {
        case 2:
          return (this.constants.SetHrs(li.orderDate, 0) >= this.constants.SetHrs(this.FilterListRiquest.FromDate, 0) && this.constants.SetHrs(li.orderDate, 0) <= this.constants.SetHrs(this.FilterListRiquest.ToDate, 1));
          break;
        case 3:
          return (this.constants.SetHrs(li.shippedDate, 0) >= this.constants.SetHrs(this.FilterListRiquest.FromDate, 0) && this.constants.SetHrs(li.shippedDate, 0) <= this.constants.SetHrs(this.FilterListRiquest.ToDate, 1));
          break;
        case 5:
          return li.shippingType.toLowerCase().includes(this.FilterListRiquest.Source.toLowerCase().trim());
          break;
        default:
          return (this.constants.ShipmentStatusFilterList.filter(s => s.statusId == this.FilterListRiquest.StatusIds)[0].id.some(y => y === li.statusId) &&
            this.constants.SetHrs(li.orderDate, 0) >= this.constants.SetHrs(this.FilterListRiquest.FromDate, 0) && this.constants.SetHrs(li.orderDate, 0) <= this.constants.SetHrs(this.FilterListRiquest.ToDate, 1));
          break;
      }
    });
    this.shippingDS = new MatTableDataSource(List);
  }
}