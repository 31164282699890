<div class="titlecstmsidebar">
    <h2 *ngIf="batchId == ''">Inventory History - P/N {{SKU}}</h2>
    <h2 *ngIf="batchId != ''">Batch Details - P/N {{SKU}}</h2>
    <h4>The detailed transactions for this part number are included below.</h4>
</div>
<div class="cstmbxdsis">
    <div class="scroll_cstmbx-era">
        <div class="col-12 select-arrow-change-icon">
            <div class="row align-items-center mb-3" *ngIf="batchId == ''">
                <div class="custom-column">
                    <div class="labelcstmhebx">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Action</mat-label>
                            <mat-select placeholder="Action" [(ngModel)]="HActionType">
                                <mat-option [value]="0">All Types</mat-option>
                                <mat-option *ngFor="let item of InvActionListFull"
                                    [value]="item.actionId">{{item.actionName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="custom-column">
                    <div class="labelcstmhebx">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Time Period</mat-label>
                            <mat-select placeholder="Time Period" [(ngModel)]="TimePeriod"
                                (selectionChange)="onTimePeriodChange($event)">
                                <mat-option [value]="0">Select One</mat-option>
                                <mat-option *ngFor="let item of TimePeriodList"
                                    [value]="item.id">{{item.value}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="custom-column">
                    <div class="labelcstmhebx">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>From</mat-label>
                            <input matInput [matDatepicker]="picker" [(ngModel)]="FromDate"
                                [max]="todayDate" placeholder="From Date" id="paymentDate"
                                [disabled]="TimePeriod==0">
                            <mat-datepicker-toggle matIconSuffix [for]="picker">
                                <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
                <div class="custom-column">
                    <div class="labelcstmhebx">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>To</mat-label>
                            <input matInput [matDatepicker]="picker1" [(ngModel)]="ToDate"
                                [max]="todayDate" placeholder="To Date" id="paymentDate1"
                                [disabled]="TimePeriod==0">
                            <mat-datepicker-toggle matIconSuffix [for]="picker1">
                                <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker1></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
                <div class="custom-column-button">
                    <div class="labelcstmhebx">
                        <button class="cancel-button" (click)="HistoryFilter()">
                            <span>Apply</span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="batchId != ''">
                <div class="col-lg-2 col-md-6 mb-3">
                    <div class="labelcstmhebx">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Action</mat-label>
                            <mat-select placeholder="Action" [(ngModel)]="HBActionType">
                                <mat-option [value]="0">All Types</mat-option>
                                <mat-option *ngFor="let item of InvActionListFull"
                                    [value]="item.actionId">{{item.actionName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-lg-2 col-md-6 mb-3">
                    <div class="labelcstmhebx">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Batch</mat-label>
                            <mat-select placeholder="Batch Id" [(ngModel)]="HBatch">
                                <mat-option [value]="0">Select One</mat-option>
                                <mat-option *ngFor="let item of HBatchList"
                                    [value]="item">{{GetBatch(item)}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-lg-2 mb-3 button-column">
                    <div class="labelcstmhebx">
                        <button class="cancel-button" (click)="BatchHistoryFilter()">
                            <span>Apply</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- --------------sidebar table ---------------- -->
    <div class="table-scroll mat-elevation-z8 master-table inventory-table box-shadow-none" *ngIf="invListHistory.length>0">
        <mat-table [dataSource]="invListHistory" class="table-inventory">
            <ng-container matColumnDef="action">
                <mat-header-cell *matHeaderCellDef class="inventory-table-header"> Action
                </mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="Action" class="inventory-mat-cell">
                    {{element.actionValue}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="reason">
                <mat-header-cell *matHeaderCellDef class="inventory-table-header"> Reason
                </mat-header-cell>
                <mat-cell *matCellDef="let element" class="inventory-mat-cell" data-label="Reason">
                    {{element.ReasonValue}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="description">
                <mat-header-cell *matHeaderCellDef class="inventory-table-header"> Description
                </mat-header-cell>
                <mat-cell *matCellDef="let element" class="inventory-mat-cell" data-label="description">
                    {{element.description}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="document">
                <mat-header-cell *matHeaderCellDef class="inventory-table-header"> Document
                </mat-header-cell>
                <mat-cell *matCellDef="let element" class="inventory-mat-cell" data-label="Document">
                    {{element.docNo}}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="qtyIn">
                <mat-header-cell *matHeaderCellDef class="inventory-table-header"> Qty In
                </mat-header-cell>
                <mat-cell *matCellDef="let element" class="inventory-mat-cell" data-label="Qty In">
                    {{element.qtyIn}}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="batchIn">
                <mat-header-cell *matHeaderCellDef class="inventory-table-header"> Batch In
                </mat-header-cell>
                <mat-cell *matCellDef="let element" class="inventory-mat-cell" data-label="Batch In">
                    {{element.transID}}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="qtyOut">
                <mat-header-cell *matHeaderCellDef class="inventory-table-header"> Qty Out
                </mat-header-cell>
                <mat-cell *matCellDef="let element" class="inventory-mat-cell" data-label="Qty Out">
                    {{element.qtyOut}}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="batchOut">
                <mat-header-cell *matHeaderCellDef class="inventory-table-header"> Batch Out
                </mat-header-cell>
                <mat-cell *matCellDef="let element" class="inventory-mat-cell" data-label="Batch Out">
                    {{element.outTransID}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="originalBalance">
                <mat-header-cell *matHeaderCellDef class="inventory-table-header"> Original Balance
                </mat-header-cell>
                <mat-cell *matCellDef="let element" class="inventory-mat-cell"
                    data-label="Original Balance">
                    {{element.origBalance}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="balance">
                <mat-header-cell *matHeaderCellDef class="inventory-table-header"> Balance
                </mat-header-cell>
                <mat-cell *matCellDef="let element" class="inventory-mat-cell" data-label="Balance">
                    <!-- {{element.balance}} -->
                    {{element.qtyIn - element.qtyOut}}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="unitCost">
                <mat-header-cell *matHeaderCellDef class="inventory-table-header"> Unit Cost
                </mat-header-cell>
                <mat-cell *matCellDef="let element" class="inventory-mat-cell" data-label="Unit Cost">
                    {{element.cost |
                    currency}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="channel">
                <mat-header-cell *matHeaderCellDef class="inventory-table-header"> Channel
                </mat-header-cell>
                <mat-cell *matCellDef="let element" class="inventory-mat-cell" data-label="Channel">
                    {{element.channel}}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="availability">
                <mat-header-cell *matHeaderCellDef class="inventory-table-header"> Availability
                </mat-header-cell>
                <mat-cell *matCellDef="let element" class="inventory-mat-cell"
                    data-label="Availability">
                    {{element.invStatusValue}} </mat-cell>
                    <!-- {{GetStatusName(element.invStatus)}} </mat-cell> -->
            </ng-container>
            <ng-container matColumnDef="location">
                <mat-header-cell *matHeaderCellDef class="inventory-table-header"> Location
                </mat-header-cell>
                <mat-cell *matCellDef="let element" class="inventory-mat-cell" data-label="Location">
                    <span>{{element.warehouseValue + ', ' +
                        element.locationValue + ', ' + element.location}} </span>
                    <!-- <span>{{GetWareHouseValue(element.warehouseID) + ', ' +
                        GetLocationValue(element.locationName) + ', ' + element.location}} </span> -->
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="dateTime">
                <mat-header-cell *matHeaderCellDef class="inventory-table-header"> Date Time
                </mat-header-cell>
                <mat-cell *matCellDef="let element" class="inventory-mat-cell" data-label="Date Time">
                    {{element.createdDate | date:'MM/dd/yyyy HH:mm:ssa'}} </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="invHistoryColumns sticky: true"
                class=" inventory-header"></mat-header-row>
            <mat-row *matRowDef="let row; columns: invHistoryColumns;"
                class="material-inventory-row"></mat-row>
        </mat-table>
    </div>

    <div class="footerbtnswrps">
        <button type="button" (click)="CloseViewHistory()" class="saveclosebnt">
            Close</button>
    </div>
</div>
