<form [formGroup]="Search">
    <app-loader [loader]="loader"></app-loader>
    <ng-container *ngIf="Data != undefined">
        <div class="row">
            <div class="col-md-2 mb-md-0 mb-sm-3 select-arrow-change-icon">
                <mat-form-field class="w-70" appearance="outline">
                    <mat-label>Filter Type</mat-label>
                    <mat-select formControlName="FilterTypeId" (selectionChange)="changeFilterTypeId($event.value)">
                        <mat-option style="width: 227px" *ngFor="let item of Data.FilterTypeList"
                            [value]="item.id">{{item.value}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-2 mb-md-0 mb-sm-3 select-arrow-change-icon" *ngIf="Search.value.FilterTypeId == 1 || Search.value.PageName == 'FFilter'">
                <mat-form-field class="w-70" appearance="outline">
                    <mat-label>Status Filter</mat-label>
                    <mat-select formControlName="StatusIds" multiple="{{Data.PageName == 'SOFilter'}}">
                        <mat-option style="width: 227px" *ngFor="let item of StatusList"
                            [value]="item.statusId">{{item.statusName}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <ng-container *ngIf="Search.value.FilterTypeId <=3">
                <div class="col-md-2 mb-md-0 mb-sm-3 select-arrow-change-icon">
                    <mat-form-field class="w-70" appearance="outline">
                        <mat-label>Time Period</mat-label>
                        <mat-select formControlName="TimePeriodId" (selectionChange)="changeTimePeriod($event.value)">
                            <mat-option style="width: 227px" *ngFor="let item of constants.TimePeriod"
                                [value]="item.id">{{item.value}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <ng-container>
                    <div class="col-md-2 col-6 mb-sm-3 align-items-center">
                        <mat-form-field class="w-70" appearance="outline">
                            <mat-label>From</mat-label>
                            <input matInput [min]="minFromDate" [max]="maxFromDate" [matDatepicker]="frompicker"
                                (dateInput)="fromDateChange($event.value)" readonly formControlName="FromDate">
                            <mat-datepicker-toggle matSuffix [for]="frompicker"
                                [disabled]="Search.value.TimePeriodId != 6"></mat-datepicker-toggle>
                            <mat-datepicker #frompicker></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-md-2 col-6 mb-sm-3">
                        <mat-form-field class="w-70" appearance="outline">
                            <mat-label>To</mat-label>
                            <input matInput [min]="minToDate" [max]="maxToDate" [matDatepicker]="topicker"
                                (dateInput)="ToDateChange($event.value)" readonly formControlName="ToDate">
                            <mat-datepicker-toggle matSuffix [for]="topicker"
                                [disabled]="Search.value.TimePeriodId != 6 || Search.value.FromDate == null"></mat-datepicker-toggle>
                            <mat-datepicker #topicker></mat-datepicker>
                        </mat-form-field>
                    </div>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="Search.value.FilterTypeId == 4">
                <div class="col-md-2 col-6 mb-sm-3">
                    <mat-form-field class="w-70" appearance="outline">
                        <mat-label>Customer</mat-label>
                        <mat-select formControlName="CustomerIds" multiple>
                            <mat-option style="width: 227px" *ngFor="let c of AllCustomerList"
                                [value]="c.id">{{c.customerName}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </ng-container>
            <ng-container *ngIf="Search.value.FilterTypeId == 5">
                <div class="col-md-4 col-6 mb-sm-4">
                    <mat-form-field class="w-100" appearance="outline" style="margin-bottom: 14px;">
                        <span class="material-icons">
                            search
                        </span>
                        <input class="w-100" formControlName="Source" matInput type="text" autocomplete="off"
                            placeholder="Search by {{Source()}}" #input>
                    </mat-form-field>
                </div>
            </ng-container>
            <div class="col-md-2 col-12  d-flex align-items-center justify-content-between">
                <button mat-button color="primary" class="btn cancel-btn update-btn me-2"
                    (click)="Apply()">Apply</button>
            </div>
        </div>
    </ng-container>
</form>