<section class="content-section">
    <div class="loading lodingtext" *ngIf="loader">
        <p>{{loadingTxt}}</p>
    </div>
    <!-- <div class="loading" *ngIf="loader">Uploading File.. &#8230;</div> -->


    <div col-sm-12>
        <div class="row">
            <div class="col-6 mb-2 d-flex align-items-baseline text-xs-left">
                <div class="page-title">
                    <div class="bread-crumb">
                        <a [routerLink]="['/adminDashboard/']">Home</a>
                        <span class="mx-1">-</span>
                        <span class="active">Utility</span>
                    </div>
                </div>
            </div>
            <div class="col-6 mb-2 text-right align-items-baseline">
            </div>
        </div>
    </div>


    <!-- ankit implementaion start -->

    <section class="">
        <div class="row mb-3">
            <div class="col-md-12"><span class="utilityHeading">Manually Import using Excel</span></div>
        </div>
        <div class="row">
            <div class="col-md-2">
                <mat-form-field class="form-field" appearance="outline">
                    <mat-label>Section Name</mat-label>
                    <mat-select [(ngModel)]="sectionType" (selectionChange)="changetype($event)">
                        <mat-option>None</mat-option>
                        <mat-option value="1">Vendor</mat-option>
                        <mat-option value="2">Customer</mat-option>
                        <mat-option value="3">Part</mat-option>
                        <mat-option value="4">Inventory</mat-option>
                    </mat-select>
                    <div *ngIf="((sectionType==0 ||sectionType==undefined) && (validation))"
                        class="text-danger input-error err-fontSize">Select Section</div>

                </mat-form-field>
            </div>
            <div class="col-md-2">
                <a class="customLabel" style="color: blue; text-decoration: underline;" *ngIf="sectionType!=0"
                    href="{{downloadFileUrl}}">{{downloadSampleName}}</a>
            </div>
            <div class="col-md-3"></div>
            <div class="col-md-3">
                <input type="file" class="common-button-upload"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    (change)="ChngSettingImage($event.target.files)" id="file" name="file">
                <div>
                    <span class="text-danger input-error err-fontSize">{{errorMsg}}</span>
                </div>
            </div>
            <div class="col-md-2">
                <button class="common-button-utility cstm_chng_btns" [disabled]="!fileToUpload"
                    (click)="uploadFiles()"><i class="fas fa-upload text-light"
                        aria-hidden="true"></i>&nbsp;&nbsp;Upload</button>
            </div>
        </div>
    </section>

    <hr>

    <section class="">
        <div class="row mb-3">
            <div class="col-md-12"><span class="utilityHeading">Sync with Wordpress via API's</span></div>
        </div>

        <div class="row">
            <div class="col-md-4">
                <form class="part-form d-flex" [formGroup]="form">
                    <mat-form-field class="form-field" appearance="outline">
                        <mat-label>Action<span style="color:red">*</span></mat-label>
                        <mat-select formControlName="action" (selectionChange)="OnSyncChanges($event)">
                            <mat-option>None</mat-option>
                            <mat-option value="1">Import from Wordpress</mat-option>
                            <mat-option value="2">Export to Wordpress</mat-option>
                        </mat-select>
                        <mat-error *ngIf="form.get('action').hasError('required')">
                            Please fill out this field.
                        </mat-error>
                    </mat-form-field>

                    <button class="common-button-utility cstm_chng_btns" (click)="OnSyncClick()"><i
                            class="fas fa-sync-alt text-light" aria-hidden="true"></i>&nbsp;&nbsp;Sync</button>
                </form>
            </div>
            <div class="col-md-4" style="width: 250px;">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Search</mat-label>
                    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
                </mat-form-field>

            </div>
            <div class="col-md-2 d-flex justify-content-end utility-pageno">

                

            </div>
        </div>

    </section>

    <!-- class="mat-elevation-z8" -->

    <div class="card-desc example-container cstm_scrollbar_theme company-table">

        <table mat-table matSort [dataSource]="dataSource" class="mat-elevation-z8 bxshadow-none mb-0 text-left">

            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                    <mat-checkbox (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllPartsSelected()"
                        [indeterminate]="selection.hasValue() && !isAllPartsSelected()">
                    </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row">
                    <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)">
                    </mat-checkbox>
                </td>
            </ng-container>

            <ng-container matColumnDef="sku">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> List Part Number </th>
                <td mat-cell *matCellDef="let row"> {{row.sku}} </td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> List Part Name </th>
                <td mat-cell *matCellDef="let row" style="max-width: 400px;"> {{row.name}} </td>
            </ng-container>

            <ng-container matColumnDef="exist_erp">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> WP ID </th>
                <td mat-cell *matCellDef="let row">
                    <span *ngIf="row.exist_erp">
                        {{row.id}}
                    </span>
                    <span *ngIf="row.exist_erp ==false" class="text-danger">
                        {{row.id}} <br>
                        Missing in ERP
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="ecomm">
                <th mat-header-cell *matHeaderCellDef> eCommerce </th>
                <td mat-cell *matCellDef="let row">
                    <mat-checkbox [checked]="row.ecomm" disabled={true}></mat-checkbox>
                </td>
            </ng-container>

            <ng-container matColumnDef="active">
                <th mat-header-cell *matHeaderCellDef> Active </th>
                <td mat-cell *matCellDef="let row">
                    <mat-checkbox [checked]="row.active" disabled={true}></mat-checkbox>
                </td>
            </ng-container>

            <ng-container matColumnDef="inv">
                <th mat-header-cell *matHeaderCellDef> Inventory </th>
                <td mat-cell *matCellDef="let row">
                    <mat-checkbox [checked]="row.inv" disabled={true}></mat-checkbox>
                </td>
            </ng-container>

            <ng-container matColumnDef="LastModified">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Modified </th>
                <td mat-cell *matCellDef="let row" [style.color]="row.color"> {{row.lastModified}} </td>
            </ng-container>

            <ng-container matColumnDef="LastSyncd">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Sync'd </th>
                <td [style.color]="(row.lastSyncd==row.lastModified || row.lastSyncd == row.lastWPModified) ? 'black': 'red'"
                    [ngClass]="row.lastSyncd=='' ? 'LasSyncIdCellBorderBottomRed': ''" mat-cell *matCellDef="let row">
                    {{row.lastSyncd}}</td>
            </ng-container>
            <ng-container matColumnDef="LastWPModified">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> WP Last Modified </th>
                <td mat-cell *matCellDef="let row" [style.color]="row.color"> {{row.lastWPModified}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns;  sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;">
            </tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="10" class="error text-center text-danger">
                    No data found !
                </td>
            </tr>
        </table>

        <!-- <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator> -->
    </div>



    <!-- ankit implementaion end -->

</section>
<div class="modal" id="myModal">
    <div class="modal-dialog">
        <div class="modal-content">
            <h4 class="modal-title darkred-bg">Summary</h4>
            <button type="button" class="close btn" (click)="CloseModalpopup()" data-dismiss="modal"
                style="margin-top: -6px;">&times;</button>

            <div class="modal-body">
                <!-- <label class="control-label">Total number of records in excel/csv:
                    {{this.summaryRecords?.length}}</label><br> -->
                <label class="control-label">Total number of records succesfully inserted: {{summarySuccesfull?.length}}
                    <!-- <i class="fas fa-info" aria-hidden="true" (click)="Preview1Details()"></i>
                    <div *ngIf="isSummarySuccessRecordShow">
                        <div *ngFor="let data of summarySuccesfull">
                            <span> Row Id:{{data.id}}</span>
                        </div>
                    </div> -->
                </label><br>
                <label class="control-label">Total number of records to be Updated:
                    {{summaryUpdateSuccesfull?.length}}</label><br>

                <label class="control-label">Total number of records to unsuccesfully uploded:
                    {{summaryUnsuccesfull?.length}}

                    <i class="fa fa-info-circle" *ngIf="summaryUnsuccesfull?.length != 0" aria-hidden="true"
                        (click)="Preview1Details()"></i>
                </label>
            </div>
            <div class="modal-footer pb-1 pt-1">
                <button type="button" class="btn red-bg round-btn m-0" (click)="CloseModalpopup()"
                    data-dismiss="modal">OK</button>
            </div>

        </div>
    </div>
</div>

<div class="modal" id="myModal2">
    <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
            <h4 class="modal-title darkred-bg">Unsuccesfully Uploded</h4>
            <button type="button" class="close btn" (click)="CloseModalpopup2()" data-dismiss="modal"
                style="margin-top: -6px;">&times;</button>

            <div class="modal-body">
                <!-- <label class="control-label">Row Id</label> -->
                <div *ngIf="isSummaryUnSuccessRecordShow">
                    <!-- <div *ngFor="let data of summaryUnsuccesfull">
                        <span>Row id: {{data.id}}</span>
                    </div> -->
                    <table class='table'>
                        <thead>
                            <tr>
                                <th>Row Number</th>
                                <th>Upload Error Msg</th>
                                <th>Error Msg</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of summaryRecords;">
                                <td>{{item.id}}</td>
                                <td>{{item.uploadErrorMsg}}</td>
                                <td>{{item.customMsg}}</td>
                            </tr>
                        </tbody>
                    </table>


                </div>
            </div>
            <div class="modal-footer pb-1 pt-1">
                <button type="button" class="btn red-bg round-btn m-0" (click)="CloseModalpopup2()"
                    data-dismiss="modal">OK</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="add_success">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="addsuccess()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You!</h1>
                    <p>Record Added Successfully</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="addsuccess()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="add_update">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="updateitem()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You!</h1>
                    <p>Record Update Successfully</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="updateitem()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="delete_success">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="deleteitem()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You!</h1>
                    <p>Record Deleted Successfully</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="deleteitem()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="wrong_msg">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="msgitem()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p>Something went wrong</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="msgitem()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>