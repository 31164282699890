import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConstantsService } from 'src/app/constants.service';
import { CompanyService } from 'src/app/services/company/company.service';
import { InventoryService } from 'src/app/services/inventory/inventory.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-trans-history-flyout',
  templateUrl: './trans-history-flyout.component.html',
  styleUrls: ['./trans-history-flyout.component.css','../../inventory-details/inventory-details.component.css']
})
export class TransHistoryFlyoutComponent implements OnInit{
  @Input() SKU: string = '';
  @Input() batchId: string = '';
  @Input() companyId: number = 0;
  @Input() partId: number = 0;
  @Input() invListDetails: any = [];
  @Input() invListHistory: any = [];
  @Input() HBatchList: any = [];
  @Input() HBatch: any;
  @Output() closeEvent = new EventEmitter<boolean>();
  todayDate = new Date();
  invHistoryColumns: string[] = this.constants.invHistoryColumns;
  HActionType: number = 0;
  TimePeriod: number = 0;
  FromDate: any;
  ToDate: any;
  HBActionType: number = 0;
  InvActionListFull: any = [];
  InvActionList: any = [];
  TimePeriodList: any = this.constants.CustomDate;

  constructor(private constants: ConstantsService, private datePipe: DatePipe, private inventoryService: InventoryService, private companyService: CompanyService, private sharedService: SharedService){
    this.resetHistoryFilters();
    this.GetInventoryAction();
  }

  ngOnInit(): void {
    
  }

  GetBatch(trackingId): string {
    const indexOfDecimal = trackingId.indexOf('.');
    if (indexOfDecimal !== -1) {
      return trackingId.substring(0, indexOfDecimal);
    }
    return trackingId;
  }

  CloseViewHistory(){
    this.closeEvent.emit(false);
  }

  HistoryFilter() {
    if (this.HActionType === Number(0)) {
      this.invListHistory = this.invListDetails.filter(item =>
      (
        this.FromDate != '' ? (this.datePipe.transform(item.createdDate, 'yyyy-MM-dd') >= this.datePipe.transform(this.FromDate, 'yyyy-MM-dd')) : (item.createdDate) &&
          this.ToDate != '' ? (this.datePipe.transform(item.createdDate, 'yyyy-MM-dd') <= this.datePipe.transform(this.ToDate, 'yyyy-MM-dd')) : (item.createdDate)
      )
      );
    } else if (this.TimePeriod === Number(0)) {
      this.invListHistory = this.invListDetails.filter(item =>
        (item.action == this.HActionType)
      );
    } else {
      this.invListHistory = this.invListDetails.filter(item =>
      (
        this.FromDate != '' ? (this.datePipe.transform(item.createdDate, 'yyyy-MM-dd') >= this.datePipe.transform(this.FromDate, 'yyyy-MM-dd')) : (item.createdDate) &&
          this.ToDate != '' ? (this.datePipe.transform(item.createdDate, 'yyyy-MM-dd') <= this.datePipe.transform(this.ToDate, 'yyyy-MM-dd')) : (item.createdDate)
      )
      ).filter(item => item.action == this.HActionType);
    }
  }

  resetHistoryFilters() {
    this.HActionType = 0;
    this.TimePeriod = 0;
    this.FromDate = '';
    this.ToDate = '';
    this.HBActionType = 0;
    this.HBatch = '';
  }

  BatchHistoryFilter() {
    const batch = this.HBatch != '' ? this.GetBatch(this.HBatch) : this.HBatch;
    if (this.HBActionType === Number(0)) {
      this.invListHistory = this.invListDetails.filter(item =>
        item.transID.startsWith(batch)
      );
    } else {
      this.invListHistory = this.invListDetails.filter(item =>
        item.transID.startsWith(batch) &&
        item.action == this.HBActionType
      );
    }
  }

  GetInventoryAction() {
    this.inventoryService.GetInventoryAction().subscribe(
      (data: any) => {
        this.InvActionListFull = data.data;
        const actionIds = [1, 4, 9, 10];
        this.InvActionList = this.InvActionListFull.filter(item => actionIds.includes(item.actionId));
      }
    )
  }

  onTimePeriodChange(event: any) {
    const today = this.todayDate;
    const year = today.getFullYear();
    const todayMinus30DaysDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - 30
    );
    const todayMinus60DaysDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - 60
    );
    const todayMinus90DaysDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - 90
    );
    const ytdStartDate = new Date(year, 0, 1);

    switch (event.value) {
      case 1:
        this.ToDate = today;
        this.FromDate = todayMinus30DaysDate;
        break;
      case 2:
        this.ToDate = today;
        this.FromDate = todayMinus60DaysDate;
        break;
      case 3:
        this.ToDate = today;
        this.FromDate = todayMinus90DaysDate;
        break;
      case 4:
        this.ToDate = today;
        this.FromDate = ytdStartDate;
        break;
      case 4:
        this.ToDate = today;
        this.FromDate = '';
        break;
      default:
        this.ToDate = '';
        this.FromDate = '';
        break;
    }
  }
}
